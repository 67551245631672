import React from "react";
import styles from "./MenuBar.module.scss";

const MenuBar = ({ _setLayerType, _setLayerClick, _themeColor }) => {
  const onChecked1 = (e) => {
    const { checked, value } = e.target;
    _setLayerType((prev) => ({
      ...prev,
      checked1: value,
      active1: checked,
      activeCount: checked
        ? [...prev.activeCount, value]
        : prev.activeCount.filter((item) => item !== value),
    }));
    _setLayerClick(null);
  };
  const onChecked2 = (e) => {
    const { checked, value } = e.target;
    _setLayerType((prev) => ({
      ...prev,
      checked2: value,
      active2: checked,
      activeCount: checked
        ? [...prev.activeCount, value]
        : prev.activeCount.filter((item) => item !== value),
    }));
    _setLayerClick(null);
  };
  const onChecked3 = (e) => {
    const { checked, value } = e.target;
    _setLayerType((prev) => ({
      ...prev,
      checked3: value,
      active3: checked,
      activeCount: checked
        ? [...prev.activeCount, value]
        : prev.activeCount.filter((item) => item !== value),
    }));
    _setLayerClick(null);
  };

  return (
    <div className={`${styles.container}`}>
      <h5
        className={`${styles.title_text}`}
        style={{ backgroundColor: _themeColor?.backgroundColor }}
      >
        ชั้นข้อมูล
      </h5>
      <div className={`${styles.group_box}`}>
        <div className={`${styles.box}`}>
          <div className={`${styles.check_box}`}>
            <input
              type="checkbox"
              name="factory"
              value="factory"
              id="checkMenu1"
              className={_themeColor?.class}
              onChange={onChecked1}
              />
          </div>
          <label htmlFor="checkMenu1">ข้อมูลโรงไฟฟ้า</label>
        </div>
        <div className={`${styles.box}`}>
          <div className={`${styles.check_box}`}>
            <input
              type="checkbox"
              name="enterprise"
              value="enterprise"
              id="checkMenu2"
              className={_themeColor?.class}
              onChange={onChecked2}
              />
          </div>
          <label htmlFor="checkMenu2">ข้อมูลกลุ่มวิสาหกิจชุมชน</label>
        </div>
        <div className={`${styles.box}`}>
          <div className={`${styles.check_box}`}>
            <input
              type="checkbox"
              name="crop"
              value="crop"
              id="checkMenu3"
              className={_themeColor?.class}
              onChange={onChecked3}
            />
          </div>
          <label htmlFor="checkMenu3">ข้อมูลการเพาะปลูก(ปัจจุบัน)</label>
        </div>
      </div>
    </div>
  );
};

export default MenuBar;
