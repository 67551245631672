import $ from 'jquery';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { defaultApi } from '../../../services/api';
import Message from '../../../utils/message/Message';
import { EditSubGrp01, EditSubGrp02 } from '../../../utils/validation_input/Validation';
import styles from '../scss/Popupform.module.scss';
import { QueryGetAll } from '../../../helpers/api.helper';

export const FormManageSubgrp2 = ({ datain, infoDatax, idForm }) => {

    /**
     * subname: detail.name,orgname: getOrgName(detail.org)
     */

    const [txtInput01, settxtInput01] = useState("")
    const [txtInput02, settxtInput02] = useState("")

    const [validErr01, setValidErr01] = useState(false);
    //const [validErr02, setValidErr02] = useState(false);

    const [_defaultLcoID, defaultLcoID] = useState([])
    const [_defaultOrg, defaultOrg] = useState([])
    const [_defaultLocalGrp, defaultLocalGrp] = useState([])

    const infoData = useCallback(async (id) => {
        try {

            // if (datain.id !== undefined) {
            //     let qryGetAll = {
            //         $count: "%24count=true",
            //         $expand: "",
            //         $top: "",
            //         $skip: ``,
            //         $filter: ``,
            //         $orderby: "",
            //     };

            //     const _query = QueryGetAll(qryGetAll);

            //     const resLcoId = await defaultApi.getLocalGrpId(id, '')
            //     defaultLcoID(resLcoId.data);
            //     console.log(resLcoId);
            // }

            // let qryGetById = {
            //     $expand: "%24expand=userGroup%2CuserLocalGroup%2CuserOrg",
            //     $filter: "",
            //     $orderby: "",
            // };

            // const _query = QueryGetByID(qryGetById)

            const resLcoId = await defaultApi.getLocalGrpId(id)
            defaultLcoID(resLcoId.data);

            const resOrg = await defaultApi.getOrg();
            const resLocalGrp = await defaultApi.getLocalGrp();

            defaultOrg(resOrg.data);
            defaultLocalGrp(resLocalGrp.data);


        } catch (error) {
            console.log(error);
        }
    }, [datain])

    const onChangeGrp = (val) => {
        settxtInput01(val)
        if (EditSubGrp01(val, _defaultLocalGrp) === null) {
            setValidErr01(false)
        } else {
            setValidErr01(true)
        }
    }

    const onChangeOrg = (val) => {
        settxtInput02(val)
        if (EditSubGrp02(val, _defaultOrg) === null) {
            // setValidErr02(false)
        } else {
            // setValidErr02(true)
        }
    }

    const onSeleteOrg = (val) => {
        settxtInput02(val)
        // if (EditSubGrp02(val, _defaultOrg) === null) {
        //     setValidErr02(false)
        // } else {
        //     setValidErr02(true)
        // }
    }

    // const onSeleteGrp = (val) => {
    //     settxtInput01(val)
    //     if (EditSubGrp01(val, _defaultLocalGrp) === null) {
    //         setValidErr01(false)
    //     } else {
    //         setValidErr01(true)
    //     }
    // }

    const submitEdit = async (afterClick) => {

        // const chkDataId01 = () => {
        //     let i = null
        //     _defaultOrg.map((data) => {
        //         if (data.name === txtInput02) {
        //             i = data.id
        //         }
        //     })
        //     return i
        // }

        //&& validErr02 === false
        if (validErr01 === false) {

            // let grpId = null
            // grpId = chkDataId()
            const loading = toast.loading(Message.notify.update_loading);
            //&& chkDataId01() !== null
            const params = {}

            await Object.keys(_defaultLcoID).forEach((key) => {
                switch (key) {
                    case "id":
                        params["id"] = datain.id
                        break;
                    case "name":
                        params["name"] = txtInput01 === "" ? datain.subname : txtInput01
                        break;
                    case "orgId":
                        params["orgId"] = txtInput02 === "" ? Number(datain.orgname) : Number(txtInput02)
                        break;
                    default:
                        params[key] = _defaultLcoID[key]
                        break;
                }
            })

            try {
                await defaultApi.updateLocalGroup(datain.id, params)
                toast.success(Message.notify.update_success, { duration: 3000 });
                infoDatax()
                $('#' + afterClick).trigger('click');
            } catch (error) {
                toast.error(Message.notify.update_error, { duration: 3000 });
                console.log(error)
            } finally {
                toast.dismiss(loading);
            }

        }
    }

    useEffect(() => {
        if (Number.isInteger(datain.id)) {
            infoData(datain.id)
        }
    }, [infoData, datain.id])

    return (
        <div
            className="modal fade"
            id={idForm}
            //key={"modal" + idForm}
            //id="exampleModal2"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"

        //className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <h1 className={`modal-title mt-3 fs-5 ${styles.h5}`} id="exampleModalLabel">แก้ไขกลุ่มผู้ใช้งานย่อย</h1>
                        <hr className={`w-100 me-1 ms-1 border border-dark-subtle`} />
                    </div>
                    <div className="modal-body ">
                        <form id={"form" + idForm} className={`${styles.form_} mx-auto`}>
                            <div className={`${styles.input_flex}`}>
                                <div className={`${styles.box_error} d-flex flex-row justify-content-between m-2 ${styles.input_}`}>
                                    <h5 className={`col-4 ${styles.h5}`}>กลุ่มผู้ใช้งานย่อย : </h5>
                                    {/* <Autocomplete
                                    id="autoCom01"
                                    inputProps={validErr01 === false ? { style: { "width": "100%" } } : { style: { "width": "100%", "border": "2px solid red" } }}
                                    wrapperStyle={{ "width": "100%" }}
                                    getItemValue={(item) => item.name}
                                    items={_defaultLocalGrp}
                                    shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                    renderItem={(item, isHighlighted) =>
                                        <div key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                            {item.name}
                                        </div>
                                    }
                                    value={txtInput01}
                                    //value={txtInput01 === "" ? datain.subname : txtInput01}
                                    onChange={(e) => { onChangeGrp(e.target.value) }}
                                    onSelect={(val) => { onSeleteGrp(val) }}
                                /> */}
                                    <input className={`col ${validErr01 && txtInput01 === "" ? styles.error : null}`}
                                        id={"input1" + idForm}
                                        //id="input1"
                                        type="text"
                                        defaultValue={datain.subname}
                                        onChange={(e) => { onChangeGrp(e.target.value) }}
                                        //value={txtInput01 === "" ? datain.subname : txtInput01}
                                        name="subgrpname" />
                                </div>
                                {validErr01 && (
                                    <div className={`${styles.box_error} w-100 d-flex justify-content-center`}>
                                        <small className={`${styles.err_message}`}>
                                            {EditSubGrp01(txtInput01, _defaultLocalGrp)}
                                        </small>
                                    </div>
                                )}

                                <div className={`${styles.box_error} d-flex flex-row justify-content-between m-2 ${styles.input_}`}>
                                    <h5 className={`col-4 ${styles.h5}`}>หน่วยงาน : </h5>
                                    {/* <Autocomplete
                                    inputProps={validErr02 === false ? { style: { "width": "100%" } } : { style: { "width": "100%", "border": "2px solid red" } }}
                                    wrapperStyle={{ "width": "100%" }}
                                    getItemValue={(item) => item.name}
                                    items={_defaultOrg}
                                    shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                    renderItem={(item, isHighlighted) =>
                                        <div key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                            {item.name}
                                        </div>
                                    }
                                    value={txtInput02}
                                    //value={txtInput02 === "" ? datain.orgname : txtInput02}
                                    onChange={(e) => { onChangeOrg(e.target.value) }}
                                    onSelect={(val) => { onSeleteOrg(val) }}
                                /> */}
                                    <select
                                        //key={"select1" + idForm}
                                        id={"select1" + idForm}
                                        style={{ "width": "100%" }}
                                        value={txtInput02 === "" ? datain.orgname : txtInput02}
                                        onChange={(e) => { onChangeOrg(e.target.value) }}
                                        onSelect={(e) => { onSeleteOrg(e.target.value) }}
                                    >
                                        {
                                            _defaultOrg.map((data, index) => {
                                                return <option key={`org${index}`} value={data.id}>{data.name}</option>
                                            })
                                        }
                                    </select>
                                    {/* <input className={`col ${validErr02 && txtInput02 === "" ? styles.error : null}`}
                                    style={{ "display": "none" }}
                                    id="input2"
                                    type="text"
                                    value={txtInput02}
                                    //value={txtInput02 === "" ? datain.orgname : txtInput02}
                                    name="orgname" /> */}
                                </div>
                                {/* {validErr02 && (
                                <div className={`${styles.box_error} w-100 d-flex justify-content-center`}>
                                    <small className={`${styles.err_message}`}>
                                        {EditSubGrp02(txtInput02, _defaultOrg)}
                                    </small>
                                </div>
                            )} */}
                            </div>
                        </form>
                    </div>
                    <div className={`d-flex justify-content-center mb-3 ${styles.boxpopup}`}>
                        <button className={`${styles.savechangebtn}`} onClick={() => { submitEdit("btnCloseForm2") }}><h4>บันทึก</h4></button><h4>|</h4><button id="btnCloseForm2" className={`${styles.savechangebtn}`} onClick={() => { settxtInput01(""); settxtInput02(""); $("#form" + idForm).trigger('reset') }} data-bs-dismiss="modal"><h4>ยกเลิก</h4></button>
                    </div>
                </div>
            </div>
        </div>
    )
}