export const showPass = () => {
  const oldPassword = document.querySelector("#oldPassword");

  const type_ =
    oldPassword.getAttribute("type") === "password" ? "text" : "password";
  oldPassword.setAttribute("type", type_);
};

export const showPassCf = () => {
  const newPassword = document.querySelector("#newPassword");

  const type_ =
    newPassword.getAttribute("type") === "password" ? "text" : "password";
  newPassword.setAttribute("type", type_);
};
