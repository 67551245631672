import React, { useCallback, useEffect, useMemo, useState } from "react";
import Footer from "../../../layouts/footer_2/Footer";
import Header from "../../../layouts/header/Header";
import Navbar from "../../../layouts/navbar/Navbar";
import useSwitchContent from "../../../utils/hooks/useSwitchContent";
import FarmerInfo from "./FarmerInfo";
import MenuBar from "./MenuBar";
import PowerPlantsInfo from "./PowerPlantsInfo";
//import "./scss/_cssContent.scss";
import useSwitchPath from "../../../utils/hooks/useSwitchPath";
import CashflowInfo from "./CashflowInfo";
import MemberInfo from "./MemberInfo";
import ProducePlantInfo from "./ProducePlantInfo";
import ProduceRateInfo from "./ProduceRateInfo";
import styles from "./scss/AdminReport.module.scss";
import { getProfileData } from "../../../services/auth.slice";
import { useSelector } from "react-redux";
import ResourceHarvest from "./ResourceHarvest";
import { defaultApi } from "../../../services/api";
import { QueryGetAll } from "../../../helpers/api.helper";

//import { useSelector } from "react-redux";
//import { getProfileData } from "../../../services/auth.slice";


const AdminReport = () => {
  //const profileData = useSelector(getProfileData);
  const [switchContent, onClickType] = useSwitchContent();

  const [location, navigate] = useSwitchPath();

  const profile_ = useSelector(getProfileData);

  const [_lstAllTypeFactory, setLstAllTypeFactory] = useState([])
  const [_lstAllFactory, setLstAllFactory] = useState([])
  const [_allFactory, setAllFactory] = useState({id:-99, name:''})
  const [_allType, setAllType] = useState({id:-99, name:''})

  const QFactoryAll = useCallback(async () => {
    try {
      let _query = {
        $count: "%24count=true",
        $expand: "",
        $top: "",
        $skip: ``,
        $filter: Number(_allType?.id) === -99 ? "" : `&%24filter=factoryTypeId eq ${_allType?.id}`,
        $orderby: "",
      };

      const query = QueryGetAll(_query);

      const res_1 = await defaultApi.getFactoryType(``)
      const res_2 = await defaultApi.getAllFactory(query)
      if (res_1?.status === 200 && res_2?.status === 200) {
        setLstAllTypeFactory([{ id: -99, name: 'เลือกประเภทโรงไฟฟ้า' }, ...res_1?.data])
        setLstAllFactory([{ id: -99, name: 'เลือกโรงไฟฟ้า' }, ...res_2?.data?.datas])
      }
    } catch (error) {

    }
  }, [_allType])

  const changeThemeByRole = useMemo(() => {
    const role = profile_?.userGroupId;
    let theme;

    if (role === 3) {
      theme = {
        color: '#2f9ced',
        backgroundColor: '#2f9ced',
        class: "gov",
      };
    } else {
      theme = "";
    }

    return theme;
  }, [profile_]);

  const DropdownType = (_lstAllTypeFactory, _lstAllFactory, _allFactory, setAllFactory, _allType, setAllType) => {
    return <div style={{ position: 'relative', padding:'0 7px', width: '100%', display: 'flex', flexWrap:true, gap: '6px' }}>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'center', gap: '15px', height: '45px' }}>
        <select
          style={{
            width: "100%",
            height: "100%",
            fontSize: "16px",
            textAlign: "center",
            color: "#000",
            border: "1px solid #d9d9d9",
            borderRadius: "10px",
            outline: "none"
          }}
          onChange={(e) => { setAllType(_lstAllTypeFactory.find((item)=> +item?.id === +e.target.value)); setAllFactory(_lstAllFactory.find((item)=> +item?.id === -99)) }}
          // onChange={(e) => { setAllType(e.target.value); setAllFactory(-99) }}
          value={_allType?.id}
          >
          {_lstAllTypeFactory?.map((data) => <option value={data?.id} >{data?.name}</option>)}
        </select>
      </div>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center', gap: '15px', height: '45px' }}>
        <select style={{
          width: "100%",
          height: "100%",
          fontSize: "16px",
          textAlign: "center",
          color: "#000",
          border: "1px solid #d9d9d9",
          borderRadius: "10px",
          outline: "none"
        }}
          onChange={(e) => { setAllFactory(_lstAllFactory.find((item)=> +item?.id === +e.target.value)) }}
          // onChange={(e) => { setAllFactory(e.target.value) }}
          value={_allFactory?.id}
        >
          {_lstAllFactory?.map((data) => <option value={data?.id} >{data?.name}</option>)}
        </select>
      </div>
    </div >
  }

  const chk_navagate = (switchContent, location, _allFactory, _allType) => {

    let pathComponent = null;

    switch (location) {
      case "/admin/report/farmerinfo":
        pathComponent = <FarmerInfo swCotent={switchContent.type} _theme={changeThemeByRole} barFact={_allFactory} typeFact={_allType} />
        break;
      case "/admin/report/memberinfo":
        pathComponent = <MemberInfo swCotent={switchContent.type} _theme={changeThemeByRole} barFact={_allFactory} typeFact={_allType} />
        break;
      case "/admin/report/cashflowinfo":
        pathComponent = <CashflowInfo swCotent={switchContent.type} _theme={changeThemeByRole} barFact={_allFactory} typeFact={_allType} />
        break;
      case "/admin/report/plan_product_plantinfo":
        pathComponent = <ProducePlantInfo swCotent={switchContent.type} _theme={changeThemeByRole} barFact={_allFactory} typeFact={_allType} />
        break;
      case "/admin/report/produce_rateinfo":
        pathComponent = <ProduceRateInfo swCotent={switchContent.type} _theme={changeThemeByRole} barFact={_allFactory} typeFact={_allType} />
        break;
      case "/admin/report/produce_planinfo":
        pathComponent = <PowerPlantsInfo swCotent={switchContent.type} _theme={changeThemeByRole} barFact={_allFactory} typeFact={_allType} />
        break;
      case "/admin/report/resource-harvest-3":
        pathComponent = <ResourceHarvest _theme={changeThemeByRole} barFact={_allFactory} typeFact={_allType} />
        break;
      case "/report/farmerinfo":
        pathComponent = <FarmerInfo swCotent={switchContent.type} _theme={changeThemeByRole} barFact={_allFactory} typeFact={_allType} />
        break;
      case "/report/memberinfo":
        pathComponent = <MemberInfo swCotent={switchContent.type} _theme={changeThemeByRole} barFact={_allFactory} typeFact={_allType} />
        break;
      case "/report/cashflowinfo":
        pathComponent = <CashflowInfo swCotent={switchContent.type} _theme={changeThemeByRole} barFact={_allFactory} typeFact={_allType} />
        break;
      case "/report/plan_product_plantinfo":
        pathComponent = <ProducePlantInfo swCotent={switchContent.type} _theme={changeThemeByRole} barFact={_allFactory} typeFact={_allType} />
        break;
      case "/report/produce_rateinfo":
        pathComponent = <ProduceRateInfo swCotent={switchContent.type} _theme={changeThemeByRole} barFact={_allFactory} typeFact={_allType} />
        break;
      case "/report/produce_planinfo":
        pathComponent = <PowerPlantsInfo swCotent={switchContent.type} _theme={changeThemeByRole} barFact={_allFactory} typeFact={_allType} />
        break;
      case "/report/resource-harvest-3":
        pathComponent = <ResourceHarvest _theme={changeThemeByRole} barFact={_allFactory} typeFact={_allType} />
        break;
      default:
        //window.location.href = '/powerplants/report/';
        //pathComponent = <FarmerInfo swCotent={"2"} />
        //pathComponent = null;
        break;
    }

    return pathComponent;
  }

  useEffect(() => {
    QFactoryAll()
  }, [QFactoryAll])

  return (

    <div className={`${styles.container} background_1`}>
      <Header />
      <section className={`section ${styles.section_} mx-auto`}>
        <Navbar />
        <article className={`article ${styles.article_} row mx-auto`}>
          <aside className={`${styles.menu_bar} col-3`}>
            {DropdownType(_lstAllTypeFactory, _lstAllFactory, _allFactory, setAllFactory, _allType, setAllType)}
            <MenuBar _theme={changeThemeByRole} switchContent={switchContent} onClickType={onClickType} location={location} navigate={navigate} />
          </aside>
          <aside className={`${styles.content_} col-9`}>
            <div className={`${styles.content_box}`}>
              {
                chk_navagate(switchContent, location, _allFactory, _allType)
              }
            </div>
          </aside>
        </article>
      </section>
      <Footer _themeColor={changeThemeByRole} />
    </div>
  );
};

export default AdminReport;
