const image = {
  how_to_coor1_1: require("./image/how_to_coor1-1.png"),
  how_to_coor1_2: require("./image/how_to_coor1-2.png"),
  how_to_coor2: require("./image/how_to_coor2.png"),
  how_to_coor3_1: require("./image/how_to_coor3-1.png"),
  how_to_coor3_2: require("./image/how_to_coor3-2.png"),
  how_to_coor4: require("./image/how_to_coor4.png"),
  how_to_coor6: require("./image/how_to_coor6.png"),
};

export default image;
