import React, { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import { getFactoryData } from "../../../../services/factory.slice";
import icon from "../../../../assets/img/Icon";
import { createUrlImage } from "../../../../helpers/func.helper";
import styles from "./scss/FarmerDetails.module.scss";

const PowerPlantsDetails = ({ _allFacMemByFacId }) => {
  const factoryData = useSelector(getFactoryData);

  const _plantTypeMain = useMemo(() => {
    if (factoryData?.plantType) {
      const json_data = JSON.parse(factoryData?.plantType);
      return json_data || [];
    }
  }, [factoryData]);
  const _plantTypeSecond = useMemo(() => {
    if (factoryData?.plantTypeSecond) {
      const json_data = JSON.parse(factoryData?.plantTypeSecond);
      return json_data || [];
    }
  }, [factoryData]);

  return (
    <div className={`${styles.container}`}>
      <h4 className={`${styles.title_text}`}>{factoryData?.name || "-"}</h4>
      <div className={`${styles.img_box}`}>
        <div className={`${styles.img_}`}>
          {factoryData?.image ? (
            <img
              className={`${styles.img_true}`}
              src={createUrlImage(factoryData?.imageNavigation?.filePath)}
              alt=""
            />
          ) : (
            <img className={`${styles.img_false}`} src={icon.pic} alt="" />
          )}
        </div>
      </div>
      {/* Details list */}
      <div className={`${styles.details_container}`}>
        <div className={`${styles.details_list}`}>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>ชื่อโรงไฟฟ้า</p>
            <p className={`${styles.list_value} col-8`}>
              {factoryData?.name || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>สถานะ</p>
            <p className={`${styles.list_value} col-8`}>
              {factoryData?.factoryStatus?.name || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>ชื่อผู้ประกอบการ</p>
            <p className={`${styles.list_value} col-8`}>
              {factoryData?.owner || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>ที่ตั้ง</p>
            <p className={`${styles.list_value} col-8`}>
              {factoryData?.address || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>ประเภทโรงไฟฟ้า</p>
            <p className={`${styles.list_value} col-8`}>
              {factoryData?.factoryType?.name || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>
              ชนิดเชื้อเพลิง / วัตถุดิบหลัก
            </p>
            <p className={`${styles.list_value} col-8`}>
              {_plantTypeMain?.map((item) => item.name).join(",") || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>
              ชนิดเชื้อเพลิง / วัตถุดิบเสริม
            </p>
            <p className={`${styles.list_value} col-8`}>
              {_plantTypeSecond?.map((item) => item.name).join(",") || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>เลขที่คำเสนอขอขายไฟฟ้า</p>
            <p className={`${styles.list_value} col-8`}>
              {factoryData?.proposalNo || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>
              กำลังการผลิตไฟฟ้า
            </p>
            <p className={`${styles.list_value} col-8 text_unit`}>
              {factoryData?.capacityProductionMonth !== null
                ? Number(factoryData?.capacityProductionMonth).toFixed(2)
                : "-"}{" "}
              <span>MW</span>
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>ผู้รับอนุญาตตามสัญญาซื้อขายไฟฟ้า</p>
            <p className={`${styles.list_value} col-8`}>
              {factoryData?.licensee || "-"}
            </p>
          </div>
          {_allFacMemByFacId?.map((item, i) => {
            return (
              <Fragment key={item.id}>
                <div className={`${styles.list_box} row mx-auto`}>
                  <p className={`${styles.list_key} col-4`}>
                    ผู้ประสานงาน (คนที่ {i + 1})
                  </p>
                  <p className={`${styles.list_value} col-8`}>
                    {item?.user.firstname} {item?.user.lastname}
                  </p>
                </div>
                <div className={`${styles.list_box} row mx-auto`}>
                  <p className={`${styles.list_key} col-4`}>อีเมล</p>
                  <p className={`${styles.list_value} col-8`}>
                    {item?.user.email}
                  </p>
                </div>
                <div className={`${styles.list_box} row mx-auto`}>
                  <p className={`${styles.list_key} col-4`}>เบอร์ติดต่อ</p>
                  <p className={`${styles.list_value} col-8`}>
                    {item?.user.mobile}
                  </p>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
      {/* End */}
    </div>
  );
};

export default PowerPlantsDetails;
