import React from "react";
import icon from "../../../../assets/img/Icon";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from "../scss/ReportYearCostAndIncome.module.scss";
import { formatterTooltip, formatterXaxis_1, formatterYaxis } from "../../../../helpers/suffixchart.helper";

const ChartsYearCostAndIncome = React.forwardRef((prop, ref) => {

  let ppaTransaction = []
  let transaction = []
  let record = []
  let totalIncome = 0
  let totalExpenses = 0

  try {
    let date = new Date()
    let dfyears = date.getFullYear()
    let dfmonths = date.getMonth() + 1
    let year = Number(prop.datetime)

    let data = prop.datain.data
    for (let index = 0; index < data.factoryTransaction.length; index++) {
      const element = data.factoryTransaction[index];

      if (year === dfyears) {
        if (element.month <= dfmonths) {
          transaction[index] = element?.value || 0
          totalExpenses = totalExpenses + element?.value || 0
        } else if (element.month > dfmonths) {
          transaction[index] = null
          totalExpenses = totalExpenses + 0
        }
      } else if (year < dfyears) {
        transaction[index] = element?.value || 0
        totalExpenses = totalExpenses + element?.value || 0
      }
    }

    for (let index = 0; index < data.factoryTransaction.length; index++) {
      const element = data.factoryRecord[index];

      if (year === dfyears) {
        if (element.month <= dfmonths) {
          record[index] = element?.value || 0
          totalExpenses = totalExpenses + element?.value || 0
        } else if (element.month > dfmonths) {
          record[index] = null
          totalExpenses = totalExpenses + 0
        }
      } else if (year < dfyears) {
        record[index] = element?.value || 0
        totalExpenses = totalExpenses + element?.value || 0
      }


    }

    for (let index = 0; index < data.factoryPpaTransaction.length; index++) {
      const element = data.factoryPpaTransaction[index];

      if (year === dfyears) {
        if (element.month <= dfmonths) {
          ppaTransaction[index] = element?.value || 0
          totalIncome = totalIncome + element?.value || 0
        } else if (element.month > dfmonths) {
          ppaTransaction[index] = null
          totalIncome = totalIncome + 0
        }
      } else if (year < dfyears) {
        ppaTransaction[index] = element?.value || 0
        totalIncome = totalIncome + element?.value || 0
      }



    }
  } catch (error) {

  }

  const imgageFlip = (value) => {
    let x
    if (Number(value) > 0) {
      x = (
        <img src={icon.rising} alt="" />
      )
    } else {
      x = (
        <img style={{ transform: 'scaleY(-1)' }} src={icon.rising} alt="" />
      )
    }
    return x
  }

  let ranges = [
    { divider: 1e18, suffix: "E" },
    { divider: 1e15, suffix: "P" },
    { divider: 1e12, suffix: "T" },
    { divider: 1e9, suffix: "G" },
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "K" },
  ];

  const options = {
    chart: {
      type: "column",
      // type: "line",
      height: 430,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "ม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    yAxis: {
      title: {
        text: "บาท",
      },
      labels: {
        formatter: formatterYaxis,
        style: {
          fontSize: "13px",
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "18px",
      },
      enabled: true,
      ...formatterTooltip
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          formatter: formatterXaxis_1,
          // formatter: function () {
          //   for (let i = 0; i < ranges.length; i++) {
          //     if (this.y >= ranges[i].divider) {
          //       return (
          //         Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
          //       );
          //     }
          //   }
          //   return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
          // },
          style: {
            fontSize: "16px",
          },
        },
      },
      line: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            for (let i = 0; i < ranges.length; i++) {
              if (this.y >= ranges[i].divider) {
                return (
                  Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
                );
              }
            }
            if (Number(this.y) === 0) {
              return null
              //return this.y.toString();
            } else {
              return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
            }
          },
          style: {
            fontSize: "16px",
          },
        },
        enableMouseTracking: true,
      },
      series: {
        lineWidth: 3,
        marker: {
          enabled: false,
        },
      },
    },
    colors: ["#789b33", "#ff0000", "#5076FF"],
    exporting: {
      chartOptions: { // specific options for the exported image
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "6px",
              },
              formatter: function () {
                for (let i = 0; i < ranges.length; i++) {
                  if (this.y >= ranges[i].divider) {
                    return (
                      Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
                    );
                  }
                }
                if (Number(this.y) === 0) {
                  return null
                  //return this.y.toString();
                } else {
                  return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                }
              },
            },
            enableMouseTracking: true,
          },
        },
        xAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
      },
      buttons: {
        contextButton: {
          enabled: false,
        },
        button: {
          text: "Export",
          theme: {
            fill: "#f9df70",
            stroke: "#f9df70",
            states: {
              hover: {
                fill: "#fcc",
                stroke: "#f00",
              },
              select: {
                fill: "#cfc",
                stroke: "#0f0",
              },
            },
          },
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "bottom",
        y: 0,
      },
    },
    series: [
      {
        name: "รายได้จากการขายไฟฟ้า",
        data: ppaTransaction,
      },
      {
        name: "ต้นทุนการรับซื้อเชื้อเพลิง",
        data: transaction,
      },
      {
        name: "รายจ่ายอื่นๆของโรงไฟฟ้า",
        data: record,
      },
    ],
  };
  return (
    <div className={`${styles.charts}`}>
      <HighCharts ref={ref} options={options} />
      <div className={`${styles.summary}`}>
        <div>
          <p>รายได้ทั้งหมด : {Number(totalIncome).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท </p>
          <p>ต้นทุนทั้งหมด : {Number(totalExpenses).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท </p>
        </div>
        <div>
          {
            imgageFlip(Number(totalIncome - totalExpenses))
          }
          {/* <img src={icon.rising} alt="" /> */}
          <p>: {Number(totalIncome - totalExpenses).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท</p>
        </div>
      </div>
    </div>
  );
});

export default ChartsYearCostAndIncome;
