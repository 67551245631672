import $ from "jquery";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { defaultApi } from "../../../services/api";
import ChartsMemberAmount2 from "./charts/ChartsMemberAmount2";
import ExportPopup from "../../../layouts/exportpopup/ExportPopup";
import { convertDateThai2 } from "../../../utils/format/Date.format";
import styles from "./scss/MemberInfo.module.scss";
import { QueryGetAll } from "../../../helpers/api.helper";

let enterpriseList = [];

const MemberInfo = ({ swCotent, _theme, barFact, typeFact }) => {
  const [_entLst, setEntLst] = useState();
  const [_resYear, setResYear] = useState();

  const chart = useRef();
  const [_triggerExport, setTriggerExport] = useState(false);

  const [_valueRes, setValueRes] = useState({
    entpid: "",
    factid: "",
    facttype: "",
    day: "",
    month: "",
    year: "",
    toyear: "",
  })

  const downloadCSV = () => {
    // if (chart && chart.current && chart.current.chart) {
    //   chart.current.chart.downloadCSV();
    // }
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.downloadCSV();
    }
  };

  const downloadImage = () => {
    if (chart && chart.current && chart.current.chart) {

      chart.current.chart.setTitle({
        text: "จำนวนสมาชิกที่เข้าร่วมกลุ่มเกษตรกร",
      });

      chart.current.chart.setSubtitle({
        text: checkWordTitle
      });
      // chart.current.chart.setSubtitle({
      //   text: allEn === "เลือกกลุ่มเกษตรกร" || allEn === "" ? "ทั้งหมด" : allEn,
      // });

      chart.current.chart.exportChart({
        type: "image/jpeg",
        filename: "chart",
      });

      chart.current.chart.setTitle({ text: "" });
      chart.current.chart.setSubtitle({ text: "" });
    }
  };

  const selectYear = (pin) => {
    const date = new Date();
    let year = date.getFullYear();
    let yearLst = [];
    if (pin === 1) {
      yearLst.push({ yearen: year - 1, yearth: year - 1 + 543 });
      for (let index = 0; index < 20; index++) {
        yearLst.push({ yearen: year, yearth: year + 543 });
        year = year + 1;
      }
    }
    if (pin === 2) {
      yearLst.push({ yearen: year - 2, yearth: year - 2 + 543 });
      yearLst.push({ yearen: year - 1, yearth: year - 1 + 543 });
      for (let index = 0; index < 20; index++) {
        yearLst.push({ yearen: year, yearth: year + 543 });
        year = year + 1;
      }
    }

    return yearLst.map((datax, index) => <option key={`${pin}_${index}`} value={datax.yearen}>
      {datax.yearth}
    </option>);
  };

  const entpLst = (pin) => {
    let x = [];
    try {
      x = _entLst.map((datax, index) => {
        return (
          <option key={index} value={datax.id}>
            {datax.name}
          </option>
        );
      });

      return <>
        <option key={`df_${pin}`} value={""}>
          เลือกกลุ่มเกษตรกร
        </option>{x}</>;
    } catch (error) { }
  };

  const apiItem = async (barFact, typeFact) => {
    try {
      let strquery = ""

      if (+typeFact?.id !== -99 && +barFact?.id !== -99) {
        strquery = `&$filter=factory/factoryTypeId eq ${typeFact?.id} and factory/id eq ${barFact?.id}`
      } else if (+typeFact?.id === -99 && +barFact?.id !== -99) {
        strquery = `&$filter=factory/id eq ${barFact?.id}`
      } else if (+typeFact?.id !== -99 && +barFact?.id === -99) {
        strquery = `&$filter=factory/factoryTypeId eq ${typeFact?.id}`
      } else {
        strquery = ""
      }

      let _query = {
        $count: "",
        $expand: "",
        $top: "",
        $skip: ``,
        $filter: `${strquery}`,
        $orderby: "",
      };

      const query = QueryGetAll(_query);

      const res_ent = await defaultApi.getAllEnterprise(query);
      enterpriseList = []
      for (let index = 0; index < res_ent.data.length; index++) {
        const element = res_ent.data[index];
        enterpriseList[index] = { id: element.id, name: element.name };
      }

      setEntLst(enterpriseList);


      let date = new Date();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let toyear = date.getFullYear() - 1;

      let factid = +barFact?.id === -99 ? "" : +barFact?.id
      let facttype = +typeFact?.id === -99 ? "" : +typeFact?.id

      setValueRes((prev) => ({
        ...prev,
        entpid: "",
        factid: factid,
        facttype: facttype,
        day: prev?.day ? prev?.day : date,
        month: prev?.month ? prev?.month : month,
        year: prev?.year ? prev?.year : Number(year),
        toyear: prev?.toyear ? prev?.toyear : Number(toyear),
      }))

      enterpriseList = [];
    } catch (error) { }
  };

  const apiRes = useCallback(async () => {
    try {
      let entp = `enterpriseId=${_valueRes?.entpid}`
      let year = `&atYear=${_valueRes?.year}`
      let toyear = `&toYear=${_valueRes?.toyear}`
      let factid = `&factoryId=${_valueRes?.factid}`
      let facttype = `&factoryTypeId=${_valueRes?.facttype}`
      let yearParame = `${entp}${year}${toyear}${factid}${facttype}`

      const res_year = await defaultApi.getreportMemberCountCompare(
        yearParame
      );
      setResYear(res_year.data);
    } catch (error) {

    }
  }, [_valueRes])

  const dateForm = useMemo(() => {
    return `${convertDateThai2(_valueRes?.year.toString(), "year")} กับ ${convertDateThai2(_valueRes?.toyear.toString(), "year")}`;
  }, [_valueRes]);

  const checkWordTitle = useMemo(() => {
    const entpId = _valueRes?.entpid
    const factId = _valueRes?.factid
    const factTp = _valueRes?.facttype

    let formattname = "กลุ่มวิสาหกิจทั้งหมด";

    if (factTp) {
      formattname = `กลุ่มวิสาหกิจที่อยู่ภายใต้โรงไฟฟ้าประเภท${typeFact?.name}`
    }
    if (factId) {
      formattname = `กลุ่มวิสาหกิจที่อยู่ภายใต้${barFact?.name}`
    }
    if (entpId) {
      formattname = `${_entLst?.find((item) => +item?.id === +entpId)?.name || ""}`
    }

    return formattname
  }, [_valueRes, barFact, typeFact, _entLst])

  useEffect(() => {
    apiRes()
  }, [apiRes])

  useEffect(() => {
    apiItem(barFact, typeFact);
  }, [barFact, typeFact]);

  if (swCotent === "22") {
    window.ChartTitleUnit = {
      title: 'เดือน',
      unit: ['คน'],
      totalStatus : false
    }
    return (
      <div className={`${styles.container}`}>
        <div className={`${styles.boxcontainer}`}>
          <div className={`${styles.drop_filter}`}>
            <select
              style={{ color: "black" }}
              name=""
              id="sel01"
              onChange={(e) => {
                setValueRes((prev) => ({ ...prev, entpid: e?.target?.value }))
              }}
              value={_valueRes?.entpid === "" ? "" : _valueRes?.entpid}
            >
              {
                entpLst(1)
              }
            </select>
          </div>
          <div className={`${styles.drop_filter}`}>
            <label>เปรียบเทียบปี</label>
            <select
              style={{ color: "black" }}
              name=""
              id=""
              onChange={(e) => {
                setValueRes((prev) => ({ ...prev, year: e?.target?.value }))
              }}
              value={_valueRes?.year === "" ? "" : _valueRes?.year}
            >
              {selectYear(1)}
            </select>
            <label>กับ</label>
            <select
              style={{ color: "black" }}
              name=""
              id=""
              onChange={(e) => {
                setValueRes((prev) => ({ ...prev, toyear: e?.target?.value }))
              }}
              value={_valueRes?.toyear === "" ? "" : _valueRes?.toyear}
            >
              {selectYear(2)}
            </select>
          </div>
        </div>

        <h4
          className={`${styles.title_text}`}
          style={{ background: _theme?.backgroundColor }}
        >
          จำนวนสมาชิกที่เข้าร่วมกลุ่มเกษตรกร
        </h4>
        <h4 id="subtitle" className={`${styles.subtitle_text}`}>
          {checkWordTitle}
        </h4>

        <div className={`${styles.show_date}`}>
          <p>ข้อมูลเปรียบเทียบระหว่างปี</p>
          <p>
            {dateForm}
          </p>
        </div>

        <div className={`${styles.charts_}`}>
          <ChartsMemberAmount2
            ref={chart}
            datain={_resYear}
            datetime={_valueRes}
            datetimelegend={{ year: _valueRes.year, toyear: _valueRes?.toyear }}
          />
        </div>

        <div className={`${styles.export_}`}>
          <ExportPopup
            label={"report"}
            trigger={_triggerExport}
            event={{ csv: downloadCSV, image: downloadImage }}
          />
          <button
            id="exporting"
            type="button"
            className={`${styles.btn_}`}
            onClick={() => {
              setTriggerExport(!_triggerExport);
            }}
          >
            Export
          </button>
        </div>
      </div>
    );
  }
};

export default MemberInfo;
