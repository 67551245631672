import React from "react";
import LoginForm from "./form/LoginForm";
import Footer from "../../layouts/footer_1/Footer";
import logo from "../../assets/img/Logo";
import styles from "./scss/Login.module.scss";

const Login = () => {
  return (
    <div className={`${styles.container} background_1`}>
      <h1 className="title text-center">โรงไฟฟ้าชุมชนเพื่อเศรษฐกิจฐานราก</h1>
      <div className="container_content">
        <section className={`section ${styles.section_}`}>
          <img src={logo.energyLogo} alt="logo_energy" />
          <article className={`article ${styles.article_} row mx-auto`}>
            <aside className="col">
              <div className={`${styles.form_box} mx-auto`}>
                <LoginForm />
              </div>
            </aside>
            <aside className="col"></aside>
          </article>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
