import React, { useEffect, useRef } from "react";
import { Overlay } from "ol";
import icon from "../../../assets/img/Icon";
import { convertCoordinates } from "../../../helpers/map.helper";
import styles from "./TradingPopup.module.scss";

const TradingPopup = ({ _map, _factory }) => {
  const container = useRef();

  useEffect(() => {
    let facData = [_factory];
    for (let i = 0; i < facData.length; i++) {
      const overlay = new Overlay({
        element: container.current,
        position: convertCoordinates(facData[i].buyLng, facData[i].buyLat),
        stopEvent: false,
      });
      _map?.addOverlay(overlay);
    }
  }, [_map, _factory]);

  return (
    <div
      id="popup_tradi"
      className={`ol_popup ${styles.ol_popup}`}
      ref={container}
    >
      <div
        id="tradi_popup_content"
        className={`popup_content ${styles.popup_content}`}
      >
        <div className={`${styles.img_icon}`}>
          <img src={icon.group384} alt="" />
        </div>
        <div className={`${styles.text_content}`}>
          <div>
            <p id="tradi_text_name">จุดรับซื้อ</p>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "5px" }}
          >
            <p id="tradi_text_details">{_factory.buyName}</p>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingPopup;
