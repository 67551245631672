import React from "react";
import styles from "./scss/MenuBar.module.scss";

const MenuBar = ({
  switchChecked,
  onSwitchChecked,
  switchChecked2,
  onSwitchChecked2,
  switchChecked3,
  onSwitchChecked3,
  _location,
  _navigate,
}) => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.checkParentBox}`} >
        <input
          type="checkbox"
          name="type"
          id="checkMenu1"
          checked={_location === "/report"}
          onClick={() => _navigate("/report")}
          readOnly
        />
        <label htmlFor="checkMenu1">พื้นที่เพาะปลูกรวม</label>
      </div>
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name="type"
          id="checkMenu2"
          checked={_location === "/report/members"}
          onClick={() => _navigate("/report/members")}
          readOnly
        />
        <label htmlFor="checkMenu2">จำนวนสมาชิก</label>
      </div>
      {_location === "/report/members" && (
        <div className={`${styles.checkChildrenBox}`}>
          <div>
            <input
              type="checkbox"
              name=""
              id="checkMenu5"
              value={1}
              checked={switchChecked === "1"}
              onClick={(e) => onSwitchChecked(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu5">สถานะการถือครองที่ดิน</label>
          </div>
          <div>
            <input
              type="checkbox"
              name=""
              id="checkMenu6"
              value={2}
              checked={switchChecked === "2"}
              onClick={(e) => onSwitchChecked(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu6">เปรียบเทียบจำนวนสมาชิก</label>
          </div>
        </div>
      )}
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name="type"
          id="checkMenu3"
          checked={_location === "/report/total-income"}
          onClick={() => _navigate("/report/total-income")}
          readOnly
        />
        <label htmlFor="checkMenu3">รายได้รวมกลุ่มวิสาหกิจชุมชน</label>
      </div>
      {_location === "/report/total-income" && (
        <div className={`${styles.checkChildrenBox}`}>
          {/* <div>
            <input
              type="checkbox"
              name=""
              id="checkMenu7"
              value={3}
              checked={switchChecked2 === "3"}
              onClick={(e) => onSwitchChecked2(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu7">รายได้รวมรายวัน</label>
          </div> */}
          <div>
            <input
              type="checkbox"
              name=""
              id="checkMenu8"
              value={4}
              checked={switchChecked2 === "4"}
              onClick={(e) => onSwitchChecked2(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu8">รายได้รวมรายเดือน</label>
          </div>
        </div>
      )}
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name="type"
          id="checkMenu4"
          checked={_location === "/report/sales-plan"}
          onClick={() => _navigate("/report/sales-plan")}
          readOnly
        />
        <label htmlFor="checkMenu4">แผนผลการขายเชื้อเพลิง</label>
      </div>
      {_location === "/report/sales-plan" && (
        <div className={`${styles.checkChildrenBox}`}>
          <div>
            <input
              type="checkbox"
              name=""
              id="checkMenu9"
              value={5}
              checked={switchChecked3 === "5"}
              onClick={(e) => onSwitchChecked3(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu9">การขายเชื้อเพลิงรายวัน</label>
          </div>
          <div>
            <input
              type="checkbox"
              name=""
              id="checkMenu10"
              value={6}
              checked={switchChecked3 === "6"}
              onClick={(e) => onSwitchChecked3(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu10">การขายเชื้อเพลิงรายเดือน</label>
          </div>
        </div>
      )}
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name="type"
          id="checkMenu11"
          checked={_location === "/report/estimateproduce"}
          onClick={() => _navigate("/report/estimateproduce")}
          readOnly
        />
        <label htmlFor="checkMenu11">ผลผลิตเชื้อเพลิงที่ผลิตได้จริง</label>
      </div>
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name="type"
          id="checkMenu12"
          checked={_location === "/report/resource-harvest-1"}
          onClick={() => _navigate("/report/resource-harvest-1")}
          readOnly
        />
        <label htmlFor="checkMenu12">วัตถุดิบที่คาดว่าจะเก็บเกี่ยว</label>
      </div>
    </div>
  );
};

export default MenuBar;
