import React from "react";
import icon from "../../../../assets/img/Icon";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from '../scss/CashflowInfo.module.scss';
import { formatterTooltip, formatterXaxis_1, formatterYaxis } from "../../../../helpers/suffixchart.helper";

const ChartsIncomeExpense2 = React.forwardRef((prop, ref) => {

  let ranges = [
    { divider: 1e18, suffix: "E" },
    { divider: 1e15, suffix: "P" },
    { divider: 1e12, suffix: "T" },
    { divider: 1e9, suffix: "G" },
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "K" },
  ];

  let x01 = []
  let x02 = []

  try {

    let date = new Date()
    let dfyears = date.getFullYear()
    let dfmonths = date.getMonth() + 1
    let year = Number(prop.datetime.year)

    let data = prop.datain
    let profit = data.factoryPpaTransaction
    let cost = data.factoryRecord
    let expense = data.factoryTransaction

    for (let index = 0; index < profit.length; index++) {
      let i = JSON.parse(profit[index].value)
      x01[index] = i || 0

      if (year === dfyears) {
        if (profit[index].month <= dfmonths) {
          x01[index] = i || 0
        } else if (profit[index].month > dfmonths) {
          x01[index] = null
        }
      } else if (year < dfyears) {
        x01[index] = i || 0
      }


    }

    for (let index = 0; index < expense.length; index++) {
      let i1 = JSON.parse(expense[index].value)
      let i2 = JSON.parse(cost[index].value)

      if (year === dfyears) {
        if (expense[index].month <= dfmonths) {
          x02[index] = (i1 + i2)
        } else if (expense[index].month > dfmonths) {
          x02[index] = null
        }
      } else if (year < dfyears) {
        x02[index] = (i1 + i2)
      }


      //x02[index] = i1
    }

  } catch (error) {

  }

  const options = {
    chart: {
      type: "column",
      // type: "line",
      height: 400,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
    },
    yAxis: {
      labels: {
        enabled: true,
        formatter: formatterYaxis,
      },
      title: {
        text: "บาท",
      },
    },
    tooltip: {
      //pointFormat: "<b>{series.name}: {point.y} ตัน</b>",
      style: {
        fontSize: "18px",
      },
      enabled: true,
      ...formatterTooltip
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: formatterXaxis_1,
          // formatter: function () {
          //   for (let i = 0; i < ranges.length; i++) {
          //     if (this.y >= ranges[i].divider) {
          //       return (
          //         Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
          //       );
          //     }
          //   }
          //   return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
          // },
          style: {
            fontSize: "16px",
          },
        },
      },
      series: {
        marker: {
          enabled: false
        },
      },
      line: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            for (let i = 0; i < ranges.length; i++) {
              if (this.y >= ranges[i].divider) {
                return (
                  Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
                );
              }
            }
            if (Number(this.y) === 0) {
              return null
              //return this.y.toString();
            } else {
              return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
            }
          },
          style: {
            fontSize: "16px",
          },
        },
        enableMouseTracking: true,
      },
    },
    colors: ["#ff0000", "#171563"],
    exporting: {
      chartOptions: { // specific options for the exported image
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "6px",
              },
              formatter: function () {
                for (let i = 0; i < ranges.length; i++) {
                  if (this.y >= ranges[i].divider) {
                    return (
                      Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
                    );
                  }
                }
                if (Number(this.y) === 0) {
                  return null
                  //return this.y.toString();
                } else {
                  return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                }
              },
            },
            enableMouseTracking: true,
          },
        },
        xAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
      },
      buttons: {
        contextButton: {
          enabled: false,
        },
        button: {
          text: "Export",
          theme: {
            fill: "#f9df70",
            stroke: "#f9df70",
            states: {
              hover: {
                fill: "#fcc",
                stroke: "#f00",
              },
              select: {
                fill: "#cfc",
                stroke: "#0f0",
              },
            },
          },
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "bottom",
        y: 0,
      },
    },
    series: [
      {
        name: "รายได้",
        data: x01,
      },
      {
        name: "รายจ่าย",
        data: x02,
      },
    ],
  };

  const calRes = () => {
    let ix01 = 0
    x01.map((value) => {
      ix01 += value
      return null
    })

    let ix02 = 0
    x02.map((value) => {
      ix02 += value
      return null
    })

    //const ix03 = (((ix01 - ix02) * 100) / ix02)
    const ix03 = ix01 - ix02

    const flipicon = (val) => {
      if (val > 0) {
        return (
          <>
            <div className={`${styles.boxicon}`}><img alt="" className={`${styles.icon}`} src={icon.rising} /> : {ix03.toLocaleString(undefined, { minimumFractionDigits: 2 })} บาท</div>
          </>
        )
      } else {
        return (
          <>
            <div className={`${styles.boxicon}`}><img alt="" style={{ "transform": "scaleY(-1)" }} className={`${styles.icon}`} src={icon.rising} /> : {ix03.toLocaleString(undefined, { minimumFractionDigits: 2 })} บาท</div>
          </>
        )
      }
    }

    return (
      <>
        <div>
          <p>รายได้ทั้งหมด : {ix01.toLocaleString()} บาท</p>
          <p>รายจ่ายทั้งหมด : {ix02.toLocaleString()} บาท</p>
        </div>
        {
          flipicon(ix03.toFixed(2))
        }
      </>
      // <div className="d-flex justify-content-between align-items-strech">
      //   <div className="font_size_total_chart">
      //     <div className="d-flex justify-content-start align-items-center">รายได้ทั้งหมด : {ix01.toLocaleString()} บาท</div>
      //     <div className="d-flex justify-content-start align-items-center">รายจ่ายทั้งหมด : {ix02.toLocaleString()} บาท</div>
      //   </div>
      //   <div className=" d-flex justify-content-center align-items-center">
      //     {
      //       flipicon(ix03.toFixed(2))
      //     }
      //     <div className="ms-2 me-3 percentage_text">{ix03.toFixed(2)}%</div>
      //   </div>
      // </div>
    )
  }

  return (
    <div className={`${styles.charts}`}>
      <HighCharts ref={ref} options={options} />
      <div className={`${styles.summary}`}>
        {
          calRes()
        }
      </div>
    </div>

    // <div className="charts_income_expense">
    //   <HighCharts options={options} />
    //   <div className="button_y d-flex justify-content-between align-items-center">
    //     <div className="w-100">
    //       {
    //         calRes()
    //       }
    //     </div>
    //     <button className="btn_">Export</button>
    //   </div>
    // </div>
  );
});

export default ChartsIncomeExpense2;
