import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import icon from "../../assets/img/Icon";
import Pagination from "../../components/pagination/Pagination";
import { QueryGetAll } from "../../helpers/api.helper";
import { defaultApi } from "../../services/api";
import {
  convertDateEu,
  convertDateThai,
  convertDateThaiAndTime,
} from "../../utils/format/Date.format";
import style from "./scss/Headtable.module.scss";
import styles from "./scss/Popupform.module.scss";
import { toast } from "react-hot-toast";
import Message from "../../utils/message/Message";
import { exportCsvFile, exportXlsxFile } from "../../helpers/func.helper";
import ExportPopup from "../../layouts/exportpopup/ExportPopup";

let listItem = {
  method: null,
  username: null,
  sdate: null,
  edate: null,
};

//let _strFilter = ''

let logDetail = [
  {
    path: "/adminAmphoe",
    action: "อำเภอ",
  },
  {
    path: "/adminProvince",
    action: "จังหวัด",
  },
  {
    path: "/adminRegion",
    action: "ภูมิภาค",
  },
  {
    path: "/adminTambol",
    action: "ตำบล",
  },
  {
    path: "/auth/login",
    action: "ล็อกอิน",
  },
  {
    path: "/auth/register",
    action: "สมัครสมาชิก",
  },
  {
    path: "/auth/profile",
    action: "โปรไฟล์",
  },
  {
    path: "/auth/changePassword",
    action: "ลืมรหัสผ่าน",
  },
  {
    path: "/contractFarming",
    action: "สัญญาเกษตร",
  },
  {
    path: "/crop",
    action: "รอบการเพาะปลูก",
  },
  {
    path: "/enterprise",
    action: "กลุ่มเกษตรกร",
  },
  {
    path: "/enterprise/manager",
    action: "ผู้ประสานงานกลุ่มเกษตรกร",
  },
  {
    path: "/enterprise/enterprise/supply",
    action: "ซัพพลายกลุ่มเกษตรกร",
  },
  {
    path: "/enterpriseType",
    action: "ประเภทกลุ่มเกษตรกร",
  },
  {
    path: "/factory",
    action: "โรงไฟฟ้าชุมชน",
  },
  {
    path: "/factory/manager",
    action: "ผู้ประสานงานโรงไฟฟ้าชุมชน",
  },
  {
    path: "/factory/factory/supply",
    action: "ซัพพลายโรงไฟฟ้าชุมชน",
  },
  {
    path: "/factoryRecord",
    action: "บันทึกโรงไฟฟ้า",
  },
  {
    path: "/factoryStatus",
    action: "สถานะโรงไฟฟ้าชุมชน",
  },
  {
    path: "/factoryType",
    action: "ประเภทโรงไฟฟ้าชุมชน",
  },
  {
    path: "/farmer",
    action: "เกษตรกร",
  },
  {
    path: "/gisLayer",
    action: "ชั้นข้อมูล",
  },
  {
    path: "/gisLayer/status",
    action: "สถานะชั้นข้อมูล",
  },
  {
    path: "/gisLayerGroup",
    action: "กลุ่มชั้นข้อมูล",
  },
  {
    path: "/localGroup",
    action: "กลุ่มผู้ใช้งานย่อย",
  },
  {
    path: "/localGroup/permission",
    action: "สิทธิกลุ่มผู้ใช้งานย่อย",
  },
  {
    path: "/log",
    action: "บันทึกการใช้ระบบของผู้ใช้งาน",
  },
  {
    path: "/org",
    action: "หน่วยงาน/องค์กร",
  },
  {
    path: "/parcel",
    action: "แปลงที่ดินตามโฉนดหรือเอกสารสิทธิต่างๆ",
  },
  {
    path: "/plantType",
    action: "ชนิดพืช",
  },
  {
    path: "/powerGeneration",
    action: "การผลิตไฟฟ้า",
  },
  {
    path: "/ppa",
    action: "สัญญาขายพลังงาน",
  },
  {
    path: "/ppaTransaction",
    action: "บันทึกขายพลังงาน",
  },
  {
    path: "/recordDetail",
    action: "รายการบันทึก",
  },
  {
    path: "/transaction",
    action: "บันทึกการซื้อ/ขาย ตามสัญญาเกษตรพันธสัญญา",
  },
  {
    path: "/transaction/paymentStatus",
    action: "สถานะบันทึกการซื้อ/ขาย ตามสัญญาเกษตรพันธสัญญา",
  },
  {
    path: "/user",
    action: "บัญชีผู้ใช้งาน",
  },
  {
    path: "/userGroup",
    action: "กลุ่มผู้ใช้งาน",
  },
];

let method = [
  {
    method: "POST",
    action: "เข้าใช้งาน/สร้าง/เพิ่ม",
  },
  {
    method: "PUT",
    action: "เปลี่ยนแปลง/แก้ไข",
  },
  {
    method: "GET",
    action: "เรียกดู",
  },
  {
    method: "DELETE",
    action: "ลบ",
  },
];

export const ManageHistData = ({ toggerCheck }) => {
  const [_hisProfile, setHisProfile] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [_hisProfileDfAll, setHisProfileDfAll] = useState([]);

  const [date, setDate] = useState("");
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const onChangeDateStartL = (e) => {
    const dateFormat = convertDateEu(e);
    setDate(dateFormat);
    setOpenDatePicker(!openDatePicker);
  };

  const [_setPlaceholder, setPlaceholder] = useState(false);

  const [date1, setDate1] = useState("");
  const [openDatePicker1, setOpenDatePicker1] = useState(false);

  const [_toastState, toastState] = useState(false);
  const [_strFilter, strFilter] = useState("");

  const [_fstLoad, fstLoad] = useState(true);

  const [_triggerExport, setTriggerExport] = useState(false);

  const downloadCSV = async () => {
    const loading = toast;

    try {
      let exportName = "LogReport";
      let fileType = "excel";

      /**
             * uremark / str 
username / str
atDate / str
toDate / str
             */
      let obj = {
        //enterpriseId: enterpriseData.id,
      };
      // Ready
      exportCsvFile(exportName, fileType, obj);
      //await defaultApi.exportCsv("LogReport/excel")
      toast.success(Message.notify.loading_success, { duration: 3000 });
    } catch (error) {
      toast.error(Message.notify.loading_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  const downloadXlsx = async () => {
    const loading = toast;

    try {
      let exportName = "LogReport";
      let fileType = "excel";

      /**
             * uremark / str 
username / str
atDate / str
toDate / str
             */
      let obj = {
        //enterpriseId: enterpriseData.id,
      };
      // Ready
      exportXlsxFile(exportName, fileType, obj);
      //await defaultApi.exportCsv("LogReport/excel")
      toast.success(Message.notify.loading_success, { duration: 3000 });
    } catch (error) {
      toast.error(Message.notify.loading_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  const onChangeDateStartL1 = (e) => {
    const dateFormat1 = convertDateEu(e);
    setDate1(dateFormat1);
    setOpenDatePicker1(!openDatePicker1);
  };

  const getApi = useCallback(
    async (valState) => {
      let qryGetAll = {
        $count: "%24count=true",
        $expand: "",
        $top: "&%24top=10",
        $skip: "&%24skip=0",
        $filter: `${_strFilter}`,
        $orderby: "&%24orderby=id desc",
      };

      let qryGetAll1 = {
        $count: "%24count=true",
        $expand: "",
        $top: "",
        $skip: "",
        $filter: "",
        $orderby: "",
      };

      const _query = QueryGetAll(qryGetAll);
      const _query1 = QueryGetAll(qryGetAll1);

      const loading = toast;

      try {
        const res1 = await defaultApi.getLog(_query);
        const res2 = await defaultApi.getLog(_query1);

        console.log("res1",res1?.data);

        if (valState === 0) {
          //setHisProfileDf(res1.data.datas)
          setHisProfile(res1.data.datas);
          setHisProfileDfAll(res2.data.datas);
        } else {
          setHisProfile(res1.data.datas);
        }
        setTotalCount(res1.data.total);
        //setCurrentPage(1)

        if (_toastState === true && res1.data.total <= 0) {
          toast.error(Message.notify.search_not_found, { duration: 3000 });
        }
      } catch (error) {
        console.log(error);
      } finally {
        toast.dismiss(loading);
        toastState(false);
      }
    },
    [_strFilter, _toastState]
  );

  const onPaginate = useCallback(
    async (page) => {
      if (Number(currentPage) === Number(page)) {
        return;
      }
      const _top = page * 10;

      const qryGetAll = {
        $count: "%24count=true",
        $expand: "",
        $top: `&%24top=10`,
        $skip: `&%24skip=${_top > 10 ? page * 10 - 10 : "0"}`,
        $filter: `${_strFilter}`,
        $orderby: "&%24orderby=id desc",
      };
      const _query = QueryGetAll(qryGetAll);
      try {
        const res = await defaultApi.getLog(_query);
        if (res.data.datas.length === 0) return;

        setCurrentPage(page);
        setHisProfile(res.data.datas);
        setTotalCount(res.data.total);
      } catch (error) {
        console.log(error);
      }
    },
    [_strFilter, currentPage]
  );

  const setAction = () => {
    const reformPath = (input) => {
      let arrSet = [];

      const pathInInt = (getPath) => {
        let outlet = "";
        for (let index = 0; index < getPath.length; index++) {
          let i = getPath.length - 1;
          if (index === 0) {
          } else if (index === i) {
          } else {
            outlet += "/" + getPath[index];
          }
        }
        return outlet;
      };

      const pathInStr = (getPath) => {
        let outlet = "";
        for (let index = 0; index < getPath.length; index++) {
          if (index === 0) {
          } else {
            outlet += "/" + getPath[index];
          }
        }
        return outlet;
      };

      try {
        for (let index = 0; index < input.length; index++) {
          const element = input[index].split("/");
          const lengthItem = element.length - 1;
          if (Number(element[lengthItem])) {
            arrSet[index] = pathInInt(element);
          } else {
            arrSet[index] = pathInStr(element);
          }
        }
        return arrSet;
      } catch (error) {}
    };

    const dataAction = _hisProfileDfAll
      .map((data, index) => {
        return data.remark;
      })
      .filter((item, index, self) => {
        return self.indexOf(item) === index;
      });

    const reformAction = reformPath(dataAction);

    const filterActionReform = reformAction
      .map((data, index) => {
        return data;
      })
      .filter((item, index, self) => {
        return self.indexOf(item) === index;
      });

    // const reformItem = (method, item) => {
    //     const loopItem = (item, pathItem, actionName) => {
    //         return item.map((data, i) => {
    //             if (data === pathItem) {
    //                 return (
    //                     <option value={`${method}||${pathItem}`}>{actionName}</option>
    //                 )
    //             }

    //         })
    //     }

    //     return logDetail.map((data) => {
    //         return loopItem(item, data.path, data.action)
    //     })
    // }

    const loopItem = (pathItem, actionName) => {
      return filterActionReform.map((data, i) => {
        let ix = null;
        if (data === pathItem) {
          ix = (
            <option key={i} value={`${pathItem}`}>
              {actionName}
            </option>
          );
        }
        return ix;
      });
    };

    return logDetail.map((data) => {
      return loopItem(data.path, data.action);
    });

    //reformItem(filterActionReform)

    // return (
    //     dataMethod.map((data, i) => {
    //         let dataname = ""
    //         if (method[i].method === data) {
    //             dataname = method[i].action
    //         }
    //         return (
    //             <optgroup key={i} label={dataname}>
    //                 {
    //                     reformItem(data, filterActionReform)
    //                 }
    //             </optgroup>
    //         )
    //     })
    // )
  };

  const setFilterItem = useCallback(() => {
    let arr = [];
    let operatorAnd = "";
    if (listItem !== undefined) {
      if (listItem.method !== "") {
        arr.push(`contains%28remark%2C%20%27${listItem?.method || ""}%27%29`);
      }
      if (listItem.username !== "") {
        arr.push(
          `contains%28username%2C%20%27${listItem?.username || ""}%27%29`
        );
      }
      if (date !== "") {
        arr.push(`recordedAt%20ge%20${date}`);
      }
      if (date1 !== "") {
        arr.push(`recordedAt%20le%20${date1}`);
      }

      for (let index = 0; index < arr.length; index++) {
        if (index === 0) {
          operatorAnd += arr[index];
        } else {
          operatorAnd += `%20and%20` + arr[index];
        }
      }

      operatorAnd = "&%24filter=" + operatorAnd;
    }

    if (_toastState === false && currentPage > 1 && totalCount <= 10) {
      setCurrentPage(1);
    }
    if (_toastState === false && currentPage > 1 && totalCount >= 11) {
      setCurrentPage(1);
    }

    strFilter(operatorAnd);
    toastState(true);
  }, [_toastState, currentPage, date, date1, totalCount]);

  const viewMethod = (item) => {
    /**
         * method: "POST",
        action: "เข้าใช้งาน/สร้าง/เพิ่ม"
         */

    return method.map((data) => {
      let i = null;
      if (data.method === item) {
        i = data.action;
      }
      return i;
    });
  };

  const exportFile = async () => {
    const loading = toast;

    try {
      let exportName = "LogReport";
      let fileType = "excel";

      /**
             * uremark / str 
username / str
atDate / str
toDate / str
             */
      let obj = {
        //enterpriseId: enterpriseData.id,
      };
      // Ready
      exportCsvFile(exportName, fileType, obj);
      //await defaultApi.exportCsv("LogReport/excel")
      toast.success(Message.notify.loading_success, { duration: 3000 });
    } catch (error) {
      toast.error(Message.notify.loading_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  useEffect(() => {
    if (_fstLoad === true) {
      getApi(0);
      fstLoad(false);
    }
  }, [getApi, _fstLoad]);

  useEffect(() => {
    if (_toastState === true) {
      getApi(1);
    }
    if (totalCount <= 10 && currentPage > 1) {
      setFilterItem();
    }
  }, [getApi, setFilterItem, _toastState, totalCount, currentPage]);

  useEffect(() => {
    /* Function close dropdown 'DatePicker and DatePicker1' : START */
    const onCloseDropdownDatePicker = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#adm_datepicker_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDatePicker(false);
      }
    };
    const onCloseDropdownDatePicker1 = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#adm_datepicker1_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDatePicker1(false);
      }
    };

    if (openDatePicker) {
      document.addEventListener("click", onCloseDropdownDatePicker);
    }
    if (openDatePicker1) {
      document.addEventListener("click", onCloseDropdownDatePicker1);
    }
    /* Function close dropdown 'DatePicker and DatePicker1' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDatePicker);
      document.removeEventListener("click", onCloseDropdownDatePicker1);
    };
  }, [openDatePicker, openDatePicker1]);

  return (
    <>
      <div
        style={toggerCheck === true ? { display: "flex" } : { display: "none" }}
        className={`${style.bodytab2}`}
      >
        <div className={`${style.hpsub}`}>
          <div className={` ${style.group_}`}>
            <div className={style.input_unit}>
              <div className={style.info_box}>
                <h6 style={{ color: "black" }}>
                  {date !== "" ? convertDateThai(date) : "วันที่"}
                </h6>
              </div>
              <div
                className={style.unit}
                onClick={() => {
                  setOpenDatePicker(!openDatePicker);
                }}
              >
                <img src={icon.calendar} alt="" id="adm_datepicker_d" />
              </div>
            </div>
            {openDatePicker && (
              <DatePicker onChange={onChangeDateStartL} inline />
            )}
          </div>

          <div className={` ${style.group_}`}>
            <div className={style.input_unit}>
              <div className={style.info_box}>
                <h6 style={{ color: "black" }}>
                  {date1 !== "" ? convertDateThai(date1) : "วันที่"}
                </h6>
              </div>
              <div
                className={style.unit}
                onClick={() => {
                  setOpenDatePicker1(!openDatePicker1);
                }}
              >
                <img src={icon.calendar} alt="" id="adm_datepicker1_d" />
              </div>
            </div>
            {openDatePicker1 && (
              <DatePicker onChange={onChangeDateStartL1} inline />
            )}
          </div>

          <div className={`${style.boxitem}`}>
            {/* <h5><b>Username : </b></h5> */}
            <input
              type={"text"}
              placeholder={
                _setPlaceholder === false ? "ค้นหาชื่อผู้ใช้งาน" : ""
              }
              onFocus={() => {
                setPlaceholder(true);
              }}
              onBlur={() => {
                setPlaceholder(false);
              }}
              onChange={(e) => {
                listItem.username = e.target.value;
              }}
            />
          </div>
          <div className={`${style.boxitem}`}>
            {/* <h5><b>Action : </b></h5> */}
            <select
              onChange={(e) => {
                listItem.method = e.target.value;
              }}
            >
              <option value="">เลือกการกระทำ</option>
              {setAction()}
            </select>
          </div>
          <img
            src={icon.search}
            alt="ค้นหา"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              setFilterItem();
            }}
          />
        </div>
        {/* <div> */}

        <div className="collapse_table table-responsive">
          <table className="table text-center">
            <thead>
              <tr className={`${style.tr}`}>
                <th scope="col">ลำดับ</th>
                <th scope="col">ชื่อผู้ใช้งาน</th>
                <th scope="col">ชื่อ</th>
                <th scope="col">นามสกุล</th>
                <th scope="col">Action</th>
                <th scope="col">IP Address</th>
                <th scope="col">วันที่-เวลา</th>
                <th scope="col">หมายเหตุ</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {/* {
                                    showlist()
                                } */}
              {_hisProfile.map((detail, i) => {
                return (
                  <tr key={i}>
                    <td>{(currentPage - 1) * 10 + (i + 1)}</td>
                    <td>{detail.username}</td>
                    <td>{detail.firstname}</td>
                    <td>{detail.lastname}</td>
                    <td>{viewMethod(detail.method)}</td>
                    <td>{detail.ip}</td>
                    <td>{convertDateThaiAndTime(detail.recordedAt)}</td>
                    <td>{detail.remark}</td>
                    <td>
                      <img
                        src={icon.password}
                        alt="ดูรายละเอียด"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          document.getElementById("textDetail").value =
                            detail.description;
                          document.getElementById("show_modal_edit_").click();
                          document.getElementById("textDetail").rows =
                            Number(detail.description.split("\n").length) >= 15
                              ? 15
                              : Number(detail.description.split("\n").length);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
              {/* convertDateThaiAndTime(replaceTime(detail.datetime)) */}
            </tbody>
          </table>
        </div>
        <div>
          <Pagination
            pageSize={10}
            totalCount={totalCount}
            currentPage={currentPage}
            onPageChange={(page) => onPaginate(page)}
          />
        </div>
        <div>
          <button
            type="button"
            id="show_modal_edit_"
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            hidden
          ></button>
          <div
            className="modal fade"
            id="exampleModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <h1
                    className={`modal-title mt-3 fs-5 ${styles.h5}`}
                    id="exampleModalLabel"
                  >
                    รายละเอียด
                  </h1>
                  <hr className={`w-100 me-1 ms-1 border border-dark-subtle`} />
                </div>
                <div className="modal-body ">
                  <textarea
                    id="textDetail"
                    className={`w-100`}
                    style={{ resize: "none" }}
                    readOnly
                  ></textarea>
                </div>
                <div
                  className={`d-flex justify-content-center mb-3 ${styles.boxpopup}`}
                >
                  <button
                    className={`${styles.savechangebtn}`}
                    data-bs-dismiss="modal"
                    onClick={() => {
                      document.getElementById("textDetail").value = "";
                      document.getElementById("exampleModal").visibility =
                        "hidden";
                    }}
                  >
                    <h4>ยกเลิก</h4>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <FormManageUserData userid={_packSetItemUser} infoProfile={infoProfile} /> */}
        </div>
        <div className={`${style.boxbtn}`}>
          <ExportPopup
            label={"document"}
            trigger={_triggerExport}
            event={{ csv: downloadCSV, xlsx: downloadXlsx }}
          />
          <button
            className={`${style.btn_}`}
            onClick={() => {
              setTriggerExport(!_triggerExport);
            }}
          >
            Export
          </button>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};
