import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getEnterpriseData } from "../../../../../services/enterprise.slice";
import { defaultApi } from "../../../../../services/api";
import {
  CheckFname,
  CheckLname,
  CheckMobile,
  CheckNumber,
  CheckNumberCard,
  CheckValidLength,
} from "../../../../../utils/validation_input/Validation";
import Message from "../../../../../utils/message/Message";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../../utils/format/Date.format";
import icon from "../../../../../assets/img/Icon";
import styles from "./scss/AddMember.module.scss";

const title = [
  {
    id: 1,
    name: "นาย",
  },
  {
    id: 2,
    name: "นาง",
  },
  {
    id: 3,
    name: "นางสาว",
  },
];

const ownershipStatus = [
  { id: 1, name: "เจ้าของกรรมสิทธิ์" },
  { id: 2, name: "ผู้เช่า" },
  { id: 3, name: "อื่นๆ" },
];

const memberStatus = [
  { id: 1, name: "สมาชิกภายในกลุ่มวิสาหกิจชุมชน" },
  { id: 2, name: "สมาชิกภายนอกกลุ่มวิสาหกิจชุมชน" },
];

const initData = {
  avatar: "",
  memberNo: "",
  title: "",
  firstname: "",
  lastname: "",
  signDate: "",
  endDate: "",
  owner: "",
  address: "",
  enterpriseId: "",
  familyIncome: "",
  ownership: "",
  status: "",
  mobile: "",
};

const AddMember = ({ _fetchData, _onSwitch }) => {
  const enterpriseData = useSelector(getEnterpriseData);

  const [_data, setData] = useState(initData);
  const [imgData, setImgData] = useState({ file: "" });
  const [selectedDate, setSelectedDate] = useState({
    sign: new Date(),
    end: new Date(),
  });
  const [validErr, setValidErr] = useState(false);
  const [openDateSign, setOpenDateSign] = useState(false);
  const [openDateEnd, setOpenDateEnd] = useState(false);
  const [placeholderDisabled, setPlaceholderDisabled] = useState(false);

  /* DatePicker : START */
  const onChangeDateSign = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, signDate: dateFormat });
    setSelectedDate({ ...selectedDate, sign: e });
    setOpenDateSign(!openDateSign);
  };
  const onChangeDateEnd = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, endDate: dateFormat });
    setSelectedDate({ ...selectedDate, end: e });
    setOpenDateEnd(!openDateEnd);
  };
  /* DatePicker : END */

  const changeImg = async (e) => {
    const file = e.target.files[0];
    const file_val = document.querySelector("#file_img");
    const param1 = "Profile";
    const param2 = new FormData();
    param2.append("FormFile", file);

    if (!_data?.avatar) {
      console.log("Create image");
      try {
        const res = await defaultApi.createFileDetail(param1, param2);
        setData({ ..._data, avatar: res?.data.id });
        setImgData({ file: URL.createObjectURL(file) });

        file_val.value = "";
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Update image");
      try {
        const res = await defaultApi.updateFileDetail(
          _data?.avatar,
          param1,
          param2
        );
        setData({ ..._data, avatar: res?.data.id });
        setImgData({ file: URL.createObjectURL(file) });

        file_val.value = "";
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };

  const createFarmer = async () => {
    if (CheckFname(_data.firstname)) return setValidErr(true);
    if (CheckLname(_data.lastname)) return setValidErr(true);
    if (CheckValidLength(_data.title)) return setValidErr(true);
    if (CheckValidLength(_data.signDate)) return setValidErr(true);
    if (CheckValidLength(_data.endDate)) return setValidErr(true);
    if (CheckNumber(_data.familyIncome)) return setValidErr(true);
    if (CheckNumberCard(_data.memberNo)) return setValidErr(true);
    if (CheckValidLength(_data.owner)) return setValidErr(true);
    if (CheckValidLength(_data.address)) return setValidErr(true);
    if (CheckValidLength(_data.ownership)) return setValidErr(true);
    if (CheckValidLength(_data.status)) return setValidErr(true);
    if (CheckMobile(_data.mobile)) return setValidErr(true);

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "enterpriseId") {
        params[key] = enterpriseData.id;
      } else if (key === "familyIncome") {
        params[key] = +_data[key];
      } else {
        params[key] = _data[key];
      }
    });
    try {
      const res = await defaultApi.createFarmer(params);
      console.log(res.statusText);
      setData(initData);
      _fetchData();
      _onSwitch(false);
      toast.success(Message.notify.insert_success, { duration: 3000 });
    } catch (error) {
      const err_member_no =
        error?.response?.data?.memberNo[0] + "❗️" ||
        Message.notify.insert_error;

      console.log(error);
      toast.error(err_member_no, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  useEffect(() => {
    /* Function close dropdown 'DateSign and DateEnd' : START */
    const onCloseDropdownDateSign = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_sign_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateSign(false);
      }
    };
    const onCloseDropdownDateEnd = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_end_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateEnd(false);
      }
    };

    if (openDateSign) {
      document.addEventListener("click", onCloseDropdownDateSign);
    }
    if (openDateEnd) {
      document.addEventListener("click", onCloseDropdownDateEnd);
    }
    /* Function close dropdown 'DateSign and DateEnd' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDateSign);
      document.removeEventListener("click", onCloseDropdownDateEnd);
    };
  }, [openDateSign, openDateEnd]);

  return (
    <div className={`${styles.container}`}>
      <h4 className={`${styles.title_text}`}>เพิ่มรายละเอียดข้อมูลสมาชิก</h4>
      <div className={`${styles.input_form}`}>
        <div className={`${styles.form_top}`}>
          <div className={`${styles.img_box}`}>
            <div className={`${styles.box_}`}>
              {!_data?.avatar && !imgData.file ? (
                <img src={icon.user} alt="" className={`${styles.no_img}`} />
              ) : (
                <img src={imgData.file} alt="" className={`${styles.img_}`} />
              )}
              <input
                type="file"
                name=""
                id="file_img"
                accept="image/jpeg, image/png"
                onChange={changeImg}
                hidden
              />
              <label htmlFor="file_img" id="file-label" hidden></label>
            </div>
            <label
              onClick={() => document.querySelector("#file-label").click()}
            >
              เพิ่มรูปภาพ
            </label>
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              คำนำหน้าชื่อ <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <select
              name="title"
              className={`${validErr && _data.title === "" ? "error" : ""} ${
                _data.title === "" ? "" : "show"
              }`}
              onChange={onChange}
            >
              <option style={{ display: "none" }}>เลือกคำนำหน้าชื่อ</option>
              {title.map((item) => {
                return (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.title)}
              </p>
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              วันที่เข้าร่วมโครงการ{" "}
              <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div
              className={`${styles.input_unit} ${
                validErr && _data.signDate === "" ? "error" : ""
              }`}
            >
              <div className={`${styles.info_box_}`}>
                <p>
                  {_data.signDate !== "" ? convertDateThai(_data.signDate) : ""}
                </p>
              </div>
              <div
                className={`${styles.unit}`}
                onClick={() => {
                  setOpenDateSign(!openDateSign);
                }}
              >
                <img src={icon.calendar} alt="" id="ent_sign_date_d" />
              </div>
            </div>
            {openDateSign && (
              <DatePicker
                selected={selectedDate.sign}
                onChange={onChangeDateSign}
                inline
              />
            )}
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.signDate)}
              </p>
            )}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              ชื่อ <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <input
              type="text"
              name="firstname"
              value={_data.firstname}
              className={`${validErr && _data.firstname === "" ? "error" : ""}`}
              onChange={onChange}
            />
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckFname(_data.firstname)}
              </p>
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              วันที่สิ้นสุดโครงการ <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div
              className={`${styles.input_unit} ${
                validErr && _data.endDate === "" ? "error" : ""
              }`}
            >
              <div className={`${styles.info_box_}`}>
                <p>
                  {_data.endDate !== "" ? convertDateThai(_data.endDate) : ""}
                </p>
              </div>
              <div
                className={`${styles.unit}`}
                onClick={() => {
                  setOpenDateEnd(!openDateEnd);
                }}
              >
                <img src={icon.calendar} alt="" id="ent_end_date_d" />
              </div>
            </div>
            {openDateEnd && (
              <DatePicker
                selected={selectedDate.end}
                onChange={onChangeDateEnd}
                inline
              />
            )}
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.endDate)}
              </p>
            )}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              นามสกุล <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <input
              type="text"
              name="lastname"
              value={_data.lastname}
              className={`${validErr && _data.lastname === "" ? "error" : ""}`}
              onChange={onChange}
            />
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckLname(_data.lastname)}
              </p>
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              รายได้ครัวเรือนเฉลี่ยต่อเดือน{" "}
              <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div
              className={`${styles.input_unit} ${
                validErr && _data.familyIncome === "" ? "error" : ""
              } ${_data.familyIncome === "" ? "" : "show"}`}
            >
              <input type="text" name="familyIncome" onChange={onChange} />
              <div className={`${styles.unit}`}>
                <span>บาท</span>
              </div>
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckNumber(_data.familyIncome)}
              </p>
            )}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              หมายเลขบัตรประชาชน <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <input
              type="text"
              name="memberNo"
              className={`${validErr && _data.memberNo === "" ? "error" : ""} ${
                _data.memberNo === "" ? "" : "show"
              }`}
              maxLength={13}
              onChange={onChange}
            />
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckNumberCard(_data.memberNo)}
              </p>
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              ชื่อเจ้าของกรรมสิทธิ์{" "}
              <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <input
              type="text"
              name="owner"
              className={`${validErr && _data.owner === "" ? "error" : ""} ${
                _data.owner === "" ? "" : "show"
              }`}
              onChange={onChange}
            />
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.owner)}
              </p>
            )}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              ที่อยู่ <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <input
              type="text"
              name="address"
              className={`${validErr && _data.address === "" ? "error" : ""} ${
                _data.address === "" ? "" : "show"
              }`}
              onChange={onChange}
              onFocus={() => setPlaceholderDisabled(true)}
              onBlur={() => setPlaceholderDisabled(false)}
              placeholder={
                placeholderDisabled
                  ? ""
                  : "ตัวอย่างที่อยู่ 7/11 ต.ภูผา อ.ภูผา จ.ขอนแก่น"
              }
            />
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.address)}
              </p>
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              สถานะการครอบครอง <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <select
              name="ownership"
              className={`${
                validErr && _data.ownership === "" ? "error" : ""
              } ${_data.ownership === "" ? "" : "show"}`}
              onChange={onChange}
            >
              <option style={{ display: "none" }}>เลือกสถานะการครอบครอง</option>
              {ownershipStatus.map((item) => {
                return (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.ownership)}
              </p>
            )}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              สถานะสมาชิก <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <select
              name="status"
              className={`${validErr && _data.status === "" ? "error" : ""} ${
                _data.status === "" ? "" : "show"
              }`}
              onChange={onChange}
            >
              <option style={{ display: "none" }}>เลือกสถานะสมาชิก</option>
              {memberStatus.map((item) => {
                return (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.status)}
              </p>
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              เบอร์โทรศัพท์ <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <input
              type="text"
              name="mobile"
              className={`${validErr && _data.mobile === "" ? "error" : ""} ${
                _data.mobile === "" ? "" : "show"
              }`}
              maxLength={10}
              onChange={onChange}
            />
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckMobile(_data.mobile)}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.button_center}`}>
        <button
          type="button"
          className={`${styles.btn_}`}
          onClick={createFarmer}
        >
          บันทึก
        </button>
        <button
          type="button"
          className={`${styles.btn_}`}
          onClick={() => _onSwitch(false)}
        >
          ยกเลิก
        </button>
      </div>
    </div>
  );
};

export default AddMember;
