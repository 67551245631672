import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Map from "ol/Map";
import View from "ol/View";
import "ol/ol.css";
import { defaults as defaultControls } from "ol/control";
import { defaults as defaultInteractions } from "ol/interaction";
import { DEFALUT_CENTER, OSM_LAYER } from "../../../helpers/map.helper";
import styles from "./MapElement.module.scss";

const MapElement = React.memo(({ onInit }) => {
  const mountRef = useRef();
  const mapRef = useRef();
  const navigate = useNavigate();

  /** @type {Array.<Map|Function>} */
  const [map, setMap] = useState(null);

  const _createMap = useCallback(
    (el) => {
      const layers = [OSM_LAYER];

      const newMap = new Map({
        target: el,
        view: new View({
          minZoom: 5,
          maxZoom: 23,
          center: DEFALUT_CENTER,
          zoom: 6.2,
        }),
        pixelRatio: 1,
        layers,
        controls: defaultControls({
          zoom: false,
          attribution: false,
        }),
        interactions: defaultInteractions({ mouseWheelZoom: true }).extend([]),
      });

      setMap(newMap);
      onInit(newMap);

      //console.log("Map initialized");
    },
    [onInit]
  );

  const clickHandler = useCallback(
    (evt) => {
      map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        const factoryId = feature.getProperties().factoryId;
        navigate(`/manage/${factoryId}`);
      });
    },
    [map, navigate]
  );

  const dispose = useCallback(() => {
    map?.un("click", clickHandler);
    map?.setTarget(null);
  }, [map, clickHandler]);

  useEffect(() => {
    // Waiting for layout initialized.
    if (!mountRef.current && mapRef.current) {
      mountRef.current = true;
      setTimeout(() => _createMap(mapRef.current), 450);
    }
  }, [mountRef, mapRef, _createMap]);

  useEffect(() => {
    map?.un("click", clickHandler);
    map?.on("click", clickHandler);
  }, [map, clickHandler, navigate]);

  useEffect(() => {
    return () => dispose();
  }, [dispose]);

  return <div ref={mapRef} className={`${styles.container}`}></div>;
});

export default MapElement;
