import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { QueryGetAll } from "../../../helpers/api.helper";
import { defaultApi } from "../../../services/api";
import { getProfileData } from "../../../services/auth.slice";
import ChartsMonthSellingPlants from "./charts/ChartsMonthSellingPlants";
import ExportPopup from "../../../layouts/exportpopup/ExportPopup";
import { convertDateThai2 } from "../../../utils/format/Date.format";
import styles from "./scss/ReportMonthSellingPlants.module.scss";

const ReportMonthSellingPlants = () => {
  const profileData = useSelector(getProfileData);
  const chart = useRef();

  /* Highcharts export function : START */
  const [_triggerExport, setTriggerExport] = useState(false);

  const [_resName, resName] = useState(null);
  const [_resData, resData] = useState(null);
  const [_resDfSet, resDfSet] = useState(null);

  const [_colors, setColors] = useState({});
  //let _resDfSet = null

  const [_setKeyFact, keyFact] = useState({
    factId: "",
  });
  const [stateMonthYear, setStateMonthYear] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    active: false,
  });

  window.ChartTitleUnit = {
    title: 'วันที่',
    unit: ['ตัน'],
    totalStatus : true
  }

  const [_activeSelect, setActiveSelect] = useState(false)

  const downloadCSV = () => {
    // if (chart && chart.current && chart.current.chart) {
    //   chart.current.chart.downloadCSV();
    // }
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.downloadCSV();
    }
  };

  const downloadImage = () => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.setTitle({ text: "แผนผลการรับซื้อเชื้อเพลิง" });
      chart.current.chart.setSubtitle({ text: _resName });

      chart.current.chart.exportChart({
        type: "image/jpeg",
        filename: "chart",
      });

      chart.current.chart.setTitle({ text: "" });
      chart.current.chart.setSubtitle({ text: "" });
    }
  };
  /* Highcharts export function : END */

  const info = useCallback(async () => {
    let qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=createdBy%20eq%20${profileData.id}`,
      $orderby: "",
    };

    const resAllFac = await defaultApi.getAllFactoryMember(``);

    if (resAllFac?.data?.length > 0) {
      for (let index = 0; index < resAllFac?.data?.length; index++) {
        const element = resAllFac?.data[index];
        if (+element?.userId === +profileData?.id) {
          qryGetAll = {
            $count: "%24count=true",
            $expand: "",
            $top: "",
            $skip: "",
            $filter: `&%24filter=id%20eq%20${element.factoryId}`,
            $orderby: "",
          };
        }
      }
    }

    const _query = QueryGetAll(qryGetAll);
    const res_entp = await defaultApi.getAllFactory(_query);
    const factId = res_entp.data.datas[0].id;

    keyFact({
      factId: factId,
    });

    let date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    const res_dfset = await defaultApi.getReportDashboard(
      "powergenYear=" +
        year +
        "&factoryTransactionYear=" +
        year +
        "&enterpriseTransactionYear=" +
        year
    );
    const res_data = await defaultApi.getReportFactoryTransactionMonth(
      "factoryId=" + factId + "&atMonth=" + month + "&atYear=" + year
    );
    const colors = await defaultApi.getColors();
    setColors(colors);
    resDfSet(res_dfset);
    resData(res_data);

    resName(res_entp.data.datas[0].name);
  }, [profileData]);

  const selectYear = () => {
    const date = new Date();
    let year = date.getFullYear();
    let yearLst = [];
    yearLst.push({ year: year-1, yearName: year-1 + 543 });
    for (let index = 0; index < 21; index++) {
      yearLst.push({ year: year, yearName: year + 543 });
      //year = year - 1
      year = year + 1;
    }

    let x = yearLst.map((datax, index) => {
      return (
        <option key={index} value={datax.year}>
          {datax.yearName}
        </option>
      );
    });

    return (
      <>
        <option key={`df_01`} style={{ display: "none" }}>
          ปี
        </option>
        {x}
      </>
    );
  };

  const selectMonth = () => {
    /**
     * "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
     */
    let monthNameLst = [
      "",
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];
    let monthLst = [];
    for (let index = 0; index <= 12; index++) {
      let num = "";
      index < 10 ? (num = "0" + index) : (num = index);
      monthLst.push({ month: num.toString(), monthName: monthNameLst[index] });
    }

    let x = monthLst.map((datax, index) => {
      let x;
      if (index >= 1) {
        x = (
          <option key={index} value={datax.month}>
            {datax.monthName}
          </option>
        );
      }
      return x;
    });

    return (
      <>
        <option key={`df_01`} style={{ display: "none" }}>
          เดือน
        </option>
        {x}
      </>
    );
  };

  const selM = (value) => {
    setStateMonthYear((prev) => ({ ...prev, month: value, active: true }));
  };

  const selY = (value) => {
    setStateMonthYear((prev) => ({ ...prev, year: value, active: true }));
  };

  const iSearch = async () => {
    // if (listSel.month !== "" && listSel.year !== "") {

    // }
    // const res_dfset = await defaultApi.getReportDashboard("powergenYear=" + listSel.year + "&factoryTransactionYear=" + listSel.year + "&enterpriseTransactionYear=" + listSel.year)
    const res_data = await defaultApi.getReportFactoryTransactionMonth(
      "factoryId=" +
        _setKeyFact.factId +
        "&atMonth=" +
        stateMonthYear.month +
        "&atYear=" +
        stateMonthYear.year
    );
    // resDfSet(res_dfset)
    resData(res_data);

    setStateMonthYear((prev) => ({ ...prev, active: false }));
  };

  const dateDefault = useMemo(() => {
    const date = new Date();
    if (_activeSelect) {
      return `${convertDateThai2(
        stateMonthYear.month.toString(),
        "month"
      )}/${convertDateThai2(stateMonthYear.year.toString(), "year")}`;
    } else {
      return `${convertDateThai2(date, "day")}/${convertDateThai2(
        stateMonthYear.month.toString(),
        "month"
      )}/${convertDateThai2(stateMonthYear.year.toString(), "year")}`;
    }
    
  }, [stateMonthYear, _activeSelect]);

  useEffect(() => {
    if (stateMonthYear.active) iSearch();
  }, [stateMonthYear]);

  useEffect(() => {
    info();
  }, [info]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.date_filter}`}>
        <select
          style={{ color: "black" }}
          name=""
          id="sel01"
          onChange={(e) => {
            selM(e.target.value);
            setActiveSelect(true)
          }}
        >
          {selectMonth()}
        </select>
        <select
          style={{ color: "black" }}
          name=""
          id="sel02"
          onChange={(e) => {
            selY(e.target.value);
            setActiveSelect(true)
          }}
        >
          {selectYear()}
        </select>
      </div>

      <h4 className={`${styles.title_text}`}>แผนผลการรับซื้อเชื้อเพลิง</h4>

      <h5 className={`${styles.title_text_}`}>{_resName}</h5>

      <div className={`${styles.show_date}`}>
        <p>ข้อมูล ณ</p>
        <p>{dateDefault}</p>
      </div>

      <div className={`${styles.charts_}`}>
        <ChartsMonthSellingPlants
          ref={chart}
          colors={_colors}
          dfset={_resDfSet}
          datain={_resData}
          datetime={stateMonthYear}
        />
      </div>

      <div className={`${styles.export_}`}>
        <ExportPopup
          label={"report"}
          trigger={_triggerExport}
          event={{ csv: downloadCSV, image: downloadImage }}
        />
        <button
          id="exporting"
          type="button"
          className={`${styles.btn_}`}
          onClick={() => {
            _triggerExport === true
              ? setTriggerExport(false)
              : setTriggerExport(true);
          }}
        >
          Export
        </button>
      </div>
    </div>
  );
};

export default ReportMonthSellingPlants;
