import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import EditSaleForm from "./EditSaleForm";
import EditForm from "./EditForm";
import Pagination from "../../../../../components/pagination/Pagination";
import useSwitch from "../../../../../utils/hooks/useSwitch";
import { defaultApi } from "../../../../../services/api";
import { convertDateThai } from "../../../../../utils/format/Date.format";
import {
  exportCsvFile,
  exportXlsxFile,
} from "../../../../../helpers/func.helper";
import { getEnterpriseData } from "../../../../../services/enterprise.slice";
import { QueryGetByID } from "../../../../../helpers/api.helper";
import ExportPopup from "../../../../../layouts/exportpopup/ExportPopup";
import styles from "./scss/Sale.module.scss";

const pageSize = 10;

const Sale = ({
  _allTransaction,
  _onPaginate,
  _filterGroupR,
  _totalCountR,
  _currentPageR,
  _onSwitch2,
  _switchPage,
  _onSwitchPage,
  _fetchData,
  _themeColor,
}) => {
  const enterpriseData = useSelector(getEnterpriseData);
  const [_switch, onSwitch] = useSwitch();

  const [transaction, setTransaction] = useState({});
  const [_triggerExport, setTriggerExport] = useState(false);

  /* Export csv and excel : START */
  const onExportCsv = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "EnterpriseTransactionReport";
    let fileType = "csv";
    let obj = {
      atDate: _filterGroupR.atDate,
      toDate: _filterGroupR.toDate,
      enterpriseId: enterpriseData?.id,
      note: "buy",
    };
    // Ready
    exportCsvFile(exportName, fileType, obj);
  };
  const onExportXlsx = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "EnterpriseTransactionReport";
    let fileType = "excel";
    let obj = {
      atDate: _filterGroupR.atDate,
      toDate: _filterGroupR.toDate,
      enterpriseId: enterpriseData?.id,
      note: "buy",
    };
    // Ready
    exportXlsxFile(exportName, fileType, obj);
  };
  /* Export csv and excel : END */

  const getTransaction = useCallback(async () => {
    const _id = _switchPage;

    if (!_id) return;

    const qryGetById = {
      $expand: `&%24expand=plantType%2C%20enterprise%2C%20contractFarming
        %2C%20TransactionReceiptNavigation%2C%20TransactionWeightNavigation`,
      $filter: "",
      $orderby: "",
    };
    const _queryID = QueryGetByID(qryGetById);
    try {
      const res = await defaultApi.getTransaction(_id, _queryID);
      setTransaction(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [_switchPage]);

  useEffect(() => {
    // Use functions
    getTransaction();
  }, [getTransaction]);

  return (
    <div className={`${styles.container}`}>
      {_switchPage === null && (
        <div className={`${styles.table_container}`}>
          <div className={`${styles.table_box} table-responsive`}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>วันที่รายงาน</th>
                  <th>โรงไฟฟ้า</th>
                  <th>ชนิดเชื้อเพลิง / วัตถุดิบ</th>
                  <th>จำนวน(ตัน)</th>
                  <th>ยอดรวมสุทธิ(บาท)</th>
                  <th>วันที่รับเงิน</th>
                  <th>ได้รับเงิน</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {_allTransaction.map((item) => {
                  const datas = JSON.parse(item.datas);
                  const plantArr = datas
                    ?.map((val) => val.plant_name)
                    ?.join(",");

                  return (
                    <tr key={item.id}>
                      <td>{convertDateThai(item.createdAt)}</td>
                      <td>{item.buyer}</td>
                      <td>{plantArr}</td>
                      <td>{item.amount}</td>
                      <td>{item.total?.toLocaleString()}</td>
                      <td>{convertDateThai(item.payDate)}</td>
                      <td>
                        {item.paymentStatus !== "received"
                          ? "ยังไม่ได้รับ"
                          : "✅"}
                      </td>
                      <td>
                        <FaEye
                          className={`${styles.fa_eye}`}
                          onClick={() => _onSwitchPage(item.id)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className={`${styles.pagination_}`}>
            <Pagination
              pageSize={pageSize}
              totalCount={_totalCountR}
              currentPage={_currentPageR}
              onPageChange={(page) => _onPaginate(page)}
              _themeColor={_themeColor}
            />
          </div>
        </div>
      )}

      {_switchPage !== null &&
        (_switch ? (
          <EditForm
            _transaction={transaction}
            _onSwitch={onSwitch}
            _fetchData={() => {
              getTransaction();
              _fetchData();
            }}
          />
        ) : (
          <EditSaleForm
            _transaction={transaction}
            _onSwitch={onSwitch}
            _onSwitchPage={_onSwitchPage}
          />
        ))}

      {_switchPage === null && (
        <div className={`${styles.button_right}`}>
          <ExportPopup
            label={"document"}
            trigger={_triggerExport}
            event={{ csv: onExportCsv, xlsx: onExportXlsx }}
          />
          <button
            type="button"
            className={`${styles.btn_}`}
            onClick={() => _onSwitch2(true)}
          >
            บันทึกการขาย
          </button>
          <button
            type="button"
            className={`${styles.btn_}`}
            onClick={() => setTriggerExport(!_triggerExport)}
          >
            Export
          </button>
        </div>
      )}
    </div>
  );
};

export default Sale;
