import React, { useMemo } from "react";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from "../scss/ReportYearSellingPlants.module.scss";
import { formatterStack, formatterTooltip, formatterXaxis_1, formatterYaxis } from "../../../../helpers/suffixchart.helper";

let ranges = [
  { divider: 1e18, suffix: "E" },
  { divider: 1e15, suffix: "P" },
  { divider: 1e12, suffix: "T" },
  { divider: 1e9, suffix: "G" },
  { divider: 1e6, suffix: "M" },
  { divider: 1e3, suffix: "K" },
];

const ChartsYearSellingPlants = React.forwardRef((prop, ref) => {
  const setitemDetail = useMemo(() => {
    let setitemDetail = [];
    let setitemTotal = [];
    let target = [];

    const loopItemData = (plantid, setplant, dfyears, dfmonths, year) => {
      let arr = [];

      const loopDetail = (id1, setplant1) => {
        try {
          let obj = JSON.parse(setplant1);
          for (let index = 0; index < obj.length; index++) {
            let i = 1 + index;
            const element = obj[index];
            if (element.plant_id === id1 && i <= obj.length) {
              return element?.amount;
            } else if (element.plant_id !== id1 && i === obj.length) {
              return 0;
            }
          }
        } catch (error) { }
      };

      try {
        for (let index = 0; index < setplant.length; index++) {
          const element = setplant[index].value;

          if (element === null) {
            arr[index] = 0;
          } else {
            arr[index] = loopDetail(plantid, element);
          }

          if (year === dfyears) {
            if (setplant[index].month <= dfmonths) {
              if (element === null) {
                arr[index] = 0;
              } else {
                arr[index] = loopDetail(plantid, element);
              }
            } else if (setplant[index].month > dfmonths) {
              arr[index] = null;
            }
          } else if (year < dfyears) {
            if (element === null) {
              arr[index] = 0;
            } else {
              arr[index] = loopDetail(plantid, element);
            }
          }
        }

        return arr;
      } catch (error) { }
    };

    const loopItemColor = (idx, setcolor) => {
      try {
        for (let index = 0; index < setcolor.length; index++) {
          const element = setcolor[index].colorCode;
          if (idx === index) {
            return element;
          } else if (idx > setcolor.length) {
            return "#000000";
          }
        }
      } catch (error) { }
    };

    const loopItemDataTotal = (setplant) => {
      try {
        let total = 0;
        if (setplant.value === null) {
          total = total + 0;
        } else {
          let obj = JSON.parse(setplant.value);
          for (let index = 0; index < obj.length; index++) {
            total = total + obj[index].amount;
          }
        }
        return total;
      } catch (error) { }
    };

    try {
      let date = new Date();
      let dfyears = date.getFullYear();
      let dfmonths = date.getMonth() + 1;
      let year = Number(prop.datetime);

      let dfset = prop.dfset.data;
      let datain = prop.datain.data;

      let plant = dfset.enterprisePlants;

      for (let index = 0; index < datain.factoryTransaction.length; index++) {
        const element = datain.factoryTransaction[index];

        if (year === dfyears) {
          if (element.month <= dfmonths) {
            setitemTotal[index] = loopItemDataTotal(
              datain.factoryTransaction[index]
            );
            target[index] = datain.factoryTransaction[index].target;
          } else if (element.month > dfmonths) {
            setitemTotal[index] = null;
            target[index] = null;
          }
        } else if (year < dfyears) {
          setitemTotal[index] = loopItemDataTotal(
            datain.factoryTransaction[index]
          );
          target[index] = datain.factoryTransaction[index].target;
        }
      }

      for (let index = 0; index < plant.length; index++) {
        const el = plant[index];
        //ลูบด้วยพืช
        setitemDetail[index] = {
          name: el.plantName,
          data: loopItemData(
            el.plantId,
            datain.factoryTransaction,
            dfyears,
            dfmonths,
            year
          ),
          color: loopItemColor(index, prop?.colors?.data?.color),
          type: 'column',
          visible: true
        };
      }

      // setitemDetail[setitemDetail.length] = {
      //   name: "ปริมาณที่รับซื้อทั้งหมด",
      //   data: setitemTotal,
      //   color: "#000000",
      //   lineWidth: 4,
      //   dashStyle: "solid",
      //   type: 'column',
      //   showInLegend: false
      // };
      setitemDetail[setitemDetail.length] = {
        name: "ปริมาณที่ส่งมอบตามสัญญา",
        data: target,
        color: "navy",
        dashStyle: "dash",
        type: 'line',
        visible: true
      };
    } catch (error) { }

    return setitemDetail;
  }, [prop]);

  const options = {
    chart: {
      type: "column",
      // type: "line",
      height: 470,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    yAxis: {
      stackLabels: {
        enabled: true,
        formatter: formatterStack,
      },
      title: {
        text: "ปริมาณผลผลิต (ตัน)",
      },
      labels: {
        formatter: formatterYaxis, // Show stack labels
        style: {
          fontSize: "13px",
        },
      },
    },
    tooltip: {
      //pointFormat: "<b>{series.name}: {point.y} ตัน</b>",
      style: {
        fontSize: "18px",
      },
      enabled: true,
      ...formatterTooltip
    },
    // legend: {
    //   layout: "vertical",
    //   align: "right",
    //   verticalAlign: "top",
    // },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter : formatterXaxis_1,
          // formatter: function () {
          //   for (let i = 0; i < ranges.length; i++) {
          //     if (this.y >= ranges[i].divider) {
          //       return (
          //         Number(this.y / ranges[i].divider).toLocaleString(undefined, {
          //           maximumFractionDigits: 2,
          //           minimumFractionDigits: 2,
          //         }) + ranges[i].suffix
          //       );
          //     }
          //   }
          //   return Number(this.y).toLocaleString(undefined, {
          //     maximumFractionDigits: 2,
          //     minimumFractionDigits: 2,
          //   });
          // },
          style: {
            fontSize: "16px",
          },
        },
      },
      line: {
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "16px",
          },
          formatter: formatterXaxis_1,
        },

        enableMouseTracking: true,
      },
      series: {
        lineWidth: 3,
        marker: {
          enabled: false,
        },
      },
    },
    // colors: ["#5076ff", "#c324c7", "#1dd8bd", "#e83880"],
    exporting: {
      chartOptions: {
        // specific options for the exported image
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "6px",
              },
              formatter: function () {
                for (let i = 0; i < ranges.length; i++) {
                  if (this.y >= ranges[i].divider) {
                    return (
                      Number(this.y / ranges[i].divider).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      ) + ranges[i].suffix
                    );
                  }
                }
                if (Number(this.y) === 0) {
                  return null;
                  //return this.y.toString();
                } else {
                  return Number(this.y).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  });
                }
              },
            },
            enableMouseTracking: true,
          },
        },
        xAxis: {
          labels: {
            style: {
              fontSize: "6px",
            },
          },
        },
        yAxis: {
          stackLabels: {
            enabled: true // Show stack labels
          },
          labels: {
            style: {
              fontSize: "6px",
            },
          },
        },
      },
      buttons: {
        contextButton: {
          enabled: false,
        },
        button: {
          text: "Export",
          theme: {
            fill: "#f9df70",
            stroke: "#f9df70",
            states: {
              hover: {
                fill: "#fcc",
                stroke: "#f00",
              },
              select: {
                fill: "#cfc",
                stroke: "#0f0",
              },
            },
          },
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "bottom",
        y: 0,
      },
    },
    series: setitemDetail?.filter((item) => {
      return item.data.some((value) => value !== 0 && value !== null);
    }),
  };

  return (
    <div className={`${styles.charts}`}>
      <HighCharts ref={ref} options={options} />
    </div>
  );
});

export default ChartsYearSellingPlants;
