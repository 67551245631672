import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "react-autocomplete";
import { GrFormClose } from "react-icons/gr";
import { toast } from "react-hot-toast";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import icon from "../../../../../../../assets/img/Icon";
import { defaultApi } from "../../../../../../../services/api";
import {
  CheckAddPlantType,
  CheckDataStDateEn,
  CheckDateEnDataSt,
  CheckNumber,
  CheckValidLength,
} from "../../../../../../../utils/validation_input/Validation";
import Message from "../../../../../../../utils/message/Message";
import {
  getFactoryData,
  getSubmitActive,
  setSubmitActive,
} from "../../../../../../../services/factory.slice";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../../../../utils/format/Date.format";
import { QueryGetAll } from "../../../../../../../helpers/api.helper";
import styles from "./EditContractFForm.module.scss";

const animated = makeAnimated();

const contractType = [
  { id: "สัญญาหลัก", name: "สัญญาหลัก" },
  { id: "สัญญารอง", name: "สัญญารอง" },
];

const Input = ({ _plant, num, setPlantArr, onDeleteInput }) => {
  const dispatchFn_ = useDispatch();
  const submitActive = useSelector(getSubmitActive);

  const [newPlant, setNewPlant] = useState({
    name: "",
  });
  const [validErr, setValidErr] = useState(true);

  const _onChange = (val) => {
    setNewPlant({ name: val });
    if (CheckAddPlantType(val, _plant)) {
      setValidErr(true);
    } else {
      setValidErr(false);
    }
  };
  const _onSelect = (val) => {
    setNewPlant(val);
    if (CheckAddPlantType(val.name, _plant)) {
      setValidErr(true);
    } else {
      setValidErr(false);
    }
  };
  const createPlantType = useCallback(async () => {
    if (CheckValidLength(newPlant.name)) {
      setValidErr(true);
      dispatchFn_(setSubmitActive(false));
      return;
    }
    if (validErr) {
      dispatchFn_(setSubmitActive(false));
      return;
    }

    const loading = toast.loading("กำลังเพิ่มเชื้อเพลิง...");
    try {
      const res = await defaultApi.createPlantType(newPlant);
      console.log(res.statusText);
      // Add to _plantArr state
      setPlantArr((prev) => [
        ...prev,
        { id: res?.data.id, name: res?.data.name },
      ]);
      // รอเพิ่มพื้นที่สร้างใหม่ในสเตจก่อนค่อน submit ขั้นสุดท้าย
      setTimeout(() => {
        dispatchFn_(setSubmitActive("Final"));
      }, 1000);
    } catch (error) {
      console.log(error);
      dispatchFn_(setSubmitActive(false));
      toast.error("เพิ่มเชื้อเพลิงไม่สำเร็จ", {
        duration: 3000,
      });
    } finally {
      toast.dismiss(loading);
    }
  }, [newPlant, validErr, setPlantArr, dispatchFn_]);

  useEffect(() => {
    if (submitActive === "createPlant") {
      createPlantType();
    }
  }, [createPlantType, submitActive]);

  return (
    <>
      <div className={`${styles.auto_com_box}`}>
        <Autocomplete
          inputProps={{
            className: `${styles.input_box} ${
              validErr && newPlant.name === "" ? "error" : ""
            }`,
          }}
          wrapperStyle={{ fontSize: "16px", width: "100%" }}
          items={_plant}
          shouldItemRender={(item, value) =>
            item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
          }
          getItemValue={(item) => item.name}
          renderItem={(item, highlighted) => (
            <div
              key={item.id}
              style={{ backgroundColor: highlighted ? "#eee" : "transparent" }}
            >
              {item.name}
            </div>
          )}
          value={newPlant.name}
          onChange={(e) => _onChange(e.target.value)}
          onSelect={(name) => _onSelect({ name })}
        />
        <GrFormClose
          onClick={() => onDeleteInput(num)}
          size={30}
          cursor={"pointer"}
        />
      </div>
      {validErr && (
        <p className={`${styles.err_message}`}>
          {CheckAddPlantType(newPlant.name, _plant)}
        </p>
      )}
    </>
  );
};

const EditForm = ({
  _switch,
  onSwitch,
  _contractF,
  _plantValue,
  _fetchData,
  _fetchDataTable,
  _contractStatus,
}) => {
  const dispatchFn_ = useDispatch();
  const submitActive = useSelector(getSubmitActive);
  const factoryData = useSelector(getFactoryData);

  const [_data, setData] = useState([]);
  const [allEnterprise, setAllEnterprise] = useState([]);
  const [allPlant, setAllPlant] = useState([]);
  const [plantValue_, setPlantValue] = useState("");
  const [plantOpt, setPlantOpt] = useState([]);
  const [_plantArr, setPlantArr] = useState("");
  const [inputList, setInputList] = useState([]);
  const [pdfName, setPdfName] = useState("");
  const [selectedDate, setSelectedDate] = useState({
    sign: new Date(),
    end: new Date(),
  });
  const [openDateSign, setOpenDateSign] = useState(false);
  const [openDateEnd, setOpenDateEnd] = useState(false);
  const [validErr, setValidErr] = useState(false);

  /* DatePicker : START */
  const onChangeDateSign = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, signDate: dateFormat });
    setSelectedDate({ ...selectedDate, sign: e });
    setOpenDateSign(!openDateSign);
  };
  const onChangeDateEnd = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, endDate: dateFormat });
    setSelectedDate({ ...selectedDate, end: e });
    setOpenDateEnd(!openDateEnd);
  };
  /* DatePicker : END */

  const onGenInput = () => {
    setInputList(
      inputList.concat(
        <Input
          key={inputList.length + 1}
          num={inputList.length + 1}
          _plant={allPlant}
          setPlantArr={setPlantArr}
          onDeleteInput={onDeleteInput}
        />
      )
    );
  };
  const onDeleteInput = (id) => {
    setInputList(inputList.filter((idx) => idx.id !== id));
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };
  const updateFileDetail = async (e) => {
    const file = document.querySelector("#add_file");
    if (!window.confirm("ต้องการที่จะแก้ไขไฟล์หรือไม่ ?")) {
      file.value = "";
      setPdfName("");
      return;
    }
    if (!e.target.files.length)
      return setData({
        ..._data,
        contractFarmingDoc: _data?.contractFarmingDoc,
      });

    const loading = toast.loading("กำลังเพิ่มสัญญาฉบับจริง");
    const _id = _data?.contractFarmingDoc;
    const param1 = "ContractFarming";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);

    try {
      const res = await defaultApi.updateFileDetail(_id, param1, param2);
      console.log(res.statusText);
      setData({ ..._data, contractFarmingDoc: res?.data.id });
      toast.success(Message.notify.update_success);
      file.value = "";
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error);
    } finally {
      toast.dismiss(loading);
    }
  };
  const getMultipleApi = useCallback(async () => {
    if (!factoryData?.id) return;

    let factoryId = factoryData?.id;
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=factoryId%20eq%20${factoryId}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const [allEnterprise, allPlantType] = await Promise.all([
        defaultApi.getAllEnterprise(_queryAll),
        defaultApi.getAllPlantType(),
      ]);

      setAllEnterprise(allEnterprise.data.datas);
      setAllPlant(allPlantType.data);
    } catch (error) {
      console.log(error);
    }
  }, [factoryData]);
  const getFilePdfName = () => {
    const file = document.querySelector("#add_file");

    file?.addEventListener("change", () => {
      if (file.value) {
        setPdfName(file.files[0].name);
      } else {
        setPdfName("");
      }
    });
  };
  const onSubmit = () => {
    if (CheckValidLength(_data.no)) return setValidErr(true);
    if (CheckValidLength(_data.enterpriseId)) return setValidErr(true);
    if (CheckValidLength(_data.period)) return setValidErr(true);
    if (CheckValidLength(_data.amountDay)) return setValidErr(true);
    if (CheckValidLength(_data.sendDay)) return setValidErr(true);
    if (CheckValidLength(_data.contractFarmingDoc)) return setValidErr(true);
    if (CheckNumber(_data.preferredDividend)) return setValidErr(true);
    if (CheckNumber(_data.communityShare)) return setValidErr(true);
    if (CheckValidLength(plantValue_)) return setValidErr(true);
    if (CheckDataStDateEn(_data.signDate, _data.endDate))
      return setValidErr(true);
    if (CheckDateEnDataSt(_data.endDate, _data.signDate))
      return setValidErr(true);

    if (inputList.length !== 0) {
      dispatchFn_(setSubmitActive("createPlant"));
    } else {
      dispatchFn_(setSubmitActive("Ready"));
    }
  };
  const updateContractFarming = useCallback(async () => {
    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "period") {
        params[key] = +_data[key];
      } else if (key === "amountDay") {
        params[key] = +_data[key];
      } else if (key === "sendDay") {
        params[key] = +_data[key];
      } else if (key === "plantType") {
        params[key] =
          _plantArr !== "" ? (params[key] = JSON.stringify(_plantArr)) : null;
      } else if (key === "contractFarmingDoc") {
        params[key] = +_data[key];
      } else if (key === "preferredDividend") {
        params[key] = +_data[key];
      } else if (key === "communityShare") {
        params[key] = +_data[key];
      } else {
        params[key] = _data[key];
      }
    });
    try {
      const res = await defaultApi.updateContractFarming(_data?.id, params);
      console.log(res.statusText);
      // setData(res.data);
      setValidErr(false);
      onSwitch(false);
      _fetchData();
      _fetchDataTable();
      toast.success(Message.notify.update_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error, { duration: 3000 });
    } finally {
      dispatchFn_(setSubmitActive(false));
      toast.dismiss(loading);
    }
  }, [_data, _plantArr, dispatchFn_, _fetchData, onSwitch, _fetchDataTable]);

  useEffect(() => {
    /* Insert data to database : START */
    if (submitActive === "Ready" && inputList.length === 0) {
      // เพิ่มข้อมูลแบบไม่มีการสร้างพื้นใหม่
      updateContractFarming();
    } else if (submitActive === "Final" && inputList.length !== 0) {
      // เพิ่มข้อมูลแบบมีการสร้างพื้นใหม่ก่อน
      updateContractFarming();
    }
    /* Insert data to database : END */
  }, [submitActive, inputList, updateContractFarming]);

  useEffect(() => {
    /* แปลงข้อมูลพืชเพื่อเอาไปใช้กับ React-select : START */
    if (allPlant.length !== 0) {
      let newArr = [];
      for (let i = 0; i < allPlant.length; i++) {
        allPlant.forEach((item) => {
          let obj_ = { value: item.id, label: item.name };
          newArr[i] = obj_;
          ++i;
        });
      }
      setPlantOpt(newArr);
    }
    /* แปลงข้อมูลพืชเพื่อเอาไปใช้กับ React-select : END */

    /* แปลงข้อมูลพืชใหม่เตรียมส่ง : START */
    if (plantValue_.length !== 0) {
      let newPlanArr = [];
      for (let i = 0; i < plantValue_.length; i++) {
        plantValue_.forEach((item) => {
          let id_ = item.value;
          let name_ = item.label;
          let obj_ = { id: id_, name: name_ };
          newPlanArr[i] = obj_;
          ++i;
        });
      }
      setPlantArr(newPlanArr);
    } else {
      setPlantArr("");
    }
    /* แปลงข้อมูลพืชใหม่เตรียมส่ง : END */
  }, [allPlant, plantValue_]);

  useEffect(() => {
    // Use function
    getFilePdfName();
    getMultipleApi();
    setData(_contractF);
    setPlantValue(_plantValue);
  }, [_contractF, _plantValue, getMultipleApi]);

  useEffect(() => {
    /* Function close dropdown 'DateSign and DateEnd' : START */
    const onCloseDropdownDateSign = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_sign_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ไม่ต้องทำอะไรเลย
        return;
      }

      // ตรวจสอบว่า element ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateSign(false);
      }
    };
    const onCloseDropdownDateEnd = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_end_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ไม่ต้องทำอะไรเลย
        return;
      }

      // ตรวจสอบว่า element ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateEnd(false);
      }
    };

    if (openDateSign) {
      document.addEventListener("click", onCloseDropdownDateSign);
    }
    if (openDateEnd) {
      document.addEventListener("click", onCloseDropdownDateEnd);
    }
    /* Function close dropdown 'DateSign and DateEnd' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDateSign);
      document.removeEventListener("click", onCloseDropdownDateEnd);
    };
  }, [openDateSign, openDateEnd]);

  return (
    <>
      <form className={`${styles.input_form}`}>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>สัญญาเลขที่</h5>
            <input
              type="text"
              name="no"
              value={_data?.no || ""}
              style={{ background: "#f5f5f5" }}
              readOnly
            />
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              สถานะสัญญา <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <select name="status" onChange={onChange}>
              <option style={{ display: "none" }}>
                {_contractStatus?.find((item) => item.id === _data?.status)
                  ?.statusName || ""}
              </option>
              {_contractStatus?.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.statusName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              วันเริ่มต้นสัญญา <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div className={`${styles.input_unit}`}>
              <div className={`${styles.info_box}`}>
                <p>
                  {_data?.signDate !== ""
                    ? convertDateThai(_data?.signDate)
                    : ""}
                </p>
              </div>
              <div
                className={`${styles.unit}`}
                onClick={() => {
                  setOpenDateSign(!openDateSign);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_sign_date_d" />
              </div>
            </div>
            {openDateSign && (
              <DatePicker
                selected={selectedDate.sign}
                onChange={onChangeDateSign}
                inline
              />
            )}
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckDataStDateEn(_data.signDate, _data.endDate)}
              </p>
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              วันสิ้นสุดสัญญา <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div className={`${styles.input_unit}`}>
              <div className={`${styles.info_box}`}>
                <p>
                  {_data?.endDate !== "" ? convertDateThai(_data?.endDate) : ""}
                </p>
              </div>
              <div
                className={`${styles.unit}`}
                onClick={() => {
                  setOpenDateEnd(!openDateEnd);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_end_date_d" />
              </div>
            </div>
            {openDateEnd && (
              <DatePicker
                selected={selectedDate.end}
                onChange={onChangeDateEnd}
                inline
              />
            )}
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckDateEnDataSt(_data.endDate, _data.signDate)}
              </p>
            )}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>สัญญาระหว่าง(ผู้ซื้อ)</h5>
            <div className={`${styles.info_con}`}>
              <input
                type="text"
                name="factoryId"
                value={_data?.factory?.name || ""}
                readOnly
              />
              <span>และ</span>
            </div>
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              สัญญาระหว่าง(ผู้ขาย) <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <select name="enterpriseId" onChange={onChange}>
              <option style={{ display: "none" }}>
                {_data?.enterprise?.name}
              </option>
              {allEnterprise.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              คู่สัญญาหลัก / รอง <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <select
              name="contractType"
              className={validErr && _data.contractType === "" ? "error" : ""}
              onChange={onChange}
            >
              <option style={{ display: "none" }}>{_data?.contractType}</option>
              {contractType.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={`${styles.input_2}`}></div>
        </div>
        <div className={`${styles.input_grid}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              ระยะเวลาสัญญา <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div
              className={`${styles.input_unit} ${
                validErr && _data.period === "" ? "error" : ""
              }`}
            >
              <input
                type="text"
                name="period"
                value={_data?.period || ""}
                onChange={onChange}
              />
              <div className={`${styles.unit}`}>
                <span>ปี</span>
              </div>
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.period)}
              </p>
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              จำนวนในการส่งมอบ (ตัน/วัน){" "}
              <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <input
              type="text"
              className={`${validErr && _data.amountDay === "" ? "error" : ""}`}
              name="amountDay"
              value={_data?.amountDay || ""}
              onChange={onChange}
            />
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.amountDay)}
              </p>
            )}
          </div>
          <div className={`${styles.input_3}`}>
            <h5>
              จำนวนวันที่ขายและส่งมอบ{" "}
              <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div
              className={`${styles.input_unit} ${
                validErr && _data.sendDay === "" ? "error" : ""
              }`}
            >
              <input
                type="text"
                name="sendDay"
                value={_data?.sendDay || ""}
                onChange={onChange}
              />
              <div className={`${styles.unit}`}>
                <span>วัน/ปี</span>
              </div>
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.sendDay)}
              </p>
            )}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              เชื้อเพลิง <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <Select
              className={`${styles.muti_select}`}
              value={plantValue_}
              closeMenuOnSelect={false}
              components={animated}
              isSearchable={false}
              options={plantOpt}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderRadius: "10px",
                }),
              }}
              placeholder={"เลือกชนิดเชื้อเพลิง"}
              onChange={(e) => setPlantValue(e)}
              isMulti
            />
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(plantValue_)}
              </p>
            )}
            <div className={`${styles.input_}`}>{inputList}</div>
            <div className={`${styles.btn_gen}`}>
              <button
                type="button"
                className={`${styles.btn_}`}
                onClick={onGenInput}
              >
                เพิ่มชนิดเชื้อเพลิง
              </button>
            </div>
          </div>
          <div className={`${styles.input_2}`}>
            <div className={`${styles.input_file}`}>
              <h5>
                สัญญาฉบับจริง <sup className={`${styles.asterisk}`}>*</sup>
              </h5>
              <div
                className={`${styles.input_unit} ${styles.mb_0} ${
                  validErr && _data.contractFarmingDoc === "" ? "error" : ""
                }`}
              >
                <div className={`${styles.info_box}`}>
                  <p>
                    {pdfName !== ""
                      ? pdfName
                      : _data?.contractFarmingDocNavigation?.fileName}
                  </p>
                </div>
                <div className={`${styles.unit}`}>
                  <img
                    src={icon.document}
                    alt=""
                    title="แนบไฟล์"
                    onClick={() =>
                      document.querySelector("#file-label").click()
                    }
                  />
                </div>
              </div>
              {validErr && (
                <p className="err_message">
                  {CheckValidLength(_data.contractFarmingDoc)}
                </p>
              )}
              <span className={`${styles.desc_text}`}>
                *ระบบรองรับไฟล์ที่มีนามสกุล .pdf และมีขนาดไม่เกิน 5 MB
              </span>
              <input
                type="file"
                name="contractFarmingDoc"
                id="add_file"
                accept="application/pdf"
                onChange={updateFileDetail}
                hidden
              />
              <label htmlFor="add_file" id="file-label" hidden></label>
            </div>
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              ปันผลหุ้นบุริมสิทธิ์ <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div
              className={`${styles.input_unit} ${
                validErr && _data?.preferredDividend === "" ? "error" : ""
              }`}
            >
              <input
                type="text"
                name="preferredDividend"
                value={_data?.preferredDividend || ""}
                onChange={onChange}
              />
              <div className={`${styles.unit}`}>
                <span>บาท/ปี</span>
              </div>
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckNumber(_data?.preferredDividend)}
              </p>
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              ส่วนแบ่งชุมชนรอบโรงไฟฟ้า{" "}
              <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <div
              className={`${styles.input_unit} ${
                validErr && _data?.communityShare === "" ? "error" : ""
              }`}
            >
              <input
                type="text"
                name="communityShare"
                value={_data?.communityShare || ""}
                onChange={onChange}
              />
              <div className={`${styles.unit}`}>
                <span>บาท/ปี</span>
              </div>
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckNumber(_data?.communityShare)}
              </p>
            )}
          </div>
        </div>
      </form>

      {_switch && (
        <div className={`${styles.button_right}`}>
          <button type="button" className={`${styles.btn_}`} onClick={onSubmit}>
            บันทึก
          </button>
          <button
            type="button"
            className={`${styles.btn_}`}
            onClick={() => {
              onSwitch(false);
              dispatchFn_(setSubmitActive(false));
            }}
          >
            ยกเลิก
          </button>
        </div>
      )}
    </>
  );
};

export default EditForm;
