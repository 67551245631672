import React, { useCallback, useEffect, useState } from 'react'
import { toast } from "react-hot-toast"
import { FaPen } from "react-icons/fa"
import icon from '../../assets/img/Icon'
import Pagination from "../../components/pagination/Pagination"
import { QueryGetAll } from '../../helpers/api.helper'
import { defaultApi } from '../../services/api'
import { convertDateThai } from '../../utils/format/Date.format'
import Message from "../../utils/message/Message"
import FormManageFactoryMeter from "./form/FormManageFactoryMeter"
import style from "./scss/Headtable.module.scss"

const ManageFactoryMeter = ({ toggerCheck }) => {

    const [_fstLoad, fstLoad] = useState(true)
    const [_allFactory, setAllFactory] = useState("")

    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [_setPlaceHolder, setPlaceHolder] = useState(false)
    const [textSearch, setTextSearch] = useState('')
    const [_toastState, toastState] = useState(false)

    const [_outSearch, setOutSearch] = useState('')

    const [_form, setForm] = useState({})

    const info = useCallback(async () => {

        const _top = currentPage * 10;

        let qryGetAllWithText1 = {
            $count: "%24count=true",
            $expand: "",
            $top: "&%24top=10",
            $skip: `&%24skip=${_top > 10 ? currentPage * 10 - 10 : "0"}`,
            $filter: `${_outSearch}`,
            $orderby: "&%24orderby=createdAt desc",
        };

        const loading = toast;

        const _query1_1 = QueryGetAll(qryGetAllWithText1);

        try {
            const [factoryAll] = await Promise.all([
                defaultApi.getAllFactory(_query1_1),
            ])

            setAllFactory(factoryAll.data.datas)
            fstLoad(false)
            setTotalCount(factoryAll.data.total)

            if (_toastState === true && factoryAll.data.total <= 0) {
                toast.error(Message.notify.search_not_found, { duration: 3000 })
            }
            if (_toastState === true) {
                setCurrentPage(1)
            }
        } catch (error) {

        } finally {
            toast.dismiss(loading);
            toastState(false)
        }

    }, [_toastState, currentPage, _outSearch])

    const onPaginate = useCallback(async (page) => {
        if (Number(currentPage) === Number(page)) {
            return
        }

        const _top = page * 10;

        const qryGetAll = {
            $count: "%24count=true",
            $expand: "",
            $top: `&%24top=10`,
            $skip: `&%24skip=${_top > 10 ? page * 10 - 10 : "0"}`,
            $filter: `${_outSearch}`,
            $orderby: "&%24orderby=createdAt desc",
        };

        const _query1_1 = QueryGetAll(qryGetAll);

        const loading = toast;

        try {
            const [factoryAll] = await Promise.all([
                defaultApi.getAllFactory(_query1_1),
            ])

            setAllFactory(factoryAll.data.datas)
            setTotalCount(factoryAll.data.total)
            fstLoad(false)
            setCurrentPage(page);


            if (_toastState === true && factoryAll.data.total <= 0) {
                toast.error(Message.notify.search_not_found, { duration: 3000 })
            }
            //setCurrentPage(1)
        } catch (error) {

        } finally {
            toast.dismiss(loading);
            toastState(false)
        }

    }, [currentPage, _toastState, _outSearch])

    const searchItem = () => {
        if (textSearch.length > 0) {
            setOutSearch(`&%24filter=contains%28name%2C%20%27${textSearch}%27%29`)
        } else {
            setOutSearch(``)
        }
        setCurrentPage(1);
        info()
    }


    const tb03 = () => {
        try {
            return _allFactory.map((detail, index) => {
                return (
                    <tr key={detail.id}>
                        <th scope="row">{(currentPage - 1) * 10 + (index + 1)}</th>
                        <td align='left'>{detail.name}</td>
                        <td align='center'>{detail?.meterGen}</td>
                        <td align='center'>{detail?.meterSell}</td>
                        <td align='center'>{convertDateThai(detail.updatedAt)}</td>
                        <td>{
                            (<FaPen
                                className={`${style.iconsize}`}
                                // style={detail?.meterGen && detail?.meterSell ? { visibility: 'visible' } : { visibility: 'hidden' }}
                                onClick={() => {
                                    setForm({
                                        id: detail.id,
                                        detailForm: _allFactory[index]
                                    })
                                    document.getElementById('show_modal_edit_2').click()
                                    //$("#show_modal_edit_2").click();
                                }}
                            />)
                        }</td>
                        {/* <td>{detail?.meterGen && detail?.meterSell ?
                            (<FaPen
                                className={`${style.iconsize}`}
                                style={detail?.meterGen && detail?.meterSell ? { visibility: 'visible' } : { visibility: 'hidden' }}
                                onClick={() => {
                                    setForm({
                                        id: detail.id,
                                        detailForm: _allFactory[index]
                                    })
                                    document.getElementById('show_modal_edit_2').click()
                                    //$("#show_modal_edit_2").click();
                                }}
                            />) : '-'
                        }</td> */}
                    </tr>
                )
            })
        } catch (error) {

        }

    }

    useEffect(() => {
        if (_fstLoad === true) {
            info()
        }
    }, [info, _fstLoad])

    useEffect(() => {
        info()
    }, [_outSearch, info, _toastState])

    return (
        <>
            <div style={toggerCheck === true ? { display: 'flex' } : { display: 'none' }} className={`${style.bodytab3}`}>
                <div>
                    <div className={`${style.hpsub}`}>
                        {/* <div className="col" onClick={() => { $("#show_modal_edit_1").click() }}>
                            <img src={icon.add2} alt="add" style={{ "cursor": "pointer" }} />
                            <h5 style={{ "cursor": "pointer" }} >เพิ่มกลุ่มผู้ใช้งานย่อย</h5>
                        </div> */}
                        <div className="col">
                            <input type={"text"}
                                placeholder={_setPlaceHolder === false ? 'กรอกข้อความเพื่อค้นหา' : ''}
                                onFocus={() => { setPlaceHolder(true) }}
                                onBlur={() => { setPlaceHolder(false) }}
                                onChange={(e) => { setTextSearch(e.target.value); }}
                            />
                            <img src={icon.search} alt="search"
                                onClick={() => { searchItem() }} style={{ "cursor": "pointer" }}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="collapse_table table-responsive">
                        <table className="table text-center">
                            <thead>
                                <tr className={`${style.tr}`}>
                                    <th scope="col">ลำดับ</th>
                                    <th scope="col">ชื่อโรงไฟฟ้า</th>
                                    <th scope="col">เลขมิเตอร์ไฟฟ้าเริ่มต้นที่ผลิต</th>
                                    <th scope="col">เลขมิเตอร์ไฟฟ้าที่เริ่มขาย</th>
                                    <th scope="col">วันที่ - เวลา</th>
                                    <th scope="col">แก้ไข</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tb03()
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    {/* <Pagination pageSize={2} totalCount={totalCount} currentPage={currentPage} onPageChange={(page) => onPaginate(page)} /> */}
                    <Pagination pageSize={10} totalCount={totalCount} currentPage={currentPage} onPageChange={(page) => onPaginate(page)} />
                </div>
            </div>
            <button type="button" id='show_modal_edit_2' className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#exampleModal2${_form.id}`} hidden></button>
            <FormManageFactoryMeter datain={_form.id} info={info} infoDatax={_form.detailForm} idForm={`exampleModal2${_form.id}`} />
        </>
    )
}



export default ManageFactoryMeter