import React from "react";
import { Navigate, Outlet } from 'react-router-dom';
import { getCookiesStorageData } from "../helpers/cookies_storage.helper";

const IndexRoutes = ({ redirectPath = "/home" }) => {
  const token_ = getCookiesStorageData("token");
  
  if (!token_) {
    return <Outlet />;
  }
  return <Navigate to={redirectPath} replace />;
};

export default IndexRoutes;
