import React from "react";

const TermsPage = () => {
  return (
    <iframe
      title="Terms website"
      src="PDPA.html"
      style={{ width: "100%", height: "100vh" }}
    ></iframe>
  );
};

export default TermsPage;
