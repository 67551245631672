import React, { useMemo } from "react";
import { convertDateThai } from "../../../../../utils/format/Date.format";
import { downloadFilePdf } from "../../../../../helpers/func.helper";
import icon from "../../../../../assets/img/Icon";
import styles from "./scss/EditSaleForm.module.scss";

const EditSaleForm = ({ _transaction, _onSwitch, _onSwitchPage }) => {
  const multipleInputArr = useMemo(() => {
    if (!_transaction?.datas) return;

    return JSON?.parse(_transaction?.datas) || [];
  }, [_transaction]);

  return (
    <>
      <div className={`${styles.input_form}`}>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>วันที่ (เริ่มต้น)</h5>
            <div className={`${styles.info_box}`}>
              <p>{convertDateThai(_transaction?.atDate)}</p>
            </div>
          </div>
          <div className={`${styles.input_2}`}>
            <h5>วันที่ (สิ้นสุด)</h5>
            <div className={`${styles.info_box}`}>
              <p>{convertDateThai(_transaction?.txDate)}</p>
            </div>
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>โรงไฟฟ้า</h5>
            <div className={`${styles.info_box}`}>
              <p>{_transaction?.buyer}</p>
            </div>
          </div>
          <div className={`${styles.input_2}`}>
            <h5>สัญญาเลขที่</h5>
            <div className={`${styles.info_box}`}>
              <p>{_transaction?.contractFarming?.no}</p>
            </div>
          </div>
        </div>
        {multipleInputArr?.map((item, index, array) => {
          return (
            <div key={index} className={`${styles.multiple_input_box}`}>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>ชนิดเชื้อเพลิง / วัตถุดิบ</h5>
                  <div className={`${styles.info_box}`}>
                    <p>{item?.plant_name}</p>
                  </div>
                </div>
                <div className={`${styles.input_2}`}>
                  {item?.plant_parts_name ? (
                    <>
                      <h5>ชีวมวล</h5>
                      <div className={`${styles.info_box}`}>
                        <p>{item?.plant_parts_name}</p>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>ปริมาณการรับซื้อภายในกลุ่ม (ตัน)</h5>
                  <div className={`${styles.info_box}`}>
                    <p>{item?.buy_amount_member}</p>
                  </div>
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>ปริมาณการรับซื้อภายนอกกลุ่ม (ตัน)</h5>
                  <div className={`${styles.info_box}`}>
                    <p>{item?.buy_amount_other}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>จำนวนรวม (ตัน)</h5>
            <div className={`${styles.info_box}`}>
              <p>{_transaction?.amount}</p>
            </div>
          </div>
          <div className={`${styles.input_2}`}>
            <h5>ยอดรวมสุทธิ(บาท)</h5>
            <div className={`${styles.info_box}`}>
              <p>{_transaction?.total}</p>
            </div>
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>วันที่รับเงิน</h5>
            <div className={`${styles.info_box}`}>
              <p>{convertDateThai(_transaction?.payDate)}</p>
            </div>
          </div>
          <div className={`${styles.input_2}`}>
            <div className={`${styles.input_file}`}>
              <h5>ใบแจ้งหนี้</h5>
              <div className={`${styles.input_unit}`}>
                <div className={`${styles.info_box}`}>
                  <p>{_transaction?.transactionReceiptNavigation?.fileName}</p>
                </div>
                <div className={`${styles.unit}`}>
                  <img
                    src={icon.document}
                    alt=""
                    title="ดาวน์โหลดไฟล์"
                    onClick={() =>
                      downloadFilePdf(
                        _transaction?.receipt,
                        _transaction?.transactionReceiptNavigation
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}></div>
          <div className={`${styles.input_2}`}>
            <div className={`${styles.input_file}`}>
              <h5>รายงานการชั่งน้ำหนัก</h5>
              <div className={`${styles.input_unit}`}>
                <div className={`${styles.info_box}`}>
                  <p>{_transaction?.transactionWeightNavigation?.fileName}</p>
                </div>
                <div className={`${styles.unit}`}>
                  <img
                    src={icon.document}
                    alt=""
                    title="ดาวน์โหลดไฟล์"
                    onClick={() =>
                      downloadFilePdf(
                        _transaction?.receipt,
                        _transaction?.transactionWeightNavigation
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.button_right}`}>
        <button
          type="button"
          className={`${styles.btn_}`}
          onClick={() => _onSwitch(true)}
        >
          แก้ไข
        </button>
        <button
          type="button"
          className={`${styles.btn_}`}
          onClick={() => _onSwitchPage(null)}
        >
          กลับ
        </button>
      </div>
    </>
  );
};

export default EditSaleForm;
