import React, { useCallback, useEffect, useRef, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from 'react-redux';
import { QueryGetAll } from '../../../helpers/api.helper';
import { defaultApi } from '../../../services/api';
import { getProfileData } from '../../../services/auth.slice';
import styles from './scss/ReportResourceHarvest.module.scss';
import ChartsResourceHarvest from './charts/ChartsResourceHarvest';


const ReportResourceHarvest = ({ themeColor }) => {
  const profileData = useSelector(getProfileData);
  const chart = useRef()
  const [_setResEnt, resEnt] = useState([])
  const [_setKeyEnt, keyEnt] = useState({
    entId: 0,
    month: "",
    year: ""
  })
  const [_resName, resName] = useState("")

  const resApi = useCallback(async () => {

    let qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=createdBy%20eq%20${profileData.id}`,
      $orderby: "",
    };

    const resAllEnp = await defaultApi.getAllEnterpriseMember(``)

    if (resAllEnp?.data?.length > 0) {
      for (let index = 0; index < resAllEnp?.data?.length; index++) {
        const element = resAllEnp?.data[index];
        if (+element?.userId === +profileData?.id) {
          qryGetAll = {
            $count: "%24count=true",
            $expand: "",
            $top: "",
            $skip: "",
            $filter: `&%24filter=id%20eq%20${element.enterpriseId}`,
            $orderby: "",
          };
        }
      }
    }


    const _query01 = QueryGetAll(qryGetAll);
    const res_entp = await defaultApi.getAllEnterprise(_query01)
    const resData01 = res_entp?.data?.datas

    resName(resData01[0]?.name)
    keyEnt((prev) => ({ ...prev, entId: resData01[0]?.id }))

  }, [profileData])

  const getEntArea = useCallback(async () => {
    const dateTime = new Date()
    const yearX = dateTime.getFullYear()
    const monthX = dateTime.getMonth() + 1
    // const param = `enterpriseId=${_setKeyEnt?.entId}&atDate=${_setKeyEnt?.year || yearX}-${_setKeyEnt?.month || monthX}-1`
    const param = `enterpriseId=${_setKeyEnt?.entId}`
    const res_area = await defaultApi.getTargetHarvest(param)
    // resEnt([
    //   { area: 278.6399985551834, target: 589, label: '06/2023',  },
    //   { area: 278.6399985551834, target: 589, label: '07/2023',  },
    //   { area: 278.6399985551834, target: 589, label: '08/2023', },
    //   { area: 278.6399985551834, target: 589, label: '09/2023',  },
    //   { area: 278.6399985551834, target: 589, label: '10/2023',  },
    //   { area: 278.6399985551834, target: 589, label: '11/2023', }
    // ])
    resEnt(res_area?.data)
  }, [_setKeyEnt])

  const dateMonth = () => {
    let monlst = [
      "",
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ]

    let month = []

    for (let index = 0; index <= 12; index++) {
      let num = ""
      index < 10 ? num = "0" + index : num = index
      month[index] = ({ month: num.toString(), monthName: monlst[index] })
    }

    let optMonths = month.map((data, index) => {
      let i
      if (index >= 1) {
        i = (
          <option key={index} value={data.month} >{data.monthName}</option>
        )
      }
      return i
    })

    return (
      <>
        <option key={`df_0`} style={{ display: 'none' }} >เดือน</option>
        {optMonths}
      </>
    )

  }

  const dateYear = () => {
    let dt = new Date()
    let year = dt.getFullYear()
    let years = []

    for (let index = 0; index < 10; index++) {
      years[index] = year
      year--
    }

    let optYear = years.map((data, index) => {
      return (
        <option key={index} value={data}>{(data + 543)}</option>
      )
    })

    return (
      <>
        <option key={`df_0`} style={{ display: 'none' }}>ปี</option>
        {optYear}
      </>
    )
  }

  useEffect(() => {
    resApi()
  }, [resApi])

  useEffect(() => {
    getEntArea()
  }, [getEntArea])

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.date_filter}`}>
        {/* <select style={{ color: 'black' }} name="" id="sel01" onChange={(e) => { keyEnt({ ..._setKeyEnt, month: e.target.value }) }}>
          {
            dateMonth()
          }
        </select>
        <select style={{ color: 'black' }} name="" id="sel02" onChange={(e) => { keyEnt({ ..._setKeyEnt, year: e.target.value }) }}>
          {
            dateYear()
          }
        </select> */}
      </div>

      <h4 className={`${styles.title_text}`} style={{ background: themeColor.backgroundColor }}>
        วัตถุดิบที่คาดว่าจะเก็บเกี่ยว
      </h4>
      <h5 className={`${styles.title_text_}`}>
        {_resName}
      </h5>

      <div className={`${styles.charts_}`}>
        <ChartsResourceHarvest ref={chart} datain={_setResEnt} />
      </div>
    </div>
  )
}

export default ReportResourceHarvest