import React from "react";
import { useSelector } from "react-redux";
import OfficerReport from "./officer/OfficerReport";
import FarmerReport from "./farmer/FarmerReport";
import { getProfileData } from "../../services/auth.slice";
import AdminReport from "./admin/AdminReport";

const Index = () => {
  const profile_ = useSelector(getProfileData);
  let userRole = profile_?.userGroupId;

  //userRole = 5;
  if (userRole === 1) {
    return <OfficerReport />;
  } else if (userRole === 5 || userRole === 3) {
    return <AdminReport />;
  } else if (userRole === 2) {
    return <FarmerReport />;
  }
};

export default Index;
