import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { GrFormClose } from "react-icons/gr";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";
import useSwitch from "../../../../utils/hooks/useSwitch";
import {
  convertMonthYearThai,
  convertDateThai,
  convertDateEu,
} from "../../../../utils/format/Date.format";
import {
  CheckNumberDot,
  CheckValidLength,
} from "../../../../utils/validation_input/Validation";
import Message from "../../../../utils/message/Message";
import { defaultApi } from "../../../../services/api";
import { getFactoryData } from "../../../../services/factory.slice";
import { QueryGetAll } from "../../../../helpers/api.helper";
import icon from "../../../../assets/img/Icon";
import { exportCsvFile, exportXlsxFile } from "../../../../helpers/func.helper";
import { clearObj } from "../../../../utils/format/Object.format";
import ExportPopup from "../../../../layouts/exportpopup/ExportPopup";
import styles from "./scss/PowerGene.module.scss";

const initData = {
  title: "",
  date: "",
  dateVal: "",
  recordedAt: "",
  powerGen: "",
  powerSell: "",
  meterGen: "",
  meterSell: "",
  color: "",
  allDay: 1,
  factoryId: "",
};

const PowerGene = () => {
  const factoryData = useSelector(getFactoryData);
  const calendarRef = useRef();
  const [_switch, onSwitch] = useSwitch();

  const [_data, setData] = useState(initData);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState({});
  const [allPowerGene, setAllPowerGene] = useState({});
  const [filterDate, setFilterDate] = useState("");
  const [_powerGen, setPowerGen] = useState(null);
  const [_powerSell, setPowerSell] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openRecordedAt, setOpenRecordedAt] = useState(false);
  const [openRecordedAt2, setOpenRecordedAt2] = useState(false);
  const [validErr, setValidErr] = useState(false);
  const [_triggerExport, setTriggerExport] = useState(false);

  const meterGen = useMemo(() => {
    let data;
    let facMeterGen = factoryData?.meterGen;
    if (allPowerGene.length === 0) {
      data = facMeterGen;
    } else {
      let newData = allPowerGene[0]?.meterGen;
      data = newData;
    }

    return data;
  }, [factoryData, allPowerGene]);
  const meterSell = useMemo(() => {
    let data;
    let facMeterSell = factoryData?.meterSell;
    if (allPowerGene.length === 0) {
      data = facMeterSell;
    } else {
      let newData = allPowerGene[0]?.meterSell;
      data = newData;
    }

    return data;
  }, [factoryData, allPowerGene]);
  // console.log(`meterGen = ${meterGen}`);
  // console.log(`meterSell = ${meterSell}`);
  const formattedDate = useMemo(() => {
    let currentDate = new Date();
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();
    let formattedDate = `${year}-${String(month).padStart(2, "0")}`;

    return formattedDate;
  }, []);
  const checkDate = useMemo(() => {
    let currentDate = new Date(filterDate);
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();
    let _formattedDate = `${year}-${String(month).padStart(2, "0")}`;

    return _formattedDate;
  }, [filterDate]);

  const getAllPowerGeneration2 = async (date) => {
    if (!factoryData?.id) return;

    let factoryId = factoryData?.id;
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: "",
      $orderby: "&%24orderby=date%20desc",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    const formatQry = (val) => {
      const checkObj = clearObj({
        date: val,
      });
      if (Object.keys(clearObj(checkObj)).length === 0) {
        return `${_queryAll}&%24filter=factoryId%20eq%20${factoryId}`;
      } else {
        let objLng = Object.keys(clearObj(checkObj)).length;
        let filter1 = "";

        for (const [key, value] of Object.entries(clearObj(checkObj))) {
          // 1 # มี objkey ตัวเดียว #
          if (objLng <= 1) {
            if (key === "date") {
              filter1 = `${key}%20le%20${value}`;
            }
          }
        }
        return `${_queryAll}&%24filter=factoryId%20eq%20${factoryId}%20and%20${filter1}`;
      }
    };
    try {
      const res = await defaultApi.getAllPowerGeneration(formatQry(date));
      const data = res.data.datas;
      const findData = data.find((item) => convertDateEu(item.date) === date);
      if (findData) {
        let _data = data.filter(
          (item) => convertDateEu(item.date) < convertDateEu(findData?.date)
        );
        console.log(`Condition`);
        setAllPowerGene(_data);
      } else {
        console.log(`No condition`);
        setAllPowerGene(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* FullCalendar all function : START */
  // Dropdown ค้นหาด้วย เดือน/ปี
  const goToPast = (e) => {
    if (!e) return setFilterDate(formattedDate);
    setFilterDate(e);
    let calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(e); // call a method on the Calendar object
  };

  // คลิ๊กปฎิทินที่วันนั้นๆ รับข้อมูลและส่งข้อมูล เปิด Modal
  const onClickDate = (e) => {
    const meterGen = factoryData?.meterGen;
    const meterSell = factoryData?.meterSell;
    if (meterGen === null && meterSell === null) {
      return toast.error(
        "ยังไม่มีข้อมูล เลขมิเตอร์เริ่มต้นที่ผลิต และ เลขมิเตอร์เริ่มต้นที่ขาย ❗️",
        { duration: 4500 }
      );
    }

    /* (เช็คว่าวันที่เลือกมีข้อมูลอยู่ไหม ? หยุดที่ return : เปิดโมดอล) : START */
    const findDate = events.find(
      (item) =>
        moment(item.date, "YYYY-MM-DD").format("YYYY-MM-DD") === e.dateStr
    );
    const currDate = findDate
      ? moment(findDate.date, "YYYY-MM-DD").format("YYYY-MM-DD")
      : "";
    const newDate = e.dateStr;
    if (newDate === currDate)
      return toast.error("วันที่เลือกมีข้อมูลอยู่แล้ว ❗️", { duration: 3000 });
    /* (เช็คว่าวันที่เลือกมีข้อมูลอยู่ไหม ? หยุดที่ return : เปิดโมดอล) : END */

    const showModal = document.querySelector("#show_modal_add");
    showModal.click();

    setData({
      ..._data,
      date: e.dateStr,
      dateVal: e.dateStr,
      allDay: e.allDay,
      powerTarget: "",
      powerGen: "",
    });
    setEvent({});
    setValidErr(false);
    // Fetch api
    getAllPowerGeneration2(e.dateStr);
  };
  const onClickEvent = (e) => {
    const event = e.event._def;
    const showModal = document.querySelector("#show_modal_edit");
    showModal.click();
    const formatObj = {
      id: +event.publicId,
      title: event.title,
      date: event.extendedProps.dateVal,
      dateVal: event.extendedProps.dateVal,
      recordedAt: event.extendedProps.recordedAt,
      meterGen: event.extendedProps.meterGen,
      meterSell: event.extendedProps.meterSell,
      powerGen: event.extendedProps.powerGen,
      powerSell: event.extendedProps.powerSell,
      color: "#789b33",
      allDay: 1,
      factoryId: event.extendedProps.factoryId,
    };
    setEvent(formatObj);
    // Fetch api
    let dateEu = convertDateEu(event.extendedProps.dateVal);
    getAllPowerGeneration2(dateEu);
  };

  const handleDatesSet = (dateInfo) => {
    const date = new Date(convertDateEu(dateInfo.endStr));
    date.setMonth(date.getMonth() - 1); // ลดค่าเดือนลงทีละ 1
    const newDate = date.toISOString().substring(0, 10); // แปลงวันที่เป็นรูปแบบ ISO และเอาเฉพาะส่วนของวันที่

    setFilterDate(newDate);
  };
  /* FullCalendar all function : END */

  const onChangeInsert = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };
  const onChangeUpdate = (e) => {
    const { name, value } = e.target;
    setEvent({ ...event, [name]: value });
  };
  const onChangeRecordedAt = (e, type) => {
    const dateFormat = convertDateEu(e);

    if (type === "Insert") {
      setData({ ..._data, recordedAt: dateFormat });
      setSelectedDate(e);
    } else if (type === "Update") {
      setEvent({ ...event, recordedAt: dateFormat });
      setSelectedDate(e);
    }

    setOpenRecordedAt(!openRecordedAt);
    setOpenRecordedAt2(!openRecordedAt2);
  };

  /* Export csv and excel : START */
  const getMonthAndYear = (datetimeString) => {
    const dateObj = new Date(datetimeString);

    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const newYear = `${year}`;
    const newMonth = `${String(month).padStart(2, "0")}`;

    return {
      newMonth,
      newYear,
    };
  };

  const onExportCsv = () => {
    // วัน-เดือน-ปี ของปัจจุบัน
    let currentDate = new Date();
    let { month, year } = {
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
    };
    // วัน-เดือน-ปี ของการกดเลือกจาก datepicker
    let { newMonth, newYear } = getMonthAndYear(filterDate);
    // ตัวแปร เดือน และ ปี
    let _month =
      filterDate === "" ? `${String(month).padStart(2, "0")}` : newMonth;
    let _year = filterDate === "" ? `${year}` : newYear;
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "FactoryPowerGenerationReport";
    let fileType = "csv";
    let obj = {
      factoryId: factoryData.id,
      atMonth: _month,
      atYear: _year,
    };
    // Ready
    exportCsvFile(exportName, fileType, obj);
  };
  const onExportXlsx = () => {
    // วัน-เดือน-ปี ของปัจจุบัน
    let currentDate = new Date();
    let { month, year } = {
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
    };
    // วัน-เดือน-ปี ของการกดเลือกจาก datepicker
    let { newMonth, newYear } = getMonthAndYear(filterDate);
    // ตัวแปร เดือน และ ปี
    let _month =
      filterDate === "" ? `${String(month).padStart(2, "0")}` : newMonth;
    let _year = filterDate === "" ? `${year}` : newYear;
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "FactoryPowerGenerationReport";
    let fileType = "excel";
    let obj = {
      factoryId: factoryData.id,
      atMonth: _month,
      atYear: _year,
    };
    // Ready
    exportXlsxFile(exportName, fileType, obj);
  };
  /* Export csv and excel : END */

  const createPowerGeneration = async () => {
    const insertModal = document.querySelector("#insert-close");
    if (CheckNumberDot(_data.meterGen)) return setValidErr(true);
    if (CheckNumberDot(_data.meterSell)) return setValidErr(true);
    if (CheckValidLength(_data.recordedAt)) return setValidErr(true);

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "title") {
        params[key] = `${_powerGen} KWh`;
      } else if (key === "color") {
        params[key] = "#789b33";
      } else if (key === "allDay") {
        params[key] = 1;
      } else if (key === "factoryId") {
        params[key] = factoryData.id;
      } else if (key === "meterGen") {
        params[key] = +_data.meterGen;
      } else if (key === "meterSell") {
        params[key] = +_data.meterSell;
      } else if (key === "powerGen") {
        params[key] = +_powerGen;
      } else if (key === "powerSell") {
        params[key] = +_powerSell;
      } else {
        params[key] = _data[key];
      }
    });
    try {
      const res = await defaultApi.createPowerGeneration(params);
      console.log(res.statusText);
      // FetchData
      getAllPowerGeneration();
      // Reset state
      setData(initData);
      // Close modal
      insertModal.click();
      toast.success(Message.notify.insert_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.insert_error, { duration: 3000 });
    } finally {
      setData(initData);
      toast.dismiss(loading);
    }
  };
  const updatePowerGeneration = async () => {
    if (CheckNumberDot(event.meterGen)) return setValidErr(true);
    if (CheckNumberDot(event.meterSell)) return setValidErr(true);

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(event).forEach((key) => {
      if (key === "title") {
        params[key] = `${_powerGen} KWh`;
      } else if (key === "meterGen") {
        params[key] = +event?.meterGen;
      } else if (key === "meterSell") {
        params[key] = +event?.meterSell;
      } else if (key === "powerGen") {
        params[key] = +_powerGen;
      } else if (key === "powerSell") {
        params[key] = +_powerSell;
      } else {
        params[key] = event[key];
      }
    });
    try {
      const res = await defaultApi.updatePowerGeneration(event.id, params);

      setEvent(res.data);
      getAllPowerGeneration();
      onSwitch(false);
      toast.success(Message.notify.update_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };
  const deletePowerGeneration = async () => {
    if (!window.confirm(Message.notify.delete_confirm)) return;

    const loading = toast.loading(Message.notify.loading);
    try {
      await defaultApi.deletePowerGeneration(event.id);
      // console.log(res.statusText);
      getAllPowerGeneration();
      toast.success(Message.notify.delete_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.delete_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  const getAllPowerGeneration = useCallback(async () => {
    if (!factoryData?.id) return;

    let factoryId = factoryData?.id;
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=factoryId%20eq%20${factoryId}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllPowerGeneration(_queryAll);
      const datas = res.data.datas;

      for (let i = 0; i < datas.length; i++) {
        datas[i].title = `ผลิต ${formatToLocaleString(
          datas[i]?.powerGen,
          0
        )} KWh`;
        datas[i].extraTitle = `ขาย ${formatToLocaleString(
          datas[i]?.powerSell * 6,
          0
        )} KWh`;
      }

      setEvents(datas);
    } catch (error) {
      console.log(error);
    }
  }, [factoryData]);

  useEffect(() => {
    /* คำนวณผลรับของ เลขมิเตอร์ที่ผลิต (เพิ่ม) : START */
    if (_data.meterGen !== "") {
      const num = parseFloat(_data.meterGen);

      if (!isNaN(num)) {
        const calculatedResult = formatToLocaleString(num - meterGen, 0); // คำนวณหาผลรับ
        setPowerGen(calculatedResult);
      }
    } else {
      setPowerGen(null);
    }
    /* คำนวณผลรับของ เลขมิเตอร์ที่ผลิต (เพิ่ม) : END */

    /* คำนวณผลรับของ เลขมิเตอร์ที่ขาย (เพิ่ม) : START */
    if (_data.meterSell !== "") {
      const num = parseFloat(_data.meterSell);

      if (!isNaN(num)) {
        const calculatedResult = formatToLocaleString((num - meterSell) * 6, 0); // คำนวณหาผลรับ
        setPowerSell(calculatedResult);
      }
    } else {
      setPowerSell(null);
    }
    /* คำนวณผลรับของ เลขมิเตอร์ที่ขาย (เพิ่ม) : END */
  }, [_data, meterGen, meterSell]);

  useEffect(() => {
    /* คำนวณผลรับของ เลขมิเตอร์ที่ผลิต (แก้ไข) : START */
    if (event?.meterGen !== "") {
      const num = parseFloat(event?.meterGen);

      if (!isNaN(num)) {
        const calculatedResult = formatToLocaleString(num - meterGen, 0); // คำนวณหาผลรับ
        setPowerGen(calculatedResult);
      }
    } else {
      setPowerGen(null);
    }
    /* คำนวณผลรับของ เลขมิเตอร์ที่ผลิต (แก้ไข) : START */

    /* คำนวณผลรับของ เลขมิเตอร์ที่ขาย (แก้ไข) : START */
    if (event?.meterSell !== "") {
      const num = parseFloat(event?.meterSell);

      if (!isNaN(num)) {
        const calculatedResult = formatToLocaleString((num - meterSell) * 6, 0); // คำนวณหาผลรับ
        setPowerSell(calculatedResult);
      }
    } else {
      setPowerSell(null);
    }
    /* คำนวณผลรับของ เลขมิเตอร์ที่ขาย (แก้ไข) : START */
  }, [event, meterGen, meterSell]);

  useEffect(() => {
    // Use functions
    getAllPowerGeneration();
  }, [getAllPowerGeneration]);

  useEffect(() => {
    /* Function close dropdown 'DatePicker' : START */
    const onCloseDropdownDatePicker = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_datepicker_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDatePicker(false);
      }
    };
    const onCloseDropdownRecordedAt = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_recorded_at_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenRecordedAt(false);
      }
    };
    const onCloseDropdownRecordedAt2 = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_recorded_at2_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenRecordedAt2(false);
      }
    };

    if (openDatePicker) {
      document.addEventListener("click", onCloseDropdownDatePicker);
    }
    if (openRecordedAt) {
      document.addEventListener("click", onCloseDropdownRecordedAt);
    }
    if (openRecordedAt2) {
      document.addEventListener("click", onCloseDropdownRecordedAt2);
    }
    /* Function close dropdown 'DatePicker' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDatePicker);
      document.removeEventListener("click", onCloseDropdownRecordedAt);
      document.removeEventListener("click", onCloseDropdownRecordedAt2);
    };
  }, [openDatePicker, openRecordedAt, openRecordedAt2]);

  return (
    <div className={`${styles.container}`}>
      <h4 className={`${styles.title_text}`}>การผลิตไฟฟ้า</h4>

      <div className={`${styles.filter_groups}`}>
        <div className={`${styles.group_}`}>
          <div className={`${styles.input_unit}`}>
            <div className={`${styles.info_box}`}>
              <p>
                {filterDate !== ""
                  ? convertMonthYearThai(filterDate)
                  : convertMonthYearThai(formattedDate)}
              </p>
              {checkDate !== formattedDate && (
                <GrFormClose
                  size={25}
                  cursor={"pointer"}
                  onClick={() => goToPast(formattedDate)}
                />
              )}
            </div>
            <div
              className={`${styles.unit}`}
              onClick={() => setOpenDatePicker(!openDatePicker)}
            >
              <img src={icon.calendar} alt="" id="fac_datepicker_d" />
            </div>
          </div>
          {openDatePicker && (
            <DatePicker
              dateFormat={"MMMM yyyy"}
              showMonthYearPicker
              onChange={goToPast}
              inline
            />
          )}
        </div>
      </div>

      <div className={styles.full_calender}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev",
            center: "title",
            right: "next",
          }}
          events={events}
          eventContent={(eventInfo) => {
            return (
              <div>
                <div>{eventInfo.event.title}</div>
                <div>{eventInfo.event.extendedProps.extraTitle}</div>
              </div>
            );
          }}
          ref={calendarRef}
          locale={"th"}
          contentHeight={465}
          eventConstraint={{ start: "00:00", end: "24:00" }}
          dateClick={onClickDate}
          eventClick={onClickEvent}
          datesSet={handleDatesSet}
        />
      </div>

      {/* Modal insert data : START */}
      <button
        type="button"
        id="show_modal_add"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        hidden
      >
        ShowModal
      </button>

      <div
        className={`${styles.modal_} modal fade`}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className={`${styles.modal_dialog} modal-dialog`}>
          <div className={`${styles.modal_content} modal-content`}>
            <div className={`${styles.modal_header} modal-header`}>
              <h5
                className={`${styles.modal_title} modal-title`}
                id="staticBackdropLabel"
              >
                การผลิตไฟฟ้า
              </h5>
              <button
                type="button"
                id="insert-close"
                className={`${styles.btn_close} btn-close`}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setData(initData);
                  setValidErr(false);
                  setOpenRecordedAt(false);
                  setSelectedDate(new Date());
                }}
              ></button>
            </div>
            <div className={`${styles.modal_body} modal-body`}>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>วันที่</h5>
                  <div className={`${styles.info_box}`}>
                    <p>{convertDateThai(_data?.date)}</p>
                  </div>
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>
                    วันที่บันทึก <sup className={`${styles.asterisk}`}>*</sup>
                  </h5>
                  <div
                    className={`${styles.input_unit} ${
                      validErr && _data.recordedAt === "" ? "error" : ""
                    }`}
                  >
                    <div className={`${styles.info_box_}`}>
                      <p>
                        {_data.recordedAt !== ""
                          ? convertDateThai(_data.recordedAt)
                          : ""}
                      </p>
                    </div>
                    <div
                      className={`${styles.unit}`}
                      onClick={() => setOpenRecordedAt(!openRecordedAt)}
                    >
                      <img src={icon.calendar} alt="" id="fac_recorded_at_d" />
                    </div>
                  </div>
                  {openRecordedAt && (
                    <DatePicker
                      selected={selectedDate}
                      onChange={(e) => onChangeRecordedAt(e, "Insert")}
                      inline
                    />
                  )}
                  {validErr && (
                    <p className={`${styles.err_message}`}>
                      {CheckValidLength(_data.recordedAt)}
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>
                    เลขมิเตอร์ที่ผลิต (ปัจจุบัน{" "}
                    {formatToLocaleString(meterGen, 0)}){" "}
                    <sup className={`${styles.asterisk}`}>*</sup>
                  </h5>
                  <input
                    type="text"
                    name="meterGen"
                    value={_data.meterGen || ""}
                    className={`${
                      validErr && _data.meterGen === "" ? "error" : ""
                    }`}
                    onChange={onChangeInsert}
                  />
                  {validErr && (
                    <p className={`${styles.err_message}`}>
                      {CheckNumberDot(_data.meterGen)}
                    </p>
                  )}
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>ปริมาณไฟฟ้าที่ผลิต (KWh)</h5>
                  <div
                    className={`${styles.info_box}`}
                    style={{ backgroundColor: "#e0e0e0" }}
                  >
                    <p>{_powerGen === null ? "" : _powerGen}</p>
                  </div>
                </div>
              </div>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>
                    เลขมิเตอร์ที่ขาย (ปัจจุบัน {formatToLocaleString(meterSell)}
                    ) <sup className={`${styles.asterisk}`}>*</sup>
                  </h5>
                  <input
                    type="text"
                    name="meterSell"
                    value={_data.meterSell || ""}
                    className={`${
                      validErr && _data.meterSell === "" ? "error" : ""
                    }`}
                    onChange={onChangeInsert}
                  />
                  {validErr && (
                    <p className={`${styles.err_message}`}>
                      {CheckNumberDot(_data.meterSell)}
                    </p>
                  )}
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>ปริมาณไฟฟ้าที่ขาย (KWh)</h5>
                  <div
                    className={`${styles.info_box}`}
                    style={{ backgroundColor: "#e0e0e0" }}
                  >
                    <p>{_powerSell === null ? "" : _powerSell}</p>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.input_flex}`}
                style={{ display: "none" }}
              >
                <div className={styles.input_1}>
                  <h5>
                    รายได้จากการขายไฟฟ้า (บาท){" "}
                    <sup className={`${styles.asterisk}`}>*</sup>
                  </h5>
                  <input
                    type="text"
                    name=""
                    value={""}
                    className={`${validErr && _data === "" ? "error" : ""}`}
                    onChange={onChangeInsert}
                  />
                  {validErr && (
                    <p className={`${styles.err_message}`}>
                      {CheckValidLength(_data)}
                    </p>
                  )}
                </div>
                <div className={styles.input_2}></div>
              </div>
            </div>
            <div className={`modal-footer ${styles.modal_footer}`}>
              <button
                type="button"
                className="button_sm"
                onClick={createPowerGeneration}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal insert data : END */}

      {/* Modal update data : START */}
      <button
        type="button"
        id="show_modal_edit"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop2"
        hidden
      >
        ShowModal
      </button>

      <div
        className={`${styles.modal_} modal fade`}
        id="staticBackdrop2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        {!_switch ? (
          <div className={`${styles.modal_dialog} modal-dialog`}>
            <div className={`${styles.modal_content} modal-content`}>
              <div className={`${styles.modal_header} modal-header`}>
                <h5
                  className={`${styles.modal_title} modal-title`}
                  id="staticBackdropLabel"
                >
                  การผลิตไฟฟ้า
                </h5>
                <button
                  type="button"
                  className={`${styles.btn_close} btn-close`}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    onSwitch(false);
                    setData(initData);
                    setOpenRecordedAt(false);
                    setSelectedDate(new Date());
                  }}
                ></button>
              </div>
              <div className={`${styles.modal_body} modal-body`}>
                <div className={`${styles.input_flex}`}>
                  <div className={`${styles.input_1}`}>
                    <h5>วันที่</h5>
                    <div className={`${styles.info_box}`}>
                      <p>{convertDateThai(event?.dateVal)}</p>
                    </div>
                  </div>
                  <div className={`${styles.input_2}`}>
                    <h5>วันที่บันทึก</h5>
                    <div className={`${styles.info_box}`}>
                      <p>{convertDateThai(event?.recordedAt)}</p>
                    </div>
                  </div>
                </div>
                <div className={`${styles.input_flex}`}>
                  <div className={`${styles.input_1}`}>
                    <h5>
                      เลขมิเตอร์ที่ผลิต (ปัจจุบัน{" "}
                      {formatToLocaleString(meterGen, 0)})
                    </h5>
                    <div className={`${styles.info_box}`}>
                      <p>{formatToLocaleString(event?.meterGen, 0)}</p>
                    </div>
                  </div>
                  <div className={`${styles.input_2}`}>
                    <h5>ปริมาณไฟฟ้าที่ผลิต (KWh)</h5>
                    <div className={`${styles.info_box}`}>
                      <p>{_powerGen === null ? "" : _powerGen}</p>
                    </div>
                  </div>
                </div>
                <div className={`${styles.input_flex}`}>
                  <div className={`${styles.input_1}`}>
                    <h5>
                      เลขมิเตอร์ที่ขาย (ปัจจุบัน{" "}
                      {formatToLocaleString(meterSell)})
                    </h5>
                    <div className={`${styles.info_box}`}>
                      <p>{formatToLocaleString(event?.meterSell)}</p>
                    </div>
                  </div>
                  <div className={`${styles.input_2}`}>
                    <h5>ปริมาณไฟฟ้าที่ขาย (KWh)</h5>
                    <div className={`${styles.info_box}`}>
                      <p>{_powerSell === null ? "" : _powerSell}</p>
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.input_flex}`}
                  style={{ display: "none" }}
                >
                  <div className={styles.input_1}>
                    <h5>รายได้จากการขายไฟฟ้า (บาท)</h5>
                    <div className={`${styles.info_box}`}>
                      <p>{}</p>
                    </div>
                  </div>
                  <div className={styles.input_2}></div>
                </div>
              </div>
              <div className={`${styles.modal_footer} modal-footer`}>
                <button
                  type="button"
                  className="button_sm"
                  data-bs-dismiss="modal"
                  onClick={deletePowerGeneration}
                >
                  ลบ
                </button>
                <button
                  type="button"
                  className="button_sm"
                  onClick={() => onSwitch(true)}
                >
                  แก้ไข
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className={`${styles.modal_dialog} modal-dialog`}>
            <div className={`${styles.modal_content} modal-content`}>
              <div className={`${styles.modal_header} modal-header`}>
                <h5
                  className={`${styles.modal_title} modal-title`}
                  id="staticBackdropLabel"
                >
                  การผลิตไฟฟ้า
                </h5>
                <button
                  type="button"
                  className={`${styles.btn_close} btn-close`}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    onSwitch(false);
                    setData(initData);
                    setOpenRecordedAt(false);
                    setSelectedDate(new Date());
                  }}
                ></button>
              </div>
              <div className={`${styles.modal_body} modal-body`}>
                <div className={`${styles.input_flex}`}>
                  <div className={`${styles.input_1}`}>
                    <h5>วันที่</h5>
                    <div className={`${styles.info_box}`}>
                      <p>{convertDateThai(event?.dateVal)}</p>
                    </div>
                  </div>
                  <div className={`${styles.input_2}`}>
                    <h5>
                      วันที่บันทึก <sup className={`${styles.asterisk}`}>*</sup>
                    </h5>
                    <div className={`${styles.input_unit}`}>
                      <div className={`${styles.info_box_}`}>
                        <p>
                          {event?.recordedAt !== null
                            ? convertDateThai(event?.recordedAt)
                            : ""}
                        </p>
                      </div>
                      <div
                        className={`${styles.unit}`}
                        onClick={() => setOpenRecordedAt2(!openRecordedAt2)}
                      >
                        <img
                          src={icon.calendar}
                          alt=""
                          id="fac_recorded_at2_d"
                        />
                      </div>
                    </div>
                    {openRecordedAt2 && (
                      <DatePicker
                        selected={selectedDate}
                        onChange={(e) => onChangeRecordedAt(e, "Update")}
                        inline
                      />
                    )}
                  </div>
                </div>
                <div className={`${styles.input_flex}`}>
                  <div className={`${styles.input_1}`}>
                    <h5>
                      เลขมิเตอร์ที่ผลิต (ปัจจุบัน{" "}
                      {formatToLocaleString(meterGen, 0)}){" "}
                      <sup className={`${styles.asterisk}`}>*</sup>
                    </h5>
                    <input
                      type="text"
                      name="meterGen"
                      value={event?.meterGen || ""}
                      className={`${
                        validErr && event?.meterGen === "" ? "error" : ""
                      }`}
                      onChange={onChangeUpdate}
                    />
                    {validErr && (
                      <p className={`${styles.err_message}`}>
                        {CheckNumberDot(event?.meterGen)}
                      </p>
                    )}
                  </div>
                  <div className={`${styles.input_2}`}>
                    <h5>ปริมาณไฟฟ้าที่ผลิต (KWh)</h5>
                    <div className={`${styles.info_box}`}>
                      <p>{_powerGen === null ? "" : _powerGen}</p>
                    </div>
                  </div>
                </div>
                <div className={`${styles.input_flex}`}>
                  <div className={`${styles.input_1}`}>
                    <h5>
                      เลขมิเตอร์ที่ขาย (ปัจจุบัน{" "}
                      {formatToLocaleString(meterSell)}){" "}
                      <sup className={`${styles.asterisk}`}>*</sup>
                    </h5>
                    <input
                      type="text"
                      name="meterSell"
                      value={event?.meterSell || ""}
                      className={`${
                        validErr && event?.meterSell === "" ? "error" : ""
                      }`}
                      onChange={onChangeUpdate}
                    />
                    {validErr && (
                      <p className={`${styles.err_message}`}>
                        {CheckNumberDot(event?.meterSell)}
                      </p>
                    )}
                  </div>
                  <div className={`${styles.input_2}`}>
                    <h5>ปริมาณไฟฟ้าที่ขาย (KWh)</h5>
                    <div className={`${styles.info_box}`}>
                      <p>{_powerSell === null ? "" : _powerSell}</p>
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.input_flex}`}
                  style={{ display: "none" }}
                >
                  <div className={styles.input_1}>
                    <h5>
                      รายได้จากการขายไฟฟ้า (บาท){" "}
                      <sup className={`${styles.asterisk}`}>*</sup>
                    </h5>
                    <input
                      type="text"
                      name=""
                      value={""}
                      className={`${validErr && event === "" ? "error" : ""}`}
                      onChange={onChangeUpdate}
                    />
                    {validErr && (
                      <p className={`${styles.err_message}`}>
                        {CheckValidLength(event)}
                      </p>
                    )}
                  </div>
                  <div className={styles.input_2}></div>
                </div>
              </div>
              <div className={`${styles.modal_footer} modal-footer`}>
                <button
                  type="button"
                  className="button_sm"
                  onClick={updatePowerGeneration}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Modal update data : END */}

      <div className={`${styles.button_right}`}>
        <ExportPopup
          label={"document"}
          trigger={_triggerExport}
          event={{ csv: onExportCsv, xlsx: onExportXlsx }}
        />
        <button
          type="button"
          className={`${styles.btn_}`}
          onClick={() => setTriggerExport(!_triggerExport)}
        >
          Export
        </button>
      </div>
    </div>
  );
};

const formatToLocaleString = (value, maxDigit) => {
  const number = Number(value);

  return number.toLocaleString("en-US", { maximumFractionDigits: maxDigit });
};

export default PowerGene;
