import React from "react";
import { convertDateThai } from "../../../../../../../utils/format/Date.format";
import icon from "../../../../../../../assets/img/Icon";
import { downloadFilePdf } from "../../../../../../../helpers/func.helper";
import styles from "./EditSalesRecordForm.module.scss";

const EditSalesRecordForm = ({
  _onSwitchPage,
  _onSwitch,
  _dataObj,
  _paymentStatus,
}) => {
  return (
    <>
      <div className={`${styles.input_form}`}>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>ผู้ขายไฟฟ้า(ผู้ผลิตไฟฟ้า)</h5>
            <div className={`${styles.info_box}`}>
              <p>{_dataObj.payee}</p>
            </div>
          </div>
          <div className={`${styles.input_2}`}>
            <h5>ผู้ซื้อไฟฟ้า(การไฟฟ้า)</h5>
            <div className={`${styles.info_box}`}>
              <p>{_dataObj.buyer}</p>
            </div>
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>สถานะการจ่ายเงิน</h5>
            <div className={`${styles.info_box}`}>
              <p>
                {
                  _paymentStatus?.find(
                    (item) => item.id === _dataObj.paymentStatus
                  )?.statusName
                }
              </p>
            </div>
          </div>
          <div className={`${styles.input_2}`}>
            <h5>วันที่บันทึกการขาย</h5>
            <div className={`${styles.info_box}`}>
              <p>{convertDateThai(_dataObj.sellDate)}</p>
            </div>
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>เลขที่สัญญา PPA</h5>
            <div className={`${styles.info_box}`}>
              <p>{_dataObj.ppa?.no}</p>
            </div>
          </div>
          <div className={`${styles.input_2}`}>
            <h5>กำหนดชำระเงิน</h5>
            <div className={`${styles.info_box}`}>
              <p>{convertDateThai(_dataObj.payDate)}</p>
            </div>
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>ปริมาณไฟฟ้าที่เสนอขาย(KWh)</h5>
            <div className={`${styles.info_box}`}>
              <p>{_dataObj.powers}</p>
            </div>
          </div>
          <div className={`${styles.input_2}`}>
            <h5>ราคาต่อหน่วย(บาท)</h5>
            <div className={`${styles.info_box}`}>
              <p>{_dataObj.priceRate}</p>
            </div>
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>ยอดรวมสุทธิ(บาท)</h5>
            <div className={`${styles.info_box}`}>
              <p>{_dataObj.total}</p>
            </div>
          </div>
          <div className={`${styles.input_2}`}>
            <div className={`${styles.input_file}`}>
              <h5>ใบแจ้งหนี้</h5>
              <div className={`${styles.input_unit}`}>
                <div className={`${styles.info_box}`}>
                  <p>{_dataObj.ppaTransactionReceiptNavigation?.fileName}</p>
                </div>
                <div className={`${styles.unit}`}>
                  <img
                    src={icon.document}
                    alt=""
                    title="ดาวน์โหลดไฟล์"
                    onClick={() =>
                      downloadFilePdf(_dataObj.receipt, _dataObj.ppaTransactionReceiptNavigation)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.button_right}`}>
        <button
          type="button"
          className={`${styles.btn_}`}
          onClick={() => _onSwitch(true)}
        >
          แก้ไข
        </button>
        <button
          type="button"
          className={`${styles.btn_}`}
          onClick={() => _onSwitchPage(null)}
        >
          กลับ
        </button>
      </div>
    </>
  );
};

export default EditSalesRecordForm;
