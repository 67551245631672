import React, { useState, useEffect } from "react";
import { FaInfo, FaSquareFull } from "react-icons/fa";
import HighCharts from "../../../components/Highcharts/HighCharts";
import styles from "../scss/Index.module.scss";

const ChartsProduction = ({ valueInput, color, selYear }) => {
  const [stateChartOptions, setStateChartOptions] = useState({
    chart: {
      type: "variablepie",
      height: 190,
    },
    title: {
      text: "",
    },
    exporting: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "<b>{point.z} %</b>",
      style: {
        fontSize: "20px",
      },
    },
    plotOptions: {
      variablepie: {
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b><br>{point.z} %",
          style: {
            fontSize: "20px",
          },
        },
      },
    },
    series: [],
  });

  const [stateXPostItem, setStateXPostItem] = useState([]);

  const outletData = (total, plantId, setplant, setcolor) => {
    const setColorItem = (id, color) => {
      try {
        for (let index = 0; index < color.length; index++) {
          // if (id === color[index].id) {
          //   return color[index].colorCode
          // } else if (id > color.length) {
          //   return "#000000"
          // }
          if (id === index) {
            return color[index].colorCode;
          } else if (id > color.length) {
            return "#000000";
          }
        }
      } catch (error) {}
    };

    try {
      let id, name, valuePercent;
      for (let index = 0; index < setplant.length; index++) {
        id = setplant[index]?.plantId;
        name = setplant[index]?.plantName;
        if (Number(total) === 0) {
          valuePercent = 0;
        } else {
          valuePercent = Number(setplant[index]?.value / Number(total)) * 100;
        }
        if (plantId === id) {
          if (Number(valuePercent) === 0) {
            valuePercent = 0;
          } else {
            valuePercent = Number(valuePercent).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            });
          }

          return {
            plantid: id,
            plantname: name,
            percent: Number(valuePercent),
            color: setColorItem(index, setcolor),
          };
          //return { plantid: id, plantname: name, percent: valuePercent, color: setColorItem(id, setcolor) }
        }
      }
    } catch (error) {}
  };

  const listItem = (postItem, colors) => {
    try {
      return postItem.map((data, index) => {
        // let color = colors?.data?.color[index]?.colorCode;
        let color = data.color;
        return (
          <div key={index} className={`${styles.type_0}`}>
            <i style={{ color: color }}>
              <FaSquareFull />
            </i>{" "}
            <span>{data.dataLabels}</span>
          </div>
        );
      });
    } catch (error) {}
  };

  useEffect(() => {
    let xTotal = 0,
      xPlant = [],
      xPostItem = [];

    try {
      for (let index = 0; index < valueInput.enterprisePlants.length; index++) {
        xTotal = xTotal + Number(valueInput.enterprisePlants[index].value);
      }

      for (let index = 0; index < valueInput.enterprisePlants.length; index++) {
        let plantId = valueInput.enterprisePlants[index].plantId;
        xPlant[index] = outletData(
          xTotal,
          plantId,
          valueInput.enterprisePlants,
          valueInput.color
        );
      }

      for (let index = 0; index < xPlant.length; index++) {
        const item = xPlant[index];
        const colors = color?.data?.color[index]?.colorCode;
        if (item.percent) {
          // xPostItem[index] = {
          //   //color: item.color,
          //   color: colors,
          //   name: "",
          //   y: 90,
          //   z: item.percent,
          //   dataLabels: item.plantname,
          // };
          xPostItem.push({
            // color: color?.data?.color[xPostItem.length]?.colorCode,
            color: colors,
            name: "",
            y: 90,
            z: item.percent,
            dataLabels: item.plantname,
          });
        }
      }
    } catch (error) {}

    setStateXPostItem(xPostItem);

    const handleResize = () => {
      const infoListHeight =
        document.querySelector("#info_list")?.clientHeight + 4;
      const newHeight =
        document.querySelector("aside")?.clientHeight / 2 - infoListHeight;

      setStateChartOptions((prevOptions) => ({
        ...prevOptions,
        chart: {
          ...prevOptions.chart,
          height: newHeight,
        },
        series: [
          {
            minPointSize: 35,
            innerSize: "30%",
            zMin: 0,
            name: "การผลิตเชื้อเพลิง",
            data: xPostItem,
            // data: [
            //   {
            //     color: "#000000",
            //     name: "",
            //     y: 90,
            //     z: 43,
            //     dataLabels: "ข้าวโพด",
            //   },
            //   {
            //     color: "#000000",
            //     name: "",
            //     y: 90,
            //     z: 9,
            //     dataLabels: "มันสำปะหลัง",
            //   },
            //   {
            //     color: "#000000",
            //     name: "",
            //     y: 90,
            //     z: 10,
            //     dataLabels: "ปาล์มน้ำมัน",
            //   },
            //   {
            //     color: "#000000",
            //     name: "",
            //     y: 90,
            //     z: 38,
            //     dataLabels: "หญ้าเนเปียร์",
            //   },
            // ],
          },
        ],
      }));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [valueInput, color]);

  return (
    <div className={`${styles.chart_production} mx-auto position-relative`}>
      <h6
        className="d-flex justify-content-center text-center"
        style={{ fontSize: "14px" }}
      >
        สัดส่วนเชื้อเพลิงที่เพาะปลูกในโครงการ (เดือนปัจจุบัน) ประจำปี{" "}
        {Number(selYear) + 543}
      </h6>
      <HighCharts options={stateChartOptions} />

      <div className={`${styles.group_type}`}>
        <FaInfo
          className={`${styles.infomation}`}
          style={{ fontSize: "17px" }}
        />
        <div className={`${styles.boxPlant}`}>
          {listItem(stateXPostItem, color)}
        </div>
        {/* <div className={`${styles.type_1}`}>
          <i>
            <FaSquareFull />
          </i>{" "}
          <span>หญ้าเนเปียร์</span>
        </div>
        <div className={`${styles.type_2}`}>
          <i>
            <FaSquareFull />
          </i>{" "}
          <span>ปาล์มน้ำมัน</span>
        </div>
        <div className={`${styles.type_3}`}>
          <i>
            <FaSquareFull />
          </i>{" "}
          <span>ข้าวโพด</span>
        </div>
        <div className={`${styles.type_4}`}>
          <i>
            <FaSquareFull />
          </i>{" "}
          <span>มันสำปะหลัง</span>
        </div> */}
      </div>
    </div>
  );
};

export default ChartsProduction;
