import { toast } from "react-hot-toast";
import { defaultApi } from "../services/api";
import Message from "../utils/message/Message";

export const downloadFilePdf = async (id, data) => {
  if (!data) return;

  const loading = toast.loading(Message.notify.loading);
  try {
    const res = await defaultApi.downloadPdf(id, { responseType: "blob" });

    const blob = new Blob([res.data]);
    const href = window.URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = href;
    link.download = data?.fileName;

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically trigger the click event
    link.click();

    // Clean up by removing the link element
    document.body.removeChild(link);
    // Alert message
    toast.success(Message.notify.loading_success);
  } catch (error) {
    console.log(error);
    toast.error(Message.notify.loading_error);
  } finally {
    toast.dismiss(loading);
  }
};

export const exportCsvFile = async (exportName, type, obj) => {
  const convertToQueryString = (data) => {
    let queryString = "";

    for (let key in data) {
      if (
        data.hasOwnProperty(key) &&
        data[key] !== undefined &&
        data[key] !== ""
      ) {
        const value = data[key] !== undefined ? data[key] : "";
        if (queryString !== "") {
          queryString += "&";
        }
        queryString += key + "=" + encodeURIComponent(value);
      }
    }
    return queryString === "" ? "" : "&" + queryString;
  };

  const loading = toast.loading(Message.notify.loading);
  try {
    const res = await defaultApi.exportCsv(
      exportName,
      type,
      convertToQueryString(obj),
      { responseType: "blob" }
    );

    // Create a blob from the response data
    const blob = new Blob([res.data], { type: "text/csv" });

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);

    // Set the filename for the downloaded file
    link.download = exportName + ".csv";

    // Append the link to the document body
    document.body.appendChild(link);

    // Simulate a click event on the link to start the download
    link.click();

    // Clean up by removing the link element
    document.body.removeChild(link);
    // Alert message
    toast.success(Message.notify.loading_success);
  } catch (error) {
    console.log(error);
    toast.error(Message.notify.loading_error);
  } finally {
    toast.dismiss(loading);
  }
};

export const exportXlsxFile = async (exportName, type, obj) => {
  const convertToQueryString = (data) => {
    let queryString = "";

    for (let key in data) {
      if (
        data.hasOwnProperty(key) &&
        data[key] !== undefined &&
        data[key] !== ""
      ) {
        const value = data[key] !== undefined ? data[key] : "";
        if (queryString !== "") {
          queryString += "&";
        }
        queryString += key + "=" + encodeURIComponent(value);
      }
    }
    return queryString === "" ? "" : "&" + queryString;
  };

  const loading = toast.loading(Message.notify.loading);
  try {
    const res = await defaultApi.exportCsv(
      exportName,
      type,
      convertToQueryString(obj),
      { responseType: "blob" }
    );

    // Create a blob from the response data
    const blob = new Blob([res.data], { type: "text/xls" });

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);

    // Set the filename for the downloaded file
    link.download = exportName + ".xls";

    // Append the link to the document body
    document.body.appendChild(link);

    // Simulate a click event on the link to start the download
    link.click();

    // Clean up by removing the link element
    document.body.removeChild(link);
    // Alert message
    toast.success(Message.notify.loading_success);
  } catch (error) {
    console.log(error);
    toast.error(Message.notify.loading_error);
  } finally {
    toast.dismiss(loading);
  }
};

export const createUrlImage = (filePath) => {
  let baseUrl = process.env.REACT_APP_ENDPOINT_URL;
  let newUrlImg = `${baseUrl}${filePath}`;
  return newUrlImg;
};
