import React, { useMemo } from "react";
import Footer from "../../../layouts/footer_2/Footer";
import Header from "../../../layouts/header/Header";
import Navbar from "../../../layouts/navbar/Navbar";
import useSwitchChecked_2 from "../../../utils/hooks/useSwitchChecked_2";
import useSwitchPath from "../../../utils/hooks/useSwitchPath";
import MenuBar from "./MenuBar";
import ReportCompare from "./ReportCompare";
import ReportEstimate from "./ReportEstimate";
import ReportFarmland from "./ReportFarmland";
import ReportMonthIncome from "./ReportMonthIncome";
import ReportMonthSalesPlan from "./ReportMonthSalesPlan";
import ReportStatus from "./ReportStatus";
import ReportYearIncome from "./ReportYearIncome";
import ReportYearSalesPlan from "./ReportYearSalesPlan";
import styles from "./scss/FarmerReport.module.scss";
import { getProfileData } from "../../../services/auth.slice";
import { useSelector } from "react-redux";
import ReportResourceHarvest from "./ReportResourceHarvest";

const FarmerReport = () => {
  const [_location, navigate] = useSwitchPath();
  const [
    switchChecked,
    onSwitchChecked,
    switchChecked2,
    onSwitchChecked2,
    switchChecked3,
    onSwitchChecked3,
  ] = useSwitchChecked_2();

  const profile_ = useSelector(getProfileData);

  const changeThemeByRole = useMemo(() => {
    const role = profile_?.userGroupId;
    let theme;

    if (role === 2) {
      theme = {
        color: "#789b33",
        backgroundColor: "#789b33",
        class: "ent",
      };
    } else if (role === 3) {
      theme = {
        color: "",
        backgroundColor: "",
        class: "gov",
      };
    } else {
      theme = "";
    }

    return theme;
  }, [profile_]);

  return (
    <div className={`${styles.container} background_1`}>
      <Header />
      <section className={`section ${styles.section_} mx-auto`}>
        <Navbar />
        <article className={`article ${styles.article_} row mx-auto`}>
          <aside className={`${styles.menu_bar} col-3`}>
            <MenuBar
              switchChecked={switchChecked}
              onSwitchChecked={onSwitchChecked}
              switchChecked2={switchChecked2}
              onSwitchChecked2={onSwitchChecked2}
              switchChecked3={switchChecked3}
              onSwitchChecked3={onSwitchChecked3}
              _location={_location}
              _navigate={navigate}
            />
          </aside>
          <aside className={`${styles.content_} col-9`}>
            <div className={`${styles.content_box}`}>
              {_location === "/report" && <ReportFarmland themeColor={changeThemeByRole} />}
              {_location === "/report/members" && switchChecked === "1" && (
                <ReportStatus themeColor={changeThemeByRole} />
              )}
              {_location === "/report/members" && switchChecked === "2" && (
                <ReportCompare themeColor={changeThemeByRole} />
              )}
              {/* {_location === "/report/total-income" &&
                switchChecked2 === "3" && <ReportMonthIncome themeColor={changeThemeByRole} />} */}
              {_location === "/report/total-income" &&
                switchChecked2 === "4" && <ReportYearIncome themeColor={changeThemeByRole} />}
              {_location === "/report/sales-plan" && switchChecked3 === "5" && (
                <ReportMonthSalesPlan themeColor={changeThemeByRole} />
              )}
              {_location === "/report/sales-plan" && switchChecked3 === "6" && (
                <ReportYearSalesPlan themeColor={changeThemeByRole} />
              )}
              {_location === "/report/estimateproduce" && <ReportEstimate themeColor={changeThemeByRole} />}
              {_location === "/report/resource-harvest-1" && <ReportResourceHarvest themeColor={changeThemeByRole} />}
            </div>
          </aside>
        </article>
      </section>
      <Footer _themeColor={changeThemeByRole} />
    </div>
  );
};

export default FarmerReport;
