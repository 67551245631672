import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import styles from "./InfoWindow.module.scss";

const InfoWindow = ({ opened, children, onToggle, className }) => {
  return (
    <div
      className={`${styles.container} ${className} ${opened ? "opened" : ""}`}
    >
      <div className={`${styles.toggle}`} onClick={() => onToggle(!opened)}>
        {opened ? (
          <FaChevronRight className={`${styles.icon_}`} />
        ) : (
          <FaChevronLeft className={`${styles.icon_}`} />
        )}
      </div>

      <div className={styles.contents}>{children}</div>
    </div>
  );
};

export default InfoWindow;
