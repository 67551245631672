import { useState } from "react";

const useSwitch = () => {
  const [_switch, setSwitch] = useState(false);
  const [_switch2, setSwitch2] = useState(false);
  const [_switch3, setSwitch3] = useState(false);

  const onSwitch = (boolean) => {
    setSwitch(boolean);
  };
  const onSwitch2 = (boolean) => {
    setSwitch2(boolean);
  };
  const onSwitch3 = (boolean) => {
    setSwitch3(boolean);
  };

  return [_switch, onSwitch, _switch2, onSwitch2, _switch3, onSwitch3];
};

export default useSwitch;
