import moment from "moment";
import "moment/locale/th";

//convertDate
export const convertDateThai = (date) => {
  const yearSlice = date?.slice(0, 4);

  if (date === `${yearSlice}-02-29`) {
    const yearFormat = +yearSlice + 543;

    return `29/02/${yearFormat}`; // ถ้าเป็นวันที่ 29 เดือนกุมภาพันธ์ ให้ return ค่านี้
  } else {
    return moment(date, "YYYY-MM-DD").add(543, "year").format("DD/MM/YYYY");
  }
};
export const convertDateThai2 = (date, options) => {
  if (options === "day") {
    return moment(date).add(543, "year").format("DD");
  } else if (options === "day_month") {
    return moment(date).add(543, "year").format("DD/MM");
  } else if (options === "month") {
    return moment(date).add(543, "year").format("MM");
  } else if (options === "year") {
    return moment(date).add(543, "year").format("YYYY");
  } else {
    return moment(date).add(543, "year").format("DD/MM/YYYY");
  }
};

export const convertDateThaiAndTime = (date) => {
  return moment(date, "YYYY-MM-DD hh:mm:ss")
    .add(543, "year")
    .format("DD/MM/YYYY hh:mm:ss");
};
export const convertDateEu = (date) => {
  return moment(date).format("YYYY-MM-DD");
};
// covertMonthYear
export const convertMonthYearThai = (date) => {
  const _date = moment(date, "YYYY-MM");
  const _moment = _date.locale("th");
  return _moment.add(543, "year").format("MMMM YYYY");
};
