
import React from "react";
import Footer from '../../layouts/footer_2/Footer';
import Header from '../../layouts/header/Header';
import Navbar from '../../layouts/navbar/Navbar';
import MenuBar from './MenuBar';
import styles from './scss/Admin.module.scss';


export const index = () => {

    return (
        //${styles.container}
        <div className={`background_1 ${styles.container}`}>
            <Header />
            <section className={`section ${styles.section_} position-relative`}>
                <Navbar />
                <article className={`article ${styles.article_} row mx-auto`}>
                    <aside className={`${styles.map} col`}>
                        <MenuBar />
                    </aside>
                </article>
            </section>
            <Footer />
        </div>
    )

}
