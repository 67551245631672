import { useState } from "react";

const useSwitchPage = () => {
  const [switchPage, setSwitchPage] = useState(null);
  const onSwitchPage = (el) => {
    setSwitchPage(el);
  };
  return [switchPage, onSwitchPage];
};

export default useSwitchPage;
