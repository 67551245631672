import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-hot-toast";
import { CgCloseR } from "react-icons/cg";
import { FaInfoCircle } from "react-icons/fa";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import icon from "../../../../../assets/img/Icon";
import { defaultApi } from "../../../../../services/api";
import {
  getFactoryData,
  getFactoryStatus,
  getFactoryType,
} from "../../../../../services/factory.slice";
import { getProfileData } from "../../../../../services/auth.slice";
import Message from "../../../../../utils/message/Message";
import {
  CheckNumberDot,
  CheckValidLength,
} from "../../../../../utils/validation_input/Validation";
import { createUrlImage } from "../../../../../helpers/func.helper";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../../utils/format/Date.format";
import { QueryGetAll } from "../../../../../helpers/api.helper";
import image from "../../../../../assets/img/image";
import styles from "../scss/PowerPlantsDetails.module.scss";

const animated = makeAnimated();

const EditPowerPlants = ({
  fetchFacData,
  fetchFacMember,
  _allFacMemByFacId,
}) => {
  const factoryData = useSelector(getFactoryData);
  const ProfileData = useSelector(getProfileData);
  const _meterGenEl = useRef();
  const _meterSellEl = useRef();
  const factoryStatus = useSelector(getFactoryStatus);
  const factoryType = useSelector(getFactoryType);

  const [_data, setData] = useState({});
  const [allPlant, setAllPlant] = useState([]);
  const [allProvince, setAllProvince] = useState([]);
  const [allAmphoe, setAllAmphone] = useState([]);
  const [allTambol, setAllTambol] = useState([]);
  const [allUserData, setAllUserData] = useState([]);
  const [allFactoryMember, setAllFactoryMember] = useState([]);
  const [initDataFacMem, setInitDataFacMem] = useState(null);
  const [plantOpt, setPlantOpt] = useState([]);
  const [_plantTypeMain, setPlantTypeMain] = useState("");
  const [_plantTypeSecond, setPlantTypeSecond] = useState("");
  const [_newplantTypeMain, setNewPlantTypeMain] = useState([]);
  const [_newplantTypeSecond, setNewPlantTypeSecond] = useState([]);
  const [validErr, setValidErr] = useState(false);
  const [image, setImage] = useState({
    file: "",
  });
  const [_meter, setMeter] = useState({
    gen: "",
    sell: "",
  });
  const [selectedDate, setSelectDate] = useState(new Date());
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [placeholderDisabled, setPlaceholderDisabled] = useState(false);
  const [renderOneTime, setRenderOneTime] = useState(true);

  const facMemberOpt = useMemo(() => {
    // สร้างเซตของ userId ที่ต้องการตัดออก
    const userIdsToRemove = new Set(
      allFactoryMember.map((member) => member.userId)
    );

    // กรองข้อมูลใน allUserData โดยตรวจสอบว่า userId อยู่ในเซต userIdsToRemove หรือไม่
    const filteredUserData = allUserData.filter(
      (user) => !userIdsToRemove.has(user.id)
    );

    // Loop data เพื่อสร้าง data ใหม่
    let data = [];
    for (let i = 0; i < filteredUserData.length; i++) {
      const element = filteredUserData[i];
      let newObj = {
        value: element.id,
        label: element.firstname + " " + element.lastname,
        email: element.email,
        mobile: element.mobile,
        status: element.status,
        facId: factoryData?.id,
      };

      data[i] = newObj;
    }

    return data;
  }, [allUserData, allFactoryMember, factoryData]);

  const changeImg = async (e) => {
    const file = e.target.files[0];
    const file_val = document.querySelector("#file");
    const param1 = "Profile";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);

    if (!_data?.image) {
      console.log("Create image");
      try {
        const res = await defaultApi.createFileDetail(param1, param2);
        console.log(res.statusText);
        setData({ ..._data, image: res?.data.id });
        setImage({ file: URL.createObjectURL(file) });
        file_val.value = "";
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Update image");
      try {
        const res = await defaultApi.updateFileDetail(
          _data?.image,
          param1,
          param2
        );
        console.log(res.statusText);
        setData({ ..._data, image: res?.data.id });
        setImage({ file: URL.createObjectURL(file) });
        file_val.value = "";
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;

    setData({ ..._data, [name]: value });
  };
  const onChangeMeter = (e) => {
    const { name, value } = e.target;
    const isValid = /^\d*\.?\d*$/.test(value);
    const validationMessage = isValid
      ? ""
      : "กรอกได้เฉพาะตัวเลขและจุด เช่น 10.10 หรือ 10";

    if (name === "meterGen") {
      setMeter({ ..._meter, gen: value });
      _meterGenEl.current.textContent = validationMessage;
    } else {
      setMeter({ ..._meter, sell: value });
      _meterSellEl.current.textContent = validationMessage;
    }
  };
  const onChangeProvince = (e) => {
    const { value } = e.target;
    allProvince.forEach((item) => {
      if (item.provName === value) {
        setData({
          ..._data,
          addressProvince: value,
          addressProvCode: item.provCode,
          addressRegioncode: item.region,
          addressAmphoe: "",
          addressAmpCode: "",
          addressTambol: "",
          addressTamCode: "",
        });
        getAllAmphoe(item.provCode);
      }
    });
  };
  const onChangeAmphoe = (e) => {
    const { value } = e.target;
    allAmphoe.forEach((item) => {
      if (item.ampName === value) {
        setData({
          ..._data,
          addressAmphoe: value,
          addressAmpCode: item.ampCode,
          addressTambol: "",
          addressTamCode: "",
        });
        getAllTambol(item.ampCode, item.ampName);
      }
    });
  };
  const onChangeTambol = (e) => {
    const { value } = e.target;
    allTambol.forEach((item) => {
      if (item.tamName === value) {
        setData({
          ..._data,
          addressTambol: value,
          addressTamCode: item.tamCode,
        });
      }
    });
  };

  /* DatePicker : START */
  const onChangeDatePicker = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, proposalPowerAt: dateFormat });
    setSelectDate(e);
    setOpenDatePicker(!openDatePicker);
  };
  /* DatePicker : END */

  const getAllPlantType = async () => {
    try {
      const res = await defaultApi.getAllPlantType();
      setAllPlant(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllProvince = async () => {
    try {
      const res = await defaultApi.getAllProvince();

      setAllProvince(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllAmphoe = async (id) => {
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=provCode%20eq%20%27${id}%27`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllAmphoe(_queryAll);
      // Create a new Set with unique ampNames
      const uniqueAmpNames = [
        ...new Set(res.data.datas.map((item) => item.ampName)),
      ];

      // Create a new array of objects with unique ampNames
      const uniqueData = uniqueAmpNames.map((ampName) => {
        return res.data.datas.find((item) => item.ampName === ampName);
      });

      setAllAmphone(uniqueData);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllTambol = async (id, name) => {
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=ampCode%20eq%20%27${id}%27
      %20and%20ampName%20eq%20%27${name}%27`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllTambol(_queryAll);
      // Create a new Set with unique ampNames
      const uniqueAmpNames = [
        ...new Set(res.data.datas.map((item) => item.tamName)),
      ];

      // Create a new array of objects with unique ampNames
      const uniqueData = uniqueAmpNames.map((tamName) => {
        return res.data.datas.find((item) => item.tamName === tamName);
      });

      setAllTambol(uniqueData);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllFactoryMember = async () => {
    try {
      const res = await defaultApi.getAllFactoryMember();

      setAllFactoryMember(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllUser = useCallback(async () => {
    if (!factoryData?.createdBy) return;

    const query = `%24filter=userGroupId%20eq%201%20
    and%20status%20eq%20%27active%27%20
    and%20id%20ne%20${factoryData?.createdBy}`;

    try {
      const res = await defaultApi.getUser(query);

      setAllUserData(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [factoryData]);

  const updateFactory = useCallback(async () => {
    const closeModal = document.querySelector("#update-close");
    const isValidNumberDot = /^[0-9.]*$/;
    // Valid value all input
    if (CheckValidLength(_data?.name)) return setValidErr(true);
    if (CheckValidLength(_data?.owner)) return setValidErr(true);
    if (CheckValidLength(_data?.address)) return setValidErr(true);
    if (CheckNumberDot(_data?.lat)) return setValidErr(true);
    if (CheckNumberDot(_data?.lng)) return setValidErr(true);
    if (CheckValidLength(_data?.addressAmphoe)) return setValidErr(true);
    if (CheckValidLength(_data?.addressTambol)) return setValidErr(true);
    if (CheckValidLength(_plantTypeMain)) return setValidErr(true);
    if (CheckValidLength(_plantTypeSecond)) return setValidErr(true);
    if (CheckValidLength(_data?.proposalNo)) return setValidErr(true);
    if (CheckNumberDot(_data?.capacityProductionMonth))
      return setValidErr(true);
    if (CheckValidLength(_data?.licensee)) return setValidErr(true);
    if (CheckValidLength(_data?.licenseeRegister)) return setValidErr(true);
    if (CheckValidLength(_data?.deedNo)) return setValidErr(true);
    if (CheckNumberDot(_data?.capacityCost)) return setValidErr(true);
    if (CheckNumberDot(_data?.proposalPower)) return setValidErr(true);
    if (CheckNumberDot(_data?.factoryIncomeMonth)) return setValidErr(true);
    if (CheckNumberDot(_data?.targetPowerGenerate)) return setValidErr(true);
    if (CheckNumberDot(_data?.fit)) return setValidErr(true);
    /* ต้องเป็นตัวเลขและ . เท่านั้น : START */
    if (!isValidNumberDot.test(_meter.gen)) return setValidErr(true);
    if (!isValidNumberDot.test(_meter.sell)) return setValidErr(true);
    /* ต้องเป็นตัวเลขและ . เท่านั้น : END */
    if (CheckValidLength(_data?.buyName)) return setValidErr(true);
    if (CheckNumberDot(_data?.buyLat)) return setValidErr(true);
    if (CheckNumberDot(_data?.buyLng)) return setValidErr(true);

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "plantType") {
        params[key] = JSON.stringify(_newplantTypeMain);
      } else if (key === "plantTypeSecond") {
        params[key] = JSON.stringify(_newplantTypeSecond);
      } else if (key === "geom") {
        params[key] = null;
      } else if (key === "buyGeom") {
        params[key] = null;
      } else if (key === "meterGen") {
        params[key] =
          _meter.gen === ""
            ? _data[key] !== null
              ? _data[key]
              : null
            : +_meter.gen;
      } else if (key === "meterSell") {
        params[key] =
          _meter.sell === ""
            ? _data[key] !== null
              ? _data[key]
              : null
            : +_meter.sell;
      } else {
        params[key] = _data[key];
      }
    });
    Object.assign(params, { buyPoint: [[+_data?.buyLat, +_data?.buyLng]] });
    Object.assign(params, { point: [[+_data?.lat, +_data?.lng]] });
    try {
      const res = await defaultApi.updateFactory(_data?.id, params);
      console.log(res.statusText);
      // Fetch api
      fetchFacData();
      setMeter({ gen: "", sell: "" });
      // Close modal
      closeModal?.click();
      toast.success(Message.notify.update_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  }, [
    _data,
    _meter,
    _newplantTypeMain,
    _newplantTypeSecond,
    _plantTypeMain,
    _plantTypeSecond,
    fetchFacData,
  ]);
  const createFactoryMember = async () => {
    if (!initDataFacMem) return;

    const loading = toast.loading(Message.notify.loading);
    let params = {
      userId: initDataFacMem?.value,
      factoryId: initDataFacMem?.facId,
    };

    try {
      const res = await defaultApi.createFactoryMember(params);

      console.log(res.statusText);

      getAllFactoryMember();
      getAllUser();
      fetchFacMember();

      setInitDataFacMem(null);

      toast.success(Message.notify.add_factory_member_success, {
        duration: 3000,
      });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.add_factory_member_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };
  const deleteFactoryMember = async (idMember, idUser) => {
    if (idUser === ProfileData?.id)
      return toast.error(Message.notify.delete_member_warning, {
        duration: 4000,
      });

    if (!window.confirm(Message.notify.confirm_delete_member)) return;

    const loading = toast.loading(Message.notify.loading);
    try {
      const res = await defaultApi.deleteFactoryMember(idMember);

      console.log(res.statusText);

      getAllFactoryMember();
      getAllUser();
      fetchFacMember();

      toast.success(Message.notify.delete_factory_member_success, {
        duration: 3000,
      });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.delete_factory_member_error, {
        duration: 3000,
      });
    } finally {
      toast.dismiss(loading);
    }
  };

  const onBackAndReset = () => {
    setData(factoryData);
    setSelectDate(new Date());
    setInitDataFacMem(null);
    setRenderOneTime(true);
  };

  useEffect(() => {
    /* จัดข้อมูลใหม่เพิ่อเอาไปใช้เป็นตัว options ให้ multi select : START */
    let plantOpt = [];
    for (let i = 0; i < allPlant.length; i++) {
      allPlant.forEach((item) => {
        let obj_ = { value: item.id, label: item.name };
        plantOpt[i] = obj_;
        ++i;
      });
    }
    setPlantOpt(plantOpt);
    /* จัดข้อมูลใหม่เพิ่อเอาไปใช้เป็นตัว options ให้ multi select : END */
  }, [allPlant]);

  useEffect(() => {
    /* จัดข้อมูลใหม่เพิ่อเอาไปใช้เป็นตัว value ให้ multi select : START */
    if (factoryData?.plantType) {
      let json_obj = JSON.parse(factoryData?.plantType);
      let plantMainArr = [];
      for (let i = 0; i < json_obj.length; i++) {
        json_obj.forEach((item) => {
          let obj_ = { value: item.id, label: item.name };
          plantMainArr[i] = obj_;
          ++i;
        });
      }
      setPlantTypeMain(plantMainArr);
    }
    if (factoryData?.plantTypeSecond) {
      let json_obj = JSON.parse(factoryData?.plantTypeSecond);
      let plantSecondArr = [];
      for (let i = 0; i < json_obj.length; i++) {
        json_obj.forEach((item) => {
          let obj_ = { value: item.id, label: item.name };
          plantSecondArr[i] = obj_;
          ++i;
        });
      }
      setPlantTypeSecond(plantSecondArr);
    }
    // จัดข้อมูลใหม่เพิ่อเอาไปใช้เป็นตัว value ให้ multi select : END
  }, [factoryData]);

  useEffect(() => {
    /* จัดข้อมูลใหม่จากตัว option ที่เลือก เพิ่อที่จะส่งข้อมูลไปที่ database : START */
    let newPlantTypeMain = [];
    for (let i = 0; i < _plantTypeMain.length; i++) {
      _plantTypeMain.forEach((item) => {
        let id_ = item.value;
        let name_ = item.label;
        let obj_ = { id: id_, name: name_ };
        newPlantTypeMain[i] = obj_;
        ++i;
      });
    }
    setNewPlantTypeMain(newPlantTypeMain);

    let newPlantTypeSecond = [];
    for (let i = 0; i < _plantTypeSecond.length; i++) {
      _plantTypeSecond.forEach((item) => {
        let id_ = item.value;
        let name_ = item.label;
        let obj_ = { id: id_, name: name_ };
        newPlantTypeSecond[i] = obj_;
        ++i;
      });
    }
    setNewPlantTypeSecond(newPlantTypeSecond);
    /* จัดข้อมูลใหม่จากตัว option ที่เลือก เพิ่อที่จะส่งข้อมูลไปที่ database : END */
  }, [_plantTypeMain, _plantTypeSecond]);

  useEffect(() => {
    // Use functions
    getAllPlantType();
    setData(factoryData);
    getAllProvince();
    getAllUser();
    getAllFactoryMember();
  }, [factoryData, getAllUser]);

  useEffect(() => {
    /* Function close dropdown 'วันเริ่มเสนอขายไฟฟ้า' : START */
    const onCloseDropdownDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_proposal_powerAt_date");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ไม่ต้องทำอะไรเลย
        return;
      }

      // ตรวจสอบว่า element ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDatePicker(false);
      }
    };

    if (openDatePicker) {
      document.addEventListener("click", onCloseDropdownDate);
    }
    /* Function close dropdown 'วันเริ่มเสนอขายไฟฟ้า' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDate);
    };
  }, [openDatePicker]);

  /* Set ข้อมูลของ state-meter ในกรณีที่ค่าเป็น 0 : START */
  useEffect(() => {
    if (factoryData?.id && renderOneTime) {
      const meterGen = factoryData?.meterGen;
      const meterSell = factoryData?.meterSell;

      setMeter({ gen: meterGen, sell: meterSell });
      setRenderOneTime(false);
    }
  }, [factoryData, renderOneTime]);
  /* Set ข้อมูลของ state-meter ในกรณีที่ค่าเป็น 0 : END */

  useEffect(() => {
    openInfoLegend();
  }, []);

  return (
    <>
      <div
        className={`modal fade ${styles.edit_power_plants}`}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                แก้ไขรายละเอียดโรงไฟฟ้า
              </h5>
            </div>

            <div className="modal-body container">
              <div className="input_form">
                <div className="aside_top">
                  <div className="img_con">
                    <h5>รูปภาพโรงไฟฟ้า</h5>
                    <div className="img_box">
                      {!factoryData?.image && !image.file && (
                        <img
                          className="img_false"
                          onClick={openInputFile}
                          src={icon.pic}
                          alt=""
                          title="เปลี่ยนรูปภาพ"
                        />
                      )}
                      {image.file ? (
                        <>
                          <img className="img_true" src={image.file} alt="" />
                          <div className="img_add">
                            <img
                              onClick={openInputFile}
                              src={icon.pic}
                              alt=""
                              title="เปลี่ยนรูปภาพ"
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <img
                            className="img_true"
                            src={createUrlImage(
                              factoryData?.imageNavigation?.filePath
                            )}
                            alt=""
                          />
                          <div className="img_add">
                            <img
                              onClick={openInputFile}
                              src={icon.pic}
                              alt=""
                              title="เปลี่ยนรูปภาพ"
                            />
                          </div>
                        </>
                      )}
                      <input
                        type="file"
                        id="file"
                        accept="image/jpeg, image/png"
                        onChange={changeImg}
                        hidden
                      />
                      <label htmlFor="file" id="file-label-img"></label>
                    </div>
                  </div>
                </div>
                <div className="aside_box">
                  <div className="aside_left">
                    <div className="input_">
                      <h5>
                        ชื่อโรงไฟฟ้า <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="name"
                        value={_data?.name || ""}
                        className={`${
                          validErr && _data?.name === "" ? "error" : ""
                        }`}
                        onChange={onChange}
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckValidLength(_data?.name)}
                        </p>
                      )}
                    </div>
                    <div className="input_flex">
                      <div className="input_1">
                        <h5>สถานะ : </h5>
                        <select
                          name="factoryStatusId"
                          id=""
                          onChange={onChange}
                        >
                          <option style={{ display: "none" }}>
                            {factoryData?.factoryStatus?.name}
                          </option>
                          {factoryStatus?.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="input_2">
                        <h5>ประเภทโรงไฟฟ้า</h5>
                        <select name="factoryTypeId" id="" onChange={onChange}>
                          <option style={{ display: "none" }}>
                            {factoryData?.factoryType?.name}
                          </option>
                          {factoryType?.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="input_">
                      <h5>
                        ชื่อผู้ประกอบการ <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="owner"
                        value={_data?.owner || ""}
                        className={`${
                          validErr && _data?.owner === "" ? "error" : ""
                        }`}
                        onChange={onChange}
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckValidLength(_data?.owner)}
                        </p>
                      )}
                    </div>
                    <div className="input_">
                      <h5>
                        ที่ตั้ง <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="address"
                        value={_data?.address || ""}
                        className={`${
                          validErr && _data?.address === "" ? "error" : ""
                        }`}
                        onChange={onChange}
                        onFocus={() => setPlaceholderDisabled(true)}
                        onBlur={() => setPlaceholderDisabled(false)}
                        placeholder={
                          placeholderDisabled
                            ? ""
                            : "ตัวอย่างที่ตั้ง 7/11 ต.ภูผา อ.ภูผา จ.ขอนแก่น 12345"
                        }
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckValidLength(_data?.address)}
                        </p>
                      )}
                    </div>
                    <div className="input_">
                      <h5>จังหวัด</h5>
                      <select
                        name="addressProvince"
                        onChange={onChangeProvince}
                      >
                        <option style={{ display: "none" }}>
                          {_data?.addressProvince}
                        </option>
                        {allProvince
                          .sort((a, b) => a.provName.localeCompare(b.provName))
                          .map((item) => {
                            return (
                              <option key={item.gid} value={item.provName}>
                                {item.provName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="input_flex">
                      <div className="input_1">
                        <h5>อำเภอ</h5>
                        <select
                          name="addressAmphoe"
                          className={`${
                            validErr && _data.addressAmphoe === ""
                              ? "error"
                              : ""
                          }`}
                          onChange={onChangeAmphoe}
                        >
                          <option style={{ display: "none" }}>
                            {_data?.addressAmphoe === ""
                              ? "เลือกอำเภอ"
                              : _data?.addressAmphoe}
                          </option>
                          {allAmphoe
                            .sort((a, b) => a.ampName.localeCompare(b.ampName))
                            .map((item) => {
                              return (
                                <option key={item.gid} value={item.ampName}>
                                  {item.ampName}
                                </option>
                              );
                            })}
                        </select>
                        {validErr && (
                          <p className="err_message">
                            {CheckValidLength(_data?.addressAmphoe)}
                          </p>
                        )}
                      </div>
                      <div className="input_2">
                        <h5>ตำบล</h5>
                        <select
                          name="addressTambol"
                          className={`${
                            validErr && _data.addressTambol === ""
                              ? "error"
                              : ""
                          }`}
                          onChange={onChangeTambol}
                        >
                          <option style={{ display: "none" }}>
                            {_data?.addressTambol === ""
                              ? "เลือกตำบล"
                              : _data?.addressTambol}
                          </option>
                          {allTambol
                            .sort((a, b) => a.tamName.localeCompare(b.tamName))
                            .map((item) => {
                              return (
                                <option key={item.gid} value={item.tamName}>
                                  {item.tamName}
                                </option>
                              );
                            })}
                        </select>
                        {validErr && (
                          <p className="err_message">
                            {CheckValidLength(_data?.addressTambol)}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="input_flex">
                      <div className="input_1">
                        <h5>
                          ละติจูด (โรงไฟฟ้า) <sup className="asterisk">*</sup>
                        </h5>
                        <input
                          type="text"
                          name="lat"
                          value={_data?.lat || ""}
                          className={`${
                            validErr && _data?.lat === "" ? "error" : ""
                          }`}
                          onChange={onChange}
                        />
                        {validErr && (
                          <p className="err_message">
                            {CheckNumberDot(_data?.lat)}
                          </p>
                        )}
                      </div>
                      <div className="input_2">
                        <h5>
                          ลองจิจูด (โรงไฟฟ้า) <sup className="asterisk">*</sup>
                        </h5>
                        <input
                          type="text"
                          name="lng"
                          value={_data?.lng || ""}
                          className={`${
                            validErr && _data?.lng === "" ? "error" : ""
                          }`}
                          onChange={onChange}
                        />
                        {validErr && (
                          <p className="err_message">
                            {CheckNumberDot(_data?.lng)}
                          </p>
                        )}
                      </div>
                    </div>
                    <span
                      className="info_how_to"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop99"
                    >
                      วิธีหาค่าพิกัด
                    </span>
                    <div className="input_">
                      <h5>ชนิดเชื้อเพลิง / วัตถุดิบหลัก</h5>
                      <Select
                        className="muti_select"
                        value={_plantTypeMain}
                        closeMenuOnSelect={false}
                        components={animated}
                        isSearchable={false}
                        options={plantOpt}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderRadius: "10px",
                          }),
                        }}
                        placeholder={"เลือกชนิดเชื้อเพลิง / วัตถุดิบหลัก"}
                        onChange={(e) => setPlantTypeMain(e)}
                        isMulti
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckValidLength(_plantTypeMain)}
                        </p>
                      )}
                    </div>
                    <div className="input_">
                      <h5>ชนิดเชื้อเพลิง / วัตถุดิบเสริม</h5>
                      <Select
                        className="muti_select"
                        value={_plantTypeSecond}
                        closeMenuOnSelect={false}
                        components={animated}
                        isSearchable={false}
                        options={plantOpt}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderRadius: "10px",
                          }),
                        }}
                        placeholder={"เลือกชนิดเชื้อเพลิง / วัตถุดิบเสริม"}
                        onChange={(e) => setPlantTypeSecond(e)}
                        isMulti
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckValidLength(_plantTypeSecond)}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="aside_center">
                    <div className="input_flex">
                      <div className="input_1">
                        <h5>
                          เลขที่คำเสนอขอขายไฟฟ้า{" "}
                          <sup className="asterisk">*</sup>
                        </h5>
                        <input
                          type="text"
                          name="proposalNo"
                          value={_data?.proposalNo || ""}
                          className={`${
                            validErr && _data?.proposalNo === "" ? "error" : ""
                          }`}
                          onChange={onChange}
                        />
                        {validErr && (
                          <p className="err_message">
                            {CheckValidLength(_data?.proposalNo)}
                          </p>
                        )}
                      </div>
                      <div className="input_2">
                        <h5>
                          ปริมาณไฟฟ้าที่เสนอขาย (MW){" "}
                          <sup className="asterisk">*</sup>
                        </h5>
                        <input
                          type="text"
                          name="proposalPower"
                          value={_data?.proposalPower || ""}
                          className={`${
                            validErr && _data?.proposalPower === ""
                              ? "error"
                              : ""
                          }`}
                          onChange={onChange}
                        />
                        {validErr && (
                          <p className="err_message">
                            {CheckNumberDot(_data?.proposalPower)}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="input_">
                      <h5>
                        เลขทะเบียนใบอนุญาตผลิตไฟฟ้า{" "}
                        <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="licenseeRegister"
                        value={_data?.licenseeRegister || ""}
                        className={`${
                          validErr && _data?.licenseeRegister === ""
                            ? "error"
                            : ""
                        }`}
                        onChange={onChange}
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckNumberDot(_data?.licenseeRegister)}
                        </p>
                      )}
                    </div>
                    <div className="input_">
                      <h5>
                        ผู้รับอนุญาตตามสัญญาซื้อขายไฟฟ้า{" "}
                        <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="licensee"
                        value={_data?.licensee || ""}
                        className={`${
                          validErr && _data?.licensee === "" ? "error" : ""
                        }`}
                        onChange={onChange}
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckValidLength(_data?.licensee)}
                        </p>
                      )}
                    </div>
                    <div className="input_">
                      <h5>
                        เลขที่โฉนด <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="deedNo"
                        value={_data?.deedNo || ""}
                        className={`${
                          validErr && _data?.deedNo === "" ? "error" : ""
                        }`}
                        onChange={onChange}
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckValidLength(_data?.deedNo)}
                        </p>
                      )}
                    </div>
                    <div className="input_">
                      <h5>
                        ประมาณการค่าเชื้อเพลิง (บาท/ปี){" "}
                        <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="capacityCost"
                        value={_data?.capacityCost || ""}
                        className={`${
                          validErr && _data?.capacityCost === "" ? "error" : ""
                        }`}
                        onChange={onChange}
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckNumberDot(_data?.capacityCost)}
                        </p>
                      )}
                    </div>
                    <div className="input_">
                      <h5>
                        กำลังการผลิตไฟฟ้า (MW) <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="capacityProductionMonth"
                        value={_data?.capacityProductionMonth || ""} // change req
                        className={`${
                          validErr && _data?.capacityProductionMonth === ""
                            ? "error"
                            : ""
                        }`}
                        onChange={onChange}
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckNumberDot(_data?.capacityProductionMonth)}
                        </p>
                      )}
                    </div>
                    <div className="input_">
                      <h5 className="private_color">
                        รายได้จากการขายไฟฟ้า (บาท/ปี){" "}
                        <sup className="asterisk">*</sup>
                        <FaInfoCircle
                          className="info_data_icon"
                          color="#000000"
                        />
                      </h5>
                      <div className="info_data_box">
                        <div className="info_d_b">
                          <div className="info_color_i c_red"></div>
                          <p>เป็นข้อมูลแบบ private</p>
                        </div>
                        <div className="info_d_b">
                          <div className="info_color_i c_orange"></div>
                          <p>ไม่เป็นข้อมูลแบบ private</p>
                        </div>
                      </div>
                      <input
                        type="text"
                        name="factoryIncomeMonth"
                        value={_data?.factoryIncomeMonth || ""}
                        className={`${
                          validErr && _data?.factoryIncomeMonth === ""
                            ? "error"
                            : ""
                        }`}
                        onChange={onChange}
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckNumberDot(_data?.factoryIncomeMonth)}
                        </p>
                      )}
                    </div>
                    <div className="input_">
                      <h5 className="private_color">
                        ประมาณการปริมาณเชื้อเพลิงที่จะใช้ (ตัน/ปี){" "}
                        <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="targetPowerGenerate"
                        value={_data?.targetPowerGenerate || ""}
                        className={`${
                          validErr && _data?.targetPowerGenerate === ""
                            ? "error"
                            : ""
                        }`}
                        onChange={onChange}
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckNumberDot(_data?.targetPowerGenerate)}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="aside_right">
                    <div className="input_">
                      <h5>
                        อัตรา FIT (บาท/หน่วย) <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="fit"
                        value={_data?.fit || ""}
                        className={`${
                          validErr && _data?.fit === "" ? "error" : ""
                        }`}
                        onChange={onChange}
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckNumberDot(_data?.fit)}
                        </p>
                      )}
                    </div>
                    <div className="input_">
                      <h5>วันเริ่มเสนอขายไฟฟ้า</h5>
                      <div className="input_unit">
                        <div className="info_box">
                          <p>
                            {_data?.proposalPowerAt !== null
                              ? convertDateThai(_data?.proposalPowerAt)
                              : ""}
                          </p>
                        </div>
                        <div
                          className="unit"
                          onClick={() => setOpenDatePicker(!openDatePicker)}
                        >
                          <img
                            src={icon.calendar}
                            alt=""
                            id="fac_proposal_powerAt_date"
                          />
                        </div>
                      </div>
                      {openDatePicker && (
                        <DatePicker
                          selected={selectedDate}
                          onChange={onChangeDatePicker}
                          inline
                        />
                      )}
                    </div>
                    <div className="input_flex">
                      <div className="input_1">
                        <h5>เลขมิเตอร์เริ่มต้นที่ผลิต</h5>
                        <input
                          type="text"
                          name="meterGen"
                          value={_data?.meterGen || _meter.gen}
                          onChange={onChangeMeter}
                          pattern={/^\d*\.?\d*$/}
                          readOnly={_data?.meterGen === null ? false : true}
                        />
                        <p ref={_meterGenEl} className="err_message"></p>
                      </div>
                      <div className="input_2">
                        <h5>เลขมิเตอร์เริ่มต้นที่ขาย</h5>
                        <input
                          type="text"
                          name="meterSell"
                          value={_data?.meterSell || _meter.sell}
                          onChange={onChangeMeter}
                          pattern={/^\d*\.?\d*$/}
                          readOnly={_data?.meterSell === null ? false : true}
                        />
                        <p ref={_meterSellEl} className="err_message"></p>
                      </div>
                    </div>
                    <div className="input_">
                      <h5>
                        จุดรับซื้อ <sup className="asterisk">*</sup>
                      </h5>
                      <input
                        type="text"
                        name="buyName"
                        value={_data?.buyName || ""}
                        className={`${
                          validErr && _data?.buyName === "" ? "error" : ""
                        }`}
                        onChange={onChange}
                      />
                      {validErr && (
                        <p className="err_message">
                          {CheckValidLength(_data?.buyName)}
                        </p>
                      )}
                    </div>
                    <div className="input_flex">
                      <div className="input_1">
                        <h5>
                          ละติจูด (จุดรับซื้อ) <sup className="asterisk">*</sup>
                        </h5>
                        <input
                          type="text"
                          name="buyLat"
                          value={_data?.buyLat || ""}
                          className={`${
                            validErr && _data?.buyLat === "" ? "error" : ""
                          }`}
                          onChange={onChange}
                        />
                        {validErr && (
                          <p className="err_message">
                            {CheckNumberDot(_data?.buyLat)}
                          </p>
                        )}
                      </div>
                      <div className="input_2">
                        <h5>
                          ลองจิจูด (จุดรับซื้อ){" "}
                          <sup className="asterisk">*</sup>
                        </h5>
                        <input
                          type="text"
                          name="buyLng"
                          value={_data?.buyLng || ""}
                          className={`${
                            validErr && _data?.buyLng === "" ? "error" : ""
                          }`}
                          onChange={onChange}
                        />
                        {validErr && (
                          <p className="err_message">
                            {CheckNumberDot(_data?.buyLng)}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="input_">
                      <h5>เพิ่มผู้ประสานงาน</h5>
                      <Select
                        className="multi_select"
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        value={initDataFacMem ? initDataFacMem : []}
                        options={facMemberOpt}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            borderRadius: "10px",
                          }),
                        }}
                        placeholder={"เลือกผู้ประสานงาน"}
                        onChange={(e) => setInitDataFacMem(e)}
                      />
                    </div>
                    {initDataFacMem && (
                      <div
                        style={{
                          border: "2px dashed #ff8311",
                          margin: "0 0 15px 0",
                          padding: "0.4rem",
                        }}
                      >
                        <div
                          className="input_grps"
                          style={{ borderTop: "none" }}
                        >
                          <div className="input_grp">
                            <div className="input_">
                              <h5>ผู้ประสานงาน</h5>
                              <input
                                type="text"
                                value={initDataFacMem?.label}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="input_grp">
                            <div className="input_">
                              <h5>อีเมล</h5>
                              <input
                                type="text"
                                value={initDataFacMem?.email}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="input_grp">
                            <div className="input_">
                              <h5>เบอร์ติดต่อ</h5>
                              <input
                                type="text"
                                value={initDataFacMem?.mobile}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>

                        <div className="btn_gen">
                          <button
                            className="btn_"
                            onClick={createFactoryMember}
                          >
                            บันทึก
                          </button>
                        </div>
                      </div>
                    )}
                    {_allFacMemByFacId?.map((item, i) => {
                      return (
                        <div key={item.id} className="input_grps">
                          <div className="input_grp">
                            <div className="input_">
                              <h5
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                ผู้ประสานงาน (คนที่ {i + 1}) (
                                {item?.user.status})
                                <CgCloseR
                                  size={25}
                                  color="red"
                                  cursor={"pointer"}
                                  onClick={() =>
                                    deleteFactoryMember(item.id, item.userId)
                                  }
                                />
                              </h5>
                              <input
                                type="text"
                                value={
                                  item?.user.firstname +
                                  " " +
                                  item?.user.lastname
                                }
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="input_grp">
                            <div className="input_">
                              <h5>อีเมล</h5>
                              <input
                                type="text"
                                value={item?.user.email}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="input_grp">
                            <div className="input_">
                              <h5>เบอร์ติดต่อ</h5>
                              <input
                                type="text"
                                value={item?.user.mobile}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="button_group">
                <button
                  type="submit"
                  className="button_sm"
                  onClick={updateFactory}
                >
                  บันทึก
                </button>
                <button
                  id="update-close"
                  className="button_sm"
                  data-bs-dismiss="modal"
                  onClick={onBackAndReset}
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalHowTo />
    </>
  );
};

const ModalHowTo = () => {
  return (
    <div
      className={`modal fade ${styles.modal_how_to}`}
      id="staticBackdrop99"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content" style={{ minHeight: "auto" }}>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              วิธีการหาพิกัดตำแหน่งที่ดิน
            </h5>
          </div>
          <div className="modal-body">
            <div className="box_">
              <p>
                1. เข้า <img src={image.how_to_coor1_1} alt="" /> Google Maps
                ผ่านเว็บบราวน์เซอร์ <img src={image.how_to_coor1_2} alt="" />
              </p>
            </div>
            <div className="box_">
              <p>2. ทำการเปลี่ยนรูปแบบแผนที่เป็นแบบ ภาพถ่ายดาวเทียม</p>
              <div className="img_box">
                <img src={image.how_to_coor2} alt="" />
              </div>
            </div>
            <div className="box_">
              <p>
                3. จากนั้นค้นหาที่ช่องค้นหา ด้วย ชื่อตำบล อำเภอ จังหวัด
                หรือสถานที่ใกล้เคียงกับ ตำแหน่งของโฉนดที่ดิน
              </p>
              <div className="img_box_flex">
                <div className="img_">
                  <img src={image.how_to_coor3_1} alt="" />
                </div>
                <p>หรือ</p>
                <div className="img_">
                  <img src={image.how_to_coor3_2} alt="" />
                </div>
              </div>
            </div>
            <div className="box_">
              <p>
                4. เมื่อพบตำแหน่งที่ดินแล้ว ให้คลิกขวาในพื้นที่ ที่ดินตามโฉนด
                จะปรากฎ พิกัดละติจูด และลองจิจูด
              </p>
              <div className="img_box">
                <img src={image.how_to_coor4} alt="" />
              </div>
            </div>
            <div className="box_">
              <p>5. คลิกที่เลขพิกัด เพื่อคัดลอก</p>
            </div>
            <div className="box_">
              <p>
                6. นำมาใส่ในข้อมูลพิกัด พร้อมทั้งลบ เว้นวรรคหลัง ,
                เพื่อให้ตรงตามรูปแบบ
              </p>
              <div className="img_box" style={{ overflowX: "auto" }}>
                <img src={image.how_to_coor6} alt="" />
              </div>
            </div>

            <div className="btn_center">
              <button type="button" className="btn_" data-bs-dismiss="modal">
                กลับ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function openInputFile() {
  document.querySelector("#file-label-img").click();
  document.querySelector("#file").value = "";
}

function openInfoLegend() {
  const icon = document.querySelector(".info_data_icon");
  const info_box = document.querySelector(".info_data_box");

  icon.addEventListener("mouseover", () => info_box.classList.add("show"));
  icon.addEventListener("mouseout", () => info_box.classList.remove("show"));
}

export default EditPowerPlants;
