import React, { useEffect, useState } from "react";
import { GoGrabber } from "react-icons/go";
import { useSelector } from "react-redux";
import { getProfileData } from "../../services/auth.slice";
import useSwitchPath from "../../utils/hooks/useSwitchPath";
import { ManageCordData } from "./ManageCordData";
import { ManageHistData } from "./ManageHistData";
import { ManageLevelData } from "./ManageLevelData";
import { ManagePmsData } from "./ManagePmsData";
import { ManageSubgrpData } from "./ManageSubgrpData";
import { ManageUserData } from './ManageUserData.js';
import styles from "./scss/MenuBar.module.scss";
import ManageFactoryMeter from "./ManageFactoryMeter";
import ManageOrgData from "./ManageOrgData";


const MenuBar = () => {
  const [_location, navigate] = useSwitchPath();

  const [_toggleBurger, toggleBurger] = useState(true);
  const profileData = useSelector(getProfileData);

  useEffect(() => {
    // navigate('/admin/manage-user')
    if (profileData?.userGroupId === 5) {
      if (_location === "/admin/") {
        navigate('/admin/manage-user')
      }
    } else {
      navigate('/')
    }
  })
  //[_location, profileData.userGroupId]

  return (
    <>
      {/* <div className={`${styles.container} d-flex flex-row`}> */}
      <div className={`${styles.container}`}>
        <button onClick={() => navigate('/admin/manage-user')} className={`btn_menu ${styles.enble0} ${_location === '/admin/manage-user' ? 'active' : ''}`}>
          จัดการผู้ใช้งาน
        </button>
        {/* <button onClick={() => navigate('/admin/manage-layers')} className={`btn_menu ${styles.enble0} ${_location === '/admin/manage-layers' ? 'active' : ''}`}>
          จัดการชั้นข้อมูล
        </button> */}
        <button onClick={() => navigate('/admin/manage-history')} className={`btn_menu ${styles.enble0} ${_location === '/admin/manage-history' ? 'active' : ''}`}>
          ประวัติการใช้งาน
        </button>
        <button onClick={() => navigate('/admin/manage-org')} className={`btn_menu ${styles.enble0} ${_location === '/admin/manage-org' ? 'active' : ''}`}>
          เพิ่มหน่วยงาน
        </button>
        <button onClick={() => navigate('/admin/manage-subgrp')} className={`btn_menu ${styles.enble0} ${_location === '/admin/manage-subgrp' ? 'active' : ''}`}>
          เพิ่มกลุ่มผู้ใช้งานย่อย
        </button>
        <button onClick={() => navigate('/admin/manage-pmssubgrp')} className={`btn_menu ${styles.enble0} ${_location === '/admin/manage-pmssubgrp' ? 'active' : ''}`}>
          สิทธิเข้าถึงผู้ใช้งาน
        </button>
        <button onClick={() => navigate('/admin/manage-coordinator')} className={`btn_menu ${styles.enble0} ${_location === '/admin/manage-coordinator' ? 'active' : ''}`}>
          รายชื่อผู้ประสานงาน
        </button>
        <button onClick={() => navigate('/admin/manage-factorymeter')} className={`btn_menu ${styles.enble0} ${_location === '/admin/manage-factorymeter' ? 'active' : ''}`}>
          จัดการมิเตอร์โรงไฟฟ้า
        </button>

        <div className={`${styles.bgdisplay}`}>
          <GoGrabber className={`${styles.ic_burger}`} onClick={() => { toggleBurger(!_toggleBurger) }} />
          <div className={`${styles.boxitemlist}`} style={_toggleBurger === true ? { display: "none" } : { display: "flex" }}>
            <button onClick={() => { navigate('/admin/manage-user'); toggleBurger(!_toggleBurger) }} className={`btn_menu ${styles.enble2} ${_location === '/admin/manage-user' ? 'active' : ''}`}>
              จัดการผู้ใช้งาน
            </button>
            {/* <button onClick={() => { navigate('/admin/manage-layers'); toggleBurger(!_toggleBurger) }} className={`btn_menu ${styles.enble2} ${_location === '/admin/manage-layers' ? 'active' : ''}`}>
              จัดการชั้นข้อมูล
            </button> */}
            <button onClick={() => { navigate('/admin/manage-history'); toggleBurger(!_toggleBurger) }} className={`btn_menu ${styles.enble2} ${_location === '/admin/manage-history' ? 'active' : ''}`}>
              ประวัติการใช้งาน
            </button>
            <button onClick={() => { navigate('/admin/manage-subgrp'); toggleBurger(!_toggleBurger) }} className={`btn_menu ${styles.enble2} ${_location === '/admin/manage-subgrp' ? 'active' : ''}`}>
              เพิ่มกลุ่มผู้ใช้งานย่อย
            </button>
            <button onClick={() => { navigate('/admin/manage-org'); toggleBurger(!_toggleBurger) }} className={`btn_menu ${styles.enble2} ${_location === '/admin/manage-org' ? 'active' : ''}`}>
              เพิ่มหน่วยงาน
            </button>
            <button onClick={() => { navigate('/admin/manage-pmssubgrp'); toggleBurger(!_toggleBurger) }} className={`btn_menu ${styles.enble2} ${_location === '/admin/manage-pmssubgrp' ? 'active' : ''}`}>
              สิทธิเข้าถึงผู้ใช้งาน
            </button>
            <button onClick={() => { navigate('/admin/manage-coordinator'); toggleBurger(!_toggleBurger) }} className={`btn_menu ${styles.enble2} ${_location === '/admin/manage-coordinator' ? 'active' : ''}`}>
              รายชื่อผู้ประสานงาน
            </button>
            <button onClick={() => { navigate('/admin/manage-factorymeter'); toggleBurger(!_toggleBurger) }} className={`btn_menu ${styles.enble2} ${_location === '/admin/manage-factorymeter' ? 'active' : ''}`}>
              จัดการมิเตอร์โรงไฟฟ้า
            </button>
          </div>
        </div>
        <div>

        </div>
      </div>

      {_location === '/admin/manage-user' && (
        <ManageUserData toggerCheck={_toggleBurger} />
      )}
      {/* {_location === '/admin/manage-layers' && (
        <ManageLevelData toggerCheck={_toggleBurger} />
      )} */}
      {_location === '/admin/manage-history' && (
        <ManageHistData toggerCheck={_toggleBurger} />
      )}
      {_location === '/admin/manage-subgrp' && (
        <ManageSubgrpData toggerCheck={_toggleBurger} />
      )}
      {_location === '/admin/manage-org' && (
        <ManageOrgData toggerCheck={_toggleBurger} />
      )}
      {_location === '/admin/manage-pmssubgrp' && (
        <ManagePmsData toggerCheck={_toggleBurger} />
      )}
      {_location === '/admin/manage-coordinator' && (
        <ManageCordData toggerCheck={_toggleBurger} />
      )}
      {_location === '/admin/manage-factorymeter' && (
        <ManageFactoryMeter toggerCheck={_toggleBurger} />
      )}
    </>
  );
};

// function switchMenuActive() {
//   const btn = document.querySelectorAll(".btn_menu");
//   btn.forEach((item) => {
//     item.addEventListener("click", () => {
//       btn.forEach((item) => item.classList.remove("active"));
//       item.classList.add("active");
//     });
//   });
// }

export default MenuBar;
