import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GrFormClose } from "react-icons/gr";
import { toast } from "react-hot-toast";
import { FaEye } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getFactoryData } from "../../../../services/factory.slice";
import icon from "../../../../assets/img/Icon";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../utils/format/Date.format";
import AddForm from "./AddForm";
import EditForm from "./EditForm";
import Pagination from "../../../../components/pagination/Pagination";
import useSwitch from "../../../../utils/hooks/useSwitch";
import { defaultApi } from "../../../../services/api";
import { QueryGetAll, QueryGetByID } from "../../../../helpers/api.helper";
import { clearObj } from "../../../../utils/format/Object.format";
import Message from "../../../../utils/message/Message";
import { exportCsvFile, exportXlsxFile } from "../../../../helpers/func.helper";
import styles from "./scss/Expenses.module.scss";
import ExportPopup from "../../../../layouts/exportpopup/ExportPopup";

const pageSize = 10;

const initDataAdd = {
  recordedAt: "",
  recordId: "",
  price: "",
  factoryId: "",
  enterpriseId: null,
};

const Expenses = () => {
  const factoryData = useSelector(getFactoryData);
  const { layerId } = useParams();
  const [_switch, onSwitch] = useSwitch();

  const [_dataAdd, setDataAdd] = useState(initDataAdd);
  const [_dataEdit, setDataEdit] = useState({});
  const [allFactoryRecord, setAllFactoryRecord] = useState([]);
  const [allRecordDetails, setAllRecordDetail] = useState([]);
  const [allEnterprise, setAllEnterprise] = useState([]);
  const [allTransaction, setAllTransaction] = useState([]);
  const [_transacData, setTransacData] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterGroup, setFilterGroup] = useState({
    recordId: "",
    atDate: "",
    toDate: "",
  });
  const [selectedDate, setSelectedDate] = useState({
    atDate: new Date(),
    toDate: new Date(),
  });
  const [openAtDate, setOpenAtDate] = useState(false);
  const [openToDate, setOpenToDate] = useState(false);
  const [validErr, setValidErr] = useState(false);
  const [_triggerExport, setTriggerExport] = useState(false);

  /* DatePicker : START */
  const onChangeAtDate = (e) => {
    const dateFormat = convertDateEu(e);
    setFilterGroup({ ...filterGroup, atDate: dateFormat });
    setSelectedDate({ ...selectedDate, atDate: e });
    setOpenAtDate(!openAtDate);
  };
  const onChangeToDate = (e) => {
    const dateFormat = convertDateEu(e);
    setFilterGroup({ ...filterGroup, toDate: dateFormat });
    setSelectedDate({ ...selectedDate, toDate: e });
    setOpenToDate(!openToDate);
  };
  /* DatePicker : END */

  /* Export csv and excel : START */
  const onExportCsv = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "FactoryRecordReport";
    let fileType = "csv";
    let obj = {
      factoryId: factoryData.id,
      recordId: filterGroup.recordId,
      atDate: filterGroup.atDate,
      toDate: filterGroup.toDate,
    };
    // Ready
    exportCsvFile(exportName, fileType, obj);
  };
  const onExportXlsx = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "FactoryRecordReport";
    let fileType = "excel";
    let obj = {
      factoryId: factoryData.id,
      recordId: filterGroup.recordId,
      atDate: filterGroup.atDate,
      toDate: filterGroup.toDate,
    };
    // Ready
    exportXlsxFile(exportName, fileType, obj);
  };
  /* Export csv and excel : END */

  /* Pagination table : START */
  const onPaginate = useCallback(
    async (page) => {
      if (currentPage === page) return;

      let factoryId = factoryData?.id;
      const qryGetAll = {
        $count: "%24count=true",
        $expand: "&%24expand=recordDetails",
        $top: "&%24top=10",
        $skip: `&%24skip=${page <= 1 ? "0" : page * 10 - 10}`,
        $filter: "",
        $orderby: "",
      };
      const _queryAll = QueryGetAll(qryGetAll);
      const formatQry = (obj) => {
        const checkObj = clearObj({
          recordId: obj.recordId,
          recordedAt: obj.atDate,
          recordedAt2: obj.toDate,
        });
        if (Object.keys(clearObj(checkObj)).length === 0) {
          return `${QueryGetAll(
            qryGetAll
          )}&%24filter=factoryId%20eq%20${factoryId}`;
        } else {
          let objLng = Object.keys(clearObj(checkObj)).length;
          let count = 0;
          let filter1 = "";
          let filter2 = "";
          let filter3 = "";
          for (const [key, value] of Object.entries(clearObj(checkObj))) {
            count++;
            // # แบ่งเป็น 2 เคส #

            // 1 # มี objkey ตัวเดียว #
            if (objLng <= 1) {
              if (key === "recordedAt") {
                filter1 = `${key}%20ge%20${value}`;
              } else if (key === "recordedAt2") {
                filter1 = `recordedAt%20le%20${value}`;
              } else {
                filter1 = `${key}%20eq%20${value}`;
              }
            } else {
              // 2 # มี objkey มากกว่า 1 ตัว #
              // ตัวแรก
              if (count === 1) {
                if (key === "recordedAt") {
                  filter1 = `${key}%20ge%20${value}%20and%20`;
                } else if (key === "recordedAt2") {
                  filter1 = `recordedAt%20le%20${value}%20and%20`;
                } else {
                  filter1 = `${key}%20eq%20${value}%20and%20`;
                }
              }
              // ตัวสุดท้าย
              else if (count === objLng) {
                if (key === "recordedAt") {
                  filter3 = `${key}%20ge%20${value}`;
                } else if (key === "recordedAt2") {
                  filter3 = `recordedAt%20le%20${value}`;
                } else {
                  filter3 = `${key}%20eq%20${value}`;
                }
              }
              // ตัวอื่นๆ
              else {
                if (key === "recordedAt") {
                  filter2 = `${key}%20ge%20${value}%20and%20`;
                } else if (key === "recordedAt2") {
                  filter2 = `recordedAt%20le%20${value}%20and%20`;
                } else {
                  filter2 = `${key}%20eq%20${value}%20and%20`;
                }
              }
            }
          }
          return `${_queryAll}&%24filter=factoryId%20eq%20${factoryId}
          %20and%20${filter1}${filter2}${filter3}`;
        }
      };
      try {
        const res = await defaultApi.getAllFactoryRecord(
          formatQry(filterGroup)
        );
        setAllFactoryRecord(res.data.datas);
        setTotalCount(res.data.total);
        setCurrentPage(page);
      } catch (error) {
        console.log(error);
      }
    },
    [factoryData, filterGroup, currentPage]
  );
  /* Pagination table : END */

  /* Filter table : START */
  const filterDataTable = useCallback(async () => {
    let factoryId = factoryData?.id;
    const loading = toast.loading(Message.notify.search_loading);
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=recordDetails",
      $top: "&%24top=10",
      $skip: "&%24skip=0",
      $filter: "",
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    const formatQry = (obj) => {
      const checkObj = clearObj({
        recordId: obj.recordId,
        recordedAt: obj.atDate,
        recordedAt2: obj.toDate,
      });
      if (Object.keys(clearObj(checkObj)).length === 0) {
        return `${QueryGetAll(
          qryGetAll
        )}&%24filter=factoryId%20eq%20${factoryId}`;
      } else {
        let objLng = Object.keys(clearObj(checkObj)).length;
        let count = 0;
        let filter1 = "";
        let filter2 = "";
        let filter3 = "";
        for (const [key, value] of Object.entries(clearObj(checkObj))) {
          count++;
          // # แบ่งเป็น 2 เคส #

          // 1 # มี objkey ตัวเดียว #
          if (objLng <= 1) {
            if (key === "recordedAt") {
              filter1 = `${key}%20ge%20${value}`;
            } else if (key === "recordedAt2") {
              filter1 = `recordedAt%20le%20${value}`;
            } else {
              filter1 = `${key}%20eq%20${value}`;
            }
          } else {
            // 2 # มี objkey มากกว่า 1 ตัว #
            // ตัวแรก
            if (count === 1) {
              if (key === "recordedAt") {
                filter1 = `${key}%20ge%20${value}%20and%20`;
              } else if (key === "recordedAt2") {
                filter1 = `recordedAt%20le%20${value}%20and%20`;
              } else {
                filter1 = `${key}%20eq%20${value}%20and%20`;
              }
            }
            // ตัวสุดท้าย
            else if (count === objLng) {
              if (key === "recordedAt") {
                filter3 = `${key}%20ge%20${value}`;
              } else if (key === "recordedAt2") {
                filter3 = `recordedAt%20le%20${value}`;
              } else {
                filter3 = `${key}%20eq%20${value}`;
              }
            }
            // ตัวอื่นๆ
            else {
              if (key === "recordedAt") {
                filter2 = `${key}%20ge%20${value}%20and%20`;
              } else if (key === "recordedAt2") {
                filter2 = `recordedAt%20le%20${value}%20and%20`;
              } else {
                filter2 = `${key}%20eq%20${value}%20and%20`;
              }
            }
          }
        }
        return `${_queryAll}&%24filter=factoryId%20eq%20${factoryId}
          %20and%20${filter1}${filter2}${filter3}`;
      }
    };
    try {
      const res = await defaultApi.getAllFactoryRecord(formatQry(filterGroup));
      // all data set at state
      setAllFactoryRecord(res.data.datas);
      // all total set at state
      setTotalCount(res.data.total);
      // set currentPage state
      setCurrentPage(1);
      // response array = 0
      if (res.data.datas.length === 0) {
        toast.error(Message.notify.search_not_found, { duration: 3000 });
      }
    } catch (error) {
      console.log(error);
    } finally {
      toast.dismiss(loading);
    }
  }, [factoryData, filterGroup]);
  /* Filter table : END */

  /* get data + filter : START */
  const getAllFactoryRecordFilter = useCallback(async () => {
    let factoryId = factoryData?.id;
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=recordDetails",
      $top: "&%24top=10",
      $skip: `&%24skip=${currentPage <= 1 ? "0" : currentPage * 10 - 10}`,
      $filter: "",
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    const formatQry = (obj) => {
      const checkObj = clearObj({
        recordId: obj.recordId,
        recordedAt: obj.atDate,
        recordedAt2: obj.toDate,
      });
      if (Object.keys(clearObj(checkObj)).length === 0) {
        return `${QueryGetAll(
          qryGetAll
        )}&%24filter=factoryId%20eq%20${factoryId}`;
      } else {
        let objLng = Object.keys(clearObj(checkObj)).length;
        let count = 0;
        let filter1 = "";
        let filter2 = "";
        let filter3 = "";
        for (const [key, value] of Object.entries(clearObj(checkObj))) {
          count++;
          // # แบ่งเป็น 2 เคส #

          // 1 # มี objkey ตัวเดียว #
          if (objLng <= 1) {
            if (key === "recordedAt") {
              filter1 = `${key}%20ge%20${value}`;
            } else if (key === "recordedAt2") {
              filter1 = `recordedAt%20le%20${value}`;
            } else {
              filter1 = `${key}%20eq%20${value}`;
            }
          } else {
            // 2 # มี objkey มากกว่า 1 ตัว #
            // ตัวแรก
            if (count === 1) {
              if (key === "recordedAt") {
                filter1 = `${key}%20ge%20${value}%20and%20`;
              } else if (key === "recordedAt2") {
                filter1 = `recordedAt%20le%20${value}%20and%20`;
              } else {
                filter1 = `${key}%20eq%20${value}%20and%20`;
              }
            }
            // ตัวสุดท้าย
            else if (count === objLng) {
              if (key === "recordedAt") {
                filter3 = `${key}%20ge%20${value}`;
              } else if (key === "recordedAt2") {
                filter3 = `recordedAt%20le%20${value}`;
              } else {
                filter3 = `${key}%20eq%20${value}`;
              }
            }
            // ตัวอื่นๆ
            else {
              if (key === "recordedAt") {
                filter2 = `${key}%20ge%20${value}%20and%20`;
              } else if (key === "recordedAt2") {
                filter2 = `recordedAt%20le%20${value}%20and%20`;
              } else {
                filter2 = `${key}%20eq%20${value}%20and%20`;
              }
            }
          }
        }
        return `${_queryAll}&%24filter=factoryId%20eq%20${factoryId}
          %20and%20${filter1}${filter2}${filter3}`;
      }
    };
    try {
      const res = await defaultApi.getAllFactoryRecord(formatQry(filterGroup));
      setAllFactoryRecord(res.data.datas);
      setTotalCount(res.data.total);
      setCurrentPage(currentPage);
    } catch (error) {
      console.log(error);
    }
  }, [factoryData, filterGroup, currentPage]);
  /* get data + filter : END */

  const getAllFactoryRecord = useCallback(async () => {
    if (!factoryData?.id) return;

    let factoryId = factoryData?.id;
    const loading = toast.loading(Message.notify.loading);
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=recordDetails",
      $top: "&%24top=10",
      $skip: "&%24skip=0",
      $filter: `&%24filter=factoryId%20eq%20${factoryId}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllFactoryRecord(_queryAll);
      // all data set at state
      setAllFactoryRecord(res.data.datas);
      // all total set at state
      setTotalCount(res.data.total);
      // set currentPage state
      setCurrentPage(1);
    } catch (error) {
      console.log(error);
    } finally {
      toast.dismiss(loading);
    }
  }, [factoryData]);
  const getFactoryRecord = async (id) => {
    const qryGetById = {
      $expand: "&%24expand=recordDetails",
      $filter: "",
      $orderby: "",
    };
    const _queryID = QueryGetByID(qryGetById);
    try {
      const res = await defaultApi.getFactoryRecord(id, _queryID);
      setDataEdit(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllEnterprise = useCallback(async () => {
    if (!layerId) return;

    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=factoryId%20eq%20${layerId}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllEnterprise(_queryAll);
      const datas = res.data.datas;
      setAllEnterprise(datas);
    } catch (error) {
      console.log(error);
    }
  }, [layerId]);
  const getMultipleApi = useCallback(async () => {
    if (!layerId) return;

    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=plantType",
      $top: "&%24top=10",
      $skip: "&%24skip=0",
      $filter: `&%24filter=factoryId%20eq%20${layerId}and%20note%20ne%20%27sell%27
      and%20note%20ne%20%27buy%27`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const [_allRecordDetail, _allTransaction] = await Promise.all([
        defaultApi.getAllRecordDetail(),
        defaultApi.getAllTransaction(_queryAll),
      ]);

      setAllRecordDetail(_allRecordDetail.data);
      setAllTransaction(_allTransaction.data.datas);
    } catch (error) {
      console.log(error);
    }
  }, [layerId]);
  const getTransaction = async (id) => {
    const findID = allTransaction.find(
      (item) => item.factoryRecordId === id
    )?.id;
    if (!findID) return;

    try {
      const res = await defaultApi.getTransaction(findID);
      setTransacData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      filterGroup.atDate === "" &&
      filterGroup.toDate === "" &&
      filterGroup.recordId === ""
    ) {
      getAllFactoryRecord();
    } else if (
      filterGroup.atDate !== "" ||
      filterGroup.toDate !== "" ||
      filterGroup.recordId !== ""
    ) {
      filterDataTable();
    }
  }, [getAllFactoryRecord, filterDataTable, filterGroup]);

  useEffect(() => {
    // Use functions
    getMultipleApi();
    getAllEnterprise();
  }, [getAllEnterprise, getMultipleApi]);

  useEffect(() => {
    /* Function close dropdown 'AtDate and ToDate' : START */
    const onCloseDropdownAtDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_at_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenAtDate(false);
      }
    };
    const onCloseDropdownToDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_to_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenToDate(false);
      }
    };

    if (openAtDate) {
      document.addEventListener("click", onCloseDropdownAtDate);
    }
    if (openToDate) {
      document.addEventListener("click", onCloseDropdownToDate);
    }
    /* Function close dropdown 'AtDate and ToDate' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownAtDate);
      document.removeEventListener("click", onCloseDropdownToDate);
    };
  }, [openAtDate, openToDate]);

  return (
    <div className={`${styles.container}`}>
      <h4 className={`${styles.title_text}`}>บันทึกค่าใช้จ่ายอื่นๆ</h4>
      <h5 className={`${styles.title_text_}`}>{factoryData.name}</h5>
      <div className={`${styles.filter_groups}`}>
        <div className={`${styles.group_}`}>
          <select
            onChange={(e) =>
              setFilterGroup({ ...filterGroup, recordId: e.target.value })
            }
          >
            <option value="">รายการค่าใช้จ่าย</option>
            {allRecordDetails.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className={`${styles.group_}`}>
          <div className={`${styles.input_unit}`}>
            <div className={`${styles.info_box}`}>
              <p>
                {filterGroup.atDate !== ""
                  ? convertDateThai(filterGroup.atDate)
                  : "วันที่"}
              </p>
              {filterGroup.atDate !== "" && (
                <GrFormClose
                  size={25}
                  cursor={"pointer"}
                  onClick={() => {
                    setFilterGroup({ ...filterGroup, atDate: "" });
                    setSelectedDate({ ...selectedDate, atDate: new Date() });
                  }}
                />
              )}
            </div>
            <div
              className={`${styles.unit}`}
              onClick={() => {
                setOpenAtDate(!openAtDate);
              }}
            >
              <img src={icon.calendar} alt="" id="fac_at_date_d" />
            </div>
          </div>
          {openAtDate && (
            <DatePicker
              selected={selectedDate.atDate}
              onChange={onChangeAtDate}
              inline
            />
          )}
        </div>
        <label>ถึง</label>
        <div className={`${styles.group_}`}>
          <div className={`${styles.input_unit}`}>
            <div className={`${styles.info_box}`}>
              <p>
                {filterGroup.toDate !== ""
                  ? convertDateThai(filterGroup.toDate)
                  : "วันที่"}
              </p>
              {filterGroup.toDate !== "" && (
                <GrFormClose
                  size={25}
                  cursor={"pointer"}
                  onClick={() => {
                    setFilterGroup({ ...filterGroup, toDate: "" });
                    setSelectedDate({ ...selectedDate, toDate: new Date() });
                  }}
                />
              )}
            </div>
            <div
              className={`${styles.unit}`}
              onClick={() => {
                setOpenToDate(!openToDate);
              }}
            >
              <img src={icon.calendar} alt="" id="fac_to_date_d" />
            </div>
          </div>
          {openToDate && (
            <DatePicker
              selected={selectedDate.toDate}
              onChange={onChangeToDate}
              inline
            />
          )}
        </div>
      </div>

      <div className={`${styles.table_container}`}>
        <div className={`${styles.table_box} table-responsive`}>
          <table className={`table`}>
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th>วันที่</th>
                <th>รายการค่าใช้จ่าย</th>
                <th>ยอดเงิน (บาท)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {allFactoryRecord.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>{(currentPage - 1) * 10 + (index + 1)}</td>
                    <td>{convertDateThai(item.recordedAt)}</td>
                    <td>{item.recordDetails.name}</td>
                    <td>{item.price?.toLocaleString()}</td>
                    <td>
                      <i
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                        onClick={() => {
                          getFactoryRecord(item.id);
                          getTransaction(item.id);
                        }}
                      >
                        <FaEye className={`${styles.fa_eye}`} />
                      </i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className={`${styles.pagination_}`}>
          <Pagination
            pageSize={pageSize}
            totalCount={totalCount}
            currentPage={currentPage}
            onPageChange={(page) => onPaginate(page)}
          />
        </div>
      </div>

      <div className={`${styles.button_right}`}>
        <ExportPopup
          label={"document"}
          trigger={_triggerExport}
          event={{ csv: onExportCsv, xlsx: onExportXlsx }}
          position={{ right: "10%" }}
        />
        <button
          type="button"
          className={`${styles.btn_}`}
          onClick={() => setTriggerExport(!_triggerExport)}
        >
          Export
        </button>
        <button
          type="button"
          className={`${styles.btn_}`}
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop2"
          onClick={() => {
            setDataAdd(initDataAdd);
            setValidErr(false);
            onSwitch(false);
          }}
        >
          เพิ่ม
        </button>
      </div>

      {/* Insert expenses : START */}
      <AddForm
        _dataAdd={_dataAdd}
        _setDataAdd={setDataAdd}
        _initDataAdd={initDataAdd}
        _switch={_switch}
        _onSwitch={onSwitch}
        _validErr={validErr}
        _setValidErr={setValidErr}
        _allRecordDetails={allRecordDetails}
        _fetchData={getAllFactoryRecord}
        _fetchData2={getMultipleApi}
        _allEnterprise={allEnterprise}
      />
      {/* Insert expenses : END */}

      {/* Update expenses : START */}
      <EditForm
        _dataEdit={_dataEdit}
        _allRecordDetails={allRecordDetails}
        _fetchData={getAllFactoryRecordFilter}
        _fetchData2={getFactoryRecord}
        _fetchData3={getMultipleApi}
        _allEnterprise={allEnterprise}
        _transacData={_transacData}
      />
      {/* Update expenses : END */}
    </div>
  );
};

export default Expenses;
