import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-hot-toast";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import icon from "../../../../../assets/img/Icon";
import IncreaseCultivation from "./increase_cultivation/IncreaseCultivation";
import AddDeed from "./add_deed/AddDeed";
import useSwitch from "../../../../../utils/hooks/useSwitch";
import { QueryGetByID } from "../../../../../helpers/api.helper";
import { defaultApi } from "../../../../../services/api";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../../utils/format/Date.format";
import Message from "../../../../../utils/message/Message";
import {
  CheckFname,
  CheckLname,
  CheckMobile,
  CheckNumber,
  CheckNumberCard,
  CheckValidLength,
} from "../../../../../utils/validation_input/Validation";
import { createUrlImage } from "../../../../../helpers/func.helper";
import styles from "./scss/EditMember.module.scss";

const title = [
  {
    id: 1,
    name: "นาย",
  },
  {
    id: 2,
    name: "นาง",
  },
  {
    id: 3,
    name: "นางสาว",
  },
];

const ownershipStatus = [
  { id: 1, name: "เจ้าของกรรมสิทธิ์" },
  { id: 2, name: "ผู้เช่า" },
  { id: 3, name: "อื่นๆ" },
];

const memberStatus = [
  { id: 1, name: "สมาชิกภายในกลุ่มวิสาหกิจชุมชน" },
  { id: 2, name: "สมาชิกภายนอกกลุ่มวิสาหกิจชุมชน" },
];

const EditMember = ({ _fetchData, farmerID, _onSwitch2 }) => {
  const [_switch, onSwitch, _switch2, onSwitch2, _switch3, onSwitch3] =
    useSwitch();
  const [_data, setData] = useState({});
  const [imgData, setImgData] = useState({ file: "" });
  const [selectedDate, setSelectedDate] = useState({
    sign: new Date(),
    end: new Date(),
  });
  const [validErr, setValidErr] = useState(false);
  const [openDateSign, setOpenDateSign] = useState(false);
  const [openDateEnd, setOpenDateEnd] = useState(false);
  const [_showPass, setShowPass] = useState(false);
  const [_showPass2, setShowPass2] = useState(false);

  /* DatePicker : START */
  const onChangeDateSign = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, signDate: dateFormat });
    setSelectedDate({ ...selectedDate, sign: e });
    setOpenDateSign(!openDateSign);
  };
  const onChangeDateEnd = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, endDate: dateFormat });
    setSelectedDate({ ...selectedDate, end: e });
    setOpenDateEnd(!openDateEnd);
  };
  /* DatePicker : END */

  const getFarmer = useCallback(async () => {
    const qryGetById = {
      $expand: "&%24expand=avatarNavigation%2Centerprise",
      $filter: "",
      $orderby: "",
    };
    const _queryId = QueryGetByID(qryGetById);
    const _id = farmerID;
    try {
      const res = await defaultApi.getFarmer(_id, _queryId);
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [farmerID]);

  const changeImg = async (e) => {
    const file = e.target.files[0];
    const file_val = document.querySelector("#file_img");
    const param1 = "Profile";
    const param2 = new FormData();
    param2.append("FormFile", file);

    if (!_data?.avatar) {
      console.log("Create image");
      try {
        const res = await defaultApi.createFileDetail(param1, param2);
        console.log(res.statusText);
        setData({ ..._data, avatar: res?.data.id });
        setImgData({ file: URL.createObjectURL(file) });
        file_val.value = "";
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Update image");
      try {
        const res = await defaultApi.updateFileDetail(
          _data?.avatar,
          param1,
          param2
        );
        console.log(res.statusText);
        setData({ ..._data, avatar: res?.data.id });
        setImgData({ file: URL.createObjectURL(file) });
        file_val.value = "";
      } catch (error) {
        console.log(error);
      }
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };
  const updateFarmer = useCallback(async () => {
    if (CheckFname(_data?.firstname)) return setValidErr(true);
    if (CheckLname(_data?.lastname)) return setValidErr(true);
    if (CheckNumber(_data?.familyIncome)) return setValidErr(true);
    if (CheckNumberCard(_data?.memberNo)) return setValidErr(true);
    if (CheckValidLength(_data?.owner)) return setValidErr(true);
    if (CheckValidLength(_data?.address)) return setValidErr(true);
    if (CheckNumber(_data?.mobile)) return setValidErr(true);

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "familyIncome") {
        params[key] = +_data[key];
      } else {
        params[key] = _data[key];
      }
    });
    const _id = farmerID;
    try {
      const res = await defaultApi.updateFarmer(_id, params);
      console.log(res.statusText);
      getFarmer();
      onSwitch(false);
      setShowPass2(false);
      toast.success(Message.notify.update_success, { duration: 3000 });
    } catch (error) {
      const err_member_no =
        error?.response?.data.memberNo[0] + "❗️" ||
        Message.notify.update_error;

      console.log(error);
      toast.error(err_member_no, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  }, [farmerID, _data, onSwitch, getFarmer]);

  const onBackAndReset = () => {
    onSwitch(false);
    getFarmer();
    setImgData({ file: "" });
    setSelectedDate({
      sign: new Date(),
      end: new Date(),
    });
  };

  useEffect(() => {
    // Use functions
    getFarmer();
  }, [getFarmer]);

  useEffect(() => {
    /* Function close dropdown 'DateSign and DateEnd' : START */
    const onCloseDropdownDateSign = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_sign_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateSign(false);
      }
    };
    const onCloseDropdownDateEnd = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_end_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateEnd(false);
      }
    };

    if (openDateSign) {
      document.addEventListener("click", onCloseDropdownDateSign);
    }
    if (openDateEnd) {
      document.addEventListener("click", onCloseDropdownDateEnd);
    }
    /* Function close dropdown 'DateSign and DateEnd' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDateSign);
      document.removeEventListener("click", onCloseDropdownDateEnd);
    };
  }, [openDateSign, openDateEnd]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.main_}`}>
        {!_switch2 && !_switch3 && (
          <>
            <h4 className={`${styles.title_text}`}>
              {_data?.firstname} {_data?.lastname}
            </h4>

            <div className={`${styles.content_}`}>
              <div className={`${styles.img_box}`}>
                <div className={`${styles.box_}`}>
                  {!_data?.avatar && !imgData.file && (
                    <img
                      src={icon.user}
                      alt=""
                      className={`${styles.no_img}`}
                    />
                  )}
                  {_data?.avatar && (
                    <img
                      src={
                        imgData.file
                          ? imgData.file
                          : createUrlImage(_data?.avatarNavigation?.filePath)
                      }
                      alt=""
                      className={`${styles.img_}`}
                    />
                  )}
                  <input
                    type="file"
                    name=""
                    id="file_img"
                    accept="image/jpeg, image/png"
                    onChange={changeImg}
                    hidden
                  />
                  <label htmlFor="file_img" id="file-label" hidden></label>
                </div>
                {_switch && !_switch2 && !_switch3 && (
                  <label
                    onClick={() =>
                      document.querySelector("#file-label").click()
                    }
                  >
                    เปลี่ยนรูปภาพ
                  </label>
                )}
              </div>
              <div className={`${styles.button_box}`}>
                <button
                  type="button"
                  className={`button_sm`}
                  onClick={() => onSwitch2(true)}
                >
                  การเพาะปลูก
                </button>
                <button
                  type="button"
                  className={`button_sm`}
                  onClick={() => onSwitch3(true)}
                >
                  โฉนด
                </button>
              </div>
            </div>
          </>
        )}

        {!_switch && !_switch2 && !_switch3 && (
          <>
            <div className={`${styles.input_form}`}>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>คำนำหน้าชื่อ</h5>
                  <div className={`${styles.info_box}`}>
                    <p>{_data?.title}</p>
                  </div>
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>วันที่เข้าร่วมโครงการ</h5>
                  <div className={`${styles.info_box}`}>
                    <p>{convertDateThai(_data?.signDate)}</p>
                  </div>
                </div>
              </div>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>ชื่อ</h5>
                  <div className={`${styles.info_box}`}>
                    <p>{_data?.firstname}</p>
                  </div>
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>วันที่สิ้นสุดโครงการ</h5>
                  <div className={`${styles.info_box}`}>
                    <p>{convertDateThai(_data?.endDate)}</p>
                  </div>
                </div>
              </div>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>นามสกุล</h5>
                  <div className={`${styles.info_box}`}>
                    <p>{_data?.lastname}</p>
                  </div>
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>รายได้ครัวเรือนเฉลี่ยต่อเดือน</h5>
                  <div className={`${styles.input_unit}`}>
                    <div className={`${styles.info_box_}`}>
                      <p>{_data?.familyIncome}</p>
                    </div>
                    <div className={`${styles.unit}`}>
                      <span>บาท</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>หมายเลขบัตรประชาชน</h5>
                  {/* <div className={`${styles.info_box}`}>
                    <p>{_data?.memberNo}</p>
                  </div> */}
                  <div className={`${styles.input_unit}`}>
                    <input
                      id="password"
                      type="password"
                      value={_data?.memberNo || ""}
                      readOnly
                    />
                    <div className={`${styles.unit}`}>
                      {!_showPass ? (
                        <AiFillEye
                          id="showPass"
                          className={`${styles.ai_fill_eye}`}
                          onClick={() => {
                            showPass();
                            setShowPass(true);
                          }}
                        />
                      ) : (
                        <AiFillEyeInvisible
                          id="showPass"
                          className={`${styles.ai_fill_eye}`}
                          onClick={() => {
                            showPass();
                            setShowPass(false);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>ชื่อเจ้าของกรรมสิทธิ์</h5>
                  <div className={`${styles.info_box}`}>
                    <p>{_data?.owner}</p>
                  </div>
                </div>
              </div>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>ที่อยู่</h5>
                  <div className={`${styles.info_box}`}>
                    <p>{_data?.address}</p>
                  </div>
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>สถานะการครอบครอง</h5>
                  <div className={`${styles.info_box}`}>
                    <p>{_data?.ownership}</p>
                  </div>
                </div>
              </div>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>สถานะสมาชิก</h5>
                  <div className={`${styles.info_box}`}>
                    <p>{_data?.status}</p>
                  </div>
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>เบอร์โทรศัพท์</h5>
                  <div className={`${styles.info_box}`}>
                    <p>{_data?.mobile}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={`${styles.button_right}`}>
              <button
                type="button"
                className={`${styles.btn_}`}
                onClick={() => {
                  onSwitch(true);
                  setShowPass(false);
                }}
              >
                แก้ไข
              </button>
              <button
                type="button"
                className={`${styles.btn_}`}
                onClick={() => {
                  _onSwitch2(false);
                  _fetchData();
                }}
              >
                กลับ
              </button>
            </div>
          </>
        )}

        {_switch && !_switch2 && !_switch3 && (
          <>
            <div className={`${styles.input_form}`}>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>คำนำหน้าชื่อ</h5>
                  <select name="title" onChange={onChange}>
                    <option style={{ display: "none" }}>{_data?.title}</option>
                    {title.map((item) => {
                      return (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>วันที่เข้าร่วมโครงการ</h5>
                  <div
                    className={`${styles.input_unit} ${
                      validErr && _data.signDate === "" ? "error" : ""
                    }`}
                  >
                    <div className={`${styles.info_box_}`}>
                      <p>
                        {_data.signDate !== ""
                          ? convertDateThai(_data.signDate)
                          : ""}
                      </p>
                    </div>
                    <div
                      className={`${styles.unit}`}
                      onClick={() => {
                        setOpenDateSign(!openDateSign);
                      }}
                    >
                      <img src={icon.calendar} alt="" id="ent_sign_date_d" />
                    </div>
                  </div>
                  {openDateSign && (
                    <DatePicker
                      selected={selectedDate.sign}
                      onChange={onChangeDateSign}
                      inline
                    />
                  )}
                  {validErr && (
                    <p className={`${styles.err_message}`}>
                      {CheckValidLength(_data.signDate)}
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>
                    ชื่อ <sup className={`${styles.asterisk}`}>*</sup>
                  </h5>
                  <input
                    type="text"
                    name="firstname"
                    value={_data?.firstname}
                    className={`${
                      validErr && _data.firstname === "" ? "error" : ""
                    }`}
                    onChange={onChange}
                  />
                  {validErr && (
                    <p className={`${styles.err_message}`}>
                      {CheckFname(_data?.firstname)}
                    </p>
                  )}
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>วันที่สิ้นสุดโครงการ</h5>
                  <div
                    className={`${styles.input_unit} ${
                      validErr && _data.endDate === "" ? "error" : ""
                    }`}
                  >
                    <div className={`${styles.info_box_}`}>
                      <p>
                        {_data.endDate !== ""
                          ? convertDateThai(_data.endDate)
                          : ""}
                      </p>
                    </div>
                    <div
                      className={`${styles.unit}`}
                      onClick={() => {
                        setOpenDateEnd(!openDateEnd);
                      }}
                    >
                      <img src={icon.calendar} alt="" id="ent_end_date_d" />
                    </div>
                  </div>
                  {openDateEnd && (
                    <DatePicker
                      selected={selectedDate.end}
                      onChange={onChangeDateEnd}
                      inline
                    />
                  )}
                  {validErr && (
                    <p className={`${styles.err_message}`}>
                      {CheckValidLength(_data.endDate)}
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>
                    นามสกุล <sup className={`${styles.asterisk}`}>*</sup>
                  </h5>
                  <input
                    type="text"
                    name="lastname"
                    value={_data?.lastname}
                    className={`${
                      validErr && _data.lastname === "" ? "error" : ""
                    }`}
                    onChange={onChange}
                  />
                  {validErr && (
                    <p className={`${styles.err_message}`}>
                      {CheckLname(_data.lastname)}
                    </p>
                  )}
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>
                    รายได้ครัวเรือนเฉลี่ยต่อเดือน{" "}
                    <sup className={`${styles.asterisk}`}>*</sup>
                  </h5>
                  <div
                    className={`${styles.input_unit} ${
                      validErr && _data.familyIncome === "" ? "error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      name="familyIncome"
                      value={_data?.familyIncome}
                      onChange={onChange}
                    />
                    <div className={`${styles.unit}`}>
                      <span>บาท</span>
                    </div>
                  </div>
                  {validErr && (
                    <p className={`${styles.err_message}`}>
                      {CheckNumber(_data.familyIncome)}
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>
                    หมายเลขบัตรประชาชน{" "}
                    <sup className={`${styles.asterisk}`}>*</sup>
                  </h5>
                  <div className={`${styles.input_unit}`}>
                    <input
                      id="password2"
                      type="password"
                      name="memberNo"
                      value={_data?.memberNo}
                      className={`${
                        validErr && _data.memberNo === "" ? "error" : ""
                      }`}
                      maxLength={13}
                      onChange={onChange}
                    />
                    <div className={`${styles.unit}`}>
                      {!_showPass2 ? (
                        <AiFillEye
                          id="showPass"
                          className={`${styles.ai_fill_eye}`}
                          onClick={() => {
                            showPass2();
                            setShowPass2(true);
                          }}
                        />
                      ) : (
                        <AiFillEyeInvisible
                          id="showPass"
                          className={`${styles.ai_fill_eye}`}
                          onClick={() => {
                            showPass2();
                            setShowPass2(false);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {validErr && (
                    <p className={`${styles.err_message}`}>
                      {CheckNumberCard(_data.memberNo)}
                    </p>
                  )}
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>
                    ชื่อเจ้าของกรรมสิทธิ์{" "}
                    <sup className={`${styles.asterisk}`}>*</sup>
                  </h5>
                  <input
                    type="text"
                    name="owner"
                    value={_data?.owner}
                    className={`${
                      validErr && _data.owner === "" ? "error" : ""
                    }`}
                    onChange={onChange}
                  />
                  {validErr && (
                    <p className={`${styles.err_message}`}>
                      {CheckValidLength(_data.owner)}
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>
                    ที่อยู่ <sup className={`${styles.asterisk}`}>*</sup>
                  </h5>
                  <input
                    type="text"
                    name="address"
                    value={_data?.address}
                    className={`${
                      validErr && _data.address === "" ? "error" : ""
                    }`}
                    onChange={onChange}
                    placeholder="ตัวอย่างที่อยู่ 7/11 ต.ภูผา อ.ภูผา จ.ขอนแก่น"
                  />
                  {validErr && (
                    <p className={`${styles.err_message}`}>
                      {CheckValidLength(_data.address)}
                    </p>
                  )}
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>สถานะการครอบครอง</h5>
                  <select name="ownership" onChange={onChange}>
                    <option style={{ display: "none" }}>
                      {_data?.ownership}
                    </option>
                    {ownershipStatus.map((item) => {
                      return (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className={`${styles.input_flex}`}>
                <div className={`${styles.input_1}`}>
                  <h5>สถานะสมาชิก</h5>
                  <select name="status" onChange={onChange}>
                    <option style={{ display: "none" }}>{_data?.status}</option>
                    {memberStatus.map((item) => {
                      return (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className={`${styles.input_2}`}>
                  <h5>
                    เบอร์โทรศัพท์ <sup className={`${styles.asterisk}`}>*</sup>
                  </h5>
                  <input
                    type="text"
                    name="mobile"
                    value={_data?.mobile || ""}
                    className={`${
                      validErr && _data.mobile === "" ? "error" : ""
                    }`}
                    maxLength={10}
                    onChange={onChange}
                  />
                  {validErr && (
                    <p className={`${styles.err_message}`}>
                      {CheckMobile(_data?.mobile)}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className={`${styles.button_right}`}>
              <button
                type="button"
                className={`${styles.btn_}`}
                onClick={updateFarmer}
              >
                บันทึก
              </button>
              <button
                type="button"
                className={`${styles.btn_}`}
                onClick={onBackAndReset}
              >
                ยกเลิก
              </button>
            </div>
          </>
        )}

        {_switch2 && (
          <IncreaseCultivation farmerData={_data} _onSwitch2={onSwitch2} />
        )}

        {_switch3 && <AddDeed farmerData={_data} _onSwitch3={onSwitch3} />}
      </div>
    </div>
  );
};

function showPass() {
  const password = document.querySelector("#password");

  const type =
    password.getAttribute("type") === "password" ? "text" : "password";
  password.setAttribute("type", type);
}

function showPass2() {
  const password = document.querySelector("#password2");

  const type =
    password.getAttribute("type") === "password" ? "text" : "password";
  password.setAttribute("type", type);
}

export default EditMember;
