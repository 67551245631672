import $ from "jquery";
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { QueryGetByID } from '../../../helpers/api.helper';
import { defaultApi } from '../../../services/api';
import Message from '../../../utils/message/Message';
import { AdminCheckEmail, AdminCheckTel, AdminFirstnameCheck, AdminLastnameCheck } from '../../../utils/validation_input/Validation';
import { } from '../ManageUserData';
import styles from '../scss/Popupform.module.scss';

/**
 * 
 * {
    "id": 1,
    "title": "mr",
    "firstname": "admin1",
    "lastname": "admin1",
    "username": "admin1",
    "email": "admin1@example.com",
    "mobile": "0921234567",
    "lastLogin": "2023-03-17T15:53:42.467",
    "status": "active",
    "createdAt": "2023-03-09T10:47:44.77",
    "createdBy": null,
    "updatedAt": "2023-03-15T17:31:21.05",
    "updatedBy": 1,
    "userGroupId": 1,
    "userOrgId": 1,
    "userLocalGroupId": 3,
    "avatar": 2,
    "avatarNavigation": {
      "id": 2,
      "name": "dog",
      "filetype": "jpg",
      "path": "https://hips.hearstapps.com/hmg-prod/images/dog-puppy-on-garden-royalty-free-image-1586966191.jpg?crop=0.752xw:1.00xh;0.175xw,0&resize=1200:*",
      "filename": "dog",
      "description": "dog",
      "createdAt": null,
      "createdBy": null
    },
    "userGroup": {
      "id": 1,
      "name": "เจ้าหน้าที่โรงไฟฟ้าชุมชน",
      "functionsid": "1"
    },
    "userLocalGroup": {
      "id": 3,
      "name": "สำนักงานจัดการบุคคล",
      "createdAt": "2023-03-09T11:17:33.633",
      "createdBy": null,
      "updatedAt": "2023-03-15T14:25:52.707",
      "updatedBy": 4,
      "permission": "[{\"name\":\"รายได้กลุ่มเกษตรกร\"}]",
      "orgId": 5,
      "org": null
    },
    "userOrg": {
      "id": 1,
      "name": "กระทรวงพลังงาน"
    }
  }
 */

const title = [
  {
    id: 1,
    name: "นาย",
  },
  {
    id: 2,
    name: "นาง",
  },
  {
    id: 3,
    name: "นางสาว",
  },
];

// const status = [
//   {
//     id: 1,
//     name: "active",
//   },
//   {
//     id: 2,
//     name: "disable",
//   }
//   // {
//   //   id: 3,
//   //   name: "del",
//   // },
// ];

const defState = {
  title: "",
  fname: "",
  lname: "",
  email: "",
  mobile: "",
  group: "",
  organize: "",
  localgroup: "",
  status: ""
}

const defStatus = {
  fname: false,
  lname: false,
  email: false,
  mobile: false
}

export const FormManageUserData = ({ userid, idForm, infoProfile, amountForm }) => {

  const [_setUserProfile, setUserProfile] = useState([])
  const [_setOrgsProfile, setOrgsProfile] = useState([])
  const [_setGrpsProfile, setGrpsProfile] = useState([])
  const [_setLcosProfile, setLcosProfile] = useState([])

  const [_state, setState] = useState(defState)
  const [_status, setStatus] = useState(defStatus)
  const [status, setStatusX] = useState([])

  const [_resetForm, resetForm] = useState(false)

  //const [_fstLoad, fstLoad] = useState(true)

  // const [_statusDisableSelect, setstatusDisableSelect] = useState(null)
  // const [_statusDisableSelectLocalGroup, setstatusDisableSelectLocalGroup] = useState(null)
  const [_statusDisableSelect, setstatusDisableSelect] = useState(true)
  const [_statusDisableSelectLocalGroup, setstatusDisableSelectLocalGroup] = useState(true)
  const [_stateLstGrpUser, setstateLstGrpUser] = useState([])

  const checkValid = (e) => {

    let nameInput = null
    let valsInput = null

    nameInput = e.target.name
    valsInput = e.target.value

    let orgId;

    switch (nameInput) {
      case "title":
        setState({ ..._state, title: valsInput })
        break;
      case "firstname":
        setState({ ..._state, fname: valsInput })
        if (AdminFirstnameCheck(valsInput) === null) { setStatus({ ..._status, fname: false }) } else { setStatus({ ..._status, fname: true }) }
        break;
      case "lastname":
        setState({ ..._state, lname: valsInput })
        if (AdminLastnameCheck(valsInput) === null) { setStatus({ ..._status, lname: false }) } else { setStatus({ ..._status, lname: true }) }
        break;
      case "email":
        setState({ ..._state, email: valsInput })
        if (AdminCheckEmail(valsInput) === null) { setStatus({ ..._status, email: false }) } else { setStatus({ ..._status, email: true }) }
        break;
      case "mobile":
        setState({ ..._state, mobile: valsInput })
        if (AdminCheckTel(valsInput) === null) { setStatus({ ..._status, mobile: false }) } else { setStatus({ ..._status, mobile: true }) }
        break;
      case "usergroupid":
        if (Number(valsInput) === 3) {
          setstatusDisableSelect(false);
          setstatusDisableSelectLocalGroup(true);
        } else {
          setstatusDisableSelect(true);
          setstatusDisableSelectLocalGroup(true);
        }
        setState({ ..._state, group: valsInput })
        break;
      case "userorgid":
        if (!valsInput) {
          setstatusDisableSelectLocalGroup(true)
        }
        // setState({ ..._state, organize: valsInput })
        setState({ ..._state, organize: valsInput })
        setListGroupByOrg(valsInput)
        orgId = valsInput
        break;
      case "userlocalgroupid":
        setState({ ..._state, localgroup: valsInput })
        setListGroupByOrg(orgId)
        //setstateLstGrpUser()
        break;
      case "status":
        setState({ ..._state, status: valsInput })
        break;
      default:
        break;
    }
  }

  const setListGroupByOrg = useCallback((valsInput) => {
    //setstateLstGrpUser([])
    if (valsInput) {
      let iSet = []
      _setOrgsProfile.map((data01) => {
        if (Number(valsInput) === Number(data01.id)) {
          _setLcosProfile.map((data02) => {
            if (Number(data01.id) === Number(data02.orgId)) {
              iSet.push({ id: data02.id, name: data02.name })
            }
            return null
          })
        }
        return null
      })
      setstateLstGrpUser(iSet)
    }

  }, [_setLcosProfile, _setOrgsProfile])

  const submitUpdateUSer = async (idx, subGrp) => {
    const params = {}

    const checkValue = (k, v) => {
      if (v === undefined || v === null || v === "") {
      } else {
        params[k] = v
      }
    }

    Object.keys(_setUserProfile).forEach((key) => {
      switch (key) {
        case "title":
          checkValue(key, _state.title === "" ? _setUserProfile[key] : _state.title)
          break;
        case "firstname":
          checkValue(key, _state.fname === "" ? _setUserProfile[key] : _state.fname)
          break;
        case "lastname":
          checkValue(key, _state.lname === "" ? _setUserProfile[key] : _state.lname)
          break;
        case "email":
          checkValue(key, _state.email === "" ? _setUserProfile[key] : _state.email)
          break;
        case "mobile":
          checkValue(key, _state.mobile === "" ? _setUserProfile[key] : _state.mobile)
          break;
        case "status":
          checkValue(key, _state.status === "" ? _setUserProfile[key] : _state.status)
          break;
        case "userGroupId":
          checkValue(key, _state.group === "" ? Number(_setUserProfile[key]) : Number(_state.group))
          break;
        case "userLocalGroupId":
          if (Number(_state.group) === 3 && _setUserProfile.userGroupId !== 3) {
            checkValue(key, _state.localgroup === "" ? Number(_setUserProfile[key]) : Number(_state.localgroup))
          } else if (_state.group === '' && _setUserProfile.userGroupId === 3) {
            checkValue(key, _state.localgroup === "" ? Number(_setUserProfile[key]) : Number(_state.localgroup))
          } else {
            params[key] = null
          }
          break;
        case "userOrgId":
          if (Number(_state.group) === 3 && _setUserProfile.userGroupId !== 3) {
            checkValue(key, _state.organize === "" ? Number(_setUserProfile[key]) : Number(_state.organize))
          } else if (_state.group === '' && _setUserProfile.userGroupId === 3) {
            checkValue(key, _state.organize === "" ? Number(_setUserProfile[key]) : Number(_state.organize))
          } else {
            params[key] = null
          }
          break;
        default:
          params[key] = _setUserProfile[key]
          break;
      }
    })

    const loading = toast.loading(Message.notify.update_loading);
    //_setUserProfile.id

    try {
      if (Number(params['userGroupId']) === 3) {
        if (!params['userOrgId']) {
          toast.error('ต้องเลือกข้อมูลหน่วยงานก่อน');
          return
        }
        if (!params['userLocalGroupId']) {
          toast.error('ต้องกรอกข้อมูลกลุ่มผู้ใช้งานย่อยก่อน');
          return
        }

        if (params['userLocalGroupId']) {
          let iCheck = []
          for (let index = 0; index < subGrp.length; index++) {
            const element = subGrp[index]?.id;
            if (Number(element) === Number(params['userLocalGroupId'])) {
              iCheck[index] = element
            }
          }

          if (iCheck.length <= 0) {
            toast.error('ต้องกรอกข้อมูลกลุ่มผู้ใช้งานย่อยก่อน');
            return
          }

        }

      }
      await defaultApi.updateUser(_setUserProfile.id, params)
      //await defaultApi.updateUser(37, paramsx)
      toast.success(Message.notify.update_success, { duration: 3000 });
      infoProfile()
      infoAllData(userid)
      setState({ ..._state, group: "" })

      $("#" + idx).trigger('click')

      //document.getElementById(idx).style.modal = 'hidden'
    } catch (error) {
      console.log(error)
      toast.error(Message.notify.update_error, { duration: 3000 })
    } finally {
      toast.dismiss(loading);
    }

  }

  const infoAllData = async (id) => {

    let qryGetById = {
      $expand: "%24expand=userGroup%2CuserLocalGroup%2CuserOrg",
      $filter: "",
      $orderby: "",
    };

    const _query = QueryGetByID(qryGetById)

    try {
      if (id === "") {
        return
      } else {
        const res1 = await defaultApi.getUserId(id, _query);
        const res2 = await defaultApi.getUserGroup();
        const res3 = await defaultApi.getOrg();
        const res4 = await defaultApi.getLocalGrp();
        const res5 = await defaultApi.getGbStatus()
        setUserProfile(res1.data)
        setGrpsProfile(res2.data)
        setOrgsProfile(res3.data)
        setLcosProfile(res4.data)
        console.log("res5.data",res5.data);
        let iArr = []
        let i = 0
        for (let index = 0; index < res5?.data?.length; index++) {
          const element = res5?.data[index];
          if (element?.id === 'active' || element?.id === 'disable') {
            iArr[i] = element
            i++
          }
        }
        setStatusX(iArr)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const clearValid = () => {
    resetForm(true)
  }

  const loopOrg = (lst, status) => {
    let i = null
    if (status) {
      i = (<option key={'none'} value={``}>เลือกหน่วยงาน</option>)
    } else if (lst.length > 0) {
      i = lst.map((data) => {
        return (<option key={data.id} value={data.id}>{data.name}</option>)
      })
    } else {
      i = (<option key={'nonfound'} value={``}>ไม่พบหน่วยงาน</option>)
    }
    return i
  }

  const loopSubGrp = (lst, status) => {
    let i = null
    if (status) {
      i = (<option key={'none'} value={``}>เลือกกลุ่มผู้ใช้งานย่อย</option>)
    } else if (lst.length > 0) {
      i = lst.map((data) => {
        return (<option key={data.id} value={data.id}>{data.name}</option>)
      })
    } else {
      i = (<option key={'nonfound'} value={``}>ไม่พบกลุ่มผู้ใช้งานย่อย</option>)
    }
    return i
  }

  useEffect(() => {
    if (_resetForm === true) {
      setstatusDisableSelect(true)
      setstatusDisableSelectLocalGroup(true)

      setState({
        title: "",
        fname: "",
        lname: "",
        email: "",
        mobile: "",
        group: "",
        organize: "",
        localgroup: "",
        status: ""
      })
      setStatus({
        fname: false,
        lname: false,
        email: false,
        mobile: false
      })
      infoProfile()
      resetForm(false)
    }

    infoAllData(userid);

  }, [userid, _state.group, _setUserProfile.userGroupId, _setUserProfile.userOrgId, _resetForm, infoProfile])

  useEffect(() => {
    if (_state.group === '' && Number(_setUserProfile.userGroupId) !== 3) {
      setstatusDisableSelect(true)
      setstatusDisableSelectLocalGroup(true)
    } else {
      if (
        (_state.group === '' && Number(_setUserProfile.userGroupId) === 3) ||
        (Number(_state.group) === 3 && Number(_setUserProfile.userGroupId) === 3) ||
        (Number(_state.group) === 3 && Number(_setUserProfile.userGroupId) !== 3)
      ) {
        setstatusDisableSelect(false)
        setListGroupByOrg(_setUserProfile.userOrgId)
        if (Number.isInteger(_state.organize) || Number.isInteger(_setUserProfile.userOrgId)) {
          setstatusDisableSelectLocalGroup(false)
          setListGroupByOrg(_state?.organize || _setUserProfile.userOrgId)
        }
      } else {
        setstatusDisableSelect(true)
        setstatusDisableSelectLocalGroup(true)
      }
    }

  }, [_state, _setUserProfile.userGroupId, _resetForm, _setUserProfile.userOrgId, setListGroupByOrg])

  useEffect(() => {
    setListGroupByOrg(_state.organize)
    setstatusDisableSelectLocalGroup(false)
  }, [_state.organize])

  let outLet = []
  for (let index = 0; index < amountForm; index++) {
    let idx = "exampleModal" + index

    let userNameId = "usernamex" + index
    let titleNameId = "titlenamex" + index
    let fnameId = "fnamex" + index
    let lnameId = "lnamex" + index
    let emailId = "emailx" + index
    let telId = "telx" + index
    let userGrpId = "usergrpx" + index
    let agencyId = "agencyx" + index
    let subAgencyId = "subagencyx" + index
    let statusId = "statusx" + index
    let closeBtn = 'clsBtnx' + index

    outLet[index] = (
      <>
        <div key={index}
          className="modal fade"
          //id="exampleModal"
          id={idx}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"

        //className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <h1 className={`modal-title mt-3 fs-5 ${styles.h5} `} id="exampleModalLabel">แก้ไขข้อมูลผู้ใช้</h1>
                <hr className={`w-100 me-1 ms-1 border border-dark-subtle`} />
              </div>
              <div className="modal-body ">
                <form className={`${styles.form_} mx-auto`}>
                  <div className={`${styles.input_flex} `}>
                    <div className={` d-flex flex-row justify-content-between m-2 ${styles.input_} `}>
                      <h5 className={`col-4 ${styles.h5} `}>ชื่อผู้ใช้งาน : </h5>
                      <input className={`col`}
                        id={userNameId}
                        type="text"
                        name="username"
                        readOnly={true}
                        defaultValue={_setUserProfile.username}
                      />
                    </div>
                  </div>
                  {/* Username */}
                  <div className={`${styles.input_flex} `}>
                    <div className={`d-flex flex-row justify-content-between m-2 ${styles.input_} `}>
                      <h5 className={`col-4 ${styles.h5} `}>คำนำหน้าชื่อ : </h5>
                      <select className={`col`}
                        name="title"
                        id={titleNameId}
                        //value={_stateTitlename === "" ? _setUserProfile.title : _stateTitlename}
                        value={_state.title === "" ? _setUserProfile.title : _state.title}
                        //onChange={(e) => { setstateTitlename(e.target.value); setState({..._state, title: e.target.value }) }}
                        onChange={(e) => { checkValid(e) }}
                      >
                        {
                          title.map((data) => {
                            return <option key={data.id} value={data.name}>{data.name}</option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                  {/* Titlename */}
                  <div className={`${styles.input_flex} `}>
                    <div className={`${styles.box_error} d-flex flex-row justify-content-between m-2 ${styles.input_1} `}>
                      <h5 className={`col-4 ${styles.h5} `}>ชื่อ : </h5>
                      {/* ${_statusFirstname && _stateFirstname === "" ? styles.error : null} */}
                      <input className={`col ${_status.fname && _state.fname === "" ? styles.error : null}`}
                        id={fnameId}
                        type="text"
                        name="firstname"
                        defaultValue={_setUserProfile.firstname}
                        //onChange={(e) => { checkfirstname(e); }}
                        onChange={(e) => { checkValid(e) }}
                      />
                    </div>
                    {_status.fname && (
                      <div className={`${styles.box_error} w-100 d-flex justify-content-center`}>
                        <small className={`${styles.err_message} `}>
                          {AdminFirstnameCheck(_state.fname)}
                        </small>
                      </div>
                    )}
                  </div>
                  {/* Firstname */}
                  <div className={`${styles.input_flex} `}>
                    <div className={`${styles.box_error} d-flex flex-row justify-content-between m-2 ${styles.input_1} `}>
                      <h5 className={`col-4 ${styles.h5} `}>นามสกุล : </h5>
                      <input className={`col ${_status.lname && _state.lname === "" ? styles.error : null} `}
                        id={lnameId}
                        type="text"
                        name="lastname"
                        //onChange={(e) => { checklastname(e); checkValid(onchange) }}
                        defaultValue={_setUserProfile.lastname}
                        onChange={(e) => { checkValid(e) }}
                      />
                    </div>
                    {_status.lname && (
                      <div className={`${styles.box_error} w-100 d-flex justify-content-center`}>
                        <small className={`${styles.err_message} `}>
                          {AdminLastnameCheck(_state.lname)}
                        </small>
                      </div>
                    )}
                  </div>
                  {/* Lastname */}
                  <div className={`${styles.input_flex} `}>
                    <div className={`${styles.box_error} d-flex flex-row justify-content-between m-2 ${styles.input_1} `}>
                      <h5 className={`col-4 ${styles.h5} `}>อีเมล : </h5>
                      <input className={`col ${_status.email && _state.email === "" ? styles.error : null} `}
                        id={emailId}
                        type="text"
                        name="email"
                        //onChange={(e) => { checkemail(e); checkValid(onchange) }}
                        onChange={(e) => { checkValid(e) }}
                        defaultValue={_setUserProfile.email}
                      />
                    </div>
                    {_status.email && (
                      <div className={`${styles.box_error} w-100 d-flex justify-content-center`}>
                        <small className={`${styles.err_message} `}>
                          {AdminCheckEmail(_state.email)}
                        </small>
                      </div>
                    )}
                  </div>
                  {/* Email */}
                  <div className={`${styles.input_flex} `}>
                    <div className={`${styles.box_error} d-flex flex-row justify-content-between m-2 ${styles.input_1} `}>
                      <h5 className={`col-4 ${styles.h5} `}>เบอร์โทรศัพท์ : </h5>
                      <input className={`col ${_status.mobile && _state.email === "" ? styles.error : null} `}
                        id={telId}
                        type="text"
                        name="mobile"
                        //onChange={(e) => { checkmobile(e); checkValid(onchange) }}
                        onChange={(e) => { checkValid(e) }}
                        defaultValue={_setUserProfile.mobile}
                      />
                    </div>
                    {_status.mobile && (
                      <div className={`${styles.box_error} w-100 d-flex justify-content-center`}>
                        <small className={`${styles.err_message} `}>
                          {AdminCheckTel(_state.email)}
                        </small>
                      </div>
                    )}
                  </div>
                  {/* Tel */}
                  <div className={`${styles.input_flex} `}>
                    <div className={`d-flex flex-row justify-content-between m-2 ${styles.input_} `}>
                      <h5 className={`col-4 ${styles.h5} `}>กลุ่มผู้ใช้งาน : </h5>
                      <select id={userGrpId} className={`col`}
                        name="usergroupid"
                        value={_state.group === "" ? _setUserProfile?.userGroupId : _state.group}
                        //onChange={(e) => { evSetGrpUser(e.target.value); checkValid(onchange) }}
                        onChange={(e) => { checkValid(e) }}
                      >
                        <>
                          {
                            _setGrpsProfile.map((data) => {
                              return <option key={data.id} value={data.id}>{data.name}</option>
                            })

                          }
                        </>
                      </select>
                    </div>
                  </div>
                  {/* UserGrp */}
                  <div className={`${styles.input_flex} `}>
                    <div className={`d-flex flex-row justify-content-between m-2 ${styles.input_} `}>
                      <h5 className={`col-4 ${styles.h5} `}>หน่วยงาน : </h5>
                      <select id={agencyId} className={`col`}
                        name="userorgid"
                        //value={_state.organize === "" ? _setUserProfile.userOrgId : _state.organize}
                        value={_state.organize === "" ? _setUserProfile?.userOrgId : _state.organize}
                        disabled={_statusDisableSelect}

                        onChange={(e) => {

                          checkValid(e);
                        }}
                      >
                        <>
                          {!_statusDisableSelect && (<option key="none" value="" style={{ display: 'none' }}>เลือกหน่วยงาน</option>)}
                          {
                            loopOrg(_setOrgsProfile, _statusDisableSelect)
                          }
                        </>
                      </select>
                    </div>
                  </div>
                  {/* UserOrg */}
                  <div className={`${styles.input_flex} `}>
                    <div className={`d-flex flex-row justify-content-between m-2 ${styles.input_} `}>
                      <h5 className={`col-4 ${styles.h5} `}>กลุ่มผู้ใช้งานย่อย : </h5>
                      <select id={subAgencyId} className={`col`}
                        name="userlocalgroupid"
                        //value={_state.localgroup === "" ? _setUserProfile.userLocalGroupId : _state.localgroup}
                        value={_state.localgroup === "" ? _setUserProfile?.userLocalGroupId : _state.localgroup}
                        disabled={_statusDisableSelectLocalGroup}
                        //onChange={(e) => { evSetLcoUser(e.target.value); checkValid(onchange) }}
                        onChange={(e) => { checkValid(e) }}
                      >
                        {!_statusDisableSelectLocalGroup && (<option key="none" value="" style={{ display: 'none' }}>เลือกกลุ่มผู้ใช้งานย่อย</option>)}
                        {
                          loopSubGrp(_stateLstGrpUser, _statusDisableSelectLocalGroup)
                        }
                      </select>
                    </div>
                  </div>
                  {/* UserLco */}
                  <div className={`${styles.input_flex} `}>
                    <div className={`d-flex flex-row justify-content-between m-2 ${styles.input_} `}>
                      <h5 className={`col-4 ${styles.h5} `}>สถานะ : </h5>
                      <select id={statusId} className={`col`}
                        name="status"
                        value={_state.status === "" ? _setUserProfile.status : _state.status}
                        //onChange={(e) => { setStatusUser(e.target.value); checkValid(onchange) }}
                        onChange={(e) => { checkValid(e) }}
                      >
                        {/* style={{ "display": "none" }} */}
                        <option key="none" value="">เลือกสถานะ</option>
                        {
                          status.map((data, index) => {
                            return <option key={`status_user${index}`} value={data.id}>{data.statusName}</option>
                          })
                        }
                      </select>
                    </div>
                  </div>
                  {/* Status */}
                </form>
              </div>
              <div className={`d-flex justify-content-center mb-3 ${styles.boxpopup} `}>
                <button className={`${styles.savechangebtn} `} onClick={() => { submitUpdateUSer(closeBtn, _stateLstGrpUser) }}><h4>บันทึก</h4></button><h4>|</h4><button id={closeBtn} className={`${styles.savechangebtn} `} data-bs-dismiss="modal" onClick={() => { clearValid() }}><h4>ยกเลิก</h4></button>
              </div>
            </div>
          </div >
        </div >
      </>
    )
  }

  // return outLet.map((data, index) => {
  //   let i
  //   if (idForm === index) {
  //     i = data
  //   }
  //   return i
  // })
  let irenderForm
  outLet.forEach((data, index) => {
    if (idForm === index) {
      irenderForm = data
    }
  })

  return irenderForm

}