import React, { useState, useEffect } from "react";
import HighCharts from "../../../components/Highcharts/HighCharts";
import styles from "../scss/Index.module.scss";
import { formatterYaxis, formatterXaxis_1, formatterStack, formatterTooltip, formatterTooltip_kwh } from "../../../helpers/suffixchart.helper.js"

const ChartsPowerGene = ({ valueInput, selYear }) => {

  const [stateChartOptions, setStateChartOptions] = useState({
    chart: {
      // type: "line",
      type: "column",
      height: 190,
    },
    title: {
      text: "",
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    yAxis: {
      stackLabels: {
        enabled: true,
        formatter: formatterStack,
      },
      title: {
        text: "kWh",
      },
      labels: {
        formatter: formatterYaxis,
        style: {
          fontSize: "13px",
        },
      },
    },
    tooltip: {
      enabled: true,
      ...formatterTooltip_kwh
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: formatterXaxis_1
        }
      },
      line: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            const item = this.point.y || 0
            return Number(item).toLocaleString(undefined, { maximumFractionDigits: 2 })
          }
        },
        enableMouseTracking: false,
      },
    },
    colors: ["#ff0000", "#5076ff"],
    series: [],
  });

  useEffect(() => {
    let x01 = [];
    let x02 = [];
    try {
      for (let index = 0; index < valueInput.factoryPowergen.length; index++) {
        const element = valueInput.factoryPowergen[index];
        x01[index] = Number(element.powerGenerateBio);
        x02[index] = Number(element.powerGenerateGas);
      }

      // for (let index = 0; index < valueInput.factoryTypeBio.length; index++) {
      //   if (valueInput.factoryTypeBio[index].value === null) {
      //     x01[index] = 0
      //   } else {
      //     x01[index] = Number(valueInput?.factoryTypeBio[index]?.value || 0).toFixed(2)
      //     x01[index] = Number(x01[index])
      //   }
      // }

      // for (let index = 0; index < valueInput.factoryTypeGas.length; index++) {
      //   if (valueInput.factoryTypeGas[index].value === null) {
      //     x02[index] = 0
      //   } else {
      //     x02[index] = Number(valueInput?.factoryTypeGas[index]?.value || 0).toFixed(2)
      //     x02[index] = Number(x02[index])
      //   }
      // }
    } catch (error) { }
    const handleResize = () => {
      const newHeight = document.querySelector("aside")?.clientHeight / 2 - 100;

      setStateChartOptions((prevOptions) => ({
        ...prevOptions,
        chart: {
          ...prevOptions.chart,
          height: newHeight,
        },
        series: [
          {
            name: "ก๊าซชีวภาพ",
            data: x02,
          },
          {
            name: "ชีวมวล",
            data: x01,
          },
        ],
      }));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [valueInput]);

  return (
    <div className={`${styles.chart_power_gene} mx-auto`}>
      <h6
        className="d-flex justify-content-center text-center"
        style={{ fontSize: "14px" }}
      >
        {/* การเปรียบเทียบกำลังการผลิตไฟฟ้าของโรงไฟฟ้าชีวมวลเเละโรงไฟฟ้าก๊าซชีวภาพประจำปี {(Number(selYear) + 543)} */}
        {/* กำลังการผลิตไฟฟ้าของโรงไฟฟ้าชีวมวลเเละโรงไฟฟ้าก๊าซชีวภาพประจำปี{" "} */}
        ปริมาณการผลิตไฟฟ้าของโรงไฟฟ้าชีวมวลเเละโรงไฟฟ้าก๊าซชีวภาพประจำปี{" "}
        {Number(selYear) + 543}
      </h6>
      <HighCharts options={stateChartOptions} />
    </div>
  );
};

export default ChartsPowerGene;
