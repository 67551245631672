import React from "react";
import { BiDownload } from "react-icons/bi";
import styles from "./ExportPopup.module.scss";

const ExportPopup = ({ label, trigger, event, position }) => {
  let iOutLet = null;

  switch (label) {
    case "document":
      iOutLet = (
        <>
          <div
            className={`${styles.container} ${trigger && "active"}`}
            style={position}
          >
            <div
              className={`${styles.row}`}
              onClick={() => {
                event?.csv();
              }}
            >
              <div>
                <BiDownload />
              </div>
              <div>Export as CSV</div>
            </div>
            <div
              className={`${styles.row}`}
              onClick={() => {
                event?.xlsx();
              }}
            >
              <div>
                <BiDownload />
              </div>
              <div>Export as Excel</div>
            </div>
          </div>
        </>
      );
      break;
    case "report":
      iOutLet = (
        <>
          <div className={`${styles.container} ${trigger && "active"}`}>
            <div
              className={`${styles.row}`}
              onClick={() => {
                event?.image();
              }}
            >
              <div>
                <BiDownload />
              </div>
              <div>Export as Image</div>
            </div>
            <div
              className={`${styles.row}`}
              onClick={() => {
                event?.csv();
              }}
            >
              <div>
                <BiDownload />
              </div>
              <div>Export as CSV</div>
            </div>
          </div>
        </>
      );
      break;
    default:
      break;
  }
  return iOutLet;
};

export default ExportPopup;
