import $ from 'jquery'
import React, { useCallback, useEffect, useState } from 'react'
import { FaPen, FaTrash } from 'react-icons/fa'
import { defaultApi } from '../../services/api'
import { FormManageCordData1 } from './form/FormManageCordData1'
import { FormManageCordData2 } from './form/FormManageCordData2'
import style from "./scss/Headtable.module.scss"
import toast from 'react-hot-toast'
import Message from '../../utils/message/Message'


export const ManageCordData = ({ toggerCheck }) => {

    const [user, setUser] = useState([])
    const [farmer, setFarmer] = useState([])
    const [factory, setFactory] = useState([])

    const [_dataGrp, setDataGroup] = useState(0)

    const [_setDataProp1, setDataProp1] = useState([])
    const [_setDataProp2, setDataProp2] = useState([])

    const [_keyFac, setKeyFac] = useState({
        id: '', name: ''
    })
    const [_keyEnt, setKeyEnt] = useState({
        id: '', name: ''
    })

    const [_resCorInFac1, setResCorInFac1] = useState([])
    const [_resCorInEnt1, setResCorInEnt1] = useState([])

    const [_resCorInFac2, setResCorInFac2] = useState([])
    const [_resCorInEnt2, setResCorInEnt2] = useState([])


    const [_stateTag, setStateTag] = useState(true)

    function setDefaultState() {
        setStateTag(true)
        setDataGroup(0)
        setKeyFac({})
        setKeyEnt({})
        setResCorInFac1([])
        setResCorInEnt1([])
        setResCorInFac2([])
        setResCorInEnt2([])
        setDataProp1([])
        setDataProp2([])
    }

    async function delUser(label, data) {

        if (!window.confirm('ต้องการลบเมนูหรือไม่')) { return }

        const loading = toast.loading(Message.notify.update_loading);
        try {

            switch (label) {
                case 1:
                    //fac
                    await defaultApi.deleteFactoryMember(+data?.id)
                    toast.success(Message.notify.update_success, { duration: 3000 });
                    searchByFactory(+data?.factoryId)
                    break;
                case 2:
                    //ent
                    await defaultApi.deleteEnterpriseMember(+data?.id)
                    toast.success(Message.notify.update_success, { duration: 3000 });
                    searchByFarmer(+data?.enterpriseId)
                    break;
                default:
                    break;
            }

        } catch (error) {
            console.log(error);
            toast.error(Message.notify.update_error, { duration: 3000 })
        } finally {
            toast.dismiss(loading);
        }
    }

    const getInfomation = async () => {
        try {
            const res1 = await defaultApi.getUser()
            const res2 = await defaultApi.getAllFactory()
            const res3 = await defaultApi.getAllEnterprise()

            setUser(res1.data)
            setFactory(res2.data)
            setFarmer(res3.data)
        } catch (error) {
            console.log(error);
        }
    }

    async function getCorFac(value) {
        try {

            function loopCheckItemList(key, value, iCount) {

                const element1 = value[iCount];
                const amount = element1?.value2?.length

                if (amount > 0) {
                    let arr = []
                    for (let index = 0; index < amount; index++) {
                        const element2 = element1?.value2[index];
                        const data = element2?.user
                        arr[index] = (
                            <div key={index} className={`${style.listset}`}>
                                <div className={`${style.rows}`}>
                                    <div>
                                        <span><h6>ชื่อผู้ประสานงาน : </h6></span>
                                        <span>{data?.title} {data?.firstname} {data?.lastname}</span>
                                    </div>
                                </div>
                                <div className={`${style.rows}`}>
                                    <div>
                                        <span><h6>อีเมล : </h6></span>
                                        <span>{data.email ? data.email : '-'}</span>
                                    </div>
                                    <div>
                                        <span><h6>เบอร์โทรศัพท์ : </h6></span>
                                        <span>{data.mobile ? data.mobile : '-'}</span>
                                    </div>
                                </div>
                                <div className={`${style.edit}`}>
                                    <FaPen className={`${style.icon}`}
                                        style={{ "cursor": "pointer" }}
                                        onClick={() => {
                                            setDataProp2({
                                                index: index,
                                                entid: key,
                                                item: element2,
                                            })
                                            document.getElementById("show_modal_edit_2").click()
                                        }}
                                    />
                                    <FaTrash className={`${style.icon}`} style={{ "cursor": "pointer" }} onClick={() => {
                                        delUser(2, element2)
                                    }} />
                                </div>
                            </div>
                        )
                    }
                    return arr
                } else {
                    return (<div key={iCount} className={`${style.listset}`}>
                        <div className={`${style.rows}`}>
                            <div>
                                <span><h6>ชื่อผู้ประสานงาน : </h6></span>
                                <span> - </span>
                            </div>
                        </div>
                        <div className={`${style.rows}`}>
                            <div>
                                <span><h6>อีเมล : </h6></span>
                                <span> - </span>
                            </div>
                            <div>
                                <span><h6>เบอร์โทรศัพท์ : </h6></span>
                                <span> - </span>
                            </div>
                        </div>
                        <div className={`${style.edit}`}>
                        </div>
                    </div>)
                }
            }

            const res1 = await defaultApi.getAllFactoryMember(`&%24filter=factoryId%20eq%20${value}&%24expand=user`)

            const amountItem = res1?.data?.length
            const data0 = res1?.data
            let arr1 = []
            for (let index = 0; index < amountItem; index++) {
                const element = data0[index];
                const data = element?.user
                arr1[index] = (
                    <div key={index} className={`${style.listset}`}>
                        <div className={`${style.rows}`}>
                            <div>
                                <span><h6>ชื่อผู้ประสานงาน : </h6></span>
                                <span>{data?.title} {data?.firstname} {data?.lastname}</span>
                            </div>
                        </div>
                        <div className={`${style.rows}`}>
                            <div>
                                <span><h6>อีเมล : </h6></span>
                                <span>{data.email ? data.email : '-'}</span>
                            </div>
                            <div>
                                <span><h6>เบอร์โทรศัพท์ : </h6></span>
                                <span>{data.mobile ? data.mobile : '-'}</span>
                            </div>
                        </div>
                        <div className={`${style.edit}`}>
                            <FaPen className={`${style.icon}`}
                                style={{ "cursor": "pointer" }}
                                onClick={() => {
                                    setDataProp1({
                                        index: index,
                                        facid: value,
                                        item: element,
                                    })
                                    document.getElementById("show_modal_edit_1").click()
                                }}
                            />
                            <FaTrash className={`${style.icon}`} style={{ "cursor": "pointer" }} onClick={() => {
                                delUser(1, element)
                            }} />
                        </div>
                    </div>
                )
            }

            const entListInFac = farmer?.map((datax) => {
                return { entId: datax?.id, facId: datax?.factoryId, name: datax?.name }
            }).filter((arr) => {
                if (+arr?.facId === +value) {
                    return arr
                }
            }).sort((a, b) => a.name - b.name)


            let memberInEnt = []
            for (let index = 0; index < entListInFac?.length; index++) {
                const element = entListInFac[index];
                const res2 = await defaultApi.getAllEnterpriseMember(`&%24filter=enterpriseId%20eq%20${element?.entId}&%24expand=user`)
                memberInEnt[index] = { value1: element, value2: res2?.data }
            }

            let arr2 = []
            for (let index = 0; index < entListInFac?.length; index++) {
                const element1 = entListInFac[index];

                arr2[index] = (
                    <div className={`${style.boxdetail}`} key={"boxdetailbot" + index}>
                        <div className={`col-10 ${style.content}`}>
                            <div className={`${style.rows}`}>
                                <div>
                                    <span><h6>กลุ่ม : </h6></span>
                                    <span>{element1?.name}</span>
                                </div >
                            </div >
                            <div className={`${style.boxnewContent} ${style.rows}`}>
                                {loopCheckItemList(element1?.entId, memberInEnt, index)}
                            </div>
                        </div >
                    </div>
                )
            }

            setResCorInFac1(arr1)
            setResCorInEnt1(arr2)
        } catch (error) {

        }
    }

    async function getCorEnt(value) {
        try {

            function loopCheckItemList(value, data) {
                let arr = []

                const amount = data?.length
                if (amount > 0) {
                    for (let index = 0; index < data?.length; index++) {
                        const element = data[index];
                        const datax = element?.user
                        arr[index] = (
                            <div key={index} className={`${style.listset}`}>
                                <div className={`${style.rows}`}>
                                    <div>
                                        <span><h6>ชื่อผู้ประสานงาน : </h6></span>
                                        <span>{datax?.title} {datax?.firstname} {datax?.lastname}</span>
                                    </div>
                                </div>
                                <div className={`${style.rows}`}>
                                    <div>
                                        <span><h6>อีเมล : </h6></span>
                                        <span>{datax.email ? datax.email : '-'}</span>
                                    </div>
                                    <div>
                                        <span><h6>เบอร์โทรศัพท์ : </h6></span>
                                        <span>{datax.mobile ? datax.mobile : '-'}</span>
                                    </div>
                                </div>
                                <div className={`${style.edit}`}>
                                    <FaPen className={`${style.icon}`}
                                        style={{ "cursor": "pointer" }}
                                        onClick={() => {
                                            setDataProp1({
                                                index: index,
                                                facid: value,
                                                item: element,
                                            })
                                            document.getElementById("show_modal_edit_1").click()
                                        }}
                                    />
                                    <FaTrash className={`${style.icon}`} style={{ "cursor": "pointer" }} onClick={() => {
                                        delUser(1, element)
                                    }} />
                                </div>
                            </div>
                        )
                    }
                } else {
                    arr[0] = (
                        <div key={0} className={`${style.listset}`}>
                            <div className={`${style.rows}`}>
                                <div>
                                    <span><h6>ชื่อผู้ประสานงาน : </h6></span>
                                    <span> - </span>
                                </div>
                            </div>
                            <div className={`${style.rows}`}>
                                <div>
                                    <span><h6>อีเมล : </h6></span>
                                    <span> - </span>
                                </div>
                                <div>
                                    <span><h6>เบอร์โทรศัพท์ : </h6></span>
                                    <span> - </span>
                                </div>
                            </div>
                            <div className={`${style.edit}`}>
                            </div>
                        </div>
                    )
                }
                return arr
            }

            const res1 = await defaultApi.getAllEnterpriseMember(`&%24filter=enterpriseId%20eq%20${value}&%24expand=user`)
            const amount = res1?.data?.length
            const data = res1?.data

            let arr1 = []
            if (amount > 0) {
                for (let index = 0; index < amount; index++) {
                    const element = data[index];
                    const datax = element?.user
                    arr1[index] = (
                        <div key={index} className={`${style.listset}`}>
                            <div className={`${style.rows}`}>
                                <div>
                                    <span><h6>ชื่อผู้ประสานงาน : </h6></span>
                                    <span>{datax?.title} {datax?.firstname} {datax?.lastname}</span>
                                </div>
                            </div>
                            <div className={`${style.rows}`}>
                                <div>
                                    <span><h6>อีเมล : </h6></span>
                                    <span>{datax.email ? datax.email : '-'}</span>
                                </div>
                                <div>
                                    <span><h6>เบอร์โทรศัพท์ : </h6></span>
                                    <span>{datax.mobile ? datax.mobile : '-'}</span>
                                </div>
                            </div>
                            <div className={`${style.edit}`}>
                                <FaPen className={`${style.icon}`}
                                    style={{ "cursor": "pointer" }}
                                    onClick={() => {
                                        setDataProp2({
                                            index: index,
                                            entid: value,
                                            item: element,
                                        })
                                        document.getElementById("show_modal_edit_2").click()
                                    }}
                                />
                                <FaTrash className={`${style.icon}`} style={{ "cursor": "pointer" }} onClick={() => {
                                    delUser(2, element)
                                }} />
                            </div>
                        </div>
                    )
                }
            } else {
                arr1[0] = (
                    <>
                        <div className={`${style.rows}`}>
                            <div>
                                <span><h6>ชื่อผู้ประสานงาน : </h6></span>
                                <span> - </span>
                            </div>
                        </div>
                        <div className={`${style.rows}`}>
                            <div>
                                <span><h6>อีเมล : </h6></span>
                                <span> - </span>
                            </div>
                            <div>
                                <span><h6>เบอร์โทรศัพท์ : </h6></span>
                                <span> - </span>
                            </div>
                        </div>
                        <div className={`${style.edit}`}>
                        </div>
                    </>
                )
            }

            let listFac = []
            let i = 0
            for (let index = 0; index < farmer?.length; index++) {
                const element = farmer[index];
                if ((+element?.id === +value)) {
                    const data = element?.factory
                    listFac[i] = { id: data?.id, name: data?.name }
                    i++
                }
            }

            let arr2 = []
            for (let index = 0; index < listFac.length; index++) {
                const element = listFac[index];
                const res = await defaultApi.getAllFactoryMember(`&%24filter=factoryId%20eq%20${element?.id}&%24expand=user`)

                arr2[index] = (
                    <div className={`${style.boxdetail}`} key={'boxdetailtop'}>
                        <div className={`col-10 ${style.content}`}>
                            <div className={`${style.rows}`}>
                                <div>
                                    <span><h6>โรงไฟฟ้า : </h6></span>
                                    <span>{element?.name}</span>
                                </div>
                            </div>
                            <div className={`${style.boxnewContent} ${style.rows}`}>
                                <>{loopCheckItemList(element?.id, res?.data)}</>
                            </div>
                        </div>
                    </div>
                )
            }

            setResCorInEnt2(arr1)
            setResCorInFac2(arr2)
        } catch (error) {

        }
    }

    function searchByFactory(value) {

        if (value) {
            setStateTag(false)
        } else {
            setStateTag(true)
            setDefaultState()
            return
        }


        if (factory?.length > 0) {
            for (let index = 0; index < factory?.length; index++) {
                const element = factory[index];
                if (+element?.id === +value) {
                    setKeyFac({
                        id: element?.id, name: element?.name
                    })
                }
            }
            document.getElementById("searchFam").value = ''
            getCorFac(value)
            setDataGroup(1)
        }
    }

    function searchByFarmer(value) {
        if (value) {
            setStateTag(false)
        } else {
            setStateTag(true)
            setDefaultState()
            return
        }

        for (let index = 0; index < farmer?.length; index++) {
            const element = farmer[index];
            if (+element?.id === +value) {
                setKeyEnt({
                    id: element?.id, name: element?.name
                })
            }
        }
        document.getElementById("searchFac").value = ''
        getCorEnt(value)
        setDataGroup(2)
    }

    useEffect(() => {
        getInfomation()
    }, [])

    return (
        <>
            <div style={toggerCheck === true ? { display: 'flex' } : { display: 'none' }} className={`${style.bodytab5}`}>
                <div>
                    <div className={`${style.hpsub}`}>
                        <select
                            id="searchFac"
                            onChange={(e) => {
                                searchByFactory(e.target.value)
                            }}
                        >
                            <option value=''>เลือกโรงงานไฟฟ้า</option>
                            {
                                factory.map((data) => {
                                    return <option key={data.id} value={data.id}>{data.name}</option>
                                })
                            }
                        </select>
                        <select
                            id="searchFam"
                            onChange={(e) => {
                                searchByFarmer(e.target.value)
                            }}
                        >
                            <option value=''>เลือกกลุ่มเกษตรกร</option>
                            {
                                farmer.map((data) => {
                                    return <option key={data.id} value={data.id}>{data.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div>
                    <div className={`${style.bpsub}`}>
                        <div className={`${style.sfbox}`}>
                            <div style={_stateTag === true ? { display: 'none' } : null} className={`${style.cookietitle}`}><h4>ผู้ประสานงานโรงไฟฟ้า</h4></div>
                            {(_dataGrp === 1 && _resCorInFac1?.length > 0) &&
                                <div className={`${style.boxdetail}`} key={'boxdetailtop'}>
                                    <div className={`col-10 ${style.content}`}>
                                        <div className={`${style.rows}`}>
                                            <div>
                                                <span><h6>โรงไฟฟ้า : </h6></span>
                                                <span>{_keyFac?.name}</span>
                                            </div>
                                        </div>
                                        <div className={`${style.boxnewContent} ${style.rows}`}>
                                            {_resCorInFac1}
                                        </div>
                                    </div>
                                </div>
                            }
                            {(_dataGrp === 1 && _resCorInFac1?.length === 0) &&
                                <div className={`${style.boxdetail}`} key={'boxdetailtop'}>
                                    <div className={`col-10 ${style.content}`}>
                                        <div className={`${style.rows}`}>
                                            <div>
                                                <span><h6>โรงไฟฟ้า : </h6></span>
                                                <span>{_keyFac?.name}</span>
                                            </div>
                                        </div>
                                        <div className={`${style.boxnewContent} ${style.rows}`}>
                                            <div key={'0'} className={`${style.listset}`}>
                                                <div className={`${style.rows}`}>
                                                    <div>
                                                        <span><h6>ชื่อผู้ประสานงาน : </h6></span>
                                                        <span> - </span>
                                                    </div>
                                                </div>
                                                <div className={`${style.rows}`}>
                                                    <div>
                                                        <span><h6>อีเมล : </h6></span>
                                                        <span> - </span>
                                                    </div>
                                                    <div>
                                                        <span><h6>เบอร์โทรศัพท์ : </h6></span>
                                                        <span> - </span>
                                                    </div>
                                                </div>
                                                <div className={`${style.edit}`}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {(_dataGrp === 2 && _resCorInFac2?.length > 0) &&
                                <>{_resCorInFac2}</>
                            }
                            <div style={_stateTag === true ? { display: 'none' } : null} className={`${style.cookietitle}`}><h4>ผู้ประสานงานกลุ่มเกษตรกร</h4></div>
                            {(_dataGrp === 1 && _resCorInEnt1?.length > 0) && (
                                <>{_resCorInEnt1}</>
                            )}

                            {(_dataGrp === 2 && _resCorInEnt2?.length > 0) &&
                                <div className={`${style.boxdetail}`} key={'boxdetailtop'}>
                                    <div className={`col-10 ${style.content}`}>
                                        <div className={`${style.rows}`}>
                                            <div>
                                                <span><h6>กลุ่ม : </h6></span>
                                                <span>{_keyEnt?.name}</span>
                                            </div>
                                        </div>
                                        <div className={`${style.boxnewContent} ${style.rows}`}>
                                            {_resCorInEnt2}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div >
                    </div >
                </div >
            </div >
            <button type="button" id='show_modal_edit_1' className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal1" hidden></button>
            <button type="button" id='show_modal_edit_2' className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal2" hidden></button>
            <FormManageCordData1 data={_setDataProp1} lst={factory} setDefaultState={setDefaultState} getInfomation={getInfomation} searchByFactory={searchByFactory} />
            <FormManageCordData2 data={_setDataProp2} lst={farmer} setDefaultState={setDefaultState} getInfomation={getInfomation} searchByFarmer={searchByFarmer} />
        </>
    )
}