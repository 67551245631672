import axios from "axios";
import {
  getCookiesStorageData,
  removeCookies,
} from "../helpers/cookies_storage.helper";
import { decodeData } from "../helpers/encode_decode.helper";
import { removeLocalStorage } from "../helpers/local_storage.helper";

const baseURL = process.env.REACT_APP_ENDPOINT_URL;

export default class Api {
  constructor() {
    this.api_token = null;
    this.client = null;
    this.api_url = baseURL + ""; //process.env.REACT_APP_API_ENDPOINT;
  }

  /**
   * @return {axios.Axios}
   */
  init = () => {
    const token_ = decodeData(getCookiesStorageData("token"));

    if (!this.client) {
      this.api_token = token_; //getCookie("ACCESS_TOKEN");
      let headers = { Accept: "application/json" };
      if (this.api_token) {
        headers.Authorization = `Bearer ${this.api_token}`;
      }
      this.client = axios.create({
        baseURL: this.api_url,
        timeout: 31000,
        headers: headers,
      });
    } else if (this.client) {
      this.api_token = token_; //getCookie("ACCESS_TOKEN");
      let headers = { Accept: "application/json" };
      if (this.api_token) {
        headers.Authorization = `Bearer ${this.api_token}`;
      }
      this.client = axios.create({
        baseURL: this.api_url,
        timeout: 31000,
        headers: headers,
      });
    }

    this.client.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      (error) => {
        // Force logout on TOKEN expired.
        if (error?.response?.status === 401) {
          removeCookies("token");
          removeCookies("userProfile");
          removeLocalStorage("allFactory");
          removeLocalStorage("allEnterprise");
          window.location.reload();
        }
        return Promise.reject(error);
      }
    );

    return this.client;
  };

  login = (params) => {
    return this.init().post(baseURL + "auth/login", params);
  };
  // login = (params) => {
  //   return this.init().post(baseURL + "auth/login", params);
  // };
  register = (params) => {
    return this.init().post(baseURL + "auth/register", params);
  };
  forgetPassword = (param) => {
    return this.init().post(baseURL + "auth/forgetPassword", param);
  };
  getProfile = (query) => {
    return this.init().get(baseURL + `auth/profile?${query}`);
  };
  updateProfile = (params) => {
    return this.init().put(baseURL + "auth/profile", params);
  };
  changePassword = (params) => {
    return this.init().put(baseURL + `auth/changePassword`, params);
  };

  getGisLayer = () => {
    return this.init().get(baseURL + "gisLayerGroup");
  };
  getGisLayerDetail = () => {
    return this.init().get(baseURL + "gisLayer");
  };
  getGisLayerDetailId = (id) => {
    return this.init().get(baseURL + `gisLayer/${id}`);
  };
  updateGisLayer = (id, params) => {
    return this.init().put(baseURL + `gisLayer/${id}`, params);
  };

  getUser = (query) => {
    return this.init().get(baseURL + `user?${query}`);
  };
  getUserId = (id, query) => {
    return this.init().get(baseURL + `user/${id}?${query}`);
  };

  getLocalGrpId = (id, query) => {
    return this.init().get(baseURL + `localGroup/${id}?${query}`);
  };

  getLocalGrp = (query) => {
    return this.init().get(baseURL + `localGroup?${query}`);
  };
  updateLocalGroup = (id, params) => {
    return this.init().put(baseURL + `localGroup/${id}`, params);
  };
  createLocalGroup = (params) => {
    return this.init().post(baseURL + "localGroup", params);
  };
  deleteLocalGroup = (id) => {
    return this.init().delete(baseURL + `localGroup/${id}`);
  };

  updatePermissionGroup = (id, params) => {
    return this.init().put(baseURL + `localGroup/permission/${id}`, params);
  };
  updateUser = (id, params) => {
    return this.init().put(baseURL + `user/${id}`, params);
  };
  getUserGroup = () => {
    return this.init().get(baseURL + "userGroup");
  };
  getOrg = () => {
    return this.init().get(baseURL + "org");
  };
  getOrgId = (id) => {
    return this.init().get(baseURL + `org/${id}`);
  };
  deleteOrg = (id) => {
    return this.init().delete(baseURL + `org/${id}`);
  };
  updateOrg = (id, params) => {
    return this.init().put(baseURL + `org/${id}`, params);
  };
  createOrg = (params) => {
    return this.init().post(baseURL + `org`, params);
  };

  getOrgQuery = (query) => {
    return this.init().get(baseURL + `org?${query}`);
  };

  createGislayer = (params) => {
    return this.init().post(baseURL + `gisLayer`, params);
  };
  updateGisLayerStatus = (id, params) => {
    return this.init().put(baseURL + `gisLayer/status/${id}`, params);
  };
  deleteGisLayer = (id) => {
    return this.init().delete(baseURL + `gisLayer/${id}`);
  };

  /* Factory - โรงไฟฟ้าชุมชน */
  getAllFactory = (query) => {
    return this.init().get(baseURL + `factory?${query}`);
  };
  getFactory = (id, query) => {
    return this.init().get(baseURL + `factory/${id}?${query}`);
  };
  updateFactory = (id, params) => {
    return this.init().put(baseURL + `factory/${id}`, params);
  };
  createFactory = (params) => {
    return this.init().post(baseURL + "factory", params);
  };
  getFactoryType = () => {
    return this.init().get(baseURL + "factoryType");
  };
  getFactoryStatus = () => {
    return this.init().get(baseURL + "factoryStatus");
  };
  updateFactoryCoordinator = (id, params) => {
    return this.init().put(baseURL + `factory/manager/${id}`, params);
  };

  getFactorySupply = (id) => {
    return this.init().get(baseURL + `factory/supply/${id}`);
  };
  getAllFactorySupply = (id, query) => {
    return this.init().get(baseURL + `factory/supply/${id}?${query}`);
  };
  getAllFactoryMember = (query) => {
    return this.init().get(baseURL + `factoryMember?${query}`);
  };
  createFactoryMember = (params) => {
    return this.init().post(baseURL + `factoryMember`, params);
  };
  updateFactoryMember = (id, params) => {
    return this.init().put(baseURL + `factoryMember/${id}`, params);
  };
  deleteFactoryMember = (id) => {
    return this.init().delete(baseURL + `factoryMember/${id}`);
  };
  /* FactoryRecord - บันทึกโรงไฟฟ้า */
  getAllFactoryRecord = (query) => {
    return this.init().get(baseURL + `factoryRecord?${query}`);
  };
  getFactoryRecord = (id, query) => {
    return this.init().get(baseURL + `factoryRecord/${id}?${query}`);
  };
  createFactoryRecord = (params) => {
    return this.init().post(baseURL + "factoryRecord", params);
  };
  updateFactoryRecord = (id, params) => {
    return this.init().put(baseURL + `factoryRecord/${id}`, params);
  };
  deleteFactoryRecord = (id) => {
    return this.init().delete(baseURL + `factoryRecord/${id}`);
  };
  /* RecordDetail - รายการบันทึก */
  getAllRecordDetail = () => {
    return this.init().get(baseURL + "recordDetail");
  };
  createRecordDetail = (param) => {
    return this.init().post(baseURL + "recordDetail", param);
  };
  /* Farmer - กลุ่มวิสาหกิจชุมชน */
  getAllFarmer = (_query) => {
    return this.init().get(baseURL + `farmer?${_query}`);
  };
  getFarmer = (id, query) => {
    return this.init().get(baseURL + `farmer/${id}?${query}`);
  };
  createFarmer = (params) => {
    return this.init().post(baseURL + "farmer", params);
  };
  updateFarmer = (id, params) => {
    return this.init().put(baseURL + `farmer/${id}`, params);
  };
  getAllEnterpriseMember = (query) => {
    return this.init().get(baseURL + `enterpriseMember?${query}`);
  };
  createEnterpriseMember = (params) => {
    return this.init().post(baseURL + `enterpriseMember`, params);
  };
  updateEnterpriseMember = (id, params) => {
    return this.init().put(baseURL + `enterpriseMember/${id}`, params);
  };
  deleteEnterpriseMember = (id) => {
    return this.init().delete(baseURL + `enterpriseMember/${id}`);
  };
  /* การจัดการสัญญา (สัญญากลุ่มวิสาหกิจชุมชน ContractFarming, สัญญาขายพลังงาน Ppa) */
  getAllContractFarming = (query) => {
    return this.init().get(baseURL + `contractFarming?${query}`);
  };
  getContractFarming = (id, query) => {
    return this.init().get(baseURL + `contractFarming/${id}?${query}`);
  };
  createContractFarming = (params) => {
    return this.init().post(baseURL + "contractFarming", params);
  };
  updateContractFarming = (id, params) => {
    return this.init().put(baseURL + `contractFarming/${id}`, params);
  };
  getAllContractPpa = (query) => {
    return this.init().get(baseURL + `ppa?${query}`);
  };
  getContractPpa = (id, query) => {
    return this.init().get(baseURL + `ppa/${id}?${query}`);
  };
  createContractPpa = (params) => {
    return this.init().post(baseURL + "ppa", params);
  };
  updateContractPpa = (id, params) => {
    return this.init().put(baseURL + `ppa/${id}`, params);
  };
  getAllTransaction = (query) => {
    return this.init().get(baseURL + `transaction?${query}`);
  };
  getTransaction = (id, query) => {
    return this.init().get(baseURL + `transaction/${id}?${query}`);
  };
  createTransaction = (params) => {
    return this.init().post(baseURL + "transaction", params);
  };
  updateTransaction = (id, params) => {
    return this.init().put(baseURL + `transaction/${id}`, params);
  };
  deleteTransaction = (id) => {
    return this.init().delete(baseURL + `transaction/${id}`);
  };
  updatePaymentStatus = (id, param) => {
    return this.init().put(baseURL + `transaction/paymentStatus/${id}`, param);
  };
  getAllPpaTransaction = (query) => {
    return this.init().get(baseURL + `ppaTransaction?${query}`);
  };
  getPpaTransaction = (id, query) => {
    return this.init().get(baseURL + `ppaTransaction/${id}?${query}`);
  };
  createPpaTransaction = (params) => {
    return this.init().post(baseURL + "ppaTransaction", params);
  };
  updatePpaTransaction = (id, params) => {
    return this.init().put(baseURL + `ppaTransaction/${id}`, params);
  };
  /* PlantType - ชนิดพืช */
  getAllPlantType = () => {
    return this.init().get(baseURL + "plantType");
  };
  createPlantType = (param) => {
    return this.init().post(baseURL + "plantType", param);
  };
  /* Enterprise - กลุ่มวิสาหกิจชุมชน */
  getAllEnterprise = (query) => {
    return this.init().get(baseURL + `enterprise?${query}`);
  };
  getEnterprise = (id, query) => {
    return this.init().get(baseURL + `enterprise/${id}?${query}`);
  };
  createEnterprise = (params) => {
    return this.init().post(baseURL + "enterprise", params);
  };
  updateEnterprise = (id, params) => {
    return this.init().put(baseURL + `enterprise/${id}`, params);
  };
  getEnterpriseType = () => {
    return this.init().get(baseURL + "enterpriseType");
  };
  // updateEnterpriseCoordinator = (id, params) => {
  //   return this.init().put(baseURL + `enterprise/coordinator/${id}`, params);
  // }
  updateEnterpriseCoordinator = (id, params) => {
    return this.init().put(baseURL + `enterprise/manager/${id}`, params);
  };
  /* รายละเอียดไฟล์/รูปภาพ */
  // getAllFileDetail = () => {
  //   return this.init().get(baseURL + "fileDetail");
  // }
  getAllEnterpriseSupply = (id, query) => {
    return this.init().get(baseURL + `enterprise/supply/${id}?${query}`);
  };
  /* FileDetail - รายละเอียดไฟล์/รูปภาพ */
  getAllFileDetail = (query) => {
    return this.init().get(baseURL + `fileDetail?${query}`);
  };
  getFileDetail = (id, query) => {
    return this.init().get(baseURL + `fileDetail/${id}?${query}`);
  };
  createFileDetail = (param1, param2) => {
    return this.init().post(
      baseURL + `fileDetail?Description=${param1}`,
      param2
    );
  };
  updateFileDetail = (id, param1, param2) => {
    return this.init().put(
      baseURL + `fileDetail/${id}?Description=${param1}`,
      param2
    );
  };
  /* PowerGeneration - การผลิตไฟฟ้า */
  getAllPowerGeneration = (query) => {
    return this.init().get(baseURL + `powerGeneration?${query}`);
  };
  getPowerGeneration = (id, query) => {
    return this.init().get(baseURL + `powerGeneration/${id}?${query}`);
  };
  createPowerGeneration = (params) => {
    return this.init().post(baseURL + "powerGeneration", params);
  };
  updatePowerGeneration = (id, params) => {
    return this.init().put(baseURL + `powerGeneration/${id}`, params);
  };
  deletePowerGeneration = (id) => {
    return this.init().delete(baseURL + `powerGeneration/${id}`);
  };
  /* Parcel - แปลงที่ดินตามโฉนดหรือเอกสารสิทธิ์ต่างๆ */
  getAllParcel = (query) => {
    return this.init().get(baseURL + `parcel?${query}`);
  };
  getParcel = (id, query) => {
    return this.init().get(baseURL + `parcel/${id}?${query}`);
  };
  createParcel = (params) => {
    return this.init().post(baseURL + "parcel", params);
  };
  updateParcel = (id, params) => {
    return this.init().put(baseURL + `parcel/${id}`, params);
  };
  deleteParcel = (id) => {
    return this.init().delete(baseURL + `parcel/${id}`);
  };
  getPolygonArea = (params) => {
    return this.init().post(baseURL + `parcel/PolygonArea`, params);
  };
  /* Crop - รอบการเพาะปลูก */
  getAllCrop = (query) => {
    return this.init().get(baseURL + `crop?${query}`);
  };
  getCrop = (id, query) => {
    return this.init().get(baseURL + `crop/${id}?${query}`);
  };
  createCrop = (params) => {
    return this.init().post(baseURL + `crop`, params);
  };
  updateCrop = (id, params) => {
    return this.init().put(baseURL + `crop/${id}`, params);
  };
  deleteCrop = (id) => {
    return this.init().delete(baseURL + `crop/${id}`);
  };
  /* Address */
  getAllRegion = () => {
    return this.init().get(baseURL + `adminRegion`);
  };
  getRegion = (id) => {
    return this.init().get(baseURL + `adminRegion/${id}`);
  };
  getAllProvince = () => {
    return this.init().get(baseURL + `adminProvince`);
  };
  getProvince = (id) => {
    return this.init().get(baseURL + `adminProvince/${id}`);
  };
  getAllAmphoe = (query) => {
    return this.init().get(baseURL + `adminAmphoe?${query}`);
  };
  getAmphoe = (id) => {
    return this.init().get(baseURL + `adminAmphoe/${id}`);
  };
  getAllTambol = (query) => {
    return this.init().get(baseURL + `adminTambol?${query}`);
  };
  getTambol = (id) => {
    return this.init().get(baseURL + `adminTambol/${id}`);
  };

  getLog = (query) => {
    return this.init().get(baseURL + `log/?${query}`);
  };

  getreportEnterpriseIncomeMonth = (query) => {
    return this.init().get(baseURL + `report/enterprise/income/month?${query}`);
  };
  getreportEnterpriseIncomeYear = (query) => {
    return this.init().get(baseURL + `report/enterprise/income/year?${query}`);
  };
  getreportMemberCount = (query) => {
    return this.init().get(
      baseURL + `report/enterprise/member/count/year?${query}`
    );
  };
  getreportMemberCountCompare = (query) => {
    return this.init().get(
      baseURL + `report/enterprise/member/count/year/v2?${query}`
    );
  };
  getreportEnterpriseTransactionYear = (query) => {
    return this.init().get(
      baseURL + `report/enterprise/transaction/year?${query}`
    );
  };
  getreportEnterpriseTransactionMonth = (query) => {
    return this.init().get(
      baseURL + `report/enterprise/transaction/month?${query}`
    );
  };
  getpreportEnterpriseMemberOwnershipYear = (query) => {
    return this.init().get(
      baseURL + `report/enterprise/member/ownership/year?${query}`
    );
  };
  getReportFactoryIncomeYear = (query) => {
    return this.init().get(baseURL + `report/factory/income/year?${query}`);
  };
  getReportFactoryIncomeMonth = (query) => {
    return this.init().get(baseURL + `report/factory/income/month?${query}`);
  };
  getReportFactoryPowerGenerateYear = (query) => {
    return this.init().get(
      baseURL + `report/factory/powerGenerate/year?${query}`
    );
  };
  getReportFactoryPowerGenerateMonth = (query) => {
    return this.init().get(
      baseURL + `report/factory/powerGenerate/month?${query}`
    );
  };
  getReportFactoryPlanYear = (query) => {
    // report/factory/plan/yearV2
    // report/factory/plan/year
    return this.init().get(baseURL + `report/factory/plan/yearV2?${query}`);
  };
  getReportFactoryPlanMonth = (query) => {
    //report/factory/plan/month
    // report/factory/plan/monthV2
    return this.init().get(baseURL + `report/factory/plan/monthV2?${query}`);
  };
  getReportFactoryTransactionYear = (query) => {
    return this.init().get(
      baseURL + `report/factory/transaction/year?${query}`
    );
  };
  getReportFactoryTransactionMonth = (query) => {
    return this.init().get(
      baseURL + `report/factory/transaction/month?${query}`
    );
  };
  getReportEnterpriseYieldPlanting = (query) => {
    ///report/enterprise/yieldPlantingV2
    // report/enterprise/yieldPlanting
    return this.init().get(
      baseURL + `report/enterprise/yieldPlantingV2?${query}`
    );
  };
  getReportPlantingArea = (query) => {
    return this.init().get(baseURL + `report/plantingarea?${query}`);
  };

  getTargetHarvest = (query) => {
    return this.init().get(
      baseURL + `report/enterprise/targetHarvest?${query}`
    );
  };

  getReportDashboard = (query) => {
    //report/dashboard
    // report/dashboardV2
    return this.init().get(baseURL + `report/dashboardV2?${query}`);
  };
  getReportFactoryRegoin = () => {
    return this.init().get(baseURL + `report/factory/region`);
  };
  /* Export and Download */
  downloadPdf = (id, responseType) => {
    return this.init().get(
      baseURL + `maintenance/exportDocument/${id}`,
      responseType
    );
  };
  // exportCsv = (query) => {
  //   return this.init().get(baseURL + `maintenance/exportReport/${query}`);
  // }
  exportCsv = (exportName, type, query, responseType) => {
    return this.init().get(
      baseURL + `maintenance/exportReport/${exportName}/${type}${query}`,
      responseType
    );
  };
  /* Colors */
  getColors = () => {
    return this.init().get(baseURL + `report/color`);
  };
  getContractSignDate = (query) => {
    return this.init().get(
      baseURL + `report/enterprise/contractSignDate?${query}`
    );
  };

  getGbStatus = (query) => {
    return this.init().get(baseURL + `gbStatus?${query}`);
  };
}

export const defaultApi = new Api();
