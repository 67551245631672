import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Autocomplete from "react-autocomplete";
import { toast } from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { defaultApi } from "../../../../../../services/api";
import { getFactoryData } from "../../../../../../services/factory.slice";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../../../utils/format/Date.format";
import Message from "../../../../../../utils/message/Message";
import {
  CheckFileDoc,
  CheckNumberDot,
  CheckValidLength,
} from "../../../../../../utils/validation_input/Validation";
import { QueryGetAll } from "../../../../../../helpers/api.helper";
import icon from "../../../../../../assets/img/Icon";

const buyerArr = [
  {
    id: 0,
    name: "การไฟฟ้าส่วนภูมิภาค (PEA)",
  },
];

const AddSalesRec = ({ onSwicthBoxR }) => {
  const factoryData = useSelector(getFactoryData);

  const [_data, setData] = useState({
    factoryId: factoryData.id,
    payee: factoryData.name,
    buyer: "การไฟฟ้าส่วนภูมิภาค (PEA)",
    paymentStatus: "",
    sellDate: "",
    ppaId: "",
    payDate: "",
    powers: "",
    priceRate: "",
    total: "",
    receipt: "",
  });
  const [allContractPpa, setAllContractPpa] = useState([]);
  const [_total, setTotal] = useState("");
  const [_paymentStatus, setPaymentStatus] = useState([]);
  const [selectedDate, setSelectedDate] = useState({
    sellDate: new Date(),
    payDate: new Date(),
  });
  const [openSellDate, setOpenSellDate] = useState(false);
  const [openPayDate, setOpenPayDate] = useState(false);
  const [validErr, setValidErr] = useState(false);

  /* DatePicker : START */
  const onChangeSellDate = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, sellDate: dateFormat });
    setSelectedDate({ ...selectedDate, sellDate: e });
    setOpenSellDate(!openSellDate);
  };
  const onChangePayDate = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, payDate: dateFormat });
    setSelectedDate({ ...selectedDate, payDate: e });
    setOpenPayDate(!openPayDate);
  };
  /* DatePicker : END */

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };
  const createFileDetail = async (e) => {
    const file = document.querySelector("#add_file");

    const loading = toast.loading("กำลังเพิ่มใบแจ้งหนี้...");
    const param1 = "PpaTransaction";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);

    try {
      const res = await defaultApi.createFileDetail(param1, param2);
      console.log(res.statusText);

      setData({ ..._data, receipt: res?.data.id });
    } catch (error) {
      console.log(error);
    } finally {
      file.value = "";
      toast.dismiss(loading);
    }
  };
  const createPpaTransaction = async () => {
    if (CheckValidLength(_data.payee)) return setValidErr(true);
    if (CheckValidLength(_data.buyer)) return setValidErr(true);
    if (CheckValidLength(_data.paymentStatus)) return setValidErr(true);
    if (CheckValidLength(_data.sellDate)) return setValidErr(true);
    if (CheckValidLength(_data.ppaId)) return setValidErr(true);
    if (CheckValidLength(_data.payDate)) return setValidErr(true);
    if (CheckNumberDot(_data.powers)) return setValidErr(true);
    if (CheckNumberDot(_data.priceRate)) return setValidErr(true);
    if (CheckNumberDot(_total)) return setValidErr(true);
    if (CheckValidLength(_data.receipt)) return setValidErr(true);

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "ppaId") {
        params[key] = +_data[key];
      } else if (key === "powers") {
        params[key] = +_data[key];
      } else if (key === "priceRate") {
        params[key] = +_data[key];
      } else if (key === "total") {
        params[key] = +_total;
      } else if (key === "receipt") {
        params[key] = +_data[key];
      } else {
        params[key] = _data[key];
      }
    });
    try {
      const res = await defaultApi.createPpaTransaction(params);
      console.log(res.statusText);
      onSwicthBoxR(false);
      toast.success(Message.notify.insert_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.insert_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  const getMultipleApi = useCallback(async () => {
    if (!factoryData?.id) return;

    let factoryId = factoryData?.id;
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=factoryId%20eq%20${factoryId}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const [allContractPpa] = await Promise.all([
        defaultApi.getAllContractPpa(_queryAll),
      ]);

      setAllContractPpa(allContractPpa.data.datas);
    } catch (error) {
      console.log(error);
    }
  }, [factoryData]);
  const getGbStatus = async () => {
    const query = `%24filter=statusCategories%20eq%20%275%27`;

    try {
      const res = await defaultApi.getGbStatus(query);

      setPaymentStatus(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    /* คำนวณผลรับของ input ยอดรวมสุทธิ(บาท) : START */
    if (_data.powers !== "" && _data.priceRate !== "") {
      const num1 = parseFloat(_data.powers);
      const num2 = parseFloat(_data.priceRate);

      if (!isNaN(num1) && !isNaN(num2)) {
        const calculatedResult = num1 * num2; // เปลี่ยนตรงนี้เป็นการคำนวณตามต้องการ
        setTotal(calculatedResult);
      } else {
        setTotal("");
      }
    } else {
      setTotal("");
    }
    /* คำนวณผลรับของ input ยอดรวมสุทธิ(บาท) : END */
  }, [_data]);

  useEffect(() => {
    // Use functions
    getMultipleApi();
    getFilePdfName();
    getGbStatus();
  }, [getMultipleApi]);

  useEffect(() => {
    /* Function close dropdown 'SellDate and PayDate' : START */
    const onCloseDropdownSellDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_sell_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenSellDate(false);
      }
    };
    const onCloseDropdownPayDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_pay_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenPayDate(false);
      }
    };

    if (openSellDate) {
      document.addEventListener("click", onCloseDropdownSellDate);
    }
    if (openPayDate) {
      document.addEventListener("click", onCloseDropdownPayDate);
    }
    /* Function close dropdown 'SellDate and PayDate' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownSellDate);
      document.removeEventListener("click", onCloseDropdownPayDate);
    };
  }, [openSellDate, openPayDate]);

  return (
    <div className="add_sales_rec mx-auto">
      <h5 className="title_text">บันทึกการขายไฟฟ้า</h5>
      <form className="input_form">
        <div className="input_flex">
          <div className="input_1">
            <h5>ผู้ขายไฟฟ้า(ผู้ผลิตไฟฟ้า)</h5>
            <input
              type="text"
              name="payee"
              value={_data.payee}
              className={`${validErr && _data.payee === "" ? "error" : ""}`}
              onChange={onChange}
            />
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.payee)}</p>
            )}
          </div>
          <div className="input_2">
            <h5>ผู้ซื้อไฟฟ้า(การไฟฟ้า)</h5>
            <div className="auto_com_box">
              <Autocomplete
                inputProps={{
                  className: `input_box ${
                    validErr && _data.buyer === "" ? "error" : ""
                  }`,
                  // placeholder: "การไฟฟ้าส่วนภูมิภาค"
                }}
                wrapperStyle={{ width: "100%", fontSize: "16px" }}
                items={buyerArr}
                shouldItemRender={(item, value) =>
                  item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                getItemValue={(item) => item.name}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                  >
                    {item.name}
                  </div>
                )}
                value={_data.buyer}
                onChange={(e) => setData({ ..._data, buyer: e.target.value })}
                onSelect={(name) => setData({ ..._data, buyer: name })}
              />
            </div>
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.buyer)}</p>
            )}
          </div>
        </div>
        <div className="input_flex">
          <div className="input_1">
            <h5>
              สถานะการจ่ายเงิน <sup className="asterisk">*</sup>
            </h5>
            <select
              name="paymentStatus"
              className={`${
                validErr && _data.paymentStatus === "" ? "error" : ""
              }`}
              onChange={onChange}
            >
              <option style={{ display: "none" }}>เลือกสถานะการจ่ายเงิน</option>
              {_paymentStatus.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.statusName}
                  </option>
                );
              })}
            </select>
            {validErr && (
              <p className="err_message">
                {CheckValidLength(_data.paymentStatus)}
              </p>
            )}
          </div>
          <div className="input_2">
            <h5>
              วันที่บันทึกการขาย <sup className="asterisk">*</sup>
            </h5>
            <div
              className={`input_unit ${
                validErr && _data.sellDate === "" ? "error" : ""
              }`}
            >
              <div className="info_box">
                <p>
                  {_data.sellDate !== "" ? convertDateThai(_data.sellDate) : ""}
                </p>
              </div>
              <div
                className="unit"
                onClick={() => {
                  setOpenSellDate(!openSellDate);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_sell_date_d" />
              </div>
            </div>
            {openSellDate && (
              <DatePicker
                selected={selectedDate.sellDate}
                onChange={onChangeSellDate}
                inline
              />
            )}
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.sellDate)}</p>
            )}
          </div>
        </div>
        <div className="input_flex">
          <div className="input_1">
            <h5>
              เลขที่สัญญา PPA <sup className="asterisk">*</sup>
            </h5>
            <select
              name="ppaId"
              className={`${validErr && _data.ppaId === "" ? "error" : ""}`}
              onChange={onChange}
            >
              <option style={{ display: "none" }}>เลือกเลขที่สัญญา</option>
              {allContractPpa.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.no}
                  </option>
                );
              })}
            </select>
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.ppaId)}</p>
            )}
          </div>
          <div className="input_2">
            <h5>กำหนดชำระเงิน</h5>
            <div className="input_unit">
              <div className="info_box">
                <p>
                  {_data.payDate !== "" ? convertDateThai(_data.payDate) : ""}
                </p>
              </div>
              <div
                className="unit"
                onClick={() => {
                  setOpenPayDate(!openPayDate);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_pay_date_d" />
              </div>
            </div>
            {openPayDate && (
              <DatePicker
                selected={selectedDate.payDate}
                onChange={onChangePayDate}
                inline
              />
            )}
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.payDate)}</p>
            )}
          </div>
        </div>
        <div className="input_flex">
          <div className="input_1">
            <h5>
              ปริมาณไฟฟ้าที่เสนอขาย(KWh) <sup className="asterisk">*</sup>
            </h5>
            <input
              type="text"
              name="powers"
              className={`${validErr && _data.powers === "" ? "error" : ""}`}
              onChange={onChange}
            />
            {validErr && (
              <p className="err_message">{CheckNumberDot(_data.powers)}</p>
            )}
          </div>
          <div className="input_2">
            <h5>
              ราคาต่อหน่วย(บาท) <sup className="asterisk">*</sup>
            </h5>
            <input
              type="text"
              name="priceRate"
              className={`${validErr && _data.priceRate === "" ? "error" : ""}`}
              onChange={onChange}
            />
            {validErr && (
              <p className="err_message">{CheckNumberDot(_data.priceRate)}</p>
            )}
          </div>
        </div>
        <div className="input_flex">
          <div className="input_1">
            <h5>ยอดรวมสุทธิ(บาท)</h5>
            <div className="input_unit">
              <div className="info_box">
                <p>{_total}</p>
              </div>
            </div>
          </div>
          <div className="input_2">
            <div className="input_file">
              <h5>
                ใบแจ้งหนี้ <sup className="asterisk">*</sup>
              </h5>
              <div
                className={`input_unit mb_0 ${
                  validErr && _data.receipt === "" ? "error" : ""
                }`}
              >
                <div className="info_box">
                  <p id="file_name"></p>
                </div>
                <div className="unit">
                  <img
                    src={icon.document}
                    alt=""
                    title="แนบไฟล์"
                    onClick={() =>
                      document.querySelector("#file-label").click()
                    }
                  />
                </div>
              </div>
              {validErr && (
                <p className="err_message">{CheckFileDoc(_data.receipt)}</p>
              )}
              <span className="desc_text">
                *ระบบรองรับไฟล์ที่มีนามสกุล .pdf และมีขนาดไม่เกิน 5 MB
              </span>
              <input
                type="file"
                name="receipt"
                id="add_file"
                accept="application/pdf"
                onChange={createFileDetail}
                hidden
              />
              <label htmlFor="add_file" id="file-label" hidden></label>
            </div>
          </div>
        </div>
      </form>

      <div className="button_center">
        <button
          type="button"
          className="button_sm"
          onClick={createPpaTransaction}
        >
          บันทึก
        </button>
        <button
          type="button"
          className="button_sm"
          onClick={() => onSwicthBoxR(false)}
        >
          ยกเลิก
        </button>
      </div>
    </div>
  );
};

const getFilePdfName = () => {
  const file = document.querySelector("#add_file");
  const label = document.querySelector("#file_name");

  file?.addEventListener("change", () => {
    if (file.value) {
      label.innerHTML = file.files[0].name;
    } else {
      label.innerHTML = "";
    }
  });
};

export default AddSalesRec;
