import $ from 'jquery';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { defaultApi } from '../../../services/api';
import Message from '../../../utils/message/Message';
import { EditSubGrp01 } from '../../../utils/validation_input/Validation';
import styles from '../scss/Popupform.module.scss';

export const FormManageOrgGrp2 = ({ datain, infoDatax, idForm }) => {

    const [txtInput01, settxtInput01] = useState("")
    const [txtStatus, settxtStatus] = useState("");

    const [validErr01, setValidErr01] = useState(false);
    //const [validErr02, setValidErr02] = useState(false);

    const [_defaultLcoID, defaultLcoID] = useState([])
    const [_status, setStatus] = useState([])
    // const [_defaultOrg, defaultOrg] = useState([])
    // const [_defaultLocalGrp, defaultLocalGrp] = useState([])

    const infoData = useCallback(async (id) => {
        try {

            // if (datain.id !== undefined) {
            //     let qryGetAll = {
            //         $count: "%24count=true",
            //         $expand: "",
            //         $top: "",
            //         $skip: ``,
            //         $filter: ``,
            //         $orderby: "",
            //     };

            //     const _query = QueryGetAll(qryGetAll);

            //     const resLcoId = await defaultApi.getLocalGrpId(id, '')
            //     defaultLcoID(resLcoId.data);
            //     console.log(resLcoId);
            // }

            // let qryGetById = {
            //     $expand: "%24expand=userGroup%2CuserLocalGroup%2CuserOrg",
            //     $filter: "",
            //     $orderby: "",
            // };

            // const _query = QueryGetByID(qryGetById)

            // const resLcoId = await defaultApi.getLocalGrpId(id)
            // defaultLcoID(resLcoId.data);

            const resOrg = await defaultApi.getOrgId(id);
            // const resLocalGrp = await defaultApi.getLocalGrp();

            defaultLcoID(resOrg.data);

            const resSta = await defaultApi.getGbStatus(``)
            let arr = []
            let i = 0
            for (let index = 0; index < resSta?.data.length; index++) {
                const element = resSta?.data[index];
                if (element?.id === 'active' || element?.id === 'disable') {
                    arr[i] = element
                    i++
                }
            }

            setStatus(arr)
            //defaultOrg(resOrg.data);
            // defaultLocalGrp(resLocalGrp.data);


        } catch (error) {
            console.log(error);
        }
    }, [datain])

    const onChangeGrp = (val) => {
        settxtInput01(val)
        // if (EditSubGrp01(val, _defaultLocalGrp) === null) {
        //     setValidErr01(false)
        // } else {
        //     setValidErr01(true)
        // }
    }

    const onChangeStatus = (val) => {
        settxtStatus(val)
    }

    const submitEdit = async (afterClick) => {

        // const chkDataId01 = () => {
        //     let i = null
        //     _defaultOrg.map((data) => {
        //         if (data.name === txtInput02) {
        //             i = data.id
        //         }
        //     })
        //     return i
        // }

        //&& validErr02 === false
        if (validErr01 === false) {

            // let grpId = null
            // grpId = chkDataId()
            const loading = toast.loading(Message.notify.update_loading);
            //&& chkDataId01() !== null
            const params = {}

            await Object.keys(_defaultLcoID).forEach((key) => {
                switch (key) {
                    case "id":
                        params["id"] = datain.id
                        break;
                    case "name":
                        params["name"] = txtInput01 === "" ? datain.subname : txtInput01
                        break;
                    case "status":
                        params["status"] = txtStatus === "" ? datain.status : txtStatus
                        break;
                    default:
                        params[key] = _defaultLcoID[key]
                        break;
                }
            })

            try {
                await defaultApi.updateOrg(datain.id, params)
                toast.success(Message.notify.update_success, { duration: 3000 });
                infoDatax()
                $('#' + afterClick).trigger('click');
            } catch (error) {
                toast.error(Message.notify.update_error, { duration: 3000 });
                console.log(error)
            } finally {
                toast.dismiss(loading);
            }

        }
    }

    useEffect(() => {
        if (Number.isInteger(datain.id)) {
            infoData(datain.id)
        }
    }, [infoData, datain.id])

    return (
        <div
            className="modal fade"
            id={idForm}
            //key={"modal" + idForm}
            //id="exampleModal2"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"

        //className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <h1 className={`modal-title mt-3 fs-5 ${styles.h5}`} id="exampleModalLabel">แก้ไขหน่วยงาน</h1>
                        <hr className={`w-100 me-1 ms-1 border border-dark-subtle`} />
                    </div>
                    <div className="modal-body ">
                        <form id={"form" + idForm} className={`${styles.form_} mx-auto`}>
                            <div className={`${styles.input_flex}`}>
                                <div className={`${styles.box_error} d-flex flex-row justify-content-between m-2 ${styles.input_}`}>
                                    <h5 className={`col-5 ${styles.h5}`}>ชื่อหน่วยงาน : </h5>
                                    <input className={`col ${validErr01 && txtInput01 === "" ? styles.error : null}`}
                                        id={"input1" + idForm}
                                        //id="input1"
                                        type="text"
                                        defaultValue={datain.subname}
                                        onChange={(e) => { onChangeGrp(e.target.value) }}
                                        //value={txtInput01 === "" ? datain.subname : txtInput01}
                                        name="subgrpname" />
                                </div>
                                {validErr01 && (
                                    <div className={`${styles.box_error} w-100 d-flex justify-content-center`}>
                                        <small className={`${styles.err_message}`}>
                                            {/* {EditSubGrp01(txtInput01, _defaultLocalGrp)} */}
                                        </small>
                                    </div>
                                )}
                            </div>
                            <div className={`${styles.input_flex}`}>
                                <div className={`${styles.box_error} d-flex flex-row justify-content-between m-2 ${styles.input_}`}>
                                    <h5 className={`col-5 ${styles.h5}`}>สถานะการมองเห็น : </h5>
                                    <select
                                        id={"input2" + idForm}
                                        value={txtStatus === '' ? datain.status : txtStatus}
                                        name="iconstate"
                                        onChange={(e) => { onChangeStatus(e.target.value) }}
                                        className={`col`}>
                                        {_status?.map((datax, index) => {
                                            return (
                                                <option value={datax?.id} key={'status' + index}>{datax?.statusName}</option>
                                            )
                                        })}
                                        {/* <option value={`active`}>เปิดการมองเห็น ✔️</option>
                                        <option value={`disable`}>ปิดการมองเห็น ✖️</option> */}
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className={`d-flex justify-content-center mb-3 ${styles.boxpopup}`}>
                        <button className={`${styles.savechangebtn}`} onClick={() => { submitEdit("btnCloseForm2") }}><h4>บันทึก</h4></button><h4>|</h4><button id="btnCloseForm2" className={`${styles.savechangebtn}`} onClick={() => { settxtInput01(""); $("#form" + idForm).trigger('reset') }} data-bs-dismiss="modal"><h4>ยกเลิก</h4></button>
                    </div>
                </div>
            </div>
        </div>
    )
}