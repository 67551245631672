export const QueryGetAll = (Obj) => {
  const query = {};
  const queryArr = [];
  Object.keys(Obj).forEach((key) => {
    if (key === "$count") {
      query[key] = Obj[key] === "" ? "" : Obj[key];
    } else if (key === "$expand") {
      query[key] = Obj[key] === "" ? "" : Obj[key];
    } else if (key === "$top") {
      query[key] = Obj[key] === "" ? "" : Obj[key];
    } else if (key === "$skip") {
      query[key] = Obj[key] === "" ? "" : Obj[key];
    } else if (key === "$filter") {
      query[key] = Obj[key] === "" ? "" : Obj[key];
    } else if (key === "$orderby") {
      query[key] = Obj[key] === "" ? "" : Obj[key];
    }
  });
  Object.values(query).forEach((val) => {
    queryArr.push(val);
  });

  return queryArr.join("");
};

export const QueryGetByID = (Obj) => {
  const query = {};
  const queryArr = [];
  Object.keys(Obj).forEach((key) => {
    if (key === "$expand") {
      query[key] = Obj[key] === "" ? "" : Obj[key];
    } else if (key === "$filter") {
      query[key] = Obj[key] === "" ? "" : Obj[key];
    } else if (key === "$orderby") {
      query[key] = Obj[key] === "" ? "" : Obj[key];
    }
  });
  Object.values(query).forEach((val) => {
    queryArr.push(val);
  });

  return queryArr.join("");
};
