import React from "react";
import { useSelector } from "react-redux";
import icon from "../../../../assets/img/Icon";
import { getFactoryData } from "../../../../services/factory.slice";
import { createUrlImage } from "../../../../helpers/func.helper";
import styles from "./scss/PowerPlantsDetails.module.scss";

const PowerPlantsDetails = ({ _allEnterprise }) => {
  const factoryData = useSelector(getFactoryData);

  return (
    <div className={`${styles.container}`}>
      <h4 className={`${styles.title_text}`}>{factoryData?.name}</h4>
      <div className={`${styles.img_box}`}>
        <div className={`${styles.img_}`}>
          {factoryData?.image ? (
            <img
              className={`${styles.img_true}`}
              src={createUrlImage(factoryData?.imageNavigation?.filePath)}
              alt=""
            />
          ) : (
            <img className={`${styles.img_false}`} src={icon.pic} alt="" />
          )}
        </div>
      </div>
      {/* Details list */}
      <div className={`${styles.details_container}`}>
        <div className={`${styles.details_list}`}>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>ชื่อโรงไฟฟ้า</p>
            <p className={`${styles.list_value} col-8`}>
              {factoryData?.name || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>สถานะ</p>
            <p className={`${styles.list_value} col-8`}>
              {factoryData?.factoryStatus?.name || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>ประเภทโรงไฟฟ้า</p>
            <p className={`${styles.list_value} col-8`}>
              {factoryData?.factoryType?.name || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>ที่ตั้ง</p>
            <p className={`${styles.list_value} col-8`}>
              {factoryData?.address || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>
              กำลังการผลิตไฟฟ้า
            </p>
            <p className={`${styles.list_value} col-8 text_unit`}>
              {factoryData?.capacityProductionMonth !== null
                ? Number(factoryData?.capacityProductionMonth).toFixed(2)
                : "-"}{" "}
              <span>MW</span>
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>กลุ่มวิสาหกิจชุมชน</p>
            <p className={`${styles.list_value} col-8`}>
              {_allEnterprise.map((item) => item.name).join(",")}
            </p>
          </div>
        </div>
      </div>
      {/* End */}
    </div>
  );
};

export default PowerPlantsDetails;
