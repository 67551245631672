import React, { useMemo } from "react";

const InfoList = ({ _allFactory, _themeColor }) => {
  const factoryLength = useMemo(() => {
    const total = _allFactory?.length;
    return total;
  }, [_allFactory]);
  const factoryCapacity = useMemo(() => {
    const data = _allFactory?.map((item) => item.capacityProductionMonth);
    const filter = data?.filter(
      (item) => item !== null || item !== undefined || item !== ""
    );
    const total = filter.reduce((acc, curr) => acc + curr, 0);
    return total;
  }, [_allFactory]);
  return (
    <>
      <div className="info_count">
        <h5 style={{ color: _themeColor?.color }}>
          จำนวนโรงไฟฟ้าชุมชน(ทั้งหมด)
        </h5>
        <p>
          {factoryLength} <span>โรง</span>
        </p>
      </div>
      <div className="info_power">
        <h5 style={{ color: _themeColor?.color }}>กำลังการผลิตไฟฟ้ารวม</h5>
        <p>
          {Number(factoryCapacity).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <span>MW</span>
        </p>
      </div>
    </>
  );
};

export default InfoList;
