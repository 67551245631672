import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import icon from "../../../../../assets/img/Icon";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../../utils/format/Date.format";
import { getFactoryData } from "../../../../../services/factory.slice";
import { defaultApi } from "../../../../../services/api";
import { QueryGetAll } from "../../../../../helpers/api.helper";
import {
  CheckNumberDot,
  CheckValidLength,
} from "../../../../../utils/validation_input/Validation";
import { getEnterpriseData } from "../../../../../services/enterprise.slice";
import Message from "../../../../../utils/message/Message";
import styles from "./scss/EditSaleForm.module.scss";

const initInputArr = {
  vals: [
    {
      i1name: "i1name1" /* ชนิดเชื้อเพลิง-วัตถุดิบ */,
      i1val: "",
      i1valName: "i1valName1",
      i2name: "i2name1" /* ปริมาณการรับซื้อภายในกลุ่ม (ตัน) */,
      i2val: "",
      i2valName: "i2valName1",
      i3name: "i3name1" /* ปริมาณการรับซื้อภายนอกกลุ่ม (ตัน) */,
      i3val: "",
      i3valName: "i3valName1",
      i4name: "i4name1" /* ชีวมวล */,
      i4val: "",
      i4valName: "i4valName1",
    },
  ],
};

const EditForm = ({ _transaction, _onSwitch, _fetchData }) => {
  const factoryData = useSelector(getFactoryData);
  const enterpriseData = useSelector(getEnterpriseData);

  const [_data, setData] = useState({});
  const [allPlant, setAllPlant] = useState([]);
  const [allPlantSub, setAllPlantSub] = useState([]);
  const [allContractF, setAllContractF] = useState([]);
  const [pdfName, setPdfName] = useState("");
  const [pdfName2, setPdfName2] = useState("");
  const [_amount, setAmount] = useState("");
  const [selectedDate, setSelectedDate] = useState({
    atDate: new Date(),
    toDate: new Date(),
    payDate: new Date(),
  });
  const [stateInputList, setStateInputList] = useState(initInputArr);
  const [openAtDate, setOpenAtDate] = useState(false);
  const [openToDate, setOpenToDate] = useState(false);
  const [openPayDate, setOpenPayDate] = useState(false);
  const [validErr, setValidErr] = useState(false);

  /* DatePicker : START */
  const onChangeAtDate = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, atDate: dateFormat });
    setSelectedDate({ ...selectedDate, atDate: e });
    setOpenAtDate(!openAtDate);
  };
  const onChangeToDate = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, txDate: dateFormat });
    setSelectedDate({ ...selectedDate, toDate: e });
    setOpenToDate(!openToDate);
  };
  const onChangePayDate = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, payDate: dateFormat });
    setSelectedDate({ ...selectedDate, payDate: e });
    setOpenPayDate(!openPayDate);
  };
  /* DatePicker : END */

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };

  const createFileDetail = async (e, text) => {
    console.log("createFile");
    const file = document.querySelector("#add_file");
    const file2 = document.querySelector("#add_file_2");

    const loading = toast.loading(Message.notify.add_file_loading);
    const param1 = "Transaction";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);

    try {
      const res = await defaultApi.createFileDetail(param1, param2);
      console.log(res.statusText);

      if (text === "ใบแจ้งหนี้") {
        setData({ ..._data, receipt: res?.data.id });
      } else {
        setData({ ..._data, weight: res?.data.id });
      }
    } catch (error) {
      console.log(error);
    } finally {
      file.value = "";
      file2.value = "";
      toast.dismiss(loading);
    }
  };
  const updateFileDetail = async (e, text) => {
    console.log("updateFile");
    const file = document.querySelector("#add_file");
    const file2 = document.querySelector("#add_file_2");

    if (!window.confirm(Message.notify.add_file_update)) {
      if (text === "ใบแจ้งหนี้") {
        file.value = "";
        setPdfName("");
        return;
      } else {
        file2.value = "";
        setPdfName2("");
        return;
      }
    }
    if (!e.target.files.length) {
      if (text === "ใบแจ้งหนี้") {
        return setData({
          ..._data,
          receipt: _data?.receipt,
        });
      } else {
        return setData({
          ..._data,
          weight: _data?.receipt,
        });
      }
    }

    const loading = toast.loading(Message.notify.add_file_loading);
    const _id = text === "ใบแจ้งหนี้" ? _data?.receipt : _data?.weight;
    const param1 = "Transaction";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);

    try {
      const res = await defaultApi.updateFileDetail(_id, param1, param2);
      console.log(res.statusText);

      if (text === "ใบแจ้งหนี้") {
        setData({ ..._data, receipt: res?.data.id });
        file.value = "";
      } else {
        setData({ ..._data, weight: res?.data.id });
        file2.value = "";
      }

      toast.success(Message.notify.update_success);
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error);

      if (text === "ใบแจ้งหนี้") {
        file.value = "";
        setPdfName("");
        return;
      } else {
        file2.value = "";
        setPdfName2("");
        return;
      }
    } finally {
      toast.dismiss(loading);
    }
  };
  const updateTransaction = async (purchaseFormDatas) => {
    if (CheckValidLength(_data?.contractFarmingId)) return setValidErr(true);
    if (CheckNumberDot(_amount))
      return toast.error("กรุณากรอกข้อมูลปริมาณการรับซื้อ", { duration: 3000 });
    if (CheckNumberDot(_data?.total)) return setValidErr(true);

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "enterpriseId") {
        params[key] = +_data[key];
      } else if (key === "contractFarmingId") {
        params[key] = +_data[key];
      } else if (key === "plantTypeId") {
        params[key] = null;
      } else if (key === "amount") {
        params[key] = +_amount;
      } else if (key === "total") {
        params[key] = +_data[key];
      } else if (key === "datas") {
        params[key] = JSON.stringify(purchaseFormDatas);
      } else {
        params[key] = _data[key];
      }
    });

    try {
      const res = await defaultApi.updateTransaction(_data?.id, params);
      console.log(res.statusText);
      _fetchData();
      _onSwitch(false);
      toast.success(Message.notify.update_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  const onFormatData = () => {
    let datas = [];

    for (let i = 0; i < stateInputList.vals.length; i++) {
      const el = stateInputList.vals[i];

      let newData = {
        plant_type_id: el.i1val,
        plant_name: allPlant.find((val) => val.id === +el.i1val)?.name,
        buy_amount_member: el.i2val,
        buy_amount_other: el.i3val,
        plant_parts_id: +el.i4val || null,
        plant_parts_name:
          allPlantSub.find((val) => val.id === +el.i4val)?.name || null,
      };

      datas.push(newData);
    }

    return datas;
  };
  const onConfirm = () => {
    const purchaseFormDatas = onFormatData();

    if (window.confirm("ต้องการที่จะบันทึกข้อมูลหรือไม่?")) {
      updateTransaction(purchaseFormDatas);
    }
  };

  const getMultipleApi = useCallback(async () => {
    if (!factoryData?.id) return;

    let factoryId = factoryData?.id;
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=contractFarmingDocNavigation",
      $top: "",
      $skip: "",
      $filter: `&%24filter=factoryId%20eq%20${factoryId}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const [allPlant, allContractF] = await Promise.all([
        defaultApi.getAllPlantType(),
        defaultApi.getAllContractFarming(_queryAll),
      ]);
      const extractPlantParts = (data) => {
        return data.reduce((acc, item) => {
          const parts = item.plantParts.map((part) => ({
            id: part.id,
            name: part.name,
            plantId: part.plantId,
          }));
          return acc.concat(parts);
        }, []);
      };
      const plantPartsArray = extractPlantParts(allPlant.data);

      setAllPlant(allPlant.data);
      setAllPlantSub(plantPartsArray);
      setAllContractF(allContractF.data.datas);
    } catch (error) {
      console.log(error);
    }
  }, [factoryData]);

  /* Function multiple-input : START */
  const onCreateInputs = () => {
    const number = stateInputList?.vals?.length + 1;
    const inputForm = {
      i1name: `i1name${number}` /* ชนิดเชื้อเพลิง-วัตถุดิบ */,
      i1val: "",
      i1valName: `i1valName${number}`,
      i2name: `i2name${number}` /* ปริมาณการรับซื้อภายในกลุ่ม (ตัน) */,
      i2val: "",
      i2valName: `i2valName${number}`,
      i3name: `i3name${number}` /* ปริมาณการรับซื้อภายนอกกลุ่ม (ตัน) */,
      i3val: "",
      i3valName: `i3valName${number}`,
      i4name: `i4name${number}` /* ชีวมวล */,
      i4val: "",
      i4valName: `i4valName${number}`,
    };

    setStateInputList({ vals: stateInputList.vals.concat(inputForm) });
  };
  const onDeleteInput = (index) => {
    if (stateInputList.vals.length <= 1) return;

    setStateInputList({
      vals: stateInputList.vals.filter((_, i) => i !== index),
    });
  };
  /* Function multiple-input : END */

  const getFilePdfName = () => {
    const file = document.querySelector("#add_file");
    const file2 = document.querySelector("#add_file_2");

    file?.addEventListener("change", () => {
      if (file.value) {
        setPdfName(file.files[0].name);
      } else {
        setPdfName("");
      }
    });

    file2?.addEventListener("change", () => {
      if (file2.value) {
        setPdfName2(file2.files[0].name);
      } else {
        setPdfName2("");
      }
    });
  };

  /* คำนวณผลรับของ input จำนวน(ตัน) : START */
  const calculateSums = (data) => {
    const initialValue = { i2valSum: 0, i3valSum: 0, totalSum: 0 };
    return data.vals.reduce((acc, current) => {
      const i2val = parseFloat(current.i2val) || 0;
      const i3val = parseFloat(current.i3val) || 0;
      acc.i2valSum += i2val;
      acc.i3valSum += i3val;
      acc.totalSum += i2val + i3val;
      return acc;
    }, initialValue);
  };
  /* คำนวณผลรับของ input จำนวน(ตัน) : END */

  useEffect(() => {
    const sums = calculateSums(stateInputList);

    setAmount(sums?.totalSum);
  }, [stateInputList]);

  useEffect(() => {
    if (_transaction?.datas) {
      const formData = JSON.parse(_transaction?.datas);

      if (formData || formData?.length !== 0) {
        let inputArr = { vals: [] };

        for (let i = 0; i < formData.length; i++) {
          const el = formData[i];
          const inputForm = {
            i1name: `i1name${i + 1}` /* ชนิดเชื้อเพลิง-วัตถุดิบ */,
            i1val: +el?.plant_type_id,
            i1valName: `i1valName${i + 1}`,
            i2name: `i2name${i + 1}` /* ปริมาณการรับซื้อภายในกลุ่ม (ตัน) */,
            i2val: el?.buy_amount_member,
            i2valName: `i2valName${i + 1}`,
            i3name: `i3name${i + 1}` /* ปริมาณการรับซื้อภายนอกกลุ่ม (ตัน) */,
            i3val: el?.buy_amount_other,
            i3valName: `i3valName${i + 1}`,
            i4name: `i4name${i + 1}` /* ชีวมวล */,
            i4val: +el?.plant_parts_id || "",
            i4valName: `i4valName${i + 1}`,
          };

          inputArr.vals.push(inputForm);
        }

        setStateInputList(inputArr);
      }
    }
  }, [_transaction]);

  useEffect(() => {
    // Use functions
    setData(_transaction);
    getMultipleApi();
    getFilePdfName();
  }, [_transaction, getMultipleApi]);

  useEffect(() => {
    /* Function close dropdown 'AtDate and ToDate and PayDate' : START */
    const onCloseDropdownAtDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_at_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenAtDate(false);
      }
    };
    const onCloseDropdownToDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_to_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenToDate(false);
      }
    };
    const onCloseDropdownPayDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_pay_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenPayDate(false);
      }
    };

    if (openAtDate) {
      document.addEventListener("click", onCloseDropdownAtDate);
    }
    if (openToDate) {
      document.addEventListener("click", onCloseDropdownToDate);
    }
    if (openPayDate) {
      document.addEventListener("click", onCloseDropdownPayDate);
    }
    /* Function close dropdown 'AtDate and ToDate and PayDate' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownAtDate);
      document.removeEventListener("click", onCloseDropdownToDate);
      document.removeEventListener("click", onCloseDropdownPayDate);
    };
  }, [openAtDate, openToDate, openPayDate]);

  return (
    <>
      <div className={`${styles.input_form}`}>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>วันที่ (เริ่มต้น)</h5>
            <div className={`${styles.input_unit}`}>
              <div className={`${styles.info_box}`}>
                <p>
                  {_data?.atDate !== null ? convertDateThai(_data?.atDate) : ""}
                </p>
              </div>
              <div
                className={`${styles.unit}`}
                onClick={() => {
                  setOpenAtDate(!openAtDate);
                }}
              >
                <img src={icon.calendar} alt="" id="ent_at_date_d" />
              </div>
            </div>
            {openAtDate && (
              <DatePicker
                selected={selectedDate.atDate}
                onChange={onChangeAtDate}
                inline
              />
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <h5>วันที่ (สิ้นสุด)</h5>
            <div className={`${styles.input_unit}`}>
              <div className={`${styles.info_box}`}>
                <p>
                  {_data?.txDate !== null ? convertDateThai(_data?.txDate) : ""}
                </p>
              </div>
              <div
                className={`${styles.unit}`}
                onClick={() => {
                  setOpenToDate(!openToDate);
                }}
              >
                <img src={icon.calendar} alt="" id="ent_to_date_d" />
              </div>
            </div>
            {openToDate && (
              <DatePicker
                selected={selectedDate.toDate}
                onChange={onChangeToDate}
                inline
              />
            )}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>โรงไฟฟ้า</h5>
            <div className={`${styles.input_unit}`}>
              <div className={`${styles.info_box}`}>
                <p>{_data?.buyer}</p>
              </div>
            </div>
          </div>
          <div className={`${styles.input_2}`}>
            <h5>สัญญาเลขที่</h5>
            <select
              name="contractFarmingId"
              className={`${
                validErr && _data?.contractFarmingId === "" ? "error" : ""
              }`}
              onChange={onChange}
            >
              <option style={{ display: "none" }}>
                {_data?.contractFarming?.no}
              </option>
              {allContractF
                .filter((item) => item.enterpriseId === +enterpriseData?.id)
                .map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.no}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <MultipleInputGroups
          allPlantOpt={allPlant}
          allPlantSubOpt={allPlantSub}
          stateInputList={stateInputList}
          setStateInputList={setStateInputList}
          onDelete={onDeleteInput}
        />
        <button
          type="button"
          className={`${styles.btn_create_input}`}
          onClick={onCreateInputs}
        >
          เพิ่มเชื้อเพลิง/วัตถุดิบ
        </button>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>จำนวนรวม (ตัน)</h5>
            <div className={`${styles.input_unit}`}>
              <div className={`${styles.info_box}`}>
                <p>{_amount !== "" ? Number(_amount).toFixed(2) : ""}</p>
              </div>
            </div>
          </div>
          <div className={`${styles.input_2}`}>
            <h5>
              ยอดรวมสุทธิ(บาท) <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <input
              type="text"
              name="total"
              value={String(_data?.total)}
              className={`${validErr && _data?.total === "" ? "error" : ""}`}
              onChange={onChange}
            />
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckNumberDot(_data?.total)}
              </p>
            )}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>วันที่รับเงิน</h5>
            <div
              className={`${styles.input_unit} ${
                validErr && _data?.payDate === "" ? "error" : ""
              }`}
            >
              <div className={`${styles.info_box}`}>
                <p>
                  {_data?.payDate !== null
                    ? convertDateThai(_data?.payDate)
                    : ""}
                </p>
              </div>
              <div
                className={`${styles.unit}`}
                onClick={() => {
                  setOpenPayDate(!openPayDate);
                }}
              >
                <img src={icon.calendar} alt="" id="ent_pay_date_d" />
              </div>
            </div>
            {openPayDate && (
              <DatePicker
                selected={selectedDate.payDate}
                onChange={onChangePayDate}
                inline
              />
            )}
          </div>
          <div className={`${styles.input_2}`}>
            <div className={`${styles.input_file}`}>
              <h5>ใบแจ้งหนี้</h5>
              <div
                className={`${styles.input_unit} ${styles.mb_0} ${
                  validErr && _data?.receipt === "" ? "error" : ""
                }`}
              >
                <div className={`${styles.info_box}`}>
                  <p>
                    {pdfName !== ""
                      ? pdfName
                      : _data?.transactionReceiptNavigation?.fileName}
                  </p>
                </div>
                <div className={`${styles.unit}`}>
                  <img
                    src={icon.document}
                    alt=""
                    title="แนบไฟล์"
                    onClick={() =>
                      document.querySelector("#file-label").click()
                    }
                  />
                </div>
                <input
                  type="file"
                  name="receipt"
                  id="add_file"
                  accept="application/pdf"
                  onChange={(e) => {
                    if (_data?.receipt !== null) {
                      updateFileDetail(e, "ใบแจ้งหนี้");
                    } else {
                      createFileDetail(e, "ใบแจ้งหนี้");
                    }
                  }}
                  hidden
                />
                <label htmlFor="add_file" id="file-label" hidden></label>
              </div>
              <span className={`${styles.desc_text}`}>
                *ระบบรองรับไฟล์ที่มีนามสกุล .pdf และมีขนาดไม่เกิน 5 MB
              </span>
            </div>
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}></div>
          <div className={`${styles.input_2}`}>
            <div className={`${styles.input_file}`}>
              <h5>รายงานการชั่งน้ำหนัก</h5>
              <div
                className={`${styles.input_unit} ${styles.mb_0} ${
                  validErr && _data?.weight === "" ? "error" : ""
                }`}
              >
                <div className={`${styles.info_box}`}>
                  <p>
                    {pdfName2 !== ""
                      ? pdfName2
                      : _data?.transactionWeightNavigation?.fileName}
                  </p>
                </div>
                <div className={`${styles.unit}`}>
                  <img
                    src={icon.document}
                    alt=""
                    title="แนบไฟล์"
                    onClick={() =>
                      document.querySelector("#file-label-2").click()
                    }
                  />
                </div>
                <input
                  type="file"
                  name="weight"
                  id="add_file_2"
                  accept="application/pdf"
                  onChange={(e) => {
                    if (_data?.weight !== null) {
                      updateFileDetail(e, "รายงานการชั่งน้ำหนัก");
                    } else {
                      createFileDetail(e, "รายงานการชั่งน้ำหนัก");
                    }
                  }}
                  hidden
                />
                <label htmlFor="add_file_2" id="file-label-2" hidden></label>
              </div>
              <span className={`${styles.desc_text}`}>
                *ระบบรองรับไฟล์ที่มีนามสกุล .pdf และมีขนาดไม่เกิน 5 MB
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.button_center}`}>
        <div className="button_sm" onClick={onConfirm}>
          บันทึก
        </div>
        <div className="button_sm" onClick={() => _onSwitch(false)}>
          ยกเลิก
        </div>
      </div>
    </>
  );
};

const MultipleInputGroups = ({
  allPlantOpt,
  allPlantSubOpt,
  stateInputList,
  setStateInputList,
  onDelete,
}) => {
  const onChangeVal = (e, valName) => {
    const { name, value } = e.target;
    const [nameKey, valueKey] = [name.slice(0, 6), valName?.slice(0, 5)];
    let currentInput = stateInputList?.vals.find(
      (val) => val[nameKey] === name
    );

    if (!currentInput) {
      currentInput = {
        [nameKey]: name,
        [valueKey]: value,
      };
    } else {
      currentInput = {
        ...currentInput,
        [valueKey]: value,
      };
    }

    setStateInputList((prevState) => ({
      ...prevState,
      vals: prevState.vals.map((val) =>
        val[nameKey] === currentInput[nameKey] ? currentInput : val
      ),
    }));
  };

  return stateInputList?.vals?.map((item, index, array) => {
    const _allPlantSubOpt = allPlantSubOpt?.filter(
      (item2) => +item2.plantId === +item.i1val
    );

    return (
      <div key={index} className={`${styles.multiple_input_box}`}>
        {array.length <= 1 ? null : (
          <button
            type="button"
            className={`${styles.btn_delete_input}`}
            onClick={() => onDelete(index)}
          >
            ลบ
          </button>
        )}
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>
              ชนิดเชื้อเพลิง / วัตถุดิบ{" "}
              <sup className={`${styles.asterisk}`}>*</sup>
            </h5>
            <select
              name={item.i1name}
              onChange={(e) => onChangeVal(e, item.i1valName)}
            >
              <option style={{ display: "none" }}>เลือกชนิดเชื้อเพลิง</option>
              {allPlantOpt?.map((option) => {
                return (
                  <option
                    selected={item.i1val === option.id ? true : false}
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={`${styles.input_2}`}>
            {_allPlantSubOpt?.length !== 0 ? (
              <>
                <h5>ชีวมวล</h5>
                <select
                  name={item.i4name}
                  onChange={(e) => onChangeVal(e, item.i4valName)}
                >
                  <option
                    selected={item.i4val === ""}
                    style={{ display: "none" }}
                  >
                    เลือกชีวมวล
                  </option>
                  {_allPlantSubOpt?.map((val) => {
                    return (
                      <option
                        selected={val.id === item.i4val ? true : false}
                        key={val.id}
                        value={val.id}
                      >
                        {val.name}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : null}
          </div>
        </div>
        <div className={`${styles.input_flex}`}>
          <div className={`${styles.input_1}`}>
            <h5>ปริมาณการรับซื้อภายในกลุ่ม (ตัน)</h5>
            <input
              type="text"
              name={item.i2name}
              value={item.i2val}
              onChange={(e) => onChangeVal(e, item.i2valName)}
              pattern={/^\d*\.?\d*$/}
            />
          </div>
          <div className={`${styles.input_2}`}>
            <h5>ปริมาณการรับซื้อภายนอกกลุ่ม (ตัน)</h5>
            <input
              type="text"
              name={item.i3name}
              value={item.i3val}
              onChange={(e) => onChangeVal(e, item.i3valName)}
              pattern={/^\d*\.?\d*$/}
            />
          </div>
        </div>
      </div>
    );
  });
};

export default EditForm;
