import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import WKT from "ol/format/WKT";
import { transform } from "ol/proj";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { Fill, Stroke, Style, Text } from "ol/style";
import MapElement from "../../../_partials/MapElement";
import AddForm from "./AddForm";
import EditForm from "./EditForm";
import icon from "../../../../../../assets/img/Icon";
import Message from "../../../../../../utils/message/Message";
import { defaultApi } from "../../../../../../services/api";
import useSwitch from "../../../../../../utils/hooks/useSwitch";
import {
  CheckCoordArrPolygon,
  CheckNumberDot,
  CheckValidLength,
} from "../../../../../../utils/validation_input/Validation";
import { QueryGetAll } from "../../../../../../helpers/api.helper";
import { convertWKT } from "../../../../../../helpers/map.helper";
import { getEnterpriseData } from "../../../../../../services/enterprise.slice";
import { downloadFilePdf } from "../../../../../../helpers/func.helper";
import styles from "./scss/AddDeed.module.scss";

const initData = {
  parcelNo: "",
  area: "",
  farmerId: 0,
  parcelDoc: "",
  address: "",
  coordinate: "",
  parcelId: "",
  polygon: "",
};

const initInputArrAdd = {
  vals: [
    {
      coorName: "coord1",
      coorVal: "",
    },
    { coorName: "coord2", coorVal: "" },
    { coorName: "coord3", coorVal: "" },
  ],
};

const AddDeed = ({ farmerData, _onSwitch3 }) => {
  const enterpriseData = useSelector(getEnterpriseData);

  const [_switch, onSwitch, _switch2, onSwitch2] = useSwitch();

  const [_map, setMap] = useState(null);
  const [_data, setData] = useState(initData);
  const [parcelData, setParcelData] = useState({});
  const [parcelId, setParcelId] = useState(null);
  const [allParcelId, setAllParcelId] = useState([]);
  const [allParcelArea, setAllParcelArea] = useState([]);
  const [allCropByParcelId, setAllCropByParcelId] = useState([]);
  const [allCropArea, setAllCropArea] = useState([]);
  const [totalPlantAreaByCrop, setTotatPlantAreaByCrop] = useState([]);
  const [parcelCoord, setParcelCoord] = useState([]);
  const [coordPolygon, setCoordPolygon] = useState(null);
  const [inputListAdd, setInputListAdd] = useState(initInputArrAdd);
  const [inputListEdit, setInputListEdit] = useState({
    vals: [],
  });
  const [geomParcel, setGeomParcel] = useState(null);
  const [validErr, setValidErr] = useState(false);

  const _createLayerParcel = useCallback(
    (data) => {
      /* Clear crop layers : START */
      _map
        ?.getLayers()
        .getArray()
        .filter((layer) => layer.get("parcelId"))
        .forEach((layer) => _map.removeLayer(layer));
      /* Clear crop layers : END */

      /* Create parcel layers : START */
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        const wkt = `${element.geom}`;
        const format = new WKT();
        const feature = format.readFeature(wkt, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857",
        });
        const vector = new VectorLayer({
          source: new VectorSource({
            features: [feature],
            loader: () => {
              feature.setProperties({
                parcelId: element.id,
                parcelIdTx: element.parcelId,
              });
            },
          }),
          style: (feature) => {
            return [
              new Style({
                stroke: new Stroke({
                  color: "orange",
                  width: 2,
                }),
                fill: new Fill({
                  color: "rgba(44, 236, 174, 0.6)",
                }),
                text: new Text({
                  font: "15px roboto,sans-serif",
                  text: feature.getProperties().parcelIdTx, // Text to display
                  fill: new Fill({
                    color: "black", // Text color
                  }),
                  stroke: new Stroke({
                    color: "white", // Text stroke color
                    width: 2, // Text stroke width
                  }),
                }),
              }),
            ];
          },
        });
        vector.setProperties({
          parcelId: element.id,
        });
        _map?.addLayer(vector);
      }
      /* Create parcel layers : END */
    },
    [_map]
  );

  const getAllParcel = useCallback(async () => {
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=farmerId%20eq%20${farmerData.id}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllParcel(_queryAll);
      const getParcelId = res.data.datas.map((item) => item.parcelId);
      const getArea = res.data.datas.map((item) => item.area);
      const filter = res.data.datas.filter(
        (item) => item.geom !== null && item.status === "active"
      );
      _createLayerParcel(filter);
      setAllParcelArea(getArea);
      setAllParcelId(getParcelId);
      if (filter.length !== 0) {
        setGeomParcel(filter[0].geom);
      }
    } catch (error) {
      console.log(error);
    }
  }, [farmerData, _createLayerParcel]);
  const getAllCrop = useCallback(async () => {
    if (!farmerData.id) return;

    const qryGetAll = {
      $count: "%24count=true",
      $expand: "&%24expand=parcel%2CplantType",
      $top: "",
      $skip: "",
      $filter: `&%24filter=farmerId%20eq%20${farmerData.id}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllCrop(_queryAll);
      const getArea = res.data.datas.map((item) => item.area);
      const getCropByParcelId = res.data.datas.filter(
        (item) => item.parcelId === parcelData.id
      );

      setAllCropByParcelId(getCropByParcelId);
      setAllCropArea(getArea);
    } catch (error) {
      console.log(error);
    }
  }, [farmerData, parcelData]);
  const getParcel = useCallback(async (id) => {
    if (!id) return;

    try {
      const res = await defaultApi.getParcel(id);
      const wktToLonLat = convertWKT(res.data.geom)?.map((item) => {
        const [lon, lat] = item;

        return [lat, lon];
      });

      setParcelData(res.data);
      setParcelCoord(wktToLonLat);
      setParcelId(res.data.id);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getPolygonArea = async (type) => {
    const coordData = type === "ADD" ? _data.polygon : coordPolygon;

    if (CheckCoordArrPolygon(coordData)) {
      toast.error(CheckCoordArrPolygon(coordData), {
        duration: 4000,
      });

      return;
    }

    if (coordData.length < 3) {
      return; //
    }

    const objs = coordData.map((item) => {
      const [lon, lat] = item;
      const toEPSG3857 = transform([lon, lat], "EPSG:4326", "EPSG:3857");

      return toEPSG3857;
    });

    const params = { geometry: objs };

    const loading = toast.loading(Message.notify.caculate_area_loading);

    try {
      const res = await defaultApi.getPolygonArea(params);

      // to area rai
      const rai = Math.floor((res.data.area / 1600) * 100) / 100; // แบบมีทศนิยม 2 ตำแหน่ง

      if (type === "ADD") {
        setData({ ..._data, area: rai });
      } else {
        setParcelData({ ...parcelData, area: rai });
      }

      toast.success(Message.notify.caculate_area_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.caculate_area_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };
  const createParcel = async () => {
    // Valid value all input
    if (CheckValidLength(_data.parcelNo)) return setValidErr(true);
    if (CheckNumberDot(_data.area)) return setValidErr(true);
    if (CheckValidLength(_data.parcelDoc)) return setValidErr(true);
    if (CheckValidLength(_data.address)) return setValidErr(true);
    if (CheckCoordArrPolygon(_data.polygon)) {
      toast.error(CheckCoordArrPolygon(_data.polygon), {
        duration: 4000,
      });

      return setValidErr(true);
    }
    if (CheckValidLength(_data.parcelId)) return setValidErr(true);

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "area") {
        params[key] = +_data[key];
      } else if (key === "farmerId") {
        params[key] = farmerData.id;
      } else if (key === "area") {
        params[key] = +_data.area;
      } else if (key === "parcelDoc") {
        params[key] = +_data[key];
      } else {
        params[key] = _data[key];
      }
    });
    Object.assign(params, {
      enterpriseId: enterpriseData.id,
      status: "active",
    });

    try {
      const res = await defaultApi.createParcel(params);
      console.log(res.statusText);

      toast.success(Message.notify.insert_success, { duration: 3000 });

      setData(initData);
      onSwitch(false);
      setValidErr(false);
      setInputListAdd({
        vals: [
          {
            coorName: "coord1",
            coorVal: "",
          },
          { coorName: "coord2", coorVal: "" },
          { coorName: "coord3", coorVal: "" },
        ],
      });
      /* Clear parcel layers : START */
      _map
        .getLayers()
        .getArray()
        .filter((layer) => layer.get("coord"))
        .forEach((layer) => _map.removeLayer(layer));
      /* Clear parcel layers : END */
      // fetch data
      getAllParcel();
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.insert_error, { duration: 3000 });
      toast.error(error.response.data.parcelNo[0], { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };
  const updateParcel = async () => {
    // Valid value all input
    if (CheckValidLength(parcelData.parcelNo)) return setValidErr(true);
    if (CheckNumberDot(parcelData.area)) return setValidErr(true);
    if (CheckValidLength(parcelData.address)) return setValidErr(true);
    if (CheckValidLength(parcelData.parcelId)) return setValidErr(true);
    if (CheckCoordArrPolygon(coordPolygon)) return setValidErr(true);

    const loading = toast.loading(Message.notify.loading);
    const _id = parcelId;
    const params = {};
    Object.keys(parcelData).forEach((key) => {
      if (key === "area") {
        params[key] = +parcelData[key];
      } else if (key === "parcelDoc") {
        params[key] = +parcelData[key];
      } else if (key === "geom") {
        params[key] = null;
      } else if (key === "polygon") {
        params[key] = coordPolygon;
      } else if (key === "enterpriseId") {
        params[key] = enterpriseData.id;
      } else {
        params[key] = parcelData[key];
      }
    });

    try {
      const res = await defaultApi.updateParcel(_id, params);

      console.log(res.statusText);

      setValidErr(false);
      onSwitch2(false);
      /* Clear parcel layers : START */
      _map
        .getLayers()
        .getArray()
        .filter((layer) => layer.get("coordEdit"))
        .forEach((layer) => _map.removeLayer(layer));
      /* Clear parcel layers : END */
      // fetch data
      getParcel(parcelId);
      getAllParcel();
      toast.success(Message.notify.update_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error);
    } finally {
      toast.dismiss(loading);
    }
  };
  const deleteParcel = async () => {
    if (!parcelId) return;
    if (!window.confirm(Message.notify.delete_parcel_confirm)) return;
    if (!window.confirm(Message.notify.delete_parcel_confirm2)) return;

    const loading = toast.loading(Message.notify.loading);
    try {
      const res = await defaultApi.deleteParcel(parcelId);
      console.log(res.statusText);

      getAllParcel();
      setParcelId(null);
      onSwitch2(false);
      toast.success(Message.notify.delete_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.delete_parcel_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  useEffect(() => {
    /* สร้าง multiple input จากข้อมูล parcelData.geom : START */
    let vals = [];
    for (let i = 0; i < parcelCoord.length; i++) {
      const element = parcelCoord[i];
      const name = `coord${i + 1}`;
      const value = element.join(",");
      const input = { coorName: name, coorVal: value };
      vals[i] = input;
    }
    // ตัด array ตัวสุดท้ายออก
    setInputListEdit({
      vals: vals.slice(0, vals.length - 1),
    });
    /* สร้าง multiple input จากข้อมูล parcelCoord : END */
  }, [parcelCoord]);

  useEffect(() => {
    /* จัดกลุ่มข้อมูลใหม่จากชนิดพืชและพื้นท่ีด้วยข้อมูลของ crop : START */
    let newData = [];
    for (let i = 0; i < allCropByParcelId.length; i++) {
      allCropByParcelId.forEach((item) => {
        let _name = item.plantType.name;
        let _value = item.area;
        let _obj = { name: _name, value: _value };
        newData[i] = _obj;
        ++i;
      });
    }
    /* จัดกลุ่มข้อมูลใหม่จากชนิดพืชและพื้นท่ีด้วยข้อมูลของ crop : END */

    /* นำกลุ่มข้อมูล(newData)ที่จัดแล้วมาหาผลรวม
    และรวมชื่อพืชที่ซ้ำกันให้เป็นชุดข้อมูลใหม่ : START */
    if (newData.length !== 0) {
      let result = [];
      newData.reduce((acc, curr) => {
        if (!acc[curr.name]) {
          acc[curr.name] = { name: curr.name, value: 0 };
          result.push(acc[curr.name]);
        }
        acc[curr.name].value += curr.value;
        return acc;
      }, {});
      setTotatPlantAreaByCrop(result);
    } else {
      setTotatPlantAreaByCrop([]);
    }
    /* นำกลุ่มข้อมูล(newData)ที่จัดแล้วมาหาผลรวม
    และรวมชื่อพืชที่ซ้ำกันให้เป็นชุดข้อมูลใหม่ : END */
  }, [allCropByParcelId]);

  useEffect(() => {
    // Use functions
    getAllParcel();
    getAllCrop();
  }, [getAllParcel, getAllCrop]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.left_}`}>
        <div className={`${styles.map_element}`}>
          <MapElement
            _onInit={(map) => setMap(map)}
            _fetchData={getParcel}
            _switch={_switch}
            _geom={geomParcel}
          />
          <div className={`${styles.button_top_right}`}>
            {!_switch2 && parcelId !== null && (
              <button
                type="button"
                className={`${styles.btn_}`}
                onClick={deleteParcel}
              >
                ลบโฉนด
              </button>
            )}
          </div>
          <div className={`${styles.button_bottom_center}`}>
            {/* ปุ่มสำหรับการเพิ่มโฉนด : START */}
            {!_switch && parcelId === null && (
              <>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={() => {
                    onSwitch(true);
                    setInputListAdd({
                      vals: [
                        {
                          coorName: "coord1",
                          coorVal: "",
                        },
                        { coorName: "coord2", coorVal: "" },
                        { coorName: "coord3", coorVal: "" },
                      ],
                    });
                  }}
                >
                  วาดแปลง
                </button>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={() => {
                    _onSwitch3(false);
                  }}
                >
                  กลับ
                </button>
              </>
            )}
            {_switch && parcelId === null && (
              <>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={createParcel}
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={() => {
                    onSwitch(false);
                    setValidErr(false);
                    setData(initData);
                  }}
                >
                  ยกเลิก
                </button>
              </>
            )}
            {/* ปุ่มสำหรับการเพิ่มโฉนด : END */}

            {/* ปุ่มสำหรับการแก้ไขโฉนด : START */}
            {!_switch2 && parcelId !== null && (
              <>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={() => {
                    onSwitch2(true);
                  }}
                >
                  แก้ไข
                </button>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={() => setParcelId(null)}
                >
                  ยกเลิก
                </button>
              </>
            )}
            {_switch2 && parcelId !== null && (
              <>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={updateParcel}
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={() => {
                    onSwitch2(false);
                    setValidErr(false);
                    getParcel();
                  }}
                >
                  ยกเลิก
                </button>
              </>
            )}
            {/* ปุ่มสำหรับการแก้ไขโฉนด : END */}
          </div>
        </div>
      </div>
      <div className={`${styles.right_}`}>
        <h4 className={`${styles.title_text}`}>
          {farmerData.firstname} {farmerData.lastname}
        </h4>
        {/* Overview Parcel : START */}
        {!_switch && parcelId === null && (
          <div className={`${styles.input_form}`}>
            <div className={`${styles.list_box} row mx-auto`}>
              <p className={`${styles.list_key} col-4`}>ชื่อเจ้าของที่ดิน</p>
              <p className={`${styles.list_value} col-8`}>
                {farmerData.title}
                {farmerData.firstname} {farmerData.lastname}
              </p>
            </div>
            <div className={`${styles.list_box} row mx-auto`}>
              <p className={`${styles.list_key} col-4`}>ที่ตั้ง</p>
              <p className={`${styles.list_value} col-8`}>
                {farmerData.address}
              </p>
            </div>

            {allParcelId.map((item, index) => {
              return (
                <div key={index} className={`${styles.list_box} row mx-auto`}>
                  <p className={`${styles.list_key} col-4`}>
                    รหัสแปลงที่ {index + 1}
                  </p>
                  <p className={`${styles.list_value} col-8`}>{item}</p>
                </div>
              );
            })}
            <div className={`${styles.list_box} row mx-auto`}>
              <p className={`${styles.list_key} col-4`}>พื้นที่ทั้งหมด</p>
              <p className={`${styles.list_value} col-8 text_unit`}>
                {allParcelArea
                  .reduce((acc, curr) => acc + curr, 0)
                  ?.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}{" "}
                <span>ไร่</span>
              </p>
            </div>
            <div className={`${styles.list_box} row mx-auto`}>
              <p className={`${styles.list_key} col-4`}>
                พื้นที่เพาะปลูกเชื้อเพลิงรวม
              </p>
              <p className={`${styles.list_value} col-8 text_unit`}>
                {allCropArea
                  .reduce((acc, curr) => acc + curr, 0)
                  ?.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}{" "}
                <span>ไร่</span>
              </p>
            </div>
            {/* <div className={`${styles.list_box} row mx-auto`}>
              <p className={`${styles.list_key} col-4`}>ชนิดเชื้อเพลิง / วัตถุดิบ</p>
              <p
                className={`${styles.list_value} col-8 row mx-auto text_unit_group`}
              >
                <span className="col-6">ข้าวโพด</span>
                <span className="col-4">8</span>
                <span className="unit_ col-2">ไร่</span>
              </p>
            </div> */}
          </div>
        )}
        {/* Overview Parcel : END */}
        {/* Form insert data : START */}
        {_switch && parcelId === null && (
          <AddForm
            _data={_data}
            setData={setData}
            validErr={validErr}
            _switch={_switch}
            _inputListAdd={inputListAdd}
            _setInputListAdd={setInputListAdd}
            _getPolygonArea={getPolygonArea}
          />
        )}
        {/* Form insert data : END */}

        {/* ฟอร์มดูรายละเอียดของโฉนดนั้นๆ : START */}
        {!_switch2 && parcelId !== null && (
          <div className={`${styles.input_form}`}>
            <div className={`${styles.list_box} row mx-auto`}>
              <p className={`${styles.list_key} col-4`}>ชื่อเจ้าของที่ดิน</p>
              <p className={`${styles.list_value} col-8`}>
                {parcelData?.farmer?.owner}
              </p>
            </div>
            <div className={`${styles.list_box} row mx-auto`}>
              <p className={`${styles.list_key} col-4`}>เลขที่โฉนด</p>
              <p className={`${styles.list_value} col-8`}>
                {parcelData?.parcelNo}
              </p>
            </div>
            <div className={`${styles.list_box} row mx-auto`}>
              <p className={`${styles.list_key} col-4`}>ที่ตั้ง</p>
              <p className={`${styles.list_value} col-8`}>
                {parcelData?.address}
              </p>
            </div>
            <div className={`${styles.list_box} row mx-auto`}>
              <p className={`${styles.list_key} col-4`}>รหัสแปลง</p>
              <p className={`${styles.list_value} col-8`}>
                {parcelData?.parcelId}
              </p>
            </div>
            {parcelCoord.map((item, index) => {
              if (index !== parcelCoord.length - 1) {
                return (
                  <div key={index} className={`${styles.list_box} row mx-auto`}>
                    <p className={`${styles.list_key} col-4`}>
                      พิกัดจุดที่ {index + 1}
                    </p>
                    <p className={`${styles.list_value} col-8`}>
                      {item.join(",")}
                    </p>
                  </div>
                );
              }
              return null;
            })}
            <div className={`${styles.list_box} row mx-auto`}>
              <p className={`${styles.list_key} col-4`}>พื้นที่ทั้งหมด</p>
              <p className={`${styles.list_value} col-8 text_unit`}>
                {parcelData?.area} <span>ไร่</span>
              </p>
            </div>
            <div className={`${styles.list_box} row mx-auto`}>
              <p className={`${styles.list_key} col-4`}>
                พื้นที่รวมของเชื้อเพลิง
              </p>
              <p className={`${styles.list_value} col-8 text_unit`}>
                {allCropByParcelId
                  .map((item) => item.area)
                  .reduce((acc, curr) => acc + curr, 0)
                  ?.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }) || 0}{" "}
                <span>ไร่</span>
              </p>
            </div>
            {totalPlantAreaByCrop.length !== 0 ? (
              <div className={`${styles.list_box} row mx-auto`}>
                <p className={`${styles.list_key} col-4`}>
                  ชนิดเชื้อเพลิง / วัตถุดิบ
                </p>
                <p
                  className={`${styles.list_value} col-8 row mx-auto text_unit_group`}
                >
                  {totalPlantAreaByCrop.map((item, index) => {
                    return (
                      <Fragment key={index}>
                        <span className="col-6">{item.name}</span>
                        <span className="col-4">
                          {item.value?.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                        <span className="unit_ col-2">ไร่</span>
                      </Fragment>
                    );
                  })}
                </p>
              </div>
            ) : (
              ""
            )}
            <div className={`${styles.list_box} row mx-auto`}>
              <p className={`${styles.list_key} col-4`}>
                เอกสารสิทธิ์แปลงที่ดิน
              </p>
              <p className={`${styles.list_value} col-8 text_unit_file`}>
                {parcelData?.parcelDocNavigation?.fileName}{" "}
                <img
                  src={icon.document}
                  alt=""
                  title="ดาวน์โหลดไฟล์"
                  width={27}
                  height={27}
                  onClick={() =>
                    downloadFilePdf(
                      parcelData?.parcelDoc,
                      parcelData?.parcelDocNavigation
                    )
                  }
                />
              </p>
            </div>
          </div>
        )}
        {/* ฟอร์มดูรายละเอียดของโฉนดนั้นๆ : END */}
        {/* Form update data : START */}
        {_switch2 && parcelId !== null && (
          <EditForm
            parcelData={parcelData}
            setParcelData={setParcelData}
            setCoordPolygon={setCoordPolygon}
            validErr={validErr}
            _inputListEdit={inputListEdit}
            _setInputListEdit={setInputListEdit}
            _getPolygonArea={getPolygonArea}
          />
        )}
        {/* Form update data : END */}
      </div>
    </div>
  );
};

export default AddDeed;
