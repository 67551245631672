import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { toast } from 'react-hot-toast';
import { defaultApi } from '../../../services/api';
import Message from '../../../utils/message/Message';
import styles from '../scss/Popupform.module.scss';

export const FormManageSubgrp1 = ({ infoDatax }) => {

    const [_defaultOrg, defaultOrg] = useState([])
    const [_defaultLocalGrp, defaultLocalGrp] = useState([])
    const [_status, setStatus] = useState("")

    const [txtInput01, settxtInput01] = useState("")
    const [txtInput02, settxtInput02] = useState("")

    // const [validErr01, setValidErr01] = useState(false);
    // const [validErr02, setValidErr02] = useState(false);

    const validErr01 = false, validErr02 = false;

    const infoData = async () => {
        try {
            const resOrg = await defaultApi.getOrg();
            const resLocalGrp = await defaultApi.getLocalGrp();
            defaultOrg(resOrg.data);
            defaultLocalGrp(resLocalGrp.data);
            const resStatus = await defaultApi.getGbStatus(``)
            for (let index = 0; index < resStatus?.data?.length; index++) {
                const element = resStatus?.data[index];
                if (element?.id === 'active') {
                    setStatus(element?.id)
                    break;
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    const onChangeGrp = (val) => {
        settxtInput01(val)
        // if (_defaultLocalGrp===[]) {
        //     setValidErr01(false)
        // } else {
        //     setValidErr01(true)
        // }
        // if (AddSubGrp01(val, _defaultLocalGrp) === null) {
        //     /setValidErr01(false)
        // } else {
        //     setValidErr01(true)
        // }
    }

    const onChangeOrg = (val) => {
        settxtInput02(val)
        // if (_defaultOrg===[]) {
        //     setValidErr02(false)
        // } else {
        //     setValidErr02(true)
        // }
        // if (AddSubGrp02(val, _defaultOrg) === null) {
        //     setValidErr02(false)
        // } else {
        //     setValidErr02(true)
        // }
    }

    const onSeleteOrg = (val) => {
        settxtInput02(val)
        // if (_defaultOrg===[]) {
        //     setValidErr02(false)
        // } else {
        //     setValidErr02(true)
        // }
        // if (AddSubGrp02(val, _defaultOrg) === null) {
        //     setValidErr02(false)
        // } else {
        //     setValidErr02(true)
        // }
    }

    const onSeleteGrp = (val) => {
        settxtInput01(val)
        // if (_defaultLocalGrp===[]) {
        //     setValidErr01(false)
        // } else {
        //     setValidErr01(true)
        // }
        // if (AddSubGrp01(val, _defaultLocalGrp) === null) {
        //     setValidErr01(false)
        // } else {
        //     setValidErr01(true)
        // }
    }

    const ResetValue = () => {
        settxtInput01("")
        settxtInput02("")
    }

    const submitDataForm = async (afterClick) => {

        const chkDataId = () => {
            //let i = null
            return _defaultOrg.map((data) => {
                let i
                if (data.name === txtInput02) {
                    i = data.id
                }
                return i
            })
        }

        let grpId = null
        grpId = chkDataId()

        if (grpId === null) {
        } else {
            const loading = toast.loading(Message.notify.insert_loading);

            for (let index = 0; index < grpId.length; index++) {
                if (grpId[index]) {
                    grpId = grpId[index]
                    break;
                }
            }

            const params = {
                name: txtInput01,
                orgId: Number(grpId),
                status: "active"
            }

            if (!params.name || !params.orgId) {
                toast.error(Message.notify.insert_error, { duration: 3000 });
            }

            try {
                await defaultApi.createLocalGroup(params)
                toast.success(Message.notify.insert_success, { duration: 3000 });
                infoDatax()
                $('#' + afterClick).trigger('click');
            } catch (error) {
                let errorx = error.response.data
                errorx = errorx.name.map((data) => {
                    return data
                })
                toast.error(Message.notify.insert_error, { duration: 3000 });
                toast.error(errorx, { duration: 6000 });
            } finally {
                toast.dismiss(loading);
            }
        }
    }

    useEffect(() => {
        infoData()
    }, [])

    return (
        <>
            <div
                className="modal fade"
                id="exampleModal1"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"

            //className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <h1 className={`modal-title mt-3 fs-5 ${styles.h5}`} id="exampleModalLabel">เพิ่มกลุ่มผู้ใช้งานย่อย</h1>
                            <hr className={`w-100 me-1 ms-1 border border-dark-subtle`} />
                        </div>
                        <div className="modal-body ">
                            <form id="formSubRegis" className={`${styles.form_} mx-auto`}>
                                <div className={`${styles.input_flex}`}>
                                    <div className={`${styles.box_error}  d-flex flex-row justify-content-between m-2 ${styles.input_1}`}>
                                        <h5 className={`col-4 ${styles.h5}`}>กลุ่มผู้ใช้งานย่อย : </h5>
                                        <Autocomplete
                                            inputProps={validErr01 === false ? { style: { "width": "100%" } } : { style: { "width": "100%", "border": "2px solid red" } }}
                                            wrapperStyle={{ "width": "100%" }}
                                            getItemValue={(item) => item.name}
                                            items={_defaultLocalGrp}
                                            shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                            renderItem={(item, isHighlighted) =>
                                                <div key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                                    {item.name}
                                                </div>
                                            }
                                            value={txtInput01}
                                            onChange={(e) => { onChangeGrp(e.target.value) }}
                                            onSelect={(val) => { onSeleteGrp(val) }}
                                        />
                                        <input className={`col ${validErr01 && txtInput01 === "" ? styles.error : null}`}
                                            style={{ "display": "none" }}
                                            id="input1"
                                            type="text"
                                            defaultValue={txtInput01}
                                            name="subgrpname" />
                                    </div>
                                    {/* {validErr01 && (
                                        <div className={`${styles.box_error} w-100 d-flex justify-content-center`}>
                                            <small className={`${styles.err_message}`}>
                                                {AddSubGrp01(txtInput01, _defaultLocalGrp)}
                                            </small>
                                        </div>
                                    )} */}
                                    <div className={`${styles.box_error} d-flex flex-row justify-content-between m-2 ${styles.input_}`}>
                                        <h5 className={`col-4 ${styles.h5}`}>หน่วยงาน : </h5>
                                        <Autocomplete
                                            inputProps={validErr02 === false ? { style: { "width": "100%" } } : { style: { "width": "100%", "border": "2px solid red" } }}
                                            wrapperStyle={{ "width": "100%" }}
                                            getItemValue={(item) => item.name}
                                            items={_defaultOrg}
                                            shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                            renderItem={(item, isHighlighted) =>
                                                <div key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                                    {item.name}
                                                </div>
                                            }
                                            value={txtInput02}
                                            onChange={(e) => { onChangeOrg(e.target.value) }}
                                            onSelect={(val) => { onSeleteOrg(val) }}
                                        />
                                        <input className={`col ${validErr02 && txtInput02 === "" ? styles.error : null}`}
                                            style={{ "display": "none" }}
                                            id="input2"
                                            type="text"
                                            defaultValue={txtInput02}
                                            name="orgname" />
                                    </div>
                                    {/* {validErr02 && (
                                        <div className={`${styles.box_error} w-100 d-flex justify-content-center`}>
                                            <small className={`${styles.err_message}`}>
                                                {AddSubGrp02(txtInput02, _defaultOrg)}
                                            </small>
                                        </div>
                                    )} */}
                                </div>
                            </form>
                        </div>
                        <div className={`d-flex justify-content-center mb-3 ${styles.boxpopup}`}>
                            <button className={`${styles.savechangebtn}`} onClick={() => { submitDataForm("btnCloseForm1") }} ><h4>บันทึก</h4></button><h4>|</h4><button id="btnCloseForm1" onClick={() => { ResetValue() }} className={`${styles.savechangebtn}`} data-bs-dismiss="modal"><h4>ยกเลิก</h4></button>
                        </div>
                    </div>
                </div>
            </div>
        </>

        //เงื่อนไขหน้านี้คือ เช็คให้ค่าไม่ซ้ำกับลิสต์ และ ค่าห้ามว่างง
    )
}