import React, { useEffect, useCallback, useMemo, useRef } from "react";
import { Overlay } from "ol";
import styles from "./OverlayPopup2.module.scss";

const OverlayPopup2 = ({
  _map,
  _layerType,
  _setCropDetail,
  _cropStatus,
  _statusName4,
}) => {
  /* Add overlay_popup in map : START */
  const container = useRef();

  const overlay = useMemo(() => {
    if (_layerType !== "factory") {
      return new Overlay({ element: container.current });
    }
  }, [_layerType]);
  /* Add overlay_popup in map : END */

  const clickHandle = useCallback(
    (evt) => {
      if ((evt.frameState?.layerStatesArray?.length || 0) < 2) return;

      overlay?.setPosition(undefined);
      _setCropDetail(null);
      evt.map.forEachFeatureAtPixel(evt.pixel, (layer) => {
        const content = document.querySelector("#overlay_content");
        const allProperties = layer.getProperties();
        const _status = layer.getProperties().cropStatus;
        const _harvest = layer.getProperties().harvestPlan;
        if (_status === _statusName4?.planting) {
          content.innerHTML = `<p id='text_1'>${
            _cropStatus?.find((item) => item.id === _status)?.statusName
          }</p>
            <p id='text_2'>${_harvest}</p>`;
        } else {
          content.innerHTML = `<p id='text_1'>${_status}</p>`;
        }
        _setCropDetail(allProperties);
        overlay?.setPosition(evt.coordinate);
      });
    },
    [overlay, _setCropDetail, _cropStatus, _statusName4]
  );

  useEffect(() => {
    if (_layerType !== "factory") {
      _map?.addOverlay(overlay);
      //console.log("Overlay Active");
    }
  }, [_map, _layerType, overlay]);

  useEffect(() => {
    _map?.un("click", clickHandle);
    _map?.on("click", clickHandle);
  }, [_map, clickHandle]);

  return (
    <div
      id="overlay_popup2"
      className={`ol_popup ${styles.ol_popup}`}
      ref={container}
    >
      <div
        id="overlay_content"
        className={`overlay_content ${styles.overlay_content}`}
      ></div>
    </div>
  );
};

export default OverlayPopup2;
