import React from "react";
import styles from "./Footer.module.scss";

const version_web = `v.1.1.08`;

const Footer = () => {
  return (
    <>
      <footer className={`${styles.container}`}>
        <p>Copyright 2023 Ministry of Energy Thailand | Privacy Policy</p>
        <div className={`${styles.version_web}`}>{version_web}</div>
      </footer>
    </>
  );
};

export default Footer;
