import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useSelector } from "react-redux";
import icon from "../../../../assets/img/Icon";
import { getFactoryType } from "../../../../services/factory.slice";
import { convertDateThai } from "../../../../utils/format/Date.format";
import { defaultApi } from "../../../../services/api";
import { QueryGetAll } from "../../../../helpers/api.helper";
import {
  createUrlImage,
  downloadFilePdf,
} from "../../../../helpers/func.helper";
import styles from "./scss/PowerPlantsDetails.module.scss";

const FarmerDetails = ({ _enterpriseData, _allEntMemByEntId }) => {
  const factoryType = useSelector(getFactoryType);

  const [allFarmer, setAllFarmer] = useState([]);
  const [allParcel, setAllParcel] = useState([]);
  const [allCrop, setAllCrop] = useState([]);
  const [totalPlantAreaByCrop, setTotalPlantAreaByCrop] = useState([]);

  const getMultipleApi = useCallback(async () => {
    if (!_enterpriseData?.id) return;

    const qryGetAll = {
      $count: "$count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&$filter=enterpriseId eq ${_enterpriseData?.id}`,
      $orderby: "",
    };
    const qryCrop = {
      $count: "$count=true",
      $expand: "&$expand=plantType",
      $top: "",
      $skip: "",
      $filter: `&$filter=enterpriseId eq ${_enterpriseData?.id} and cropStatus eq 'planting' and status eq 'active'`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    const _queryCrop = QueryGetAll(qryCrop);
    try {
      const [allFarmer, allParcel, allCrop] = await Promise.all([
        defaultApi.getAllFarmer(_queryAll),
        defaultApi.getAllParcel(_queryAll),
        defaultApi.getAllCrop(_queryCrop),
      ]);
      setAllParcel(allParcel.data.datas);
      setAllCrop(allCrop.data.datas);
      setAllFarmer(allFarmer.data.datas);
    } catch (error) {
      console.log(error);
    }
  }, [_enterpriseData]);

  useEffect(() => {
    /* จัดกลุ่มข้อมูลใหม่จากชนิดพืชและพื้นท่ีด้วยข้อมูลของ crop : START */
    let newData = [];
    for (let i = 0; i < allCrop.length; i++) {
      const el = allCrop[i];
      const _name = el.plantType.name;
      const _value = el.area;
      const newObj = { name: _name, value: _value };

      newData[i] = newObj;
    }
    /* จัดกลุ่มข้อมูลใหม่จากชนิดพืชและพื้นท่ีด้วยข้อมูลของ crop : END */

    /* นำกลุ่มข้อมูล(newData)ที่จัดแล้วมาหาผลรวม
    และรวมชื่อพืชที่ซ้ำกันให้เป็นชุดข้อมูลใหม่ : START */
    if (newData.length !== 0) {
      let result = [];
      newData.reduce((acc, curr) => {
        if (!acc[curr.name]) {
          acc[curr.name] = { name: curr.name, value: 0 };
          result.push(acc[curr.name]);
        }
        acc[curr.name].value += curr.value;
        return acc;
      }, {});
      setTotalPlantAreaByCrop(result);
    } else {
      setTotalPlantAreaByCrop([]);
    }
    /* นำกลุ่มข้อมูล(newData)ที่จัดแล้วมาหาผลรวม
    และรวมชื่อพืชที่ซ้ำกันให้เป็นชุดข้อมูลใหม่ : END */
  }, [allCrop]);

  useEffect(() => {
    // Use functions
    getMultipleApi();
  }, [getMultipleApi]);

  return (
    <div className={`${styles.container}`}>
      <h4 className={`${styles.title_text}`}>{_enterpriseData?.name}</h4>
      <div className={`${styles.img_box}`}>
        <div className={`${styles.img_}`}>
          {_enterpriseData?.enterpriseImage ? (
            <img
              className={`${styles.img_true}`}
              src={createUrlImage(
                _enterpriseData?.enterpriseImageNavigation?.filePath
              )}
              alt=""
            />
          ) : (
            <img className={`${styles.img_false}`} src={icon.pic} alt="" />
          )}
        </div>
      </div>
      <div className={`${styles.details_container}`}>
        <div className={`${styles.details_list}`}>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>ชื่อกลุ่มวิสาหกิจชุมชน</p>
            <p className={`${styles.list_value} col-8`}>
              {_enterpriseData?.name || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>ประเภทกลุ่ม</p>
            <p className={`${styles.list_value} col-8`}>
              {_enterpriseData?.enterpriseType?.name || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>วันที่จัดตั้ง</p>
            <p className={`${styles.list_value} col-8`}>
              {convertDateThai(_enterpriseData?.openDate) || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>ที่ตั้ง</p>
            <p className={`${styles.list_value} col-8`}>
              {_enterpriseData?.address || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>
              เลขที่ทะเบียนกลุ่มวิสาหกิจชุมชน
            </p>
            <p className={`${styles.list_value} col-8`}>
              {_enterpriseData?.registrationNumber || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>เบอร์ติดต่อวิสาหกิจ</p>
            <p className={`${styles.list_value} col-8`}>
              {_enterpriseData?.mobile || "-"}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>โรงไฟฟ้าที่รับซื้อ</p>
            <p className={`${styles.list_value} col-8`}>
              {_enterpriseData?.factory?.name}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>ประเภทโรงไฟฟ้า</p>
            <p className={`${styles.list_value} col-8`}>
              {factoryType
                .filter(
                  (item) => item.id === _enterpriseData?.factory?.factoryTypeId
                )
                .map((item) => item.name)}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>หนังสือจดทะเบียน</p>
            <p className={`${styles.list_value} col-8 text_unit`}>
              {_enterpriseData?.enterpriseDocNavigation?.fileName || "-"}{" "}
              <img
                src={icon.document}
                alt=""
                title="ดาวน์โหลดไฟล์"
                width={27}
                height={27}
                onClick={() =>
                  downloadFilePdf(
                    _enterpriseData?.enterpriseDoc,
                    _enterpriseData?.enterpriseDocNavigation
                  )
                }
              />
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>วัตถุประสงค์</p>
            <p className={`${styles.list_value} col-8`}>
              {_enterpriseData?.objective}
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>จำนวนสมาชิก</p>
            <p className={`${styles.list_value} col-8 text_unit`}>
              {allFarmer.length} <span>คน</span>
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>พื้นที่ทั้งหมด</p>
            <p className={`${styles.list_value} col-8 text_unit`}>
              {allParcel
                .map((item) => item.area)
                .reduce((acc, curr) => acc + curr, 0)
                ?.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}{" "}
              <span>ไร่</span>
            </p>
          </div>
          <div className={`${styles.list_box} row mx-auto`}>
            <p className={`${styles.list_key} col-4`}>พื้นที่ของเชื้อเพลิง</p>
            <p className={`${styles.list_value} col-8 text_unit`}>
              {allCrop
                .map((item) => item.area)
                .reduce((acc, curr) => acc + curr, 0)
                ?.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}{" "}
              <span>ไร่</span>
            </p>
          </div>
          {totalPlantAreaByCrop.length !== 0 ? (
            <div className={`${styles.list_box} row mx-auto`}>
              <p className={`${styles.list_key} col-4`}>
                ชนิดเชื้อเพลิง / วัตถุดิบ
              </p>
              <p
                className={`${styles.list_value} col-8 row mx-auto text_unit_group`}
              >
                {totalPlantAreaByCrop.map((item, i) => {
                  return (
                    <Fragment key={i}>
                      <span className="col-6">{item.name}</span>
                      <span className="col-4">
                        {item.value?.toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                      <span className="unit_ col-2">ไร่</span>
                    </Fragment>
                  );
                })}
              </p>
            </div>
          ) : (
            ""
          )}
          {_allEntMemByEntId?.map((item, i) => {
            return (
              <Fragment key={item.id}>
                <div className={`${styles.list_box} row mx-auto`}>
                  <p className={`${styles.list_key} col-4`}>
                    ผู้ประสานงาน (คนที่ {i + 1})
                  </p>
                  <p className={`${styles.list_value} col-8`}>
                    {item?.user.firstname} {item?.user.lastname}
                  </p>
                </div>
                <div className={`${styles.list_box} row mx-auto`}>
                  <p className={`${styles.list_key} col-4`}>อีเมล</p>
                  <p className={`${styles.list_value} col-8`}>
                    {item?.user.email}
                  </p>
                </div>
                <div className={`${styles.list_box} row mx-auto`}>
                  <p className={`${styles.list_key} col-4`}>เบอร์ติดต่อ</p>
                  <p className={`${styles.list_value} col-8`}>
                    {item?.user.mobile}
                  </p>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FarmerDetails;
