import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./scss/ResourceHarvest.module.scss";
import { defaultApi } from "../../../services/api";
import ChartResourceHarvest from "./charts/ChartResourceHarvest";
import { QueryGetAll } from "../../../helpers/api.helper";

const ResourceHarvest = ({ _theme, barFact, typeFact }) => {
  const chart = useRef();

  const [_selItem, setSelItem] = useState({
    entpid: "",
    factid: "",
    facttype: "",
  });

  const [_resItem, setResItem] = useState("")
  const [_listEntp, setListEntp] = useState([])

  const resApiList = useCallback(async () => {
    try {
      let strquery = ""

      if (+typeFact?.id !== -99 && +barFact?.id !== -99) {
        strquery = `&$filter=factory/factoryTypeId eq ${typeFact?.id} and factory/id eq ${barFact?.id}`
      } else if (+typeFact?.id === -99 && +barFact?.id !== -99) {
        strquery = `&$filter=factory/id eq ${barFact?.id}`
      } else if (+typeFact?.id !== -99 && +barFact?.id === -99) {
        strquery = `&$filter=factory/factoryTypeId eq ${typeFact?.id}`
      } else {
        strquery = ""
      }

      let _query = {
        $count: "",
        $expand: ``,
        $top: "",
        $skip: ``,
        $filter: `${strquery}`,
        $orderby: "",
      };

      const query = QueryGetAll(_query);

      const res_ent_list = await defaultApi.getAllEnterprise(query);

      if (+res_ent_list?.status === 200) {
        let factid = +barFact?.id === -99 ? "" : +barFact?.id
        let facttype = +typeFact?.id === -99 ? "" : +typeFact?.id

        setListEntp(res_ent_list?.data)
        setSelItem((prev) => ({ ...prev, entpid: "", factid: factid, facttype: facttype }));
      }

    } catch (error) { }
  }, [barFact, typeFact]);

  const getHarvestTarget = useCallback(async () => {
    try {
      let entp = `enterpriseId=${_selItem?.entpid}`
      let factid = `&factoryId=${_selItem?.factid}`
      let facttype = `&factoryTypeId=${_selItem?.facttype}`
      const param = `${entp}${factid}${facttype}`;
      const res_data = await defaultApi.getTargetHarvest(param);
      if (+res_data?.status === 200) {
        setResItem(res_data?.data);
      }
    } catch (error) {

    }
  }, [_selItem]);

  const selectEnterprise = (value) => {
    const dataX = value;
    let iLetOut = [];
    for (let index = 0; index < dataX?.length; index++) {
      const element = dataX[index];
      iLetOut[index] = <option value={element?.id}>{element?.name}</option>;
    }
    return (
      <>
        <option value={""}>เลือกกลุ่มเกษตรกร</option>
        {iLetOut}
      </>
    );
  };

  const checkWordTitle = useMemo(() => {
    const entpId = _selItem?.entpid
    const factId = _selItem?.factid
    const factTp = _selItem?.facttype

    let formattname = "กลุ่มวิสาหกิจทั้งหมด";

    if (factTp) {
      formattname = `กลุ่มวิสาหกิจที่อยู่ภายใต้โรงไฟฟ้าประเภท${typeFact?.name}`
    }
    if (factId) {
      formattname = `กลุ่มวิสาหกิจที่อยู่ภายใต้${barFact?.name}`
    }
    if (entpId) {
      formattname = `${_listEntp?.find((item) => +item?.id === +entpId)?.name || ""}`
    }

    return formattname
  }, [_selItem, barFact, typeFact, _listEntp])

  useEffect(() => {
    resApiList();
  }, [resApiList]);

  useEffect(() => {
    getHarvestTarget();
  }, [getHarvestTarget]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.boxcontainer}`}>
        <div className={`${styles.drop_filter}`}>
          <select
            style={{ color: "black" }}
            name=""
            id="sel02"
            onChange={(e) => {
              setSelItem((prev) => ({ ...prev, entpid: e.target.value }));
            }}
            value={_selItem?.entpid ? _selItem?.entpid : ""}
          >
            {selectEnterprise(_listEntp)}
          </select>
        </div>
      </div>
      <h4
        className={`${styles.title_text}`}
        style={{ background: _theme?.backgroundColor }}
      >
        วัตถุดิบที่คาดว่าจะเก็บเกี่ยว
      </h4>
      <h4 id="subtitle01" className={`${styles.subtitle_text}`}>
        {checkWordTitle}
      </h4>

      <div className={`${styles.charts_}`}>
        <ChartResourceHarvest ref={chart} datain={_resItem} />
      </div>
    </div>
  );
};

export default ResourceHarvest;
