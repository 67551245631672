import React from "react";
import icon from "../../assets/img/Icon";
import styles from './scss/Index.module.scss';

const InfoList = ({ valueInput }) => {

  let x1 = 0, x2 = 0, x3 = 0
  try {
    x1 = Number(valueInput?.enterpriseOverview[0]?.enterpriseCount).toLocaleString(undefined, { minimumFractionDigits: 0 })
    x2 = Number(valueInput?.enterpriseOverview[0]?.farmerCount).toLocaleString(undefined, { minimumFractionDigits: 0 })
    x3 = Number(valueInput?.enterpriseOverview[0]?.areaTotal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  } catch (error) {

  }

  return (
    <>
      <div id="info_list" className={styles.info_list}>
        <div className={`${styles.info_} row mx-auto`}>
          <div className="col-6 d-flex align-items-center">
            <i>
              <img src={icon.index_2} alt="" />
            </i>
            <span>วิสาหกิจชุมชนที่เข้าร่วม</span>
            {/* <span>จำนวนวิสาหกิจที่เข้าร่วม</span> */}
          </div>
          <span className={`${styles.count_} col-4 d-flex align-items-center`}>{x1}</span>
          <span className={`${styles.type_} col-2 d-flex align-items-center`}>กลุ่ม</span>
        </div>
        <div className={`${styles.info_} row mx-auto`}>
          <div className="col-6 d-flex align-items-center">
            <i>
              <img src={icon.index_3} alt="" />
            </i>
            <span>จำนวนครัวเรือนที่เข้าร่วม</span>
          </div>
          <span className={`${styles.count_} col-4 d-flex align-items-center`}>{x2}</span>
          <span className={`${styles.type_} col-2 d-flex align-items-center`}>
            ครัวเรือน
          </span>
        </div>
        <div className={`${styles.info_} row mx-auto`}>
          <div className="col-6 d-flex align-items-center">
            <i>
              <img src={icon.index_4} alt="" />
            </i>
            <span>พื้นที่ปลูกรวม</span>
          </div>
          <span className={`${styles.count_} col-4 d-flex align-items-center`}>{x3}</span>
          <span className={`${styles.type_} col-2 d-flex align-items-center`}>ไร่</span>
        </div>
      </div>
    </>
  );
};

export default InfoList;
