import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast } from "react-hot-toast";
import { showPass, showPassCf } from "../functions/index";
import { defaultApi } from "../../../services/api";
import Message from "../../../utils/message/Message";
import {
  CheckTitle,
  CheckFname,
  CheckLname,
  CheckEmail,
  CheckMobile,
  CheckuserGroupId,
  CheckuserOrgId,
  CheckUsername,
  CheckPassword,
  CheckPasswordMatch,
  CheckuserLocalGroupId,
} from "../../../utils/validation_input/Validation";
import styles from "../scss/Register.module.scss";

const title = [
  {
    id: 1,
    name: "นาย",
  },
  {
    id: 2,
    name: "นาง",
  },
  {
    id: 3,
    name: "นางสาว",
  },
];

const initData = {
  title: "",
  firstname: "",
  lastname: "",
  username: "",
  password: "",
  password2: "",
  email: "",
  mobile: "",
  userOrgId: null,
  userGroupId: "",
  userLocalGroupId: null,
};

const RegisterForm = () => {
  const navigate = useNavigate();

  const [userGroup, setUserGroup] = useState([]);
  const [userOrg, setUserOrg] = useState([]);
  const [userLocalGrp, setUserLocalGrp] = useState([]);
  const [_data, setData] = useState(initData);
  const [validErr, setValidErr] = useState(false);
  const [_showPass, setShowPass] = useState(false);
  const [_showPassCf, setShowPassCf] = useState(false);
  const [checkTerms, setCheckTerms] = useState(false);

  const userLocalGrpOpt = useMemo(() => {
    const filterOpt = userLocalGrp.filter(
      (item) => item.orgId === +_data.userOrgId
    );

    if (!_data.userOrgId) return userLocalGrp;

    return filterOpt;
  }, [userLocalGrp, _data]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };
  const onChangeGroupID = (e) => {
    const { name, value } = e.target;
    /* usetGroupid ไม่เท่ากับ เจ้าหน้าที่ภาครัฐ : START */
    if (_data.userGroupId !== 3) {
      _data.userOrgId = null;
      _data.userLocalGroupId = null;
    }
    /* usetGroupid ไม่เท่ากับ เจ้าหน้าที่ภาครัฐ : END */
    setData({ ..._data, [name]: value });
  };
  const onRegister = async (e) => {
    e.preventDefault();
    if (!window.confirm(Message.notify.register_confirm)) return;
    if (_data.userGroupId === "3") {
      if (CheckuserOrgId(_data.userOrgId)) {
        return setValidErr(true);
      } else if (CheckuserLocalGroupId(_data.userLocalGroupId)) {
        return setValidErr(true);
      }
    }
    if (CheckFname(_data.firstname)) return setValidErr(true);
    if (CheckLname(_data.lastname)) return setValidErr(true);
    if (CheckEmail(_data.email)) return setValidErr(true);
    if (CheckMobile(_data.mobile)) return setValidErr(true);
    if (CheckUsername(_data.username)) return setValidErr(true);
    if (CheckPassword(_data.password)) return setValidErr(true);
    if (CheckPasswordMatch(_data.password, _data.password2))
      return setValidErr(true);

    const loading = toast.loading(Message.notify.register_loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "userOrgId") {
        params[key] = _data.userOrgId === null ? null : +_data.userOrgId;
      } else if (key === "userGroupId") {
        params[key] = +_data.userGroupId;
      } else if (key === "password2") {
      } else {
        params[key] = _data[key];
      }
    });
    try {
      const res = await defaultApi.register(params);
      console.log(res.statusText);
      toast.success(Message.notify.register_success, { duration: 3000 });
      /* Clear input : START */
      setData(initData);
      /* Clear input : END */
      navigate("/");
    } catch (error) {
      console.log(error);
      setValidErr(true);
      if (error?.response?.status === 400) {
        if (
          error?.response?.data.email?.length ||
          error?.response?.data.username?.length
        ) {
          toast.error("ชื่อผู้ใช้ หรือ อีเมล ถูกใช้งานแล้ว ❗️", {
            duration: 5000,
          });
          toast.dismiss(loading);
        } else {
          toast.error(Message.notify.register_error, { duration: 3000 });
          toast.dismiss(loading);
        }
      } else {
        toast.error(Message.notify.register_error, { duration: 3000 });
        toast.dismiss(loading);
      }
    } finally {
      toast.dismiss(loading);
    }
  };

  const _getUserGroup = async () => {
    try {
      const res = await defaultApi.getUserGroup();
      const newData = res.data?.filter((item) => item.id !== 4);

      setUserGroup(newData);
    } catch (error) {
      console.log(error);
    }
  };
  const _getUserOrg = async () => {
    try {
      const res = await defaultApi.getOrg();

      setUserOrg(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const _getUserLocalGrp = async () => {
    try {
      const res = await defaultApi.getLocalGrp();

      setUserLocalGrp(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    /* Fetch Api : START */
    _getUserGroup();
    _getUserOrg();
    _getUserLocalGrp();
    /* Fetch Api : END */
  }, []);

  return (
    <form
      onSubmit={onRegister}
      id="submit_"
      className={`${styles.form_} mx-auto`}
    >
      <div className={`${styles.input_}`}>
        <h5>
          คำนำหน้าชื่อ <sup className={`${styles.asterisk}`}>*</sup>
        </h5>
        <select
          name="title"
          id=""
          className={`${validErr && _data.title === "" ? "error" : ""}`}
          onChange={onChange}
        >
          <option style={{ display: "none" }}>เลือกคำนำหน้าชื่อ</option>
          {title.map((item) => {
            return (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            );
          })}
        </select>
        {validErr && (
          <small className={`${styles.err_message}`}>
            {CheckTitle(_data.title)}
          </small>
        )}
      </div>
      <div className={`${styles.input_flex}`}>
        <div className={`${styles.input_1}`}>
          <h5>
            ชื่อ <sup className={`${styles.asterisk}`}>*</sup>
          </h5>
          <input
            type="text"
            name="firstname"
            className={`${validErr && _data.firstname === "" ? "error" : ""}`}
            onChange={onChange}
          />
          {validErr && (
            <small className={`${styles.err_message}`}>
              {CheckFname(_data.firstname)}
            </small>
          )}
        </div>
        <div className={`${styles.input_2}`}>
          <h5>
            นามสกุล <sup className={`${styles.asterisk}`}>*</sup>
          </h5>
          <input
            type="text"
            name="lastname"
            className={`${validErr && _data.lastname === "" ? "error" : ""}`}
            onChange={onChange}
          />
          {validErr && (
            <small className={`${styles.err_message}`}>
              {CheckLname(_data.lastname)}
            </small>
          )}
        </div>
      </div>
      <div className={`${styles.input_flex}`}>
        <div className={`${styles.input_1}`}>
          <h5>
            อีเมล <sup className={`${styles.asterisk}`}>*</sup>
          </h5>
          <input
            type="email"
            name="email"
            className={`${validErr && _data.email === "" ? "error" : ""}`}
            onChange={onChange}
          />
          {validErr && (
            <small className={`${styles.err_message}`}>
              {CheckEmail(_data.email)}
            </small>
          )}
        </div>
        <div className={`${styles.input_2}`}>
          <h5>
            เบอร์โทรศัพท์ <sup className={`${styles.asterisk}`}>*</sup>
          </h5>
          <input
            type="text"
            name="mobile"
            className={`${validErr && _data.mobile === "" ? "error" : ""}`}
            onChange={onChange}
          />
          {validErr && (
            <small className={`${styles.err_message}`}>
              {CheckMobile(_data.mobile)}
            </small>
          )}
        </div>
      </div>
      <div className={`${styles.input_}`}>
        <h5>
          กลุ่มผู้ใช้งาน <sup className={`${styles.asterisk}`}>*</sup>
        </h5>
        <select
          name="userGroupId"
          className={`${validErr && _data.userGroupId === "" ? "error" : ""}`}
          onChange={onChangeGroupID}
        >
          <option style={{ display: "none" }}>เลือกกลุ่มผู้ใช้งาน</option>
          {userGroup.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </select>
        {validErr && (
          <small className={`${styles.err_message}`}>
            {CheckuserGroupId(_data.userGroupId)}
          </small>
        )}
      </div>
      {_data.userGroupId === "3" && (
        <div className={`${styles.input_}`}>
          <h5>
            หน่วยงาน <sup className={`${styles.asterisk}`}>*</sup>
          </h5>
          <select
            name="userOrgId"
            className={`${validErr && _data.userOrgId === null ? "error" : ""}`}
            onChange={onChange}
          >
            <option style={{ display: "none" }}>เลือกหน่วยงาน</option>
            {userOrg.map((item) => {
              return (
                item.status === "active" && (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                )
              );
            })}
          </select>
          {validErr && (
            <small className={`${styles.err_message}`}>
              {CheckuserOrgId(_data.userOrgId)}
            </small>
          )}
        </div>
      )}
      {_data.userOrgId !== null && userLocalGrpOpt.length !== 0 ? (
        <div className={`${styles.input_}`}>
          <h5>
            กลุ่มผู้ใช้งานย่อย <sup className={`${styles.asterisk}`}>*</sup>
          </h5>
          <select
            name="userLocalGroupId"
            className={`${
              validErr && _data.userLocalGroupId === null ? "error" : ""
            }`}
            onChange={onChange}
          >
            <option style={{ display: "none" }}>เลือกกลุ่มผู้ใช้งานย่อย</option>
            {userLocalGrpOpt.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })}
          </select>
          {validErr && (
            <small className={`${styles.err_message}`}>
              {CheckuserLocalGroupId(_data.userLocalGroupId)}
            </small>
          )}
        </div>
      ) : null}
      <div className={`${styles.input_}`}>
        <h5>
          ชื่อผู้ใช้งาน <sup className={`${styles.asterisk}`}>*</sup>
        </h5>
        <input
          type="text"
          name="username"
          className={`${validErr && _data.username === "" ? "error" : ""}`}
          onChange={onChange}
        />
        {validErr && (
          <small className={`${styles.err_message}`}>
            {CheckUsername(_data.username)}
          </small>
        )}
      </div>
      <div className={`${styles.input_}`}>
        <h5>
          รหัสผ่าน <sup className={`${styles.asterisk}`}>*</sup>
        </h5>
        <div
          className={`${styles.input_unit} ${
            validErr && _data.password === "" ? "error" : ""
          }`}
        >
          <input
            id="password"
            type="password"
            name="password"
            onChange={onChange}
          />
          <div className={`${styles.unit}`}>
            {!_showPass ? (
              <AiFillEye
                id="showPass"
                className={`${styles.ai_fill_eye}`}
                onClick={() => {
                  showPass();
                  setShowPass(true);
                }}
              />
            ) : (
              <AiFillEyeInvisible
                id="showPass"
                className={`${styles.ai_fill_eye}`}
                onClick={() => {
                  showPass();
                  setShowPass(false);
                }}
              />
            )}
          </div>
        </div>
        {validErr && (
          <small className={`${styles.err_message}`}>
            {CheckPassword(_data.password)}
          </small>
        )}
      </div>
      <div className={`${styles.input_}`}>
        <h5>
          ยืนยันรหัสผ่าน <sup className={`${styles.asterisk}`}>*</sup>
        </h5>
        <div
          className={`${styles.input_unit} ${
            validErr && _data.password2 === "" ? "error" : ""
          }`}
        >
          <input
            id="password_check"
            type="password"
            name="password2"
            onChange={onChange}
          />
          <div className={`${styles.unit}`}>
            {!_showPassCf ? (
              <AiFillEye
                id="showPassCf"
                className={`${styles.ai_fill_eye}`}
                onClick={() => {
                  showPassCf();
                  setShowPassCf(true);
                }}
              />
            ) : (
              <AiFillEyeInvisible
                id="showPassCf"
                className={`${styles.ai_fill_eye}`}
                onClick={() => {
                  showPassCf();
                  setShowPassCf(false);
                }}
              />
            )}
          </div>
        </div>
        {validErr && (
          <small className={`${styles.err_message}`}>
            {CheckPasswordMatch(_data.password, _data.password2)}
          </small>
        )}
      </div>
      <div className={`${styles.pdpa_check}`}>
        <input type="checkbox" onClick={() => setCheckTerms(!checkTerms)} />
        <p>
          <span>*</span> ฉันอ่านแล้วยอมรับข้อตกลงแล้ว{" "}
          <a href="/powerapp/terms" target="_blank" rel="noopener noreferrer">
            เงื่อนไขและข้อตกลงในการใช้บริการ
          </a>
        </p>
      </div>
      <div className={`${styles.button_center}`}>
        <button
          type="submit"
          form="submit_"
          className={`button_sm ${styles.button_sm}`}
          disabled={!checkTerms}
        >
          ลงทะเบียน
        </button>
        <button
          type="button"
          className={`button_sm ${styles.button_sm}`}
          onClick={() => navigate("/")}
        >
          กลับ
        </button>
      </div>
    </form>
  );
};

export default RegisterForm;
