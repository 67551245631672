import React from "react";
import styles from "./scss/MenuBar.module.scss";

const baseName = "/report";

const MenuBar = ({
  _location,
  _navigate,
  switchChecked,
  onSwitchChecked,
  switchChecked2,
  onSwitchChecked2,
  switchChecked3,
  onSwitchChecked3,
  switchChecked4,
  onSwitchChecked4,
}) => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name=""
          id="check1"
          checked={_location === baseName}
          onClick={() => _navigate(baseName)}
          readOnly
        />
        <label htmlFor="check1">พื้นที่รอเก็บเกี่ยวของกลุ่มที่อยู่ภายใต้โรงไฟฟ้า</label>
      </div>
      {/* <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name=""
          id="check2"
          checked={_location === `${baseName}/production-plan`}
          onClick={() => _navigate(`${baseName}/production-plan`)}
          readOnly
        />
        <label htmlFor="check2">รายงานการผลิตไฟฟ้า</label>
      </div> */}
      {/* {_location === `${baseName}/production-plan` && (
        <div className={`${styles.checkChildrenBox}`}>
          <div>
            <input
              type="checkbox"
              name=""
              id="checkMenu6"
              value={1}
              checked={switchChecked === "1"}
              onClick={(e) => onSwitchChecked(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu6">รายวัน</label>
          </div>
          <div>
            <input
              type="checkbox"
              name=""
              id="checkMenu7"
              value={2}
              checked={switchChecked === "2"}
              onClick={(e) => onSwitchChecked(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu7">รายเดือน</label>
          </div>
        </div>
      )} */}
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name=""
          id="check3"
          checked={_location === `${baseName}/production-capacity`}
          onClick={() => _navigate(`${baseName}/production-capacity`)}
          readOnly
        />
        <label htmlFor="check3">รายงานการผลิตและจำหน่ายไฟฟ้า</label>
        {/* <label htmlFor="check3">ปริมาณการผลิตไฟฟ้า</label> */}
      </div>
      {_location === `${baseName}/production-capacity` && (
        <div className={`${styles.checkChildrenBox}`}>
          {/* <div>
            <input
              type="checkbox"
              name=""
              id="checkMenu8"
              value={3}
              checked={switchChecked2 === "3"}
              onClick={(e) => onSwitchChecked2(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu8">รายวัน</label>
          </div> */}
          <div>
            <input
              type="checkbox"
              name=""
              id="checkMenu9"
              value={4}
              checked={switchChecked2 === "4"}
              onClick={(e) => onSwitchChecked2(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu9">รายเดือน</label>
          </div>
        </div>
      )}
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name=""
          id="check4"
          checked={_location === `${baseName}/cost&income`}
          onClick={() => _navigate(`${baseName}/cost&income`)}
          readOnly
        />
        <label htmlFor="check4">ต้นทุนและรายได้</label>
      </div>
      {_location === `${baseName}/cost&income` && (
        <div className={`${styles.checkChildrenBox}`}>
          {/* <div>
            <input
              type="checkbox"
              name=""
              id="checkMenu10"
              value={5}
              checked={switchChecked3 === "5"}
              onClick={(e) => onSwitchChecked3(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu10">รายวัน</label>
          </div> */}
          <div>
            <input
              type="checkbox"
              name=""
              id="checkMenu11"
              value={6}
              checked={switchChecked3 === "6"}
              onClick={(e) => onSwitchChecked3(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu11">รายเดือน</label>
          </div>
        </div>
      )}
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name=""
          id="check5"
          checked={_location === `${baseName}/selling-plants`}
          onClick={() => _navigate(`${baseName}/selling-plants`)}
          readOnly
        />
        <label htmlFor="check5">แผนผลการรับซื้อเชื้อเพลิง</label>
      </div>
      {_location === `${baseName}/selling-plants` && (
        <div className={`${styles.checkChildrenBox}`}>
          <div>
            <input
              type="checkbox"
              name=""
              id="checkMenu12"
              value={7}
              checked={switchChecked4 === "7"}
              onClick={(e) => onSwitchChecked4(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu12">รายวัน</label>
          </div>
          <div>
            <input
              type="checkbox"
              name=""
              id="checkMenu13"
              value={8}
              checked={switchChecked4 === "8"}
              onClick={(e) => onSwitchChecked4(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu13">รายเดือน</label>
          </div>
        </div>
      )}
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name=""
          id="check14"
          checked={_location === `${baseName}/resource-harvest-2`}
          onClick={() => _navigate(baseName+'/resource-harvest-2')}
          readOnly
        />
        <label htmlFor="check14">วัตถุดิบที่คาดว่าจะเก็บเกี่ยว</label>
      </div>
    </div>
  );
};

export default MenuBar;
