import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { getProfileData } from "../../services/auth.slice";
import { defaultApi } from "../../services/api";
import styles from "./Navbar.module.scss";

const Navbar = () => {
  const profile_ = useSelector(getProfileData);
  const location = useLocation();

  const [allFactory, setAllFactory] = useState([]);
  const [allEnterprise, setAllEnterprise] = useState([]);
  const [allFactoryMember, setAllFactoryMember] = useState([]);
  const [allEnterpriseMember, setAllEnterpriseMember] = useState([]);
  const [opened, setOpened] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const userRole = useMemo(() => {
    let userRole = profile_?.userGroupId;

    return userRole;
  }, [profile_]);
  const layerFacId = useMemo(() => {
    // สร้างเซตของ factoryId ที่ต้องการตัดออก
    let facIdsToRemove = new Set(allFactory?.map((factory) => factory.id));

    // กรองข้อมูลใน allFacMemByFacId โดยตรวจสอบว่า factoryId อยู่ในเซต factoryIdsToRemove หรือไม่
    let filteredUserData = allFactoryMember?.filter((user) =>
      facIdsToRemove.has(user.factoryId)
    );

    // กรองข้อมูลใน filteredUserData โดยตรวจสอบว่า userId ตรงกับ profileData.id หรือไม่
    let findedUserData = filteredUserData.find(
      (user) => profile_?.id === user.userId
    );

    let findFac = allFactory?.find(
      (item) =>
        item.createdBy === profile_?.id || item.id === findedUserData?.factoryId
    );
    let output = findFac?.id;

    return output;
  }, [allFactory, profile_, allFactoryMember]);
  const layerEntId = useMemo(() => {
    // สร้างเซตของ enterpriseId ที่ต้องการตัดออก
    let entIdsToRemove = new Set(
      allEnterprise.map((enterprise) => enterprise.id)
    );

    // กรองข้อมูลใน allEntMemByEntId โดยตรวจสอบว่า enterpriseId อยู่ในเซต enterpriseIdsToRemove หรือไม่
    let filteredUserData = allEnterpriseMember?.filter((user) =>
      entIdsToRemove.has(user.enterpriseId)
    );
    // กรองข้อมูลใน filteredUserData โดยตรวจสอบว่า userId ตรงกับ profileData.id หรือไม่
    let findedUserData = filteredUserData.find(
      (user) => profile_?.id === user.userId
    );

    let findEnt = allEnterprise?.find(
      (item) =>
        item.createdBy === profile_?.id ||
        item.id === findedUserData?.enterpriseId
    );
    let output = findEnt?.factoryId;

    return output;
  }, [allEnterprise, profile_, allEnterpriseMember]);

  const getAllFactory = async () => {
    try {
      const res = await defaultApi.getAllFactory();
      setAllFactory(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllEnterprise = async () => {
    try {
      const res = await defaultApi.getAllEnterprise();
      setAllEnterprise(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllFactoryMember = async () => {
    try {
      const res = await defaultApi.getAllFactoryMember();

      setAllFactoryMember(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllEnterpriseMember = async () => {
    try {
      const res = await defaultApi.getAllEnterpriseMember();

      setAllEnterpriseMember(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let reportDirectory = null;

  switch (userRole) {
    case 5:
      reportDirectory = "/admin";
      break;

    default:
      reportDirectory = "";
      break;
  }

  useEffect(() => {
    // Use functions
    getAllFactory();
    getAllEnterprise();
    getAllFactoryMember();
    getAllEnterpriseMember();
  }, []);

  return (
    <nav className={`${styles.container}`}>
      <FaBars
        className={`${styles.fa_bars}`}
        size={30}
        cursor={"pointer"}
        onClick={() => setOpened((prev) => !prev)}
      />
      <ul
        style={
          opened
            ? { display: "flex" }
            : { display: screenWidth > 485 ? "flex" : "none" }
        }
      >
        <li>
          <NavLink to={`/home`}>
            {userRole !== 4
              ? "หน้าหลัก"
              : location.pathname !== "/home"
              ? "กลับ"
              : ""}
          </NavLink>
        </li>
        {userRole === 1 && layerFacId !== undefined && (
          <li>
            <NavLink to={`/manage/${layerFacId}`}>การจัดการโรงไฟฟ้า</NavLink>
          </li>
        )}
        {userRole === 2 && layerEntId !== undefined && (
          <li>
            <NavLink to={`/manage/${layerEntId}`}>การจัดการวิสาหกิจ</NavLink>
          </li>
        )}
        {userRole !== 4 && (
          <li>
            <NavLink to={`${reportDirectory}/report`}>รายงาน</NavLink>
          </li>
        )}
        {userRole !== 4 && userRole !== 5 && (
          <li>
            <NavLink to={`/map-overview`}>แผนที่</NavLink>
          </li>
        )}
        {userRole === 5 && (
          <li>
            <NavLink to={`/admin/map-overview`}>แผนที่</NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
