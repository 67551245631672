/* Set Data */
export const setLocalStorageData = (key, value) => {
  typeof value === "object"
    ? localStorage.setItem(key, JSON.stringify(value))
    : localStorage.setItem(key, value);
};

/* Get Data */
export const getLocalStorageData = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    return localStorage.getItem(key);
  }
};

/* Remove Data */
export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};
