import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import Autocomplete from "react-autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import icon from "../../../../assets/img/Icon";
import {
  convertDateThai,
  convertDateEu,
} from "../../../../utils/format/Date.format";
import {
  CheckAddRecordName,
  CheckNumber,
  CheckValidLength,
} from "../../../../utils/validation_input/Validation";
import { defaultApi } from "../../../../services/api";
import Message from "../../../../utils/message/Message";
import {
  getFactoryData,
  getSubmitActive,
  setSubmitActive,
} from "../../../../services/factory.slice";
import styles from "./scss/Expenses.module.scss";

const initData = {
  factoryRecordId: "" /* id */,
  txDate: "" /* recordedAt */,
  total: "" /* price */,
  paymentStatus: "unreceived",
  note: "sell" /* recordDetails => name */,
  factoryId: "" /* factoryId */,
  enterpriseId: "" /* enterpriseId */,
};

const AddForm = ({
  _dataAdd,
  _setDataAdd,
  _initDataAdd,
  _switch,
  _onSwitch,
  _validErr,
  _setValidErr,
  _allRecordDetails,
  _fetchData,
  _fetchData2,
  _allEnterprise,
}) => {
  const dispatchFn_ = useDispatch();
  const factoryData = useSelector(getFactoryData);
  const submitActive = useSelector(getSubmitActive);

  const [data, setData] = useState(initData);
  const [newRecordName, setNewRecordName] = useState({
    name: "",
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [validErr, setValidErr] = useState(true);

  /* DatePicker : START */
  const onChangeDate = (e) => {
    const dateFormat = convertDateEu(e);
    _setDataAdd({ ..._dataAdd, recordedAt: dateFormat });
    setSelectedDate(e);
    setOpenDatePicker(!openDatePicker);
  };
  /* DatePicker : END */

  /* AutoComplete : START */
  const _onChange = (e) => {
    setNewRecordName({ name: e });
    if (CheckAddRecordName(e, _allRecordDetails)) {
      setValidErr(true);
    } else {
      setValidErr(false);
    }
  };
  const _onSelect = (e) => {
    setNewRecordName(e);

    if (CheckAddRecordName(e.name, _allRecordDetails)) {
      setValidErr(true);
    } else {
      setValidErr(false);
    }
  };
  /* AutoComplete : END */

  const onChange = (e) => {
    const { name, value } = e.target;
    _setDataAdd({ ..._dataAdd, [name]: value });
  };
  const onChangeRecordId = (e) => {
    const { name, value } = e.target;
    if (value === "1") {
      _setDataAdd({ ..._dataAdd, [name]: value });
    } else {
      _setDataAdd({ ..._dataAdd, recordId: value, enterpriseId: null });
    }
  };
  const createRecordDetail = useCallback(async () => {
    if (CheckValidLength(newRecordName.name)) {
      setValidErr(true);
      dispatchFn_(setSubmitActive(false));
      return;
    }
    if (validErr) {
      dispatchFn_(setSubmitActive(false));
      return;
    }

    const loading = toast.loading(Message.notify.loading);
    try {
      const res = await defaultApi.createRecordDetail(newRecordName);
      // เพิ่มข้อมูลในสเตจ
      _setDataAdd({ ..._dataAdd, recordId: res.data.id });
      // Clear redux state เพื่อไม่ให้เรียก Api นี้ซ้ำอีกครั้ง
      dispatchFn_(setSubmitActive(false));
      // รอรับไอดีของข้อมูลที่สร้างใหม่เพิ่มในสเตจก่อนค่อน submit ขั้นสุดท้าย
      setTimeout(() => {
        dispatchFn_(setSubmitActive("Final"));
      }, 1000);
      // Reset state
      _setValidErr(false);
    } catch (error) {
      console.log(error);
      dispatchFn_(setSubmitActive(false));
      toast.error("เพิ่มรายการค่าใช้จ่ายไม่สำเร็จ", { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  }, [
    _dataAdd,
    _setDataAdd,
    _setValidErr,
    dispatchFn_,
    newRecordName,
    validErr,
  ]);
  const onSubmit = () => {
    if (!_switch) {
      console.log("false");
      if (CheckValidLength(_dataAdd.recordId)) return _setValidErr(true);
      if (CheckValidLength(_dataAdd.recordedAt)) return _setValidErr(true);
      if (CheckNumber(_dataAdd.price)) return _setValidErr(true);
    } else {
      console.log("true");
      if (CheckValidLength(_dataAdd.recordedAt)) return _setValidErr(true);
      if (CheckNumber(_dataAdd.price)) return _setValidErr(true);
      if (CheckValidLength(newRecordName.name)) return setValidErr(true);
    }

    if (newRecordName.name !== "") {
      dispatchFn_(setSubmitActive("createRecordDetail"));
    } else {
      dispatchFn_(setSubmitActive("Ready"));
    }
  };
  const createTransaction = useCallback(
    async (res) => {
      const _note = _allRecordDetails?.find(
        (item) => item.id === +_dataAdd?.recordId
      )?.name;
      const params = {};
      Object.keys(data).forEach((key) => {
        if (key === "factoryRecordId") {
          params[key] = res?.id;
        } else if (key === "txDate") {
          params[key] = res?.recordedAt;
        } else if (key === "total") {
          params[key] = res?.price;
        } else if (key === "note") {
          params[key] = _note;
        } else if (key === "factoryId") {
          params[key] = res?.factoryId;
        } else if (key === "enterpriseId") {
          params[key] = +res?.enterpriseId;
        } else {
          params[key] = data[key];
        }
      });
      try {
        const res = await defaultApi.createTransaction(params);
        console.log(res.statusText);

        setData(initData);
        //
        _fetchData2();
      } catch (error) {
        console.log(error);
      }
    },
    [data, _allRecordDetails, _dataAdd, _fetchData2]
  );
  const createFactoryRecord = useCallback(async () => {
    const closeModal = document.querySelector("#insert-close");
    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_dataAdd).forEach((key) => {
      if (key === "recordId") {
        params[key] = +_dataAdd[key];
      } else if (key === "price") {
        params[key] = +_dataAdd[key];
      } else if (key === "factoryId") {
        params[key] = factoryData?.id;
      } else {
        params[key] = _dataAdd[key];
      }
    });
    try {
      const res = await defaultApi.createFactoryRecord(params);

      if (res.data.recordId === 1) {
        createTransaction(res.data);
      }
      // Reset form
      _setDataAdd(_initDataAdd);
      setNewRecordName({ name: "" });
      // Reset state
      _setValidErr(false);
      // Fetch data allFactoryRecord & allRecordDetail
      _fetchData();
      _fetchData2();
      // Close modal
      closeModal.click();
      toast.success(Message.notify.insert_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.insert_error, { duration: 3000 });
    } finally {
      dispatchFn_(setSubmitActive(false));
      toast.dismiss(loading);
    }
  }, [
    _dataAdd,
    _initDataAdd,
    _setDataAdd,
    _setValidErr,
    _fetchData,
    _fetchData2,
    dispatchFn_,
    createTransaction,
    factoryData,
  ]);

  const onBackAndReset = () => {
    _setDataAdd(_initDataAdd);
    _setValidErr(false);
    _onSwitch(false);
    setSelectedDate(new Date());
  };

  useEffect(() => {
    /* สร้างรายการค่าใช้จ่ายใหม่ : START */
    if (submitActive === "createRecordDetail") {
      createRecordDetail();
    }
    /* สร้างรายการค่าใช้จ่ายใหม่ : END */
  }, [createRecordDetail, submitActive]);

  useEffect(() => {
    /* Insert data to database : START */
    if (submitActive === "Ready") {
      // เพิ่มข้อมูลแบบไม่มีการสร้างรายการค่าใช้จ่ายใหม่
      createFactoryRecord();
    } else if (submitActive === "Final" && _dataAdd.recordId !== "") {
      // เพิ่มข้อมูลแบบมีการสร้างรายการค่าใช้จ่ายใหม่
      createFactoryRecord();
    }
    /* Insert data to database : END */
  }, [createFactoryRecord, submitActive, _dataAdd]);

  useEffect(() => {
    /* Function close dropdown 'DatePicker' : START */
    const onCloseDropdownDatePicker = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_datepicker_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDatePicker(false);
      }
    };

    if (openDatePicker) {
      document.addEventListener("click", onCloseDropdownDatePicker);
    }
    /* Function close dropdown 'DatePicker' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDatePicker);
    };
  }, [openDatePicker]);

  return (
    <div
      className={`${styles.modal_} modal fade`}
      id="staticBackdrop2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className={`${styles.modal_content} modal-content`}>
          <div className={`${styles.modal_header} modal-header`}>
            <h5
              className={`${styles.modal_title} modal-title`}
              id="staticBackdropLabel"
            >
              เพิ่มรายการค่าใช้จ่าย
            </h5>
            <button
              type="button"
              id="insert-close"
              className={`${styles.btn_close} btn-close`}
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onBackAndReset}
            ></button>
          </div>
          <div className={`${styles.modal_body} modal-body`}>
            <div className={`${styles.input_}`}>
              <h5>
                วันที่ <sup className={`${styles.asterisk}`}>*</sup>
              </h5>
              <div
                className={`${styles.input_unit} ${
                  _validErr && _dataAdd.recordedAt === "" ? "error" : ""
                }`}
              >
                <div className={`${styles.info_box}`}>
                  <p>
                    {_dataAdd.recordedAt !== ""
                      ? convertDateThai(_dataAdd.recordedAt)
                      : ""}
                  </p>
                </div>
                <div
                  className={`${styles.unit}`}
                  onClick={() => setOpenDatePicker(!openDatePicker)}
                >
                  <img src={icon.calendar} alt="" id="fac_datepicker_d" />
                </div>
              </div>
              {openDatePicker && (
                <DatePicker
                  selected={selectedDate}
                  onChange={onChangeDate}
                  inline
                />
              )}
              {_validErr && (
                <p className={`${styles.err_message}`}>
                  {CheckValidLength(_dataAdd.recordedAt)}
                </p>
              )}
            </div>
            <div className={`${styles.input_}`}>
              <h5>
                รายการค่าใช้จ่าย <sup className={`${styles.asterisk}`}>*</sup>
              </h5>
              {!_switch ? (
                <>
                  <select
                    name="recordId"
                    className={`${
                      _validErr && _dataAdd.recordId === "" ? "error" : ""
                    }`}
                    onChange={onChangeRecordId}
                  >
                    <option
                      selected={_dataAdd.recordId !== "" ? false : true}
                      style={{ display: "none" }}
                    >
                      เลือกรายการ
                    </option>
                    {_allRecordDetails.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {_validErr && (
                    <p className={`${styles.err_message}`}>
                      {CheckValidLength(_dataAdd.recordId)}
                    </p>
                  )}
                </>
              ) : (
                <>
                  <div className={`${styles.auto_com_box}`}>
                    <Autocomplete
                      inputProps={{
                        className: `${styles.input_box} ${
                          validErr && newRecordName.name === "" ? "error" : ""
                        }`,
                      }}
                      wrapperStyle={{ fontSize: "20px", width: "100%" }}
                      items={_allRecordDetails}
                      shouldItemRender={(item, value) =>
                        item.name.toLowerCase().indexOf(value.toLowerCase()) >
                        -1
                      }
                      getItemValue={(item) => item.name}
                      renderItem={(item, highlighted) => (
                        <div
                          key={item.id}
                          style={{
                            backgroundColor: highlighted
                              ? "#eee"
                              : "transparent",
                          }}
                        >
                          {item.name}
                        </div>
                      )}
                      value={newRecordName.name}
                      onChange={(e) => _onChange(e.target.value)}
                      onSelect={(name) => _onSelect({ name })}
                    />
                  </div>
                  {validErr && (
                    <p className={`${styles.err_message}`}>
                      {CheckAddRecordName(
                        newRecordName.name,
                        _allRecordDetails
                      )}
                    </p>
                  )}
                </>
              )}
            </div>
            {_dataAdd.recordId === "" && !_switch && (
              <div className={`${styles.record_other}`}>
                <h6
                  onClick={() => {
                    _onSwitch(true);
                    setNewRecordName({ name: "" });
                    _setDataAdd({ ..._dataAdd, enterpriseId: null });
                  }}
                >
                  เพิ่มรายการอื่นๆ
                </h6>
              </div>
            )}
            {_dataAdd.recordId === "1" ? (
              <div className={`${styles.input_}`}>
                <h5>วิสาหกิจชุมชน</h5>
                <select
                  name="enterpriseId"
                  onChange={onChange}
                  disabled={_dataAdd.recordId === "1" ? false : true}
                >
                  <option
                    selected={_dataAdd.enterpriseId !== null ? false : true}
                    style={{ display: "none" }}
                  >
                    เลือกวิสาหกิจชุมชน
                  </option>
                  {_allEnterprise?.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : (
              ""
            )}
            <div className={`${styles.input_}`}>
              <h5>
                ยอดเงิน (บาท) <sup className={`${styles.asterisk}`}>*</sup>
              </h5>
              <input
                type="text"
                name="price"
                value={_dataAdd.price || ""}
                className={`${
                  _validErr && _dataAdd.price === "" ? "error" : ""
                }`}
                onChange={onChange}
              />
              {_validErr && (
                <p className={`${styles.err_message}`}>
                  {CheckNumber(_dataAdd.price)}
                </p>
              )}
            </div>
          </div>
          <div className={`${styles.modal_footer} modal-footer`}>
            <button type="button" className="button_sm" onClick={onSubmit}>
              บันทึก
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddForm;
