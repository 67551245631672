import React from "react";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from "../scss/ReportStatus.module.scss";
import { formatterTooltip, formatterXaxis_1, formatterYaxis } from "../../../../helpers/suffixchart.helper";

const ChartsStatus = React.forwardRef((prop, ref) => {

  let ranges = [
    { divider: 1e18, suffix: "E" },
    { divider: 1e15, suffix: "P" },
    { divider: 1e12, suffix: "T" },
    { divider: 1e9, suffix: "G" },
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "K" },
  ];


  let other = []
  let owner = []
  let renter = []
  try {
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    for (let index = 0; index < prop.datain.enterpriseOwnershipOther.length; index++) {
      if (Number(prop.yearChk) === Number(year)) {
        if (prop.datain.enterpriseOwnershipOther[index].month > month) {
          other[index] = null
          owner[index] = null
          renter[index] = null
        } else {
          other[index] = prop.datain.enterpriseOwnershipOther[index].value
          owner[index] = prop.datain.enterpriseOwnershipOwner[index].value
          renter[index] = prop.datain.enterpriseOwnershipRenter[index].value
        }
      } else if (Number(prop.yearChk) < Number(year)) {
        other[index] = prop.datain.enterpriseOwnershipOther[index].value
        owner[index] = prop.datain.enterpriseOwnershipOwner[index].value
        renter[index] = prop.datain.enterpriseOwnershipRenter[index].value
      }

    }
  } catch (error) {

  }

  const options = {
    chart: {
      type: "column",
      height: 450,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "จำนวน (คน)",
      },
      labels: {
        formatter: formatterYaxis,
        style: {
          fontSize: "13px",
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "18px",
      },
      enabled: true,
      ...formatterTooltip
      // shared: true,
      // split: false,
      // formatter: function () {
      //   var point = this;
      //   let i = ''
      //   for (let index = 0; index < point.points.length; index++) {
      //     const element = point.points[index];

      //     if (index === 0) {
      //       i = i + (
      //         point.x + '<br/><b>' + element.series.name + '</b> ' + Number(element.y).toLocaleString() + ' คน'
      //       )
      //     } else {
      //       i = i + (
      //         '<br/><b>' + element.series.name + '</b> ' + Number(element.y).toLocaleString() + ' คน'
      //       )
      //     }
      //   }
      //   return i
      // }
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            for (let i = 0; i < ranges.length; i++) {
              if (this.y >= ranges[i].divider) {
                return (
                  this.y.toString() + ranges[i].suffix
                );
              }
            }
            return this.y.toString();
          },
        },
        enableMouseTracking: true,
      },
      column: {
        dataLabels: {
          enabled: true,
          style: {
            fontSize: 16,
          },
          formatter: formatterXaxis_1,
        },
      },
    },
    colors: ["#ffcf00", "#1b75bc", "#ff0000"],
    exporting: {
      chartOptions: { // specific options for the exported image
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "6px",
              },
              formatter: function () {
                for (let i = 0; i < ranges.length; i++) {
                  if (this.y >= ranges[i].divider) {
                    return (
                      Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
                    );
                  }
                }
                if (Number(this.y) === 0) {
                  return null
                  //return this.y.toString();
                } else {
                  return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                }
              },
            },
            enableMouseTracking: true,
          },
        },
        xAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
      },
      buttons: {
        contextButton: {
          enabled: false,
        },
        button: {
          text: "Export",
          theme: {
            fill: "#f9df70",
            stroke: "#f9df70",
            states: {
              hover: {
                fill: "#fcc",
                stroke: "#f00",
              },
              select: {
                fill: "#cfc",
                stroke: "#0f0",
              },
            },
          },
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "bottom",
        y: 0,
      },
    },
    series: [
      {
        name: "ผู้เช่า",
        data: renter,
      },
      {
        name: "เจ้าของกรรมสิทธิ์",
        data: owner,
      },
      {
        name: "อื่นๆ",
        data: other,
      },
    ],
  };
  return (
    <div className={`${styles.charts}`}>
      <HighCharts ref={ref} options={options} />
    </div>
  );
});

export default ChartsStatus;
