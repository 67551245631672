import React, { useState, useMemo } from "react";
import Pagination from "../../../../components/pagination/Pagination";
import { convertDateThai } from "../../../../utils/format/Date.format";
import { exportCsvFile, exportXlsxFile } from "../../../../helpers/func.helper";
import ExportPopup from "../../../../layouts/exportpopup/ExportPopup";
import styles from "./scss/RawMaterial.module.scss";

const pageSize = 10;

const Table = ({
  _allEnterpriseSupply,
  _currentPage,
  _setCurrentPage,
  _onSwitchPage,
  _obj,
  _resetFilter,
}) => {
  const [_triggerExport, setTriggerExport] = useState(false);

  /* Pagination table : START */
  const currentTableData = useMemo(() => {
    const firstPageIndex = (_currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;

    return _allEnterpriseSupply.slice(firstPageIndex, lastPageIndex);
  }, [_allEnterpriseSupply, _currentPage]);
  /* Pagination table : END */

  /* Export csv and excel : START */
  const onExportCsv = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "EnterpriseSupplyReport";
    let fileType = "csv";
    let obj = _obj;
    // Ready
    exportCsvFile(exportName, fileType, obj);
  };
  const onExportXlsx = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "EnterpriseSupplyReport";
    let fileType = "excel";
    let obj = _obj;
    // Ready
    exportXlsxFile(exportName, fileType, obj);
  };
  /* Export csv and excel : END */

  const onBackAndReset = () => {
    _onSwitchPage(null);
    _resetFilter();
  };

  return (
    <>
      <div className={`${styles.table_containerR}`}>
        <div className={`${styles.table_box} table-responsive`}>
          <table className={`table`}>
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th>รหัสแปลง</th>
                <th>ชื่อ - นามสกุล</th>
                <th>ชนิดเชื้อเพลิง / วัตถุดิบ</th>
                <th>พื้นที่เพาะปลูก(ไร่)</th>
                <th>วันที่เริ่มเพาะปลูก</th>
                <th>วันที่คาดว่าจะเก็บเกี่ยว</th>
                <th>ผลผลิตที่คาดว่าจะได้รับ(ตัน)</th>
                <th>วันที่เก็บเกี่ยว</th>
                <th>ผลผลิตที่ได้รับ(ตัน)</th>
                <th>ผลผลิต(ตัน/ไร่)</th>
                <th>บันทึกเพิ่มเติม</th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.map((item, index) => {
                return (
                  <tr key={(_currentPage - 1) * pageSize + (index + 1)}>
                    <td>{(_currentPage - 1) * pageSize + (index + 1)}</td>
                    <td>{item.parcelId}</td>
                    <td>
                      {item.firstname} {item.lastname}
                    </td>
                    <td>{item.name}</td>
                    <td>{item.area}</td>
                    <td>{convertDateThai(item.plantDate)}</td>
                    <td>{convertDateThai(item.harvestPlan)}</td>
                    <td>{item.yieldTarget}</td>
                    <td>
                      {item.harvestDate === null
                        ? "-"
                        : convertDateThai(item.harvestDate)}
                    </td>
                    <td>{item.yield}</td>
                    <td>
                      {item.yieldPerAreaRai?.toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td>{item.note || "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className={`${styles.pagination_}`}>
          <Pagination
            pageSize={pageSize}
            totalCount={_allEnterpriseSupply.length}
            currentPage={_currentPage}
            onPageChange={(page) => _setCurrentPage(page)}
          />
        </div>
      </div>

      <div className={`${styles.button_right}`}>
        <ExportPopup
          label={"document"}
          trigger={_triggerExport}
          event={{ csv: onExportCsv, xlsx: onExportXlsx }}
          position={{ right: "10%" }}
        />
        <button
          type="button"
          className={`${styles.btn_}`}
          onClick={() => setTriggerExport(!_triggerExport)}
        >
          Export
        </button>
        <button
          type="button"
          className={`${styles.btn_}`}
          onClick={onBackAndReset}
        >
          กลับ
        </button>
      </div>
    </>
  );
};

export default Table;
