import React from "react";
import { useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Index from "./pages/index/Index";
import { index as Admin } from "./pages/admin/index";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Profile from "./pages/profile/Profile";
import Register from "./pages/register/Register";
import Report from "./pages/report/Index.js";
import Manage from "./pages/manage/Index";
import TermsPage from "./pages/terms/TermsPage";
import IndexRoutes from "./routes/Index.routes";
import AuthRoutes from "./routes/Auth.routes";
import { setProfileData } from "./services/auth.slice";
import "./assets/scss/global.scss";
import MapOverview from "./pages/map_overview/MapOverview";
import AdminRoutes from "./routes/Admin.routes";
import { setAllFactoryData } from "./services/factory.slice";
import { setAllEnterpriseData } from "./services/enterprise.slice";
import ExportPopup from "./layouts/exportpopup/ExportPopup";
import { getLocalStorageData } from "./helpers/local_storage.helper.js";
import { getCookiesStorageData } from "./helpers/cookies_storage.helper.js";

const App = () => {
  const dispatchFn_ = useDispatch();

  const profileData = getCookiesStorageData("userProfile");
  const allFactory = getLocalStorageData("allFactory");
  const allEnterprise = getLocalStorageData("allEnterprise");
  dispatchFn_(setAllEnterpriseData(allEnterprise));
  dispatchFn_(setAllFactoryData(allFactory));
  dispatchFn_(setProfileData(profileData));

  return (
    <>
      <Routes>
        <Route element={<IndexRoutes />}>
          <Route index path="/" element={<Index />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/terms" element={<TermsPage />} />
        </Route>

        <Route element={<AuthRoutes />}>
          <Route index path="/home" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/report/*" element={<Report />} />
          <Route path="/manage/:layerId/*" element={<Manage />} />
          <Route path="/map-overview" element={<MapOverview />} />
        </Route>

        <Route element={<AdminRoutes />}>
          <Route index path="/admin/*" element={<Admin />} />
          <Route path="/admin/profile" element={<Profile />} />
          <Route path="/admin/report/*" element={<Report />} />
          <Route path="/admin/map-overview" element={<MapOverview />} />
        </Route>
      </Routes>

      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default App;
