import React from "react";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi";
import { usePagination, DOTS } from "./UsePagination";
import styles from "./Pagination.module.scss";

//Pagination
const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  _themeColor,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  //onPrevious
  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  //onNext
  const onNext = () => {
    onPageChange(currentPage + 1);
  };
  //lastPage
  let lastPage = paginationRange[paginationRange?.length - 1];

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  return (
    <>
      <div className="d-flex justify-content-center">
        <ul className={`${styles.pagination_container}`}>
          <li
            className={`${styles.previous_arrow} ${
              currentPage === 1 && "disabled"
            }`}
            onClick={onPrevious}
          >
            <HiChevronDoubleLeft className={`${styles.arrow}`} />
          </li>

          {paginationRange.map((pageNumber, idx) => {
            if (pageNumber === DOTS) {
              return (
                <li
                  key={idx}
                  className={`${styles.pagination_item} dots ${_themeColor?.class}`}
                >
                  &#8230;
                </li>
              );
            }

            return (
              <li
                key={idx}
                className={`${styles.pagination_item} ${
                  pageNumber === currentPage ? "selected" : _themeColor?.class
                } `}
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </li>
            );
          })}

          <li
            className={`${styles.next_arrow} ${
              currentPage === lastPage && "disabled"
            }`}
            onClick={onNext}
          >
            <HiChevronDoubleRight className={`${styles.arrow}`} />
          </li>
        </ul>
      </div>
    </>
  );
};

export default Pagination;
