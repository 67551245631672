import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { defaultApi } from '../../services/api';
import Message from '../../utils/message/Message';
import style from "./scss/Headtable.module.scss";

const pmsLst = [
    {
        id: "1",
        name: "รายได้กลุ่มเกษตรกร",
    }, {
        id: "2",
        name: "จำนวนสมาชิก",
    }, {
        id: "3",
        name: "แผนผลการผลิตเชื้อเพลิง",
    }, {
        id: "4",
        name: "รายได้ - รายจ่ายโรงไฟฟ้า",
    }, {
        // id: "5",
        // name: "กำลังผลิตไฟฟ้า",
        id: "5",
        name: "ปริมาณการผลิตไฟฟ้า",
    }, {
        id: "6",
        name: "แผนผลการผลิตไฟฟ้า",
    }, {
        id: "7",
        name: "วัตถุดิบที่คาดว่าจะเก็บเกี่ยว",
    }
];

// const valRep = {
//     report1: "",
//     report2: "",
//     report3: "",
//     report4: "",
//     report5: "",
//     report6: ""
// }

export const ManagePmsData = ({ toggerCheck }) => {

    const [_userGrp, setUserGrp] = useState([])

    const [_pmsRep, setPmsRep] = useState({
        report1: "",
        report2: "",
        report3: "",
        report4: "",
        report5: "",
        report6: "",
        report7: ""
    })

    const [_subGroup, subGroup] = useState("");

    const [_appearContent, appearContent] = useState(true);

    const [_stateSubmit, stateSubmit] = useState(true)

    const infoData = async () => {
        try {
            const res = await defaultApi.getLocalGrp();
            setUserGrp(res.data)
        } catch (error) {
            console.log(error);
        }
    }

    const changesubGroup = (val) => {
        stateSubmit(true)
        subGroup(val)
        if (val === "") {
            appearContent(true)
        } else {
            appearContent(false)
        }

        setPmsRep({
            report1: "",
            report2: "",
            report3: "",
            report4: "",
            report5: "",
            report6: "",
            report7: ""
        })

        const setValidDefault = (caseVal, nameItem) => {
            switch (Number(caseVal)) {
                case 1:
                    iObj = ({ ...iObj, report1: nameItem })
                    //setPmsRep({ ..._pmsRep, report1: nameItem })
                    break;
                case 2:
                    iObj = ({ ...iObj, report2: nameItem })
                    //setPmsRep({ ..._pmsRep, report2: nameItem })
                    break;
                case 3:
                    iObj = ({ ...iObj, report3: nameItem })
                    //setPmsRep({ ..._pmsRep, report3: nameItem })
                    break;
                case 4:
                    iObj = ({ ...iObj, report4: nameItem })
                    //setPmsRep({ ..._pmsRep, report4: nameItem })
                    break;
                case 5:
                    iObj = ({ ...iObj, report5: nameItem })
                    //setPmsRep({ ..._pmsRep, report5: nameItem })
                    break;
                case 6:
                    iObj = ({ ...iObj, report6: nameItem })
                    //setPmsRep({ ..._pmsRep, report6: nameItem })
                    break;
                default:
                    break;
            }
        }

        let iArr = []
        let iObj = {
            report1: "",
            report2: "",
            report3: "",
            report4: "",
            report5: "",
            report6: "",
            report7: ""
        }

        //data _userGrp
        _userGrp.map((data01) => {
            if (Number(val) === data01.id) {
                let obj01 = JSON.parse(data01.permission)
                if (obj01 === null) { return null }
                //console.clear()
                obj01.map((data02) => {
                    iArr.push(data02.name)
                    return null
                })
            }
            return null
        })

        pmsLst.map((data01) => {
            iArr.map((data02) => {
                if (data01.name === data02) {
                    //setValidDefault(Number(data01.id), JSON.stringify({ name: data01.name }))
                    setValidDefault(Number(data01.id), { name: data01.name })
                }
                return null
            })
            return null
        })

        setPmsRep(iObj)
    }

    const lstChkData = () => {
        try {
            return pmsLst?.map((data01) => {
                let iOutlet
                if (_pmsRep?.report1 !== "" && Number(data01.id) === 1) {
                    iOutlet = (
                        <div key={data01.id} style={_appearContent === true ? { "display": "none" } : null} className={`${style.sfbox}`}>
                            <div>
                                <div className={`${style.btn_checkbox}`}>
                                    <input id={data01.id} type={"checkbox"}
                                        defaultValue={true} checked={true}
                                        onChange={(e) => { setdataPms(e, data01.name) }} />
                                </div>
                                <label style={{ "cursor": "pointer" }} htmlFor={data01.id}>{data01.name}</label>
                            </div>
                            <div>
                            </div>
                        </div >
                    )
                } else if (_pmsRep?.report2 !== "" && Number(data01.id) === 2) {
                    iOutlet = (
                        <div key={data01.id} style={_appearContent === true ? { "display": "none" } : null} className={`${style.sfbox}`}>
                            <div>
                                <div className={`${style.btn_checkbox}`} >
                                    <input id={data01.id} type={"checkbox"}
                                        defaultValue={true} checked={true}
                                        onChange={(e) => { setdataPms(e, data01.name) }} />
                                </div>
                                <label style={{ "cursor": "pointer" }} htmlFor={data01.id}>{data01.name}</label>
                            </div>
                            <div>
                            </div>
                        </div >
                    )
                } else if (_pmsRep?.report3 !== "" && Number(data01.id) === 3) {
                    iOutlet = (
                        <div key={data01.id} style={_appearContent === true ? { "display": "none" } : null} className={`${style.sfbox}`}>
                            <div>
                                <div className={`${style.btn_checkbox}`}>
                                    <input id={data01.id} type={"checkbox"}
                                        defaultValue={true} checked={true}
                                        onChange={(e) => { setdataPms(e, data01.name) }} />
                                </div>
                                <label style={{ "cursor": "pointer" }} htmlFor={data01.id}>{data01.name}</label>
                            </div>
                            <div>
                            </div>
                        </div >
                    )
                } else if (_pmsRep?.report4 !== "" && Number(data01.id) === 4) {
                    iOutlet = (
                        <div key={data01.id} style={_appearContent === true ? { "display": "none" } : null} className={`${style.sfbox}`}>
                            <div>
                                <div className={`${style.btn_checkbox}`}>
                                    <input id={data01.id} type={"checkbox"}
                                        defaultValue={true} checked={true}
                                        onChange={(e) => { setdataPms(e, data01.name) }} />
                                </div>
                                <label style={{ "cursor": "pointer" }} htmlFor={data01.id}>{data01.name}</label>
                            </div>
                            <div>
                            </div>
                        </div >
                    )
                } else if (_pmsRep?.report5 !== "" && Number(data01.id) === 5) {
                    iOutlet = (
                        <div key={data01.id} style={_appearContent === true ? { "display": "none" } : null} className={`${style.sfbox}`}>
                            <div>
                                <div className={`${style.btn_checkbox}`}>
                                    <input id={data01.id} type={"checkbox"}
                                        defaultValue={true} checked={true}
                                        onChange={(e) => { setdataPms(e, data01.name) }} />
                                </div>
                                <label style={{ "cursor": "pointer" }} htmlFor={data01.id}>{data01.name}</label>
                            </div>
                            <div>
                            </div>
                        </div >
                    )
                } else if (_pmsRep?.report6 !== "" && Number(data01.id) === 6) {
                    iOutlet = (
                        <div key={data01.id} style={_appearContent === true ? { "display": "none" } : null} className={`${style.sfbox}`}>
                            <div>
                                <div className={`${style.btn_checkbox}`}>
                                    <input id={data01.id} type={"checkbox"} defaultValue={true} checked={true} onChange={(e) => { setdataPms(e, data01.name) }} />
                                </div>
                                <label style={{ "cursor": "pointer" }} htmlFor={data01.id}>{data01.name}</label>
                            </div>
                            <div>
                            </div>
                        </div >
                    )
                } else if (_pmsRep?.report7 !== "" && Number(data01.id) === 7) {
                    iOutlet = (
                        <div key={data01.id} style={_appearContent === true ? { "display": "none" } : null} className={`${style.sfbox}`}>
                            <div>
                                <div className={`${style.btn_checkbox}`}>
                                    <input id={data01.id} type={"checkbox"} defaultValue={true} checked={true} onChange={(e) => { setdataPms(e, data01.name) }} />
                                </div>
                                <label style={{ "cursor": "pointer" }} htmlFor={data01.id}>{data01.name}</label>
                            </div>
                            <div>
                            </div>
                        </div >
                    )
                } else {
                    iOutlet = (
                        <div key={data01.id} style={_appearContent === true ? { "display": "none" } : null} className={`${style.sfbox}`}>
                            <div>
                                <div className={`${style.btn_checkbox}`}>
                                    <input id={data01.id}
                                        defaultValue={false} checked={false}
                                        type={"checkbox"} onChange={(e) => { setdataPms(e, data01.name) }} />
                                </div>
                                <label style={{ "cursor": "pointer" }} htmlFor={data01.id}>{data01.name}</label>
                            </div>
                            <div>
                            </div>
                        </div >
                    )
                }
                return iOutlet
            })
        } catch (error) {

        }
    }

    const setdataPms = (e, nameItem) => {

        nameItem = { name: nameItem }//JSON.stringify({ name: nameItem })

        switch (Number(e.target.id)) {
            case 1:
                //nameItem = { name: nameItem }
                if (e.target.checked === true) { setPmsRep({ ..._pmsRep, report1: nameItem }) } else { setPmsRep({ ..._pmsRep, report1: "" }) }
                break;
            case 2:
                //nameItem = { name: nameItem }
                if (e.target.checked === true) { setPmsRep({ ..._pmsRep, report2: nameItem }) } else { setPmsRep({ ..._pmsRep, report2: "" }) }
                break;
            case 3:
                //nameItem = { name: nameItem }
                if (e.target.checked === true) { setPmsRep({ ..._pmsRep, report3: nameItem }) } else { setPmsRep({ ..._pmsRep, report3: "" }) }
                break;
            case 4:
                //nameItem = { name: nameItem }
                if (e.target.checked === true) { setPmsRep({ ..._pmsRep, report4: nameItem }) } else { setPmsRep({ ..._pmsRep, report4: "" }) }
                break;
            case 5:
                //nameItem = { name: nameItem }
                if (e.target.checked === true) { setPmsRep({ ..._pmsRep, report5: nameItem }) } else { setPmsRep({ ..._pmsRep, report5: "" }) }
                break;
            case 6:
                //nameItem = { name: nameItem }
                if (e.target.checked === true) { setPmsRep({ ..._pmsRep, report6: nameItem }) } else { setPmsRep({ ..._pmsRep, report6: "" }) }
                break;
            case 7:
                //nameItem = { name: nameItem }
                if (e.target.checked === true) { setPmsRep({ ..._pmsRep, report7: nameItem }) } else { setPmsRep({ ..._pmsRep, report7: "" }) }
                break;
            default:
                break;
        }
    }

    const submitPms = async () => {
        const params = {}
        let iArr = []

        const checkValue = (k, v) => {
            if (v === "") {
            } else {
                iArr.push(v)
            }
        }

        Object.keys(_pmsRep).forEach((key) => {
            checkValue(key, _pmsRep[key])
        })
        //data _userGrp
        _userGrp.map((data) => {
            Object.keys(data).forEach((key) => {
                // if (key === 'permission') {
                //     //params[key] = JSON.stringify(iArr)
                //     console.log(iArr);
                // }
                switch (key) {
                    case "permission":
                        params[key] = JSON.stringify(iArr)
                        break;
                    default:
                        break;
                }
            })
            return null
        })

        const loading = toast.loading(Message.notify.update_loading);

        try {
            await defaultApi.updatePermissionGroup(_subGroup, params)
            toast.success(Message.notify.update_success, { duration: 3000 });
            infoData()
            stateSubmit(false)
        } catch (error) {
            console.log(error);
            toast.error(Message.notify.update_error, { duration: 3000 })
        } finally {
            toast.dismiss(loading);
        }
    }

    useEffect(() => {
        infoData()
    }, [])

    return (
        <>
            <div style={toggerCheck === true ? { display: 'flex' } : { display: 'none' }} className={`${style.bodytab4}`}>
                <div>
                    <div className={`${style.hpsub}`}>
                        {/* <h5>เลือกกลุ่มผู้ใช้งานย่อย</h5> */}
                        <select
                            onChange={(e) => { changesubGroup(e.target.value); }}
                        >
                            <>
                                <option value=''>เลือกกลุ่มผู้ใช้งานย่อย</option>
                                {
                                    //_userGrp data
                                    _userGrp.map((detail) => {
                                        return (
                                            <option key={detail.id} value={detail.id}>{detail.name}</option>
                                        )
                                    })
                                }
                            </>
                        </select>
                    </div>
                </div>
                <div>
                    <div className={`${style.bpsub}`} >
                        {
                            lstChkData()
                        }
                    </div>
                </div>
                {_appearContent === false && (
                    <div className="d-flex justify-content-center">
                        {_stateSubmit &&
                            (
                                <button onClick={() => { submitPms() }} className={`button_sm`}>ยืนยัน</button>
                            )
                        }
                    </div>
                )}
            </div>
        </>
    )
}