import React, { useState, useEffect, useCallback, useMemo } from "react";
import Autocomplete from "react-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { GrFormClose } from "react-icons/gr";
import { toast } from "react-hot-toast";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getFactoryData,
  getSubmitActive,
  setSubmitActive,
} from "../../../../../../services/factory.slice";
import { defaultApi } from "../../../../../../services/api";
import icon from "../../../../../../assets/img/Icon";
import {
  CheckAddPlantType,
  CheckDataStDateEn,
  CheckDateEnDataSt,
  CheckFileDoc,
  CheckNumber,
  CheckValidLength,
} from "../../../../../../utils/validation_input/Validation";
import Message from "../../../../../../utils/message/Message";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../../../utils/format/Date.format";
import { QueryGetAll } from "../../../../../../helpers/api.helper";

const animated = makeAnimated();

const initData = {
  no: "",
  status: "",
  signDate: "",
  endDate: "",
  factoryId: 0,
  enterpriseId: "",
  period: "",
  amountDay: "",
  sendDay: "",
  plantType: "",
  contractFarmingDoc: "",
  contractType: "",
  preferredDividend: "",
  communityShare: "",
};

const contractType = [
  { id: "สัญญาหลัก", name: "สัญญาหลัก" },
  { id: "สัญญารอง", name: "สัญญารอง" },
];

const Input = ({ _plant, num, setPlantArr, onDeleteInput }) => {
  const dispatchFn_ = useDispatch();
  const submitActive = useSelector(getSubmitActive);

  const [newPlant, setNewPlant] = useState({
    name: "",
  });
  const [validErr, setValidErr] = useState(true);

  const _onChange = (val) => {
    setNewPlant({ name: val });
    if (CheckAddPlantType(val, _plant)) {
      setValidErr(true);
    } else {
      setValidErr(false);
    }
  };
  const _onSelect = (val) => {
    setNewPlant(val);
    if (CheckAddPlantType(val.name, _plant)) {
      setValidErr(true);
    } else {
      setValidErr(false);
    }
  };
  const createPlantType = useCallback(async () => {
    if (CheckValidLength(newPlant.name)) {
      setValidErr(true);
      dispatchFn_(setSubmitActive(false));
      return;
    }
    if (validErr) {
      dispatchFn_(setSubmitActive(false));
      return;
    }

    const loading = toast.loading("กำลังเพิ่มเชื้อเพลิง...");
    try {
      const res = await defaultApi.createPlantType(newPlant);
      console.log(res.statusText);
      // Add to _plantArr state
      setPlantArr((prev) => [
        ...prev,
        { id: res?.data.id, name: res?.data.name },
      ]);
      // รอเพิ่มพื้นที่สร้างใหม่ในสเตจก่อนค่อน submit ขั้นสุดท้าย
      setTimeout(() => {
        dispatchFn_(setSubmitActive("Final"));
      }, 1000);
      // Reset state
      setValidErr(false);
    } catch (error) {
      console.log(error);
      dispatchFn_(setSubmitActive(false));
      toast.error("เพิ่มเชื้อเพลิงไม่สำเร็จ", {
        duration: 3000,
      });
    } finally {
      toast.dismiss(loading);
    }
  }, [newPlant, setPlantArr, dispatchFn_, validErr]);

  useEffect(() => {
    if (submitActive === "createPlant") {
      createPlantType();
    }
  }, [submitActive, newPlant, createPlantType]);

  return (
    <>
      <div className="auto_com_box">
        <Autocomplete
          inputProps={{
            className: `input_box ${
              validErr && newPlant.name === "" ? "error" : ""
            }`,
          }}
          wrapperStyle={{ fontSize: "20px", width: "100%" }}
          items={_plant}
          shouldItemRender={(item, value) =>
            item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
          }
          getItemValue={(item) => item.name}
          renderItem={(item, highlighted) => (
            <div
              key={item.id}
              style={{ backgroundColor: highlighted ? "#eee" : "transparent" }}
            >
              {item.name}
            </div>
          )}
          value={newPlant.name}
          onChange={(e) => _onChange(e.target.value)}
          onSelect={(name) => _onSelect({ name })}
        />
        <GrFormClose
          onClick={() => onDeleteInput(num)}
          size={30}
          cursor={"pointer"}
        />
      </div>
      {validErr && (
        <p className="err_message">
          {CheckAddPlantType(newPlant.name, _plant)}
        </p>
      )}
    </>
  );
};

const AddContractF = ({ onSwitchBoxL }) => {
  const dispatchFn_ = useDispatch();
  const factoryData = useSelector(getFactoryData);
  const submitActive = useSelector(getSubmitActive);

  const [_data, setData] = useState(initData);
  const [allEnterprise, setAllEnterprise] = useState([]);
  const [allContractF, setAllContractF] = useState([]);
  const [allPlant, setAllPlant] = useState([]);
  const [plantOpt, setPlantOpt] = useState([]);
  const [_plantArr, setPlantArr] = useState([]);
  const [befPlant, setBefPlant] = useState("");
  const [_contractStatus, setContractStatus] = useState([]);
  const [inputList, setInputList] = useState([]);
  const [selectedDate, setSelectDate] = useState({
    sign: new Date(),
    end: new Date(),
  });
  const [openDateSign, setOpenDateSign] = useState(false);
  const [openDateEnd, setOpenDateEnd] = useState(false);
  const [validErr, setValidErr] = useState(false);

  const contractNo = useMemo(() => {
    let filterCf = allContractF.filter(
      (item) => item.factoryId === factoryData.id
    );
    // แปลงตัวเลขที่รับเข้ามาเป็นตัวเลขจริง
    let number1 = parseInt(factoryData.id);
    let number2 = parseInt(filterCf.length + 1);
    // เพิ่มตัวเลขที่รับเข้ามากับค่าเริ่มต้น 0
    let result1 = (number1 + 0).toString().padStart(3, "0");
    let result2 = (number2 + 0).toString().padStart(3, "0");

    return result1 + result2;
  }, [factoryData, allContractF]);

  /* DatePicker : START */
  const onChangeDateSign = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, signDate: dateFormat });
    setSelectDate({ ...selectedDate, sign: e });
    setOpenDateSign(!openDateSign);
  };
  const onChangeDateEnd = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, endDate: dateFormat });
    setSelectDate({ ...selectedDate, end: e });
    setOpenDateEnd(!openDateEnd);
  };
  /* DatePicker : END */

  const onGenInput = () => {
    setInputList(
      inputList.concat(
        <Input
          key={inputList.length + 1}
          num={inputList.length + 1}
          _plant={allPlant}
          setPlantArr={setPlantArr}
          onDeleteInput={onDeleteInput}
        />
      )
    );
  };
  const onDeleteInput = (id) => {
    setInputList(inputList.filter((item, idx) => idx !== id));
  };
  const createFileDetail = async (e) => {
    const file = document.querySelector("#add_file");

    const loading = toast.loading("กำลังเพิ่มสัญญาฉบับจริง...");
    const param1 = "ContractFarming";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);

    try {
      const res = await defaultApi.createFileDetail(param1, param2);
      console.log(res.statusText);

      setData({ ..._data, contractFarmingDoc: res?.data.id });
    } catch (error) {
      console.log(error);
    } finally {
      file.value = "";
      toast.dismiss(loading);
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };
  const onSubmit = () => {
    if (CheckValidLength(_data.signDate)) return setValidErr(true);
    if (CheckValidLength(_data.endDate)) return setValidErr(true);
    if (CheckValidLength(_data.enterpriseId)) return setValidErr(true);
    if (CheckValidLength(_data.period)) return setValidErr(true);
    if (CheckValidLength(_data.amountDay)) return setValidErr(true);
    if (CheckValidLength(_data.sendDay)) return setValidErr(true);
    if (CheckValidLength(_data.contractFarmingDoc)) return setValidErr(true);
    if (CheckValidLength(_data.contractType)) return setValidErr(true);
    if (CheckNumber(_data.preferredDividend)) return setValidErr(true);
    if (CheckNumber(_data.communityShare)) return setValidErr(true);
    if (CheckValidLength(befPlant)) return setValidErr(true);
    if (CheckDataStDateEn(_data.signDate, _data.endDate))
      return setValidErr(true);
    if (CheckDateEnDataSt(_data.endDate, _data.signDate))
      return setValidErr(true);

    if (inputList.length !== 0) {
      dispatchFn_(setSubmitActive("createPlant"));
    } else if (inputList.length === 0) {
      dispatchFn_(setSubmitActive("Ready"));
    }
  };
  const createContractFarming = useCallback(async () => {
    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "no") {
        params[key] = contractNo;
      } else if (key === "factoryId") {
        params[key] = factoryData?.id;
      } else if (key === "enterpriseId") {
        params[key] = +_data[key];
      } else if (key === "period") {
        params[key] = +_data[key];
      } else if (key === "amountDay") {
        params[key] = +_data[key];
      } else if (key === "sendDay") {
        params[key] = +_data[key];
      } else if (key === "plantType") {
        params[key] =
          _plantArr !== "" ? (params[key] = JSON.stringify(_plantArr)) : null;
      } else if (key === "contractFarmingDoc") {
        params[key] = +_data[key];
      } else if (key === "preferredDividend") {
        params[key] = +_data[key];
      } else if (key === "communityShare") {
        params[key] = +_data[key];
      } else {
        params[key] = _data[key];
      }
    });
    try {
      const res = await defaultApi.createContractFarming(params);
      console.log(res.statusText);
      // Reset form
      setData(initData);
      // Reset state
      setValidErr(false);
      // Switch page
      onSwitchBoxL(false);
      toast.success(Message.notify.insert_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.insert_error, { duration: 3000 });
    } finally {
      dispatchFn_(setSubmitActive(false));
      toast.dismiss(loading);
    }
  }, [_data, _plantArr, factoryData, contractNo, dispatchFn_, onSwitchBoxL]);

  const getMultipleApi = async () => {
    const query = `%24filter=statusCategories%20eq%20%272%27`;

    try {
      const [allPlant, allContractF, gbStatus] = await Promise.all([
        defaultApi.getAllPlantType(),
        defaultApi.getAllContractFarming(),
        defaultApi.getGbStatus(query),
      ]);

      setAllPlant(allPlant.data);
      setAllContractF(allContractF.data);
      setContractStatus(gbStatus.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllEnterprise = useCallback(async () => {
    if (!factoryData?.id) return;

    let factoryId = factoryData?.id;
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=factoryId%20eq%20${factoryId}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);

    try {
      const res = await defaultApi.getAllEnterprise(_queryAll);

      setAllEnterprise(res.data.datas);
    } catch (error) {
      console.log(error);
    }
  }, [factoryData]);

  useEffect(() => {
    /* Insert data to database : START */
    if (submitActive === "Ready" && inputList.length === 0) {
      // เพิ่มข้อมูลแบบไม่มีการสร้างพื้นใหม่
      createContractFarming();
    } else if (submitActive === "Final" && inputList.length !== 0) {
      // เพิ่มข้อมูลแบบมีการสร้างพื้นใหม่ก่อน
      createContractFarming();
    }
    /* Insert data to database : END */
  }, [submitActive, inputList, createContractFarming]);

  useEffect(() => {
    /* แปลงข้อมูลพืชเพื่อเอาไปใช้กับ React-select : START */
    if (allPlant.length !== 0) {
      let newArr = [];
      for (let i = 0; i < allPlant.length; i++) {
        allPlant.forEach((item) => {
          let obj_ = { value: item.id, label: item.name };
          newArr[i] = obj_;
          ++i;
        });
      }
      setPlantOpt(newArr);
    }
    /* แปลงข้อมูลพืชเพื่อเอาไปใช้กับ React-select : END */

    /* แปลงข้อมูลพืชใหม่เตรียมส่ง : START */
    if (befPlant.length !== 0) {
      let newPlanType = [];
      for (let i = 0; i < befPlant.length; i++) {
        befPlant.forEach((item) => {
          let id_ = item.value;
          let name_ = item.label;
          let obj_ = { id: id_, name: name_ };
          newPlanType[i] = obj_;
          ++i;
        });
      }
      setPlantArr(newPlanType);
    } else {
      setPlantArr("");
      setBefPlant("");
    }
    /* แปลงข้อมูลพืชใหม่เตรียมส่ง : END */
  }, [allPlant, befPlant]);

  useEffect(() => {
    // Use function
    getFilePdfName();
    getMultipleApi();
    getAllEnterprise();
  }, [getAllEnterprise]);

  useEffect(() => {
    /* Function close dropdown 'DateSign and DateEnd' : START */
    const onCloseDropdownDateSign = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_sign_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ไม่ต้องทำอะไรเลย
        return;
      }

      // ตรวจสอบว่า element ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateSign(false);
      }
    };
    const onCloseDropdownDateEnd = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_sign_end_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ไม่ต้องทำอะไรเลย
        return;
      }

      // ตรวจสอบว่า element ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDateEnd(false);
      }
    };

    if (openDateSign) {
      document.addEventListener("click", onCloseDropdownDateSign);
    }
    if (openDateEnd) {
      document.addEventListener("click", onCloseDropdownDateEnd);
    }
    /* Function close dropdown 'DateSign and DateEnd' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDateSign);
      document.removeEventListener("click", onCloseDropdownDateEnd);
    };
  }, [openDateSign, openDateEnd]);

  return (
    <div className="add_farming">
      <h5 className="title_text">เพิ่มสัญญาการรับซื้อเชื้อเพลิง</h5>
      <form className="input_form">
        <div className="input_flex">
          <div className="input_1">
            <h5>สัญญาเลขที่</h5>
            <input
              type="text"
              name=""
              value={contractNo}
              style={{ background: "#f5f5f5" }}
              disabled
            />
          </div>
          <div className="input_2">
            <h5>
              สถานะสัญญา <sup className="asterisk">*</sup>
            </h5>
            <select
              name="status"
              className={validErr && _data.status === "" ? "error" : ""}
              onChange={onChange}
            >
              <option style={{ display: "none" }}>เลือกสถานะสัญญา</option>
              {_contractStatus.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.statusName}
                  </option>
                );
              })}
            </select>
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.status)}</p>
            )}
          </div>
        </div>
        <div className="input_flex">
          <div className="input_1">
            <h5>
              วันเริ่มต้นสัญญา <sup className="asterisk">*</sup>
            </h5>
            <div
              className={`input_unit ${
                validErr && _data.signDate === "" ? "error" : ""
              }`}
            >
              <div className="info_box">
                <p>
                  {_data.signDate !== "" ? convertDateThai(_data.signDate) : ""}
                </p>
              </div>
              <div
                className="unit"
                onClick={() => {
                  setOpenDateSign(!openDateSign);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_sign_date_d" />
              </div>
            </div>
            {openDateSign && (
              <DatePicker
                selected={selectedDate.sign}
                onChange={onChangeDateSign}
                inline
              />
            )}
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.signDate)}</p>
            )}
            {validErr && _data.signDate !== "" && (
              <p className="err_message">
                {CheckDataStDateEn(_data.signDate, _data.endDate)}
              </p>
            )}
          </div>
          <div className="input_2">
            <h5>
              วันสิ้นสุดสัญญา <sup className="asterisk">*</sup>
            </h5>
            <div
              className={`input_unit ${
                validErr && _data.endDate === "" ? "error" : ""
              }`}
            >
              <div className="info_box">
                <p>
                  {_data.endDate !== "" ? convertDateThai(_data.endDate) : ""}
                </p>
              </div>
              <div
                className="unit"
                onClick={() => {
                  setOpenDateEnd(!openDateEnd);
                }}
              >
                <img src={icon.calendar} alt="" id="fac_sign_end_d" />
              </div>
            </div>
            {openDateEnd && (
              <DatePicker
                selected={selectedDate.end}
                onChange={onChangeDateEnd}
                inline
              />
            )}
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.endDate)}</p>
            )}
            {validErr && _data.endDate !== "" && (
              <p className="err_message">
                {CheckDateEnDataSt(_data.endDate, _data.signDate)}
              </p>
            )}
          </div>
        </div>
        <div className="input_flex">
          <div className="input_1_unit">
            <h5>สัญญาระหว่าง(ผู้ซื้อ)</h5>
            <input
              type="text"
              name="factoryId"
              defaultValue={factoryData?.name}
              readOnly
            />
            <span>และ</span>
          </div>
          <div className="input_2">
            <h5>
              สัญญาระหว่าง(ผู้ขาย) <sup className="asterisk">*</sup>
            </h5>
            <select
              name="enterpriseId"
              className={validErr && _data.enterpriseId === "" ? "error" : ""}
              onChange={onChange}
            >
              <option style={{ display: "none" }}>เลือกผู้ขาย</option>;
              {allEnterprise.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {validErr && (
              <p className="err_message">
                {CheckValidLength(_data.enterpriseId)}
              </p>
            )}
          </div>
        </div>
        <div className="input_flex">
          <div className="input_1">
            <h5>
              คู่สัญญาหลัก / รอง <sup className="asterisk">*</sup>
            </h5>
            <select
              name="contractType"
              className={validErr && _data.contractType === "" ? "error" : ""}
              onChange={onChange}
            >
              <option style={{ display: "none" }}>เลือกคู่สัญญา</option>;
              {contractType.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {validErr && (
              <p className="err_message">
                {CheckValidLength(_data.contractType)}
              </p>
            )}
          </div>
          <div className="input_2"></div>
        </div>
        <div className="input_grid">
          <div className="input_1">
            <h5>
              ระยะเวลาสัญญา <sup className="asterisk">*</sup>
            </h5>
            <div
              className={`input_unit ${
                validErr && _data.period === "" ? "error" : ""
              }`}
            >
              <input type="text" name="period" onChange={onChange} />
              <div className="unit">
                <span>ปี</span>
              </div>
            </div>
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.period)}</p>
            )}
          </div>
          <div className="input_2">
            <h5>
              จำนวนในการส่งมอบ (ตัน/วัน) <sup className="asterisk">*</sup>
            </h5>
            <input
              type="text"
              name="amountDay"
              className={validErr && _data.amountDay === "" ? "error" : ""}
              onChange={onChange}
            />
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.amountDay)}</p>
            )}
          </div>
          <div className="input_3">
            <h5>
              จำนวนวันที่ขายและส่งมอบ <sup className="asterisk">*</sup>
            </h5>
            <div
              className={`input_unit ${
                validErr && _data.sendDay === "" ? "error" : ""
              }`}
            >
              <input type="text" name="sendDay" onChange={onChange} />
              <div className="unit">
                <span>วัน/ปี</span>
              </div>
            </div>
            {validErr && (
              <p className="err_message">{CheckValidLength(_data.sendDay)}</p>
            )}
          </div>
        </div>
        <div className="input_flex">
          <div className="input_1">
            <h5>
              ชนิดเชื้อเพลิง / วัตถุดิบ <sup className="asterisk">*</sup>
            </h5>
            <Select
              className="muti_select"
              closeMenuOnSelect={false}
              components={animated}
              isSearchable={false}
              options={plantOpt}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  borderRadius: "10px",
                }),
              }}
              placeholder={"เลือกชนิดเชื้อเพลิง"}
              onChange={(e) => setBefPlant(e)}
              isMulti
            />
            {validErr && (
              <p className="err_message">{CheckValidLength(befPlant)}</p>
            )}
            <div className="input_">{inputList}</div>
            <div className="btn_gen">
              <button type="button" className="btn_" onClick={onGenInput}>
                เพิ่มชนิดเชื้อเพลิง
              </button>
            </div>
          </div>
          <div className="input_2">
            <div className="input_file">
              <h5>
                สัญญาฉบับจริง <sup className="asterisk">*</sup>
              </h5>
              <div
                className={`input_unit mb_0 ${
                  validErr && _data.contractFarmingDoc === "" ? "error" : ""
                }`}
              >
                <div className="info_box">
                  <p id="file_name"></p>
                </div>
                <div className="unit">
                  <img
                    src={icon.document}
                    alt=""
                    title="แนบไฟล์"
                    onClick={() =>
                      document.querySelector("#file-label").click()
                    }
                  />
                </div>
              </div>
              {validErr && (
                <p className="err_message">
                  {CheckFileDoc(_data.contractFarmingDoc)}
                </p>
              )}
              <span className="desc_text">
                *ระบบรองรับไฟล์ที่มีนามสกุล .pdf และมีขนาดไม่เกิน 5 MB
              </span>
              <input
                type="file"
                name="contractFarmingDoc"
                id="add_file"
                accept="application/pdf"
                onChange={createFileDetail}
                hidden
              />
              <label htmlFor="add_file" id="file-label" hidden></label>
            </div>
          </div>
        </div>
        <div className="input_flex">
          <div className="input_1">
            <h5>
              ปันผลหุ้นบุริมสิทธิ์ <sup className="asterisk">*</sup>
            </h5>
            <div
              className={`input_unit ${
                validErr && _data.preferredDividend === "" ? "error" : ""
              }`}
            >
              <input type="text" name="preferredDividend" onChange={onChange} />
              <div className="unit">
                <span>บาท/ปี</span>
              </div>
            </div>
            {validErr && (
              <p className="err_message">
                {CheckNumber(_data.preferredDividend)}
              </p>
            )}
          </div>
          <div className="input_2">
            <h5>
              ส่วนแบ่งชุมชนรอบโรงไฟฟ้า <sup className="asterisk">*</sup>
            </h5>
            <div
              className={`input_unit ${
                validErr && _data.communityShare === "" ? "error" : ""
              }`}
            >
              <input type="text" name="communityShare" onChange={onChange} />
              <div className="unit">
                <span>บาท/ปี</span>
              </div>
            </div>
            {validErr && (
              <p className="err_message">{CheckNumber(_data.communityShare)}</p>
            )}
          </div>
        </div>
      </form>

      <div className="button_center">
        <button type="button" className="button_sm" onClick={onSubmit}>
          บันทึก
        </button>
        <button
          type="button"
          className="button_sm"
          onClick={() => {
            onSwitchBoxL(false);
            dispatchFn_(setSubmitActive(false));
          }}
        >
          ยกเลิก
        </button>
      </div>
    </div>
  );
};

const getFilePdfName = () => {
  const file = document.querySelector("#add_file");
  const label = document.querySelector("#file_name");

  file?.addEventListener("change", () => {
    if (file.value) {
      label.innerHTML = file.files[0].name;
    } else {
      label.innerHTML = "";
    }
  });
};

export default AddContractF;
