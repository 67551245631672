import React from "react";
import styles from "./scss/MenuBar.module.scss";

const MenuBar = ({
  switchChecked,
  onSwitchChecked,
  _location,
  _navigate,
  _layerId,
}) => {
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name="type"
          id="checkMenu1"
          checked={
            !_location.includes("contract") &&
            !_location.includes("production") &&
            !_location.includes("raw-material") &&
            !_location.includes("expenses")
          }
          onClick={() => _navigate(`/manage/${_layerId}`)}
          readOnly
        />
        <label htmlFor="checkMenu1">ข้อมูลโรงไฟฟ้าชุมชน</label>
      </div>
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name="type"
          id="checkMenu2"
          checked={_location.includes("contract")}
          onClick={() => _navigate(`/manage/${_layerId}/contract`)}
          readOnly
        />
        <label htmlFor="checkMenu2">การจัดการสัญญา</label>
      </div>
      {_location.includes("contract") && (
        <div className={`${styles.checkChildrenBox}`}>
          <div>
            <input
              type="checkbox"
              name="type"
              id="checkMenu5"
              value={1}
              checked={switchChecked === "1"}
              onClick={(e) => onSwitchChecked(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu5">ประเภทสัญญา</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="type"
              id="checkMenu6"
              value={2}
              checked={switchChecked === "2"}
              onClick={(e) => onSwitchChecked(e.target.value)}
              readOnly
            />
            <label htmlFor="checkMenu6">บันทึกการซื้อขาย</label>
          </div>
        </div>
      )}
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name="type"
          id="checkMenu3"
          checked={_location.includes("production")}
          onClick={() => _navigate(`/manage/${_layerId}/production`)}
          readOnly
        />
        <label htmlFor="checkMenu3">การผลิตไฟฟ้า</label>
      </div>
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name="type"
          id="checkMenu4"
          checked={_location.includes("raw-material")}
          onClick={() => _navigate(`/manage/${_layerId}/raw-material`)}
          readOnly
        />
        <label htmlFor="checkMenu4">การจัดการวัตถุดิบ</label>
      </div>
      <div className={`${styles.checkParentBox}`}>
        <input
          type="checkbox"
          name="type"
          id="checkMenu7"
          checked={_location.includes("expenses")}
          onClick={() => _navigate(`/manage/${_layerId}/expenses`)}
          readOnly
        />
        <label htmlFor="checkMenu7">บันทึกค่าใช้จ่ายอื่นๆ</label>
      </div>
    </div>
  );
};

export default MenuBar;
