import React from "react";
import { MdEmail, MdPhone } from "react-icons/md";
import styles from "./Footer.module.scss";

const Footer = ({ _themeColor }) => {
  return (
    <footer
      className={`${styles.container}`}
      style={{ backgroundColor: _themeColor?.backgroundColor }}
    >
      <div className={`${styles.content}`}>
        <div className={`${styles.address}`}>
          <p className={`${styles.address_header}`}>
            กรมพัฒนาพลังงานทดเเทนเเละอนุรักษ์พลังงาน (พพ.)
          </p>
          <p>เลขที่ 17 ถนนพระรามที่ 1</p>
          <p>เขตปทุมวัน กรุงเทพมหานคร 10330</p>
        </div>
        <div className={`${styles.contact_tel}`}>
          {/* <p className="contact_tel_header">โทรศัพท์</p> */}
          <i>
            <MdPhone />
          </i>
          <div className={`${styles.box_text}`}>
            <p>0-2223-0021-9</p>
            <p>-</p>
          </div>
        </div>
        <div className={`${styles.contact_email}`}>
          {/* <p className="contact_email_header">อีเมล</p> */}
          <i>
            <MdEmail />
          </i>
          <div className={`${styles.box_text}`}>
            <p>saraban@dede.go.th</p>
            <p>kcdede@dede.go.th</p>
          </div>
        </div>
        {/* <div className="navigate_icon">
            <i>
              <MdEmail />
            </i>
            <i>
              <AiFillFacebook />
            </i>
            <i>
              <MdHome />
            </i>
            <i>
              <MdPhone />
            </i>
          </div> */}
      </div>
      <p className={`${styles.copyright}`}>
        Copyright 2023 Ministry of Energy Thailand | Privacy Policy
      </p>
    </footer>
  );
};

export default Footer;
