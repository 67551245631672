import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import Autocomplete from "react-autocomplete";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import icon from "../../../../assets/img/Icon";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../utils/format/Date.format";
import useSwitch from "../../../../utils/hooks/useSwitch";
import {
  CheckAddRecordName,
  CheckNumber,
  CheckValidLength,
} from "../../../../utils/validation_input/Validation";
import { defaultApi } from "../../../../services/api";
import Message from "../../../../utils/message/Message";
import {
  getSubmitActive,
  setSubmitActive,
} from "../../../../services/factory.slice";
import styles from "./scss/Expenses.module.scss";

const EditForm = ({
  _dataEdit,
  _allRecordDetails,
  _fetchData,
  _fetchData2,
  _fetchData3,
  _allEnterprise,
  _transacData,
}) => {
  const dispatchFn_ = useDispatch();
  const submitActive = useSelector(getSubmitActive);
  const [_switch, onSwitch, _switch2, onSwitch2] = useSwitch();

  const [_data, setData] = useState({});
  const [newRecordName, setNewRecordName] = useState({
    name: "",
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [validErr, setValidErr] = useState(false);
  const [validErr2, setValidErr2] = useState(true);

  /* DatePicker : START */
  const onChangeDate = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, recordedAt: dateFormat });
    setSelectedDate(e);
    setOpenDatePicker(!openDatePicker);
  };
  /* DatePicker : END */

  /* AutoComplete : START */
  const _onChange = (e) => {
    setNewRecordName({ name: e });
    if (CheckAddRecordName(e, _allRecordDetails)) {
      setValidErr2(true);
    } else {
      setValidErr2(false);
    }
  };
  const _onSelect = (e) => {
    setNewRecordName(e);
    if (CheckAddRecordName(e.name, _allRecordDetails)) {
      setValidErr2(true);
    } else {
      setValidErr2(false);
    }
  };
  /* AutoComplete : END */

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };
  const onChangeRecordId = (e) => {
    const { name, value } = e.target;
    if (value === "1" || value === "2") {
      setData({ ..._data, [name]: value });
    } else {
      setData({ ..._data, recordId: value, enterpriseId: null });
    }
  };
  const createRecordDetail = useCallback(async () => {
    if (CheckValidLength(newRecordName.name)) {
      setValidErr2(true);
      dispatchFn_(setSubmitActive(false));
      return;
    }
    if (validErr2) {
      dispatchFn_(setSubmitActive(false));
      return;
    }

    const loading = toast.loading(Message.notify.loading);
    try {
      const res = await defaultApi.createRecordDetail(newRecordName);
      // เพิ่มข้อมูลในสเตจ
      setData({ ..._data, recordId: res.data.id });
      // Clear redux state เพื่อไม่ให้เรียก Api นี้ซ้ำอีกครั้ง
      dispatchFn_(setSubmitActive(false));
      // รอรับไอดีของข้อมูลที่สร้างใหม่เพิ่มในสเตจก่อนค่อน submit ขั้นสุดท้าย
      setTimeout(() => {
        dispatchFn_(setSubmitActive("Final2"));
      }, 1000);
      // Reset state
      setValidErr2(false);
    } catch (error) {
      console.log(error);
      dispatchFn_(setSubmitActive(false));
      toast.error("เพิ่มรายการค่าใช้จ่ายไม่สำเร็จ", { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  }, [_data, newRecordName, validErr2, dispatchFn_]);
  const deleteFactoryRecord = async () => {
    if (!window.confirm(Message.notify.delete_confirm)) return;

    const closeModal = document.querySelector("#update-close");
    const loading = toast.loading(Message.notify.loading);
    try {
      const res1 = await defaultApi.deleteTransaction(_transacData.id);
      console.log(res1.statusText);
      if (res1.status === 204 || res1.status === 200) {
        const res2 = await defaultApi.deleteFactoryRecord(_data.id);
        console.log(res2.statusText);
      }
      // Fetch data allFactoryRecord
      _fetchData();
      _fetchData3();
      // Close modal
      closeModal.click();
      toast.success(Message.notify.delete_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.delete_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };
  const onSubmit = () => {
    if (!_switch2) {
      console.log("false");
      if (CheckNumber(_data?.price)) return setValidErr(true);
    } else {
      console.log("true");
      if (CheckNumber(_data?.price)) return setValidErr(true);
      if (CheckValidLength(newRecordName.name)) return setValidErr2(true);
    }

    if (newRecordName.name !== "") {
      dispatchFn_(setSubmitActive("createRecordDetail2"));
    } else {
      dispatchFn_(setSubmitActive("Ready2"));
    }
  };
  // console.log(_transacData);
  const updateTransaction = useCallback(
    async (res) => {
      const _note = _allRecordDetails?.find(
        (item) => item.id === +_data?.recordId
      )?.name;
      const params = {};
      Object.keys(_transacData).forEach((key) => {
        if (key === "factoryRecordId") {
          params[key] = res?.id;
        } else if (key === "txDate") {
          params[key] = res?.recordedAt;
        } else if (key === "total") {
          params[key] = res?.price;
        } else if (key === "note") {
          params[key] = _note;
        } else if (key === "factoryId") {
          params[key] = res?.factoryId;
        } else if (key === "enterpriseId") {
          params[key] = +res?.enterpriseId;
        } else {
          params[key] = _transacData[key];
        }
      });
      try {
        const res = await defaultApi.updateTransaction(_transacData.id, params);
        console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    },
    [_transacData, _allRecordDetails, _data]
  );
  const updateFactoryRecord = useCallback(async () => {
    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "recordId") {
        params[key] = +_data[key];
      } else if (key === "price") {
        params[key] = +_data[key];
      } else {
        params[key] = _data[key];
      }
    });
    console.log(params);
    try {
      const res = await defaultApi.updateFactoryRecord(_data.id, params);
      console.log(res.statusText);
      updateTransaction(res.data);
      // Reset state
      setNewRecordName({ name: "" });
      setValidErr(false);
      onSwitch2(false);
      onSwitch(false);
      // Fetch data
      _fetchData();
      _fetchData2(res.data.id);
      _fetchData3();
      toast.success(Message.notify.update_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error, { duration: 3000 });
    } finally {
      dispatchFn_(setSubmitActive(false));
      toast.dismiss(loading);
    }
  }, [
    _data,
    onSwitch,
    onSwitch2,
    dispatchFn_,
    updateTransaction,
    _fetchData,
    _fetchData2,
    _fetchData3,
  ]);

  const onBackAndReset = () => {
    onSwitch(false);
    onSwitch2(false);
    setSelectedDate(new Date());
  };

  useEffect(() => {
    /* สร้างรายการค่าใช้จ่ายใหม่ : START */
    if (submitActive === "createRecordDetail2") {
      createRecordDetail();
    }
    /* สร้างรายการค่าใช้จ่ายใหม่ : END */
  }, [createRecordDetail, submitActive]);

  useEffect(() => {
    /* Insert data to database : START */
    if (submitActive === "Ready2") {
      // เพิ่มข้อมูลแบบไม่มีการสร้างรายการค่าใช้จ่ายใหม่
      updateFactoryRecord();
    } else if (submitActive === "Final2") {
      // เพิ่มข้อมูลแบบมีการสร้างรายการค่าใช้จ่ายใหม่
      updateFactoryRecord();
    }
    /* Insert data to database : END */
  }, [submitActive, updateFactoryRecord]);

  useEffect(() => {
    // Use functions
    setData(_dataEdit);
  }, [setData, _dataEdit]);

  useEffect(() => {
    /* Function close dropdown 'DatePicker' : START */
    const onCloseDropdownDatePicker = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#fac_datepicker2_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenDatePicker(false);
      }
    };

    if (openDatePicker) {
      document.addEventListener("click", onCloseDropdownDatePicker);
    }
    /* Function close dropdown 'DatePicker' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownDatePicker);
    };
  }, [openDatePicker]);

  return (
    <div
      className={`${styles.modal_} modal fade`}
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className={`${styles.modal_content} modal-content`}>
          <div className={`${styles.modal_header} modal-header`}>
            <h5
              className={`${styles.modal_title} modal-title`}
              id="staticBackdropLabel"
            >
              รายการค่าใช้จ่าย
            </h5>
            <button
              type="button"
              id="update-close"
              className={`${styles.btn_close} btn-close`}
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onBackAndReset}
            ></button>
          </div>
          {!_switch ? (
            <div className={`${styles.modal_body} modal-body`}>
              <div className={`${styles.input_}`}>
                <h5>วันที่</h5>
                <div className={`${styles.info_box}`}>
                  <p>{convertDateThai(_data?.recordedAt)}</p>
                </div>
              </div>
              <div className={`${styles.input_}`}>
                <h5>รายการค่าใช้จ่าย</h5>
                <div className={`${styles.info_box}`}>
                  <p>{_data?.recordDetails?.name}</p>
                </div>
              </div>
              {_data?.enterpriseId !== null ? (
                <div className={`${styles.input_}`}>
                  <h5>วิสาหกิจชุมชน</h5>
                  <div className={`${styles.info_box}`}>
                    <p>
                      {_data?.enterpriseId === null
                        ? ""
                        : _allEnterprise?.find(
                            (item1) => item1.id === +_data?.enterpriseId
                          )?.name}
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className={`${styles.input_}`}>
                <h5>ยอดเงิน (บาท)</h5>
                <div className={`${styles.info_box}`}>
                  <p>{_data?.price}</p>
                </div>
              </div>
            </div>
          ) : (
            <div className={`${styles.modal_body} modal-body`}>
              <div className={`${styles.input_}`}>
                <h5>วันที่ <sup className={`${styles.asterisk}`}>*</sup></h5>
                <div className={`${styles.input_unit}`}>
                  <div className={`${styles.info_box}`}>
                    <p>{convertDateThai(_data?.recordedAt)}</p>
                  </div>
                  <div
                    className={`${styles.unit}`}
                    onClick={() => setOpenDatePicker(!openDatePicker)}
                  >
                    <img src={icon.calendar} alt="" id="fac_datepicker2_d" />
                  </div>
                </div>
                {openDatePicker && (
                  <DatePicker
                    selected={selectedDate}
                    onChange={onChangeDate}
                    inline
                  />
                )}
              </div>
              <div className={`${styles.input_}`}>
                <h5>รายการค่าใช้จ่าย <sup className={`${styles.asterisk}`}>*</sup></h5>
                {!_switch2 ? (
                  <>
                    <select name="recordId" onChange={onChangeRecordId}>
                      <option style={{ display: "none" }}>
                        {_data.recordDetails.name}
                      </option>
                      {String(_data?.recordId) === "1"
                        ? _allRecordDetails
                            ?.filter((item1) => item1.id <= 1)
                            ?.map((item2) => {
                              return (
                                <option key={item2.id} value={item2.id}>
                                  {item2.name}
                                </option>
                              );
                            })
                        : _allRecordDetails
                            .filter((item1) => item1.id > 1)
                            ?.map((item2) => {
                              return (
                                <option key={item2.id} value={item2.id}>
                                  {item2.name}
                                </option>
                              );
                            })}
                    </select>
                  </>
                ) : (
                  <>
                    <div className={`${styles.auto_com_box}`}>
                      <Autocomplete
                        inputProps={{
                          className: `${styles.input_box} ${
                            validErr2 && newRecordName.name === ""
                              ? "error"
                              : ""
                          }`,
                        }}
                        wrapperStyle={{ fontSize: "20px", width: "100%" }}
                        items={_allRecordDetails}
                        shouldItemRender={(item, value) =>
                          item.name.toLowerCase().indexOf(value.toLowerCase()) >
                          -1
                        }
                        getItemValue={(item) => item.name}
                        renderItem={(item, highlighted) => (
                          <div
                            key={item.id}
                            style={{
                              backgroundColor: highlighted
                                ? "#eee"
                                : "transparent",
                            }}
                          >
                            {item.name}
                          </div>
                        )}
                        value={newRecordName.name}
                        onChange={(e) => _onChange(e.target.value)}
                        onSelect={(name) => _onSelect({ name })}
                      />
                    </div>
                    {validErr2 && (
                      <p className={`${styles.err_message}`}>
                        {CheckAddRecordName(
                          newRecordName.name,
                          _allRecordDetails
                        )}
                      </p>
                    )}
                  </>
                )}
              </div>
              {String(_data?.recordId) === "1"
                ? ""
                : !_switch2 && (
                    <div className={`${styles.record_other}`}>
                      <h6
                        onClick={() => {
                          onSwitch2(true);
                          setNewRecordName({ name: "" });
                          setData({ ..._data, enterpriseId: null });
                        }}
                      >
                        เพิ่มรายการอื่นๆ
                      </h6>
                    </div>
                  )}
              {String(_data?.recordId) === "1" ? (
                <div className={`${styles.input_}`}>
                  <h5>วิสาหกิจชุมชน</h5>
                  <select
                    name="enterpriseId"
                    onChange={onChange}
                    disabled={
                      String(_data?.recordId) === "1" ||
                      String(_data?.recordId) === "2"
                        ? false
                        : true
                    }
                  >
                    <option
                      selected={_data?.enterpriseId !== null ? false : true}
                      style={{ display: "none" }}
                    >
                      {_data?.enterpriseId === null
                        ? "เลือกวิสาหกิจชุมชน"
                        : _allEnterprise?.find(
                            (item) => item.id === +_data?.enterpriseId
                          )?.name}
                    </option>
                    {_allEnterprise?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : (
                ""
              )}
              <div className={`${styles.input_}`}>
                <h5>ยอดเงิน (บาท) <sup className={`${styles.asterisk}`}>*</sup></h5>
                <input
                  type="text"
                  name="price"
                  value={_data?.price || ""}
                  className={`${
                    validErr && _data?.price === "" ? "error" : ""
                  }`}
                  onChange={onChange}
                />
                {validErr && (
                  <p className={`${styles.err_message}`}>
                    {CheckValidLength(_data?.price)}
                  </p>
                )}
              </div>
            </div>
          )}
          {!_switch ? (
            <div className={`${styles.modal_footer} modal-footer`}>
              <button
                type="button"
                className="button_sm"
                onClick={deleteFactoryRecord}
              >
                ลบ
              </button>
              <button
                type="button"
                className="button_sm"
                onClick={() => onSwitch(true)}
              >
                แก้ไข
              </button>
            </div>
          ) : (
            <div className={`${styles.modal_footer} modal-footer`}>
              <button type="button" className="button_sm" onClick={onSubmit}>
                บันทึก
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditForm;
