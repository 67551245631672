import React from "react";
import SwitchMenu from "../_layout/SwitchMenu";
import SalesRecord from "./sales_record/SalesRecord";
import AddSalesRec from "./sales_record/AddSalesRec";
import PurchaseRecord from "./purchase_record/PurchaseRecord";
import useSwitch from "../../../../../utils/hooks/useSwitch";
import useSwitchBox from "../../../../../utils/hooks/useSwitchBox";
import styles from "./scss/TradingRecords.module.scss";

const TradingRecords = () => {
  const [_switch, onSwitch] = useSwitch();
  const [switchBoxL, onSwicthBoxL, switchBoxR, onSwicthBoxR] = useSwitchBox();
  return (
    <div className={`${styles.container}`}>
      <h4 className={`${styles.title_text}`}>การจัดการสัญญา</h4>
      <SwitchMenu
        onSwitch={onSwitch}
        btn1={"บันทึกการรับซื้อเชื้อเพลิง"}
        btn2={"บันทึกการขายไฟฟ้า"}
      />
      <div className={`${styles.content_box}`}>
        {!_switch && (
          <div className={`${styles.content_left}`}>
            <div className={`${styles.purchase_rec}`}>
              {!switchBoxL && <PurchaseRecord onSwicthBoxL={onSwicthBoxL} />}
            </div>
          </div>
        )}
        {_switch && (
          <div className={`${styles.content_right}`}>
            <div className={`${styles.sales_rec}`}>
              {switchBoxR && <AddSalesRec onSwicthBoxR={onSwicthBoxR} />}
              {!switchBoxR && <SalesRecord onSwicthBoxR={onSwicthBoxR} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TradingRecords;
