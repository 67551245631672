import React, { useState, useEffect } from "react";
import HighCharts from "../../../components/Highcharts/HighCharts";
import styles from "../scss/Index.module.scss";
import { formatterTooltip, formatterTooltip_baht, formatterXaxis_1, formatterYaxis } from "../../../helpers/suffixchart.helper";

let ranges = [
  { divider: 1e18, suffix: "E" },
  { divider: 1e15, suffix: "P" },
  { divider: 1e12, suffix: "T" },
  { divider: 1e9, suffix: "G" },
  { divider: 1e6, suffix: "M" },
  { divider: 1e3, suffix: "K" },
];

const ChartPurchase = ({ valueInput, selYear, color }) => {

  const [stateChartOptions, setStateChartOptions] = useState({
    chart: {
      type: "line",
      height: 190,
    },
    title: {
      text: "",
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      categories: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    yAxis: {
      title: {
        text: "บาท",
      },
      labels: {
        formatter: formatterYaxis,
        style: {
          fontSize: "13px",
        },
      },
    },
    tooltip: {
      enabled: true,
      ...formatterTooltip_baht
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          formatter: formatterXaxis_1
        },
      },
    },
    series: [],
  });

  const mapColorPlant = (id, color) => {
    try {
      for (let index = 0; index < color.length; index++) {
        // if (id === color[index].id) {
        //   return color[index].colorCode
        // } else if (id > color.length) {
        //   return "#000000"
        // }
        if (id === index) {
          return color[index].colorCode;
        } else if (id > color.length) {
          return "#000000";
        }
      }
    } catch (error) { }
  };

  const mapItemPlant = (id, setItem) => {
    const loopItem = (id, valItem) => {
      try {
        valItem = JSON.parse(valItem);
        let i = 1;
        for (let index = 0; index < valItem?.length; index++) {
          i += index;
          const source = valItem[index];
          if (id === source?.plant_id && i <= valItem?.length) {
            return source?.total;
          } else if (id !== source?.plant_id && i === valItem?.length) {
            return 0;
          }
        }
      } catch (error) {
        //console.log(error);
      }
    };

    let x = [];

    try {
      for (let index = 0; index < setItem?.length; index++) {
        const item = setItem[index]?.value === null ? 0 : setItem[index]?.value;
        if (item === 0) {
          x[index] = 0;
        } else {
          x[index] = loopItem(id, setItem[index]?.value);
        }
      }

      for (let index = 0; index < x.length; index++) {
        let mirrorx = x[index];
        x[index] === undefined ? (x[index] = 0) : (x[index] = mirrorx);
      }

      return x;
    } catch (error) { }
  };

  useEffect(() => {
    let x0 = [];

    try {
      for (
        let index = 0;
        index < valueInput?.enterprisePlants?.length;
        index++
      ) {
        const plantId = valueInput?.enterprisePlants[index]?.plantId;
        const plantName = valueInput?.enterprisePlants[index]?.plantName;

        const valItemId = mapItemPlant(plantId, valueInput?.factoryTransaction)

        x0[index] = {
          name: plantName,
          data: valItemId,
          showInLegend: true ? valItemId.reduce((sumVal, curVal) => sumVal + curVal) > 0 : false,
          // visible: true ? valItemId.reduce((sumVal, curVal)=> sumVal + curVal) > 0 : false,
          // data: mapItemPlant(plantId, valueInput?.factoryTransaction),
          //color: mapColorPlant(plantId, valueInput.color),
          color: mapColorPlant(index, color?.data?.color),
        };
      }
    } catch (error) { }

    const handleResize = () => {
      const newHeight = document.querySelector('aside')?.clientHeight / 2 - 105

      setStateChartOptions((prevOptions) => ({
        ...prevOptions,
        chart: {
          ...prevOptions.chart,
          height: newHeight
        },
        series: x0,
      }));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [valueInput]);

  return (
    <div className={`${styles.chart_purchase} mx-auto`}>
      <h6
        className="d-flex justify-content-center text-center"
        style={{ fontSize: "14px" }}
      >
        รายได้จากการขายเชื้อเพลิงของวิสาหกิจชุมชน {Number(selYear) + 543}
      </h6>
      <HighCharts options={stateChartOptions} />
    </div>
  );
};

export default ChartPurchase;
