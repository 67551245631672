import React, { useEffect } from "react";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from "../scss/ReportMonthSellingPlants.module.scss";
import { formatterTooltip, formatterXaxis_1 } from "../../../../helpers/suffixchart.helper";

const ChartsMonthSellingPlants = React.forwardRef((prop, ref) => {
  let setitemDetail = [];
  let setitemTotal = [];
  let days = [];
  let target = [];
  let middleLen = 0

  let ranges = [
    { divider: 1e18, suffix: "E" },
    { divider: 1e15, suffix: "P" },
    { divider: 1e12, suffix: "T" },
    { divider: 1e9, suffix: "G" },
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "K" },
  ];

  const loopItemData = (
    plantid,
    setplant,
    dfyears,
    dfmonths,
    dfdays,
    year,
    month
  ) => {
    let arr = [];

    const loopDetail = (id1, setplant1) => {
      try {
        let obj = JSON.parse(setplant1);
        for (let index = 0; index < obj.length; index++) {
          let i = 1 + index;
          const element = obj[index];
          if (element.plant_id === id1 && i <= obj.length) {
            return element?.amount;
          } else if (element.plant_id !== id1 && i === obj.length) {
            return 0;
          }
        }
      } catch (error) { }
    };

    try {
      for (let index = 0; index < setplant.length; index++) {
        const element = setplant[index].value;
        if (year === dfyears) {
          if (month === dfmonths) {
            if (setplant[index].month > dfdays) {
              arr[index] = null;
            } else {
              if (element === null) {
                arr[index] = 0;
              } else {
                arr[index] = loopDetail(plantid, element);
              }
            }
          } else if (month > dfmonths) {
            arr[index] = null;
          } else if (month < dfmonths) {
            if (element === null) {
              arr[index] = 0;
            } else {
              arr[index] = loopDetail(plantid, element);
            }
          }
        } else if (year < dfyears) {
          if (element === null) {
            arr[index] = 0;
          } else {
            arr[index] = loopDetail(plantid, element);
          }
        }
      }

      return arr;
    } catch (error) { }
  };

  const loopItemColor = (idx, setcolor) => {
    try {
      for (let index = 0; index < setcolor.length; index++) {
        const element = setcolor[index].colorCode;
        if (idx === index) {
          return element;
        } else if (idx > setcolor.length) {
          return "#000000";
        }
      }
    } catch (error) { }
  };

  const loopItemDataTotal = (
    setplant,
    dfyears,
    dfmonths,
    dfdays,
    year,
    month
  ) => {
    let total = 0;
    try {
      if (year === dfyears) {
        if (month === dfmonths) {
          if (setplant.month > dfdays) {
            total = null;
          } else {
            if (setplant.value === null) {
              total = total + 0;
            } else {
              let obj = JSON.parse(setplant.value);
              for (let index = 0; index < obj.length; index++) {
                total = total + obj[index].amount;
              }
            }
          }
        } else if (month > dfmonths) {
          total = null;
        } else if (month < dfmonths) {
          if (setplant.value === null) {
            total = total + 0;
          } else {
            let obj = JSON.parse(setplant.value);
            for (let index = 0; index < obj.length; index++) {
              total = total + obj[index].amount;
            }
          }
        }
      } else if (year < dfyears) {
        if (setplant.value === null) {
          total = total + 0;
        } else {
          let obj = JSON.parse(setplant.value);
          for (let index = 0; index < obj.length; index++) {
            total = total + obj[index].amount;
          }
        }
      }

      return total;
    } catch (error) { }
  };

  try {
    setitemDetail = []
    setitemTotal = []
    days = []
    target = []
    let date = new Date();
    let dfyears = date.getFullYear();
    let dfmonths = date.getMonth() + 1;
    let dfdays = date.getDate();
    let year = Number(prop.datetime.year);
    let month = Number(prop.datetime.month);

    let dfset = prop.dfset.data;
    let datain = prop.datain.data;

    let plant = dfset.enterprisePlants;
    let color = dfset.color;

    for (let index = 0; index < datain.factoryTransaction.length; index++) {
      setitemTotal[index] = loopItemDataTotal(
        datain.factoryTransaction[index],
        dfyears,
        dfmonths,
        dfdays,
        year,
        month
      );
      days[index] = datain.factoryTransaction[index].month;
    }

    for (let index = 0; index < plant.length; index++) {
      const el = plant[index];
      //ลูบด้วยพืช
      const itemValue = loopItemData(
        el.plantId,
        datain.factoryTransaction,
        dfyears,
        dfmonths,
        dfdays,
        year,
        month
      )
      setitemDetail[index] = {
        name: el.plantName,
        data: itemValue,
        color: loopItemColor(index, prop.colors?.data?.color),
        type: 'column',
        visible: true
      };
    }

    for (let index = 0; index < datain.factoryTransaction.length; index++) {
      const element = datain.factoryTransaction[index];

      if (year === dfyears) {
        if (month === dfmonths) {
          if (element.month > dfdays) {
            target[index] = null;
          } else {
            target[index] = element.target;
          }
        } else if (month > dfmonths) {
          target[index] = null;
        } else if (month < dfmonths) {
          target[index] = element.target;
        }
      } else if (year < dfyears) {
        target[index] = element.target;
      }
    }

    middleLen = Math.ceil(target.filter((val) => {
      if (val > 0) {
        return val
      }
    }).length / 2)

    // setitemDetail[setitemDetail.length] = {
    //   name: "ปริมาณที่รับซื้อทั้งหมด",
    //   data: setitemTotal,
    //   color: "#000000",
    //   lineWidth: 4,
    //   dashStyle: "solid",
    //   type: 'column',
    //   visible: false
    // };

    setitemDetail[setitemDetail.length] = {
      name: "ปริมาณที่ส่งมอบตามสัญญา",
      data: target,
      color: "navy",
      dashStyle: "dash",
      type: 'line',
      visible: true
    };

  } catch (error) { }

  const options = {
    chart: {
      type: "column",
      // type: "line",
      height: 470,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: days,
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    yAxis: {
      title: {
        text: "ปริมาณผลผลิต (ตัน)",
      },
      stackLabels: {
        enabled: true // Show stack labels
      },
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    tooltip: {
      //pointFormat: "<b>{series.name}: {point.y} ตัน</b>",
      style: {
        fontSize: "18px",
      },
      enabled: true,
      ...formatterTooltip
    },
    // legend: {
    //   layout: "vertical",
    //   align: "right",
    //   verticalAlign: "top",
    // },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter : formatterXaxis_1,
          // formatter: function () {
          //   for (let i = 0; i < ranges.length; i++) {
          //     if (this.y >= ranges[i].divider) {
          //       return (
          //         Number(this.y / ranges[i].divider).toLocaleString(undefined, {
          //           maximumFractionDigits: 2,
          //           minimumFractionDigits: 2,
          //         }) + ranges[i].suffix
          //       );
          //     }
          //   }
          //   return Number(this.y).toLocaleString(undefined, {
          //     maximumFractionDigits: 2,
          //     minimumFractionDigits: 2,
          //   });
          // },
          // style: {
          //   fontSize: "16px",
          // },
        },
      },
      line: {
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "16px",
          },
          formatter : formatterXaxis_1,
          // formatter: function () {
          //   for (let i = 0; i < ranges.length; i++) {
          //     if (this.y >= ranges[i].divider) {
          //       return (
          //         Number(this.y / ranges[i].divider).toLocaleString(undefined, {
          //           maximumFractionDigits: 2,
          //           minimumFractionDigits: 2,
          //         }) + ranges[i].suffix
          //       );
          //     }
          //   }
          //   if (Number(this.y) === 0) {
          //     return null;
          //     //return this.y.toString();
          //   } else {
          //     return Number(this.y).toLocaleString(undefined, {
          //       maximumFractionDigits: 2,
          //       minimumFractionDigits: 2,
          //     });
          //   }
          // },
          formatter: function () {
            if (this.x === middleLen) {
              return Number(this.y).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
            return null;
          },
        },

        enableMouseTracking: true,
      },
      series: {
        lineWidth: 3,
        marker: {
          enabled: false,
        },
      },
    },
    // colors: ["#5076ff", "#c324c7", "#1dd8bd", "#e83880"],
    exporting: {
      chartOptions: {
        // specific options for the exported image
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "6px",
              },
              formatter: function () {
                for (let i = 0; i < ranges.length; i++) {
                  if (this.y >= ranges[i].divider) {
                    return (
                      Number(this.y / ranges[i].divider).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 2, minimumFractionDigits: 2 }
                      ) + ranges[i].suffix
                    );
                  }
                }
                if (Number(this.y) === 0) {
                  return null;
                  //return this.y.toString();
                } else {
                  return Number(this.y).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  });
                }
              },
            },
            enableMouseTracking: true,
          },
        },
        xAxis: {
          labels: {
            style: {
              fontSize: "6px",
            },
          },
        },
        yAxis: {
          stackLabels: {
            enabled: true // Show stack labels
          },
          labels: {
            style: {
              fontSize: "6px",
            },
          },
        },
      },
      buttons: {
        contextButton: {
          enabled: false,
        },
        button: {
          text: "Export",
          theme: {
            fill: "#f9df70",
            stroke: "#f9df70",
            states: {
              hover: {
                fill: "#fcc",
                stroke: "#f00",
              },
              select: {
                fill: "#cfc",
                stroke: "#0f0",
              },
            },
          },
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "bottom",
        y: 0,
      },
    },
    // series: setitemDetail?.filter((item) => {
    //   return item.data.some((value) => value !== 0 && value !== null);
    // }),
    series: setitemDetail?.filter((item) => {
      // return item.data.reduce((sum,cur)=> sum += cur) > 0
      return item.data.some((value) => value !== 0 && value !== null);
    }),
  };

  return (
    <div className={`${styles.charts}`} >
      <HighCharts ref={ref} options={options} />
    </div >
  );
});

export default ChartsMonthSellingPlants;
