import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { QueryGetAll } from "../../../helpers/api.helper";
import { defaultApi } from "../../../services/api";
import { getProfileData } from "../../../services/auth.slice";
import ChartsYearProductionCapacity from "./charts/ChartsYearProductionCapacity";
import ExportPopup from "../../../layouts/exportpopup/ExportPopup";
import { convertDateThai2 } from "../../../utils/format/Date.format";
import styles from "./scss/ReportYearProductionCapacity.module.scss";

const ReportYearProductionCapacity = () => {
  const profileData = useSelector(getProfileData);
  const chart = useRef();

  const [_triggerExport, setTriggerExport] = useState(false);
  //const [_resName, resName] = useState(null)
  const [_resData, resData] = useState(null);
  //const [_resDfSet, resDfSet] = useState(null)
  let _resDfSet = null;
  const [_fstLoad, fstLoad] = useState(true);

  const [_setKeyFact, keyFact] = useState({
    factId: "",
  });
  const [stateYear, setStateYear] = useState({
    year: new Date().getFullYear(),
    active: false,
  });

  /* Highcharts export function : START */
  const downloadImage = () => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.setTitle({ text: "เปรียบเทียบปริมาณการผลิตไฟฟ้าและการจำหน่ายไฟฟ้า" });

      chart.current.chart.exportChart({
        type: "image/jpeg",
        filename: "chart",
      });

      chart.current.chart.setTitle({ text: "" });
    }
  };

  const downloadCSV = () => {
    // if (chart && chart.current && chart.current.chart) {
    //   chart.current.chart.downloadCSV();
    // }
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.downloadCSV();
    }
  };
  /* Highcharts export function : END */

  const info = useCallback(async () => {
    let qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=createdBy%20eq%20${profileData.id}`,
      $orderby: "",
    };

    const resAllFac = await defaultApi.getAllFactoryMember(``);

    if (resAllFac?.data?.length > 0) {
      for (let index = 0; index < resAllFac?.data?.length; index++) {
        const element = resAllFac?.data[index];
        if (+element?.userId === +profileData?.id) {
          qryGetAll = {
            $count: "%24count=true",
            $expand: "",
            $top: "",
            $skip: "",
            $filter: `&%24filter=id%20eq%20${element.factoryId}`,
            $orderby: "",
          };
        }
      }
    }

    const _query = QueryGetAll(qryGetAll);
    const res_entp = await defaultApi.getAllFactory(_query);
    const factId = res_entp.data.datas[0].id;

    keyFact({
      factId: factId,
    });

    let date = new Date();
    //let month = date.getMonth()
    let year = date.getFullYear();

    // const res_dfset = await defaultApi.getReportDashboard("powergenYear=" + year + "&factoryTransactionYear=" + year + "&enterpriseTransactionYear=" + year)
    const res_data = await defaultApi.getReportFactoryPowerGenerateYear(
      "factoryId=" + factId + "&atYear=" + year
    );
    
    // resDfSet(res_dfset)
    resData(res_data);

    //resName(res_entp.data.datas[0].name)
  }, [profileData]);

  const selectYear = () => {
    const date = new Date();
    let year = date.getFullYear();
    let yearLst = [];
    yearLst.push({ year: year-1, yearName: year-1 + 543 });
    for (let index = 0; index < 21; index++) {
      yearLst.push({ year: year, yearName: year + 543 });
      //year = year - 1
      year = year + 1;
    }

    let x = yearLst.map((datax, index) => {
      return (
        <option key={index} value={datax.year}>
          {datax.yearName}
        </option>
      );
    });

    return (
      <>
        <option key={`df_01`} style={{ display: "none" }}>
          ปี
        </option>
        {x}
      </>
    );
  };

  // const selM = (value) => {
  //   listSel = {
  //     ...listSel, month: value
  //   }
  //   iSearch()
  // }

  const selY = (value) => {
    setStateYear((prev) => ({ ...prev, year: value, active: true }));
  };

  const iSearch = async () => {
    // const res_dfset = await defaultApi.getReportDashboard("powergenYear=" + listSel.year + "&factoryTransactionYear=" + listSel.year + "&enterpriseTransactionYear=" + listSel.year)
    const res_data = await defaultApi.getReportFactoryPowerGenerateYear(
      "factoryId=" + _setKeyFact.factId + "&atYear=" + stateYear.year
    );
    // resDfSet(res_dfset)
    resData(res_data);

    setStateYear((prev) => ({ ...prev, active: false }));
  };

  const dateDefault = useMemo(() => {
    const date = new Date();

    return stateYear.year
      ? convertDateThai2(date, "day_month") +
          "/" +
          convertDateThai2(stateYear.year.toString(), "year")
      : convertDateThai2(date);
  }, [stateYear]);

  useEffect(() => {
    if (stateYear.active) iSearch();
  }, [stateYear]);

  useEffect(() => {
    if (_fstLoad === true) {
      info();
      fstLoad(false);
    }
  }, [info, _fstLoad]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.date_filter}`}>
        <select
          style={{ color: "black" }}
          name=""
          id="sel01"
          onChange={(e) => {
            selY(e.target.value);
          }}
        >
          {selectYear()}
        </select>
      </div>

      <h4 className={`${styles.title_text}`}>เปรียบเทียบปริมาณการผลิตไฟฟ้าและการจำหน่ายไฟฟ้า</h4>

      <div className={`${styles.show_date}`}>
        <p>ข้อมูลล่าสุด ณ วันที่</p>
        <p>{dateDefault}</p>
      </div>

      <div className={`${styles.charts_}`}>
        <ChartsYearProductionCapacity
          ref={chart}
          dfset={_resDfSet}
          datain={_resData}
          datetime={stateYear.year}
        />
      </div>

      <div className={`${styles.export_}`}>
        <ExportPopup
          label={"report"}
          trigger={_triggerExport}
          event={{ csv: downloadCSV, image: downloadImage }}
        />
        <button
          id="exporting"
          type="button"
          className={`${styles.btn_}`}
          onClick={() => {
            _triggerExport === true
              ? setTriggerExport(false)
              : setTriggerExport(true);
          }}
        >
          Export
        </button>
      </div>
    </div>
  );
};

export default ReportYearProductionCapacity;
