import React from "react";
import { FaEye } from "react-icons/fa";
import Pagination from "../../../../../../components/pagination/Pagination";
import { convertDateThai } from "../../../../../../utils/format/Date.format";

const pageSize = 10;

const Table = ({
  _dataArr,
  _totalCount,
  _currentPage,
  _onPaginate,
  onSwitchPage,
  _paymentStatus,
}) => {
  return (
    <div className="table_container">
      <div className="table_box table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>วันที่</th>
              <th>ผู้รับซื้อพลังงาน</th>
              <th>ปริมาณไฟฟ้าที่เสนอขาย(KWh)</th>
              <th>ราคาต่อหน่วย(บาท)</th>
              <th>ยอดรวมสุทธิ(บาท)</th>
              <th>สถานะการจ่ายเงิน</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {_dataArr.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{convertDateThai(item.sellDate)}</td>
                  <td>{item.buyer}</td>
                  <td>{item.powers}</td>
                  <td>{(+item.priceRate)?.toLocaleString()}</td>
                  <td>{(+item.total)?.toLocaleString()}</td>
                  <td>
                    {
                      _paymentStatus?.find(
                        (item1) => item1.id === item.paymentStatus
                      )?.statusName
                    }
                  </td>
                  <td>
                    <FaEye
                      className="fa_eye"
                      onClick={() => onSwitchPage(item.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="pagination_">
        <Pagination
          pageSize={pageSize}
          totalCount={_totalCount}
          currentPage={_currentPage}
          onPageChange={(page) => _onPaginate(page)}
        />
      </div>
    </div>
  );
};

export default Table;
