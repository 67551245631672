import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import $ from "jquery";
import { defaultApi } from "../../../services/api";
import ChartsIncomeExpense from "./charts/ChartsIncomeExpense";
import ChartsIncomeExpense2 from "./charts/ChartsIncomeExpense2";
import ExportPopup from "../../../layouts/exportpopup/ExportPopup";
import { convertDateThai2 } from "../../../utils/format/Date.format";
import styles from "./scss/CashflowInfo.module.scss";
import { QueryGetAll } from "../../../helpers/api.helper";

let factoryList = [];

const CashflowInfo = ({ swCotent, _theme, barFact, typeFact }) => {
  const chart = useRef();

  const [_triggerExport, setTriggerExport] = useState(false);
  const [_resMonth, setResMonth] = useState();
  const [_resYear, setResYear] = useState();
  const [_facLst, setFacLst] = useState();

  const [_activeSelect, setActiveSelect] = useState(false)

  const [_valueRes, setValueRes] = useState({
    factid: "",
    facttype: "",
    day: "",
    month: "",
    year: "",
  });

  const downloadCSV = () => {
    // if (chart && chart.current && chart.current.chart) {
    //   chart.current.chart.downloadCSV();
    // }
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.downloadCSV();
    }
  };

  const downloadImage = () => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.setTitle({
        text: "ข้อมูลเปรียบเทียบรายได้-รายจ่ายของโรงไฟฟ้า",
      });
      chart.current.chart.setSubtitle({
        text: checkWordTitle
      });
      // chart.current.chart.setSubtitle({
      //   text: allFc === "เลือกโรงไฟฟ้า" || allFc === "" ? "ทั้งหมด" : allFc,
      // });

      chart.current.chart.exportChart({
        type: "image/jpeg",
        filename: "chart",
      });

      chart.current.chart.setTitle({ text: "" });
      chart.current.chart.setSubtitle({ text: "" });
    }
  };

  const apiItem = async (barFact, typeFact) => {
    try {
      let strquery = ""

      if (+typeFact?.id !== -99 && +barFact?.id !== -99) {
        strquery = `&$filter=factoryTypeId eq ${typeFact?.id} and id eq ${barFact?.id}`
      } else if (+typeFact?.id === -99 && +barFact?.id !== -99) {
        strquery = `&$filter=id eq ${barFact?.id}`
      } else if (+typeFact?.id !== -99 && +barFact?.id === -99) {
        strquery = `&$filter=factoryTypeId eq ${typeFact?.id}`
      } else {
        strquery = ""
      }

      let _query = {
        $count: "",
        $expand: "",
        $top: "",
        $skip: ``,
        $filter: `${strquery}`,
        $orderby: "",
      };

      const query = QueryGetAll(_query);

      const res_fac = await defaultApi.getAllFactory(query);
      for (let index = 0; index < res_fac.data.length; index++) {
        const element = res_fac.data[index];
        factoryList[index] = { id: element.id, name: element.name };
      }
      setFacLst(factoryList);

      let date = new Date();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      let factid = +barFact?.id === -99 ? "" : +barFact?.id
      let facttype = +typeFact?.id === -99 ? "" : +typeFact?.id

      setValueRes((prev) => ({
        ...prev,
        factid: factid,
        facttype: facttype,
        day: prev?.day ? prev?.day : date,
        month: prev?.month ? prev?.month : month,
        year: prev?.year ? prev?.year : Number(year),
      }))

      factoryList = [];
    } catch (error) { }
  };

  const checkWordTitle = useMemo(() => {
    const factId = _valueRes?.factid
    const factTp = _valueRes?.facttype

    let formattname = "โรงไฟฟ้าทั้งหมด";

    if (factTp) {
      formattname = `กลุ่มโรงไฟฟ้าภายใต้ประเภท${typeFact?.name}`
    }
    if (factId) {
      formattname = `${_facLst?.find((item) => +item?.id === +factId)?.name || ""}`
    }

    return formattname
  }, [_valueRes, barFact, typeFact, _facLst])

  const facLst = (pin) => {
    let x = null;
    try {
      x = _facLst.map((datax, index) => {
        let key = pin + index;
        return (
          <option key={key} value={datax.id}>
            {datax.name}
          </option>
        );
      });

      return (
        <>
          <option key={`df_${pin}`} value={""}>
            เลือกโรงไฟฟ้า
          </option>
          {x}
        </>
      );
    } catch (error) { }
  };

  const selectMonth = (pin) => {
    let monthNameLst = [
      "เดือน",
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];

    return monthNameLst.map((datax, index) => <option key={`${pin}_${index}`} value={index === 0 ? "" : index}>
      {datax}
    </option>);
  };

  const selectYear = (pin) => {
    const date = new Date();
    let year = date.getFullYear();
    let yearLst = [];
    yearLst.push({ yearen: year-1, yearth: year-1 + 543 });
    for (let index = 0; index < 20; index++) {
      yearLst.push({ yearen: year, yearth: year + 543 });
      year = year + 1;
    }

    return yearLst.map((datax, index) => <option key={`${pin}_${index}`} value={datax.yearen}>
      {datax.yearth}
    </option>);
  };

  const resSearchMonth = useCallback(async () => {
    try {
      let mont = `&atMonth=${_valueRes?.month}`
      let year = `&atYear=${_valueRes?.year}`
      let factid = `&factoryId=${_valueRes?.factid}`
      let facttype = `&factoryTypeId=${_valueRes?.facttype}`
      let monthParame = `${mont}${year}${factid}${facttype}`

      const res = await defaultApi.getReportFactoryIncomeMonth(
        monthParame
      );
      setResMonth(res?.data);
    } catch (error) {

    }
  }, [_valueRes])

  const resSearchYear = useCallback(async () => {
    try {
      let year = `&atYear=${_valueRes?.year}`
      let factid = `&factoryId=${_valueRes?.factid}`
      let facttype = `&factoryTypeId=${_valueRes?.facttype}`
      let yearParame = `${year}${factid}${facttype}`

      const res = await defaultApi.getReportFactoryIncomeYear(
        yearParame
      );
      setResYear(res?.data);
    } catch (error) {

    }
  }, [_valueRes])

  const dateForm = useMemo(() => {
    if (swCotent === "41") {
      if (_activeSelect) {
        return `${convertDateThai2(
          _valueRes?.month.toString(),
          "month"
        )}/${convertDateThai2(_valueRes?.year.toString(), "year")}`
      } else {

        return `${convertDateThai2(_valueRes?.day, "day")}/${convertDateThai2(
          _valueRes?.month.toString(),
          "month"
        )}/${convertDateThai2(_valueRes?.year.toString(), "year")}`
      }
    }
    if (swCotent === "42") {
      return `${convertDateThai2(_valueRes?.year.toString(), "year")}`
    }
  }, [swCotent, _valueRes, _activeSelect])

  useEffect(() => {
    resSearchMonth()
  }, [resSearchMonth])

  useEffect(() => {
    resSearchYear()
  }, [resSearchYear])

  useEffect(() => {
    apiItem(barFact, typeFact);
  }, [barFact, typeFact]);

  // /report/factory/income/year

  // if (swCotent === "41") {
  //   return (
  //     <div className={`${styles.container}`}>
  //       <div className={`${styles.boxcontainer}`}>
  //         <div className={`${styles.drop_filter}`}>
  //           <select
  //             style={{ color: "black", visibility:'hidden' }}
  //             name=""
  //             id="sel01"
  //             onChange={(e) => {
  //               setValueRes((prev) => ({ ...prev, factid: e?.target?.value }))
  //             }}
  //             disabled={true}
  //             value={_valueRes.factid === "" ? "" : _valueRes.factid}
  //           >
  //             {facLst(1)}
  //           </select>
  //         </div>
  //         <div className={`${styles.drop_filter}`}>
  //           <select
  //             style={{ color: "black" }}
  //             name=""
  //             id="sel02"
  //             // defaultValue={formMattsendMonth.month === '' ? 'เดือน' : formMattsendMonth.month}
  //             onChange={(e) => {
  //               setValueRes((prev) => ({ ...prev, month: e?.target?.value }))
  //               setActiveSelect(true)
  //             }}
  //             value={_valueRes.month === "" ? "" : _valueRes.month}
  //           >
  //             {selectMonth(1)}
  //           </select>
  //         </div>
  //         <div className={`${styles.drop_filter}`}>
  //           <select
  //             style={{ color: "black" }}
  //             name=""
  //             id="sel03"
  //             // defaultValue={formMattsendMonth.year === '' ? 'ปี' : formMattsendMonth.year}
  //             onChange={(e) => {
  //               setValueRes((prev) => ({ ...prev, year: e?.target?.value }))
  //               setActiveSelect(true)
  //             }}
  //             value={_valueRes.year === "" ? "" : _valueRes.year}
  //           >
  //             {selectYear(1)}
  //           </select>
  //         </div>
  //       </div>

  //       <h4
  //         className={`${styles.title_text}`}
  //         style={{ background: _theme?.backgroundColor }}
  //       >
  //         ข้อมูลเปรียบเทียบรายได้-รายจ่ายของโรงไฟฟ้า
  //       </h4>
  //       <h4 id="subtitle01" className={`${styles.subtitle_text}`}>
  //         {checkWordTitle}
  //       </h4>

  //       <div className={`${styles.show_date}`}>
  //         <p>ข้อมูล ณ</p>
  //         <p>{dateForm}</p>
  //       </div>

  //       <div className={`${styles.charts_}`}>
  //         <ChartsIncomeExpense
  //           ref={chart}
  //           datain={_resMonth}
  //           datetime={_valueRes}
  //         />
  //       </div>

  //       <div className={`${styles.export_}`}>
  //         <ExportPopup
  //           label={"report"}
  //           trigger={_triggerExport}
  //           event={{ csv: downloadCSV, image: downloadImage }}
  //         />
  //         <button
  //           id="exporting01"
  //           type="button"
  //           className={`${styles.btn_}`}
  //           onClick={() => {
  //             setTriggerExport(!_triggerExport);
  //           }}
  //         >
  //           Export
  //         </button>
  //       </div>
  //     </div>
  //   );
  // }

  if (swCotent === "42") {
    window.ChartTitleUnit = {
      title: 'เดือน',
      unit: ['บาท'],
      totalStatus : false
    }
    return (
      <div className={`${styles.container}`}>
        <div className={`${styles.boxcontainer}`}>
          <div className={`${styles.drop_filter}`}>
            <select
              style={{ color: "black", visibility:'hidden' }}
              name=""
              id="sel04"

              // defaultValue={formMattsendYear.month === '' ? 'เดือน' : formMattsendYear.month}
              onChange={(e) => {
                setValueRes((prev) => ({ ...prev, factid: e?.target?.value }))
              }}
              disabled={true}
              value={_valueRes.factid === "" ? "" : _valueRes.factid}
            >
              {facLst(2)}
            </select>
          </div>
          <div className={`${styles.drop_filter}`}>
            <select
              style={{ color: "black" }}
              name=""
              id="sel05"
              // defaultValue={formMattsendYear.year === '' ? 'ปี' : formMattsendYear.year}
              onChange={(e) => {
                setValueRes((prev) => ({ ...prev, year: e?.target?.value }))
              }}
              value={_valueRes.year === "" ? "" : _valueRes.year}
            >
              {selectYear(2)}
            </select>
          </div>
        </div>

        <h4
          className={`${styles.title_text}`}
          style={{ background: _theme?.backgroundColor }}
        >
          ข้อมูลเปรียบเทียบรายได้-รายจ่ายของโรงไฟฟ้า
        </h4>
        <h4 id="subtitle02" className={`${styles.subtitle_text}`}>
          {checkWordTitle}
        </h4>

        <div className={`${styles.show_date}`}>
          <p>ข้อมูลล่าสุด ณ ปีที่</p>
          <p>{dateForm}</p>
        </div>

        <div className={`${styles.charts_}`}>
          <ChartsIncomeExpense2
            ref={chart}
            datain={_resYear}
            datetime={_valueRes}
          />
        </div>

        <div className={`${styles.export_}`}>
          <ExportPopup
            label={"report"}
            trigger={_triggerExport}
            event={{ csv: downloadCSV, image: downloadImage }}
          />
          <button
            id="exporting02"
            type="button"
            className={`${styles.btn_}`}
            onClick={() => {
              setTriggerExport(!_triggerExport);
            }}
          >
            Export
          </button>
        </div>
      </div>
    );
  }
};

export default CashflowInfo;
