import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { Map, View } from "ol";
import { fromLonLat } from "ol/proj";
import { defaults as defaultControls } from "ol/control";
import { defaults as defaultInteractions } from "ol/interaction";
import {
  GOOGLE_HYBRID_LAYER,
  getPolygonCenter,
} from "../../../../helpers/map.helper";
import { getEnterpriseData } from "../../../../services/enterprise.slice";
import { defaultApi } from "../../../../services/api";
import styles from "./MapElement.module.scss";

const MapElement = React.memo(
  ({ _onInit, _switch, _setCropData, _setCropId, _geom }) => {
    const enterpriseData = useSelector(getEnterpriseData);
    const mountRef = useRef();
    const mapRef = useRef();

    /** @type {Array.<Map|Function>} */
    const [map, setMap] = useState(null);

    const getCrop = useCallback(
      async (id) => {
        if (!id) return;
        // Reset State
        _setCropData({});

        try {
          const res = await defaultApi.getCrop(id);
          _setCropData(res.data);
          _setCropId(res.data.id);
        } catch (error) {
          console.log(error);
        }
      },
      [_setCropData, _setCropId]
    );

    const _createMap = useCallback(
      (el) => {
        const layers = [GOOGLE_HYBRID_LAYER];

        const newMap = new Map({
          target: el,
          view: new View({
            minZoom: 5,
            maxZoom: 23,
            center: fromLonLat([enterpriseData?.lng, enterpriseData?.lat]),
            zoom: 18,
          }),
          pixelRatio: 1,
          layers,
          controls: defaultControls({
            zoom: false,
            attribution: false,
          }),
          interactions: defaultInteractions({ mouseWheelZoom: true }).extend(
            []
          ),
        });

        setMap(newMap);
        _onInit(newMap);

        console.log("Map initialized");
      },
      [_onInit, enterpriseData]
    );

    const clickHandler = useCallback(
      (evt) => {
        evt.map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
          const parcelId = feature.getProperties().parcelId;
          const cropId = feature.getProperties().cropId;
          if (!parcelId) {
            getCrop(cropId);
            console.log("fetch crop data...");
          }
        });
      },
      [getCrop]
    );

    const dispose = useCallback(() => {
      map?.un("click", clickHandler);
      map?.setTarget(null);
    }, [map, clickHandler]);

    useEffect(() => {
      // Waiting for layout initialized.
      if (!mountRef.current && mapRef.current) {
        mountRef.current = true;
        setTimeout(() => _createMap(mapRef.current), 450);
      }
    }, [mountRef, mapRef, _createMap, map, enterpriseData]);

    useEffect(() => {
      // _switch ต้องเท่ากับ false เท่านั้นถึงจะคลิก map ได้ !
      if (_switch) {
        map?.un("click", clickHandler);
      } else {
        map?.un("click", clickHandler);
        map?.on("click", clickHandler);
      }

      // setCenter map
      if (_geom !== null && !_switch) {
        let _point = getPolygonCenter(_geom);
        let _center = fromLonLat(_point);
        map?.getView()?.setCenter(_center);
        map?.getView()?.setZoom(18);
      }
    }, [map, clickHandler, _switch, _geom]);

    useEffect(() => {
      return () => dispose();
    }, [dispose]);

    return <div ref={mapRef} className={`${styles.container}`}></div>;
  }
);

export default MapElement;
