import Cookies from "js-cookie";

/* Set Data */
export const setCookiesStorageData = (key, value, options) => {
  typeof value === "object"
    ? Cookies.set(key, JSON.stringify(value), options)
    : Cookies.set(key, value, options);
};

/* Get Data */
export const getCookiesStorageData = (key) => {
  try {
    return JSON.parse(Cookies.get(key));
  } catch (error) {
    return Cookies.get(key);
  }
};

/* Remove Data */
export const removeCookies = (key, options) => {
  Cookies.remove(key, options);
};
