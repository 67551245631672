import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../layouts/header/Header";
import Navbar from "../../../layouts/navbar/Navbar";
import Footer from "../../../layouts/footer_2/Footer";
import MapElement from "../_partials/MapElement";
import FactoryPopup from "../_partials/FactoryPopup";
import TradingPopup from "../_partials/TradingPopup";
import EnterprisePopup from "../_partials/EnterprisePopup";
import PowerPlantsDetails from "./details/PowerPlantsDetails";
import { defaultApi } from "../../../services/api";
import { getProfileData, setEditabled } from "../../../services/auth.slice";
import {
  setFactoryData,
  setFactoryStatus,
  setFactoryType,
} from "../../../services/factory.slice";
import { QueryGetAll } from "../../../helpers/api.helper";
import styles from "./scss/GuestManage.module.scss";

const GuestManage = () => {
  const dispatchFn_ = useDispatch();
  const profileData = useSelector(getProfileData);
  const { layerId } = useParams();

  const [_map, setMap] = useState(null);
  const [factory, setFactory] = useState({});
  const [allEnterprise, setAllEnterprise] = useState([]);

  const getFactory = useCallback(async () => {
    try {
      const res = await defaultApi.getFactory(layerId);
      setFactory(res.data);
      dispatchFn_(setFactoryData(res.data));
      /* ถ้าไอดีผู้สร้างตรงกับไอดีผู้ใช้ถึงจะสามารถแก้ไขข้อมูลได้ */
      if (res.data.createdBy === profileData?.id) {
        dispatchFn_(setEditabled(true));
      } else {
        dispatchFn_(setEditabled(false));
      }
    } catch (error) {
      console.log(error);
    }
  }, [dispatchFn_, layerId, profileData]);
  const getMultipleApi = useCallback(async () => {
    try {
      const [factoryStatus, factoryType] = await Promise.all([
        defaultApi.getFactoryStatus(),
        defaultApi.getFactoryType(),
      ]);
      dispatchFn_(setFactoryStatus(factoryStatus.data));
      dispatchFn_(setFactoryType(factoryType.data));
    } catch (error) {
      console.log(error);
    }
  }, [dispatchFn_]);
  const getAllEnterprise = useCallback(async () => {
    if (!layerId) return;

    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=factoryId%20eq%20${layerId}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllEnterprise(_queryAll);
      const datas = res.data.datas;
      const filter = datas.filter((item) => item.geom !== null);
      setAllEnterprise(filter);
    } catch (error) {
      console.log(error);
    }
  }, [layerId]);

  useEffect(() => {
    // Use functions
    getFactory();
    getMultipleApi();
    getAllEnterprise();
    return () => {
      dispatchFn_(setFactoryData({}));
      dispatchFn_(setEditabled(false));
    };
  }, [getFactory, getMultipleApi, getAllEnterprise, dispatchFn_]);

  return (
    <div className={`${styles.container} background_1`}>
      <Header />
      <section className={`section ${styles.section_} mx-auto`}>
        <Navbar />
        <article className={`article ${styles.article_} row mx-auto`}>
          <aside className={`${styles.map_} col-8`}>
            <div className={`${styles.map_element}`}>
              <MapElement
                onInit={(map) => setMap(map)}
                _setLayerType={(val) => console.log()}
                _setCoordFeature={() => console.log()}
              />
              <FactoryPopup
                _map={_map}
                _factory={factory}
                _setLayerType={(val) => console.log()}
                _setCoordFeature={(val) => console.log()}
              />
              <TradingPopup _map={_map} _factory={factory} />
              <EnterprisePopup
                _map={_map}
                _allEnterprise={allEnterprise}
                _setLayerType={(val) => console.log()}
                _setCoordFeature={(val) => console.log()}
                _fetchEntId={(val) => console.log()}
              />
            </div>
          </aside>
          <aside className={`${styles.content_details} col-4`}>
            <PowerPlantsDetails _allEnterprise={allEnterprise} />
          </aside>
        </article>
      </section>
      <Footer />
    </div>
  );
};

export default GuestManage;
