import React from "react";
import icon from "../../../../assets/img/Icon";
import HighCharts from "../../../../components/Highcharts/HighCharts";
import styles from "../scss/FarmerInfo.module.scss";
import { formatterTooltip, formatterXaxis_1, formatterYaxis } from "../../../../helpers/suffixchart.helper";


const ChartsIncomeTotal2 = React.forwardRef((prop, ref) => {

  let x_zone = 0

  let currYear = []
  let lastYear = []

  let x_cur_year = prop.datetime.year

  let getValue = (prop) => {
    try {

      let date = new Date()
      let dfyears = date.getFullYear()
      let dfmonths = date.getMonth() + 1
      x_zone = dfmonths - 1
      //let dfdays = date.getDate()
      let year = Number(prop.datetime.year)
      //let month = Number(prop.datetime.month)

      let curY = prop.datain.enterpriseIncomeCurrentYear
      curY.forEach((element, index) => {

        if (year === dfyears) {
          if (element.month <= dfmonths) {
            currYear[index] = element?.value || 0
          } else if (element.month > dfmonths) {
            currYear[index] = 0
            // currYear[index] = null
          }
        } else if (year < dfyears) {
          currYear[index] = element?.value || 0
        }

      });

      let lstY = prop.datain.enterpriseIncomeLastYear
      lstY.forEach((element, index) => {

        if (year === dfyears) {
          if (element.month <= dfmonths) {
            lastYear[index] = element?.value || 0
          } else if (element.month > dfmonths) {
            lastYear[index] = element?.value || 0
            // lastYear[index] = null
          }
        } else if (year < dfyears) {
          lastYear[index] = element?.value || 0
        }

      })
    } catch (error) {
      //console.log(error)
    }

  }

  getValue(prop)

  let ranges = [
    { divider: 1e18, suffix: "E" },
    { divider: 1e15, suffix: "P" },
    { divider: 1e12, suffix: "T" },
    { divider: 1e9, suffix: "G" },
    { divider: 1e6, suffix: "M" },
    { divider: 1e3, suffix: "K" },
  ];

  const options2 = {
    chart: {
      // type: "column",
      type: "line",
      height: 400,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ],
    },
    yAxis: {
      labels: {
        enabled: true,
        formatter: formatterYaxis,
      },
      title: {
        text: "รายได้ ( บาท )",
      },
    },
    tooltip: {
      //pointFormat: "<b>{series.name}: {point.y} บาท</b><br/>",
      style: {
        fontSize: "18px",
      },
      enabled: true,
      ...formatterTooltip
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: function () {
            for (let i = 0; i < ranges.length; i++) {
              if (this.y >= ranges[i].divider) {
                return (
                  Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
                );
              }
            }
            return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
          },
          style: {
            fontSize: "16px",
          },
        },
      },
      line: {
        dataLabels: {
          enabled: true,
          formatter: formatterXaxis_1,
          // formatter: function () {
          //   for (let i = 0; i < ranges.length; i++) {
          //     if (this.y >= ranges[i].divider) {
          //       return (
          //         Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
          //       );
          //     }
          //   }
          //   if (Number(this.y) === 0) {
          //     return null
          //     //return this.y.toString();
          //   } else {
          //     return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
          //   }

          // },
          style: {
            fontSize: "16px",
          },
        },
        enableMouseTracking: true,
        marker: {
          enabled: false
        }
      },
      // line: {
      //   enableMouseTracking: false,
      // },
    },
    exporting: {
      chartOptions: { // specific options for the exported image
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "6px",
              },
              formatter: function () {
                for (let i = 0; i < ranges.length; i++) {
                  if (this.y >= ranges[i].divider) {
                    return (
                      Number(this.y / ranges[i].divider).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + ranges[i].suffix
                    );
                  }
                }
                if (Number(this.y) === 0) {
                  return null
                  //return this.y.toString();
                } else {
                  return Number(this.y).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                }
              },
            },
            enableMouseTracking: true,
          },
        },
        xAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
        yAxis: {
          labels: {
            style: {
              fontSize: '6px'
            },
          }
        },
      },
      buttons: {
        contextButton: {
          enabled: false,
        },
        button: {
          text: "Export",
          theme: {
            fill: "#f9df70",
            stroke: "#f9df70",
            states: {
              hover: {
                fill: "#fcc",
                stroke: "#f00",
              },
              select: {
                fill: "#cfc",
                stroke: "#0f0",
              },
            },
          },
          menuItems: [
            "viewFullscreen",
            "separator",
            "downloadPNG",
            "downloadSVG",
            "downloadPDF",
            "separator",
            "downloadXLS",
          ],
        },
      },
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        align: "right",
        verticalAlign: "bottom",
        y: 0,
      },
    },
    series: [
      {
        name: +x_cur_year + 543,
        // name: "ปีปัจจุบัน",
        data: currYear,
        color: "#789b33",
        zoneAxis: 'x',
        zones: [{
          value: x_zone
        }, {
          dashStyle: 'dot'
        }],
      },
      {
        name: +x_cur_year + 542,
        data: lastYear,
        color: "#0086ea",
        zoneAxis: 'x',
        zones: [{
          value: x_zone
        }, {
          dashStyle: 'solid'
        }],
      },
    ],
  };

  const calRes = () => {
    let x0 = 0;
    let x1 = 0;
    let x3 = 0;
    currYear.map((data_1) => {
      x0 += data_1
      return null
    })

    lastYear.map((data_2) => {
      x1 += data_2
      return null
    })

    const flipicon = (val) => {
      if (val > 0) {
        return (
          <>
            {/* <div className={`${styles.boxicon}`}><img className={`${styles.icon}`} src={icon.rising} /> : {x3.toFixed(2)} %</div> */}
            <div className={`${styles.boxicon}`}><img alt="" className={`${styles.icon}`} src={icon.rising} /> : {x3.toLocaleString(undefined, { minimumFractionDigits: 2 })} บาท</div>
          </>
        )
      } else {
        return (
          <>
            {/* <div className={`${styles.boxicon}`}><img style={{ "transform": "scaleY(-1)" }} className={`${styles.icon}`} src={icon.rising} /> : {x3.toFixed(2)} %</div> */}
            <div className={`${styles.boxicon}`}><img alt="" style={{ "transform": "scaleY(-1)" }} className={`${styles.icon}`} src={icon.rising} /> : {x3.toLocaleString(undefined, { minimumFractionDigits: 2 })} บาท</div>
          </>
        )
      }
    }

    x3 = x0 - x1

    //x3 = (((x0 - x1) * 100) / x1)

    return (
      <>
        <div>
          <p>รายได้รวมทั้งหมด ( ปีปัจจุบัน ) : {x0.toLocaleString()} บาท</p>
          <p>รายได้รวมทั้งหมด ( ปีก่อน ) : {x1.toLocaleString()} บาท</p>
        </div>
        {
          flipicon(x3.toFixed(2))
        }
      </>
      // <div className="d-flex justify-content-between align-items-strech">
      //   <div className="font_size_total_chart">
      //     <div style={{"color":"#ffcf00"}} className="d-flex justify-content-start align-items-center">รายได้รวมทั้งหมด ( ปีปัจจุบัน ) : { x0.toLocaleString() } บาท</div>
      //     <div style={{"color":"#a52352"}} className="d-flex justify-content-start align-items-center">รายได้รวมทั้งหมด ( ปีก่อน ) : { x1.toLocaleString() } บาท</div>
      //   </div>
      //   <div className=" d-flex justify-content-center align-items-center">
      //     {
      //       flipicon(x3.toFixed(2))
      //     }
      //     <div className="ms-2 me-3 percentage_text">{x3.toFixed(2)}%</div>
      //   </div>
      // </div>
    )
  }

  return (
    <div className={`${styles.charts}`}>
      <HighCharts ref={ref} options={options2} />
      <div className={`${styles.summary}`}>
        {
          calRes()
        }
      </div>
    </div>
    // <div className="charts_income_total position-relative">
    //   <HighCharts options={options2} />
    //   <div className="button_y d-flex justify-content-between align-items-center">
    //     <div className="w-100">
    //       {
    //         calRes()
    //       }
    //     </div>
    //     <button className="btn_">Export</button>
    //   </div>
    // </div>
  );
});

export default ChartsIncomeTotal2;
