import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  enterpriseData: {},
  allEnterpriseData: [],
  enterpriseType: [],
  factoryType: [],
};

export const enterpriseSlice = createSlice({
  name: "enterpriseSlice",
  initialState,
  reducers: {
    setEnterpriseData(state, { payload }) {
      state.enterpriseData = payload;
    },
    setAllEnterpriseData(state, { payload }) {
      state.allEnterpriseData = payload;
    },
    setEnterpriseType(state, { payload }) {
      state.enterpriseType = payload;
    },
    setFactoryType(state, { payload }) {
      state.factoryType = payload;
    },
  },
});

export const {
  setEnterpriseData,
  setAllEnterpriseData,
  setEnterpriseType,
  setFactoryType,
} = enterpriseSlice.actions;

export const getEnterpriseData = (state) =>
  state.enterpriseSlice.enterpriseData;
export const getAllEnterpriseData = (state) =>
  state.enterpriseSlice.allEnterpriseData;
export const getEnterpriseType = (state) =>
  state.enterpriseSlice.enterpriseType;
export const getFactoryType = (state) => state.enterpriseSlice.factoryType;
