import React, { useEffect } from "react";
import { IoMdAlert } from "react-icons/io";
import { FaSquare } from "react-icons/fa";
import styles from "./Legends.module.scss";

const Legends = ({ _newPlantArr }) => {
  useEffect(() => {
    over_Out();
  }, []);

  return (
    <div className={`${styles.container}`}>
      <IoMdAlert
        className={`btn_`}
        style={{ color: "#FFFFFF" }}
        size={25}
        cursor={"pointer"}
      />
      <div className={`legend_box show`}>
        {_newPlantArr?.map((item, index) => {
          return (
            <div key={index} className={`${styles.legend_list}`}>
              <FaSquare color={item.color} className={styles.fa_square} />
              <h6>{item.name}</h6>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const over_Out = () => {
  /* const btn_ = document.querySelector(".btn_");
  const btn_s = document.querySelector(".legend_box");

  btn_.addEventListener("mouseover", () => btn_s.classList.add("show"));
  btn_.addEventListener("mouseout", () => btn_s.classList.remove("show"));
  btn_s.addEventListener("mouseover", () => btn_s.classList.add("show"));
  btn_s.addEventListener("mouseout", () => btn_s.classList.remove("show")); */
};

export default Legends;
