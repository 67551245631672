import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getProfileData } from "../services/auth.slice";
import { useSelector } from "react-redux";
import { getCookiesStorageData } from "../helpers/cookies_storage.helper";

const AdminRoutes = ({ redirectPath = "/" }) => {
  const profileData = useSelector(getProfileData);
  const token_ = getCookiesStorageData("token");

  if(!token_) {
    return <Navigate to={redirectPath} replace />;
  } else if (profileData?.userGroupId === 5) {
    return <Outlet />;
  } else {
    return <Navigate to={redirectPath} replace />;
  }

};

export default AdminRoutes;

