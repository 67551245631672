import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { QueryGetAll } from "../../../helpers/api.helper";
import { defaultApi } from "../../../services/api";
import { getProfileData } from "../../../services/auth.slice";
import styles from "./scss/MenuBar.module.scss";
import { toast } from "react-hot-toast";
import Message from '../../../utils/message/Message';

const reportPms = {
  rep1: true, //รายได้กลุ่มเกษตร
  rep2: true, //จำนวนสมารชิก
  rep3: true, //แผนผลการผลิตพืชพลังาน
  rep4: true, //รายได้ - รายจ่ายโรงไฟฟ้า
  rep5: true, //กำลังผลิตไฟฟ้า
  rep6: true, //แผนผลผลิตไฟฟ้า
  rep7: true //วัตถุดิบที่คาดว่าจะเก็บเกี่ยว
}


const MenuBar = ({ switchContent, onClickType, location, navigate, _theme }) => {
  const profile_ = useSelector(getProfileData);
  const [_userPf, setUserPf] = useState(reportPms)
  const [_idMenu, setIdMenu] = useState("")

  const setPermissionReport = useCallback(async () => {

    let qryGetAll = {
      $count: "",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: "",
      $orderby: "",
    };

    const _query = QueryGetAll(qryGetAll);

    try {
      const res = await defaultApi.getLocalGrp(_query)
      res.data.map((data) => {
        //profile_.userLocalGroupId
        //Number(profile_.userLocalGroupId)
        //console.log("profile_.userGroupId",profile_.userGroupId);
        if (profile_.userGroupId === 5) {
          let obj = {
            rep1: true, //รายได้กลุ่มเกษตร
            rep2: true, //จำนวนสมารชิก
            rep3: true, //แผนผลการผลิตพืชพลังาน
            rep4: true, //รายได้ - รายจ่ายโรงไฟฟ้า
            rep5: true, //กำลังผลิตไฟฟ้า
            rep6: true, //แผนผลผลิตไฟฟ้า
            rep7: true, //วัตถุดิบที่คาดว่าจะเก็บเกี่ยว
          }
          obj = ({ ...obj, rep1: false, rep2: false, rep3: false, rep4: false, rep5: false, rep6: false, rep7: false })
          setUserPf(obj)
          setIdMenu(document.getElementById("check10"))
          // document.getElementById("check10").click()
        } else {
          if (Number(data.id) === Number(profile_.userLocalGroupId)) {
            let x = JSON.parse(data.permission)
            let obj = {
              rep1: true, //รายได้กลุ่มเกษตร
              rep2: true, //จำนวนสมารชิก
              rep3: true, //แผนผลการผลิตพืชพลังาน
              rep4: true, //รายได้ - รายจ่ายโรงไฟฟ้า
              rep5: true, //กำลังผลิตไฟฟ้า
              rep6: true, //แผนผลผลิตไฟฟ้า
              rep7: true, //วัตถุดิบที่คาดว่าจะเก็บเกี่ยว
            }
            x.map((pmsLst) => {
              switch (pmsLst.name) {
                case "รายได้กลุ่มเกษตรกร":
                  obj = ({ ...obj, rep1: false })
                  break;
                case "จำนวนสมาชิก":
                  obj = ({ ...obj, rep2: false })
                  break;
                case "แผนผลการผลิตเชื้อเพลิง":
                  obj = ({ ...obj, rep3: false })
                  break;
                case "รายได้ - รายจ่ายโรงไฟฟ้า":
                  obj = ({ ...obj, rep4: false })
                  break;
                case "ปริมาณการผลิตไฟฟ้า":
                  obj = ({ ...obj, rep5: false })
                  break;
                case "แผนผลการผลิตไฟฟ้า":
                  obj = ({ ...obj, rep6: false })
                  break;
                case 'วัตถุดิบที่คาดว่าจะเก็บเกี่ยว':
                  obj = ({ ...obj, rep7: false })
                  break;
                default:
                  break;
              }
              return null
            })

            if (x) {
              for (let index = 0; index < x.length; index++) {
                const element = x[index];
                if (element.name === 'รายได้กลุ่มเกษตรกร') {
                  setIdMenu(document.getElementById("check10"))
                  // document.getElementById("check10").click()
                  break;
                }
                if (element.name === 'จำนวนสมาชิก') {
                  setIdMenu(document.getElementById("check20"))
                  // document.getElementById("check20").click()
                  break;
                }
                if (element.name === 'แผนผลการผลิตเชื้อเพลิง') {
                  setIdMenu(document.getElementById("check30"))
                  // document.getElementById("check30").click()
                  break;
                }
                if (element.name === 'รายได้ - รายจ่ายโรงไฟฟ้า') {
                  setIdMenu(document.getElementById("check40"))
                  // document.getElementById("check40").click()
                  break;
                }
                if (element.name === 'ปริมาณการผลิตไฟฟ้า') {
                  setIdMenu(document.getElementById("check50"))
                  // document.getElementById("check50").click()
                  break;
                }
                if (element.name === 'แผนผลการผลิตไฟฟ้า') {
                  setIdMenu(document.getElementById("check60"))
                  // document.getElementById("check60").click()
                  break;
                }
                if (element.name === 'วัตถุดิบที่คาดว่าจะเก็บเกี่ยว') {
                  setIdMenu(document.getElementById("check70"))
                  // document.getElementById("check70").click()
                  break;
                }
              }
            }


            // obj = {
            //   rep1: false,
            //   rep2: false,
            //   rep3: false,
            //   rep4: false,
            //   rep5: false,
            //   rep6: false,
            // }
            setUserPf(obj)
          }
        }
        return null
      })

      //setUserPf(res.data)
    } catch (error) {
      console.log(error);
    } finally {
      //toast.dismiss(loading);
    }
  }, [profile_])

  const _farmer = (location) => {
    //1
    if (location === "/admin/report/farmerinfo") {
      return (
        <div className={`${styles.container}`}>
          <div className={`${styles.boxcol}`}>
            {/* <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check2"
                value={11}
                checked={switchContent.type === "11"}
                onClick={(e) => { onClickType(e.target.value); navigate("/admin/report/farmerinfo") }}
                readOnly />
              <label htmlFor="check2">รายวัน</label>
            </div> */}
            <div className={`${styles.boxrow}`}></div>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check3"
                value={12}
                checked={switchContent.type === "12"}
                onClick={(e) => { onClickType(e.target.value); navigate("/admin/report/farmerinfo") }}
                readOnly />
              <label htmlFor="check3">รายเดือน</label>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const _member = (location) => {
    //4
    if (location === "/admin/report/memberinfo") {
      return (
        <div className={`${styles.container}`}>
          <div className={`${styles.boxcol}`}>
            {/* <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check5"
                value={5}
                checked={switchContent.type === "5"}
                onClick={(e) => { onClickType(e.target.value); navigate("/admin/report/memberinfo") }}
                readOnly />
              <label htmlFor="check5">รายวัน</label>
            </div> */}
            <div className={`${styles.boxrow}`}></div>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check6"
                value={22}
                checked={switchContent.type === "22"}
                onClick={(e) => { onClickType(e.target.value); navigate("/admin/report/memberinfo") }}
                readOnly />
              <label htmlFor="check6">รายเดือน</label>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const _plantproductplantinfo = (location) => {
    if (location === "/admin/report/plan_product_plantinfo") {
      return (
        <div className={`${styles.container}`}>
          <div className={`${styles.boxcol}`}>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check31"
                value={31}
                checked={switchContent.type === "31"}
                onClick={(e) => { onClickType(e.target.value); navigate("/admin/report/plan_product_plantinfo") }}
                readOnly />
              <label htmlFor="check31">รายวัน</label>
            </div>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check32"
                value={32}
                checked={switchContent.type === "32"}
                onClick={(e) => { onClickType(e.target.value); navigate("/admin/report/plan_product_plantinfo") }}
                readOnly />
              <label htmlFor="check32">รายเดือน</label>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const _cashinfo = (location) => {
    if (location === "/admin/report/cashflowinfo") {
      return (
        <div className={`${styles.container}`}>
          <div className={`${styles.boxcol}`}>
            {/* <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check41"
                value={41}
                checked={switchContent.type === "41"}
                onClick={(e) => { onClickType(e.target.value); navigate("/admin/report/cashflowinfo") }}
                readOnly />
              <label htmlFor="check41">รายวัน</label>
            </div> */}
            <div className={`${styles.boxrow}`}></div>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check42"
                value={42}
                checked={switchContent.type === "42"}
                onClick={(e) => { onClickType(e.target.value); navigate("/admin/report/cashflowinfo") }}
                readOnly />
              <label htmlFor="check42">รายเดือน</label>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const _producerateinfo = (location) => {
    if (location === "/admin/report/produce_rateinfo") {
      return (
        <div className={`${styles.container}`}>
          <div className={`${styles.boxcol}`}>
            {/* <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check51"
                value={51}
                checked={switchContent.type === "51"}
                onClick={(e) => { onClickType(e.target.value); navigate("/admin/report/produce_rateinfo") }}
                readOnly />
              <label htmlFor="check51">รายวัน</label>
            </div> */}
            <div className={`${styles.boxrow}`}></div>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check52"
                value={52}
                checked={switchContent.type === "52"}
                onClick={(e) => { onClickType(e.target.value); navigate("/admin/report/produce_rateinfo") }}
                readOnly />
              <label htmlFor="check52">รายเดือน</label>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const _produceinfo = (location) => {
    if (location === "/admin/report/produce_planinfo") {
      return (
        <div className={`${styles.container}`}>
          <div className={`${styles.boxcol}`}>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check61"
                value={61}
                checked={switchContent.type === "61"}
                onClick={(e) => { onClickType(e.target.value); navigate("/admin/report/produce_planinfo") }}
                readOnly />
              <label htmlFor="check61">รายวัน</label>
            </div>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check62"
                value={62}
                checked={switchContent.type === "62"}
                onClick={(e) => { onClickType(e.target.value); navigate("/admin/report/produce_planinfo") }}
                readOnly />
              <label htmlFor="check62">รายเดือน</label>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const __farmer = (location) => {
    if (location === "/report/farmerinfo") {
      if (switchContent.type === '1') {
        onClickType("11")
      }
      return (
        <div className={`${styles.container}`}>
          <div className={`${styles.boxcol}`}>
            {/* <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check11"
                value={11}
                checked={switchContent.type === "11"}
                onClick={(e) => { onClickType(e.target.value); navigate("/report/farmerinfo") }}
                className={_theme?.class}
                readOnly />
              <label htmlFor="check11">รายวัน</label>
            </div> */}
            <div className={`${styles.boxrow}`}></div>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check12"
                value={12}
                checked={switchContent.type === "12"}
                onClick={(e) => { onClickType(e.target.value); navigate("/report/farmerinfo") }}
                className={_theme?.class}
                readOnly />
              <label htmlFor="check12">รายเดือน</label>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const __member = (location) => {
    //4
    if (location === "/report/memberinfo") {
      if (switchContent.type === '1') {
        onClickType("22")
      }
      return (
        <div className={`${styles.container}`}>
          <div className={`${styles.boxcol}`}>
            {/* <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check5"
                value={5}
                checked={switchContent.type === "5"}
                onClick={(e) => { onClickType(e.target.value); navigate("/report/memberinfo") }}
                readOnly />
              <label htmlFor="check5">รายวัน</label>
            </div> */}
            <div className={`${styles.boxrow}`}></div>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check22"
                value={22}
                checked={switchContent.type === "22"}
                onClick={(e) => { onClickType(e.target.value); navigate("/report/memberinfo") }}
                className={_theme?.class}
                readOnly />
              <label htmlFor="check22">รายเดือน</label>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const __plantproductplantinfo = (location) => {
    if (location === "/report/plan_product_plantinfo") {
      if (switchContent.type === '1') {
        onClickType("31")
      }
      return (
        <div className={`${styles.container}`}>
          <div className={`${styles.boxcol}`}>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check31"
                value={31}
                checked={switchContent.type === "31"}
                onClick={(e) => { onClickType(e.target.value); navigate("/report/plan_product_plantinfo") }}
                className={_theme?.class}
                readOnly />
              <label htmlFor="check31">รายวัน</label>
            </div>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check32"
                value={32}
                checked={switchContent.type === "32"}
                onClick={(e) => { onClickType(e.target.value); navigate("/report/plan_product_plantinfo") }}
                className={_theme?.class}
                readOnly />
              <label htmlFor="check32">รายเดือน</label>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const __cashinfo = (location) => {
    if (location === "/report/cashflowinfo") {
      if (switchContent.type === '1') {
        onClickType("41")
      }
      return (
        <div className={`${styles.container}`}>
          <div className={`${styles.boxcol}`}>
            {/* <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check41"
                value={41}
                checked={switchContent.type === "41"}
                onClick={(e) => { onClickType(e.target.value); navigate("/report/cashflowinfo") }}
                className={_theme?.class}
                readOnly />
              <label htmlFor="check41">รายวัน</label>
            </div> */}
            <div className={`${styles.boxrow}`}></div>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check42"
                value={42}
                checked={switchContent.type === "42"}
                onClick={(e) => { onClickType(e.target.value); navigate("/report/cashflowinfo") }}
                className={_theme?.class}
                readOnly />
              <label htmlFor="check42">รายเดือน</label>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const __producerateinfo = (location) => {
    if (location === "/report/produce_rateinfo") {
      if (switchContent.type === '1') {
        onClickType("51")
      }
      return (
        <div className={`${styles.container}`}>
          <div className={`${styles.boxcol}`}>
            {/* <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check51"
                value={51}
                checked={switchContent.type === "51"}
                onClick={(e) => { onClickType(e.target.value); navigate("/report/produce_rateinfo") }}
                className={_theme?.class}
                readOnly />
              <label htmlFor="check51">รายวัน</label>
            </div> */}
            <div className={`${styles.boxrow}`}></div>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check52"
                value={52}
                checked={switchContent.type === "52"}
                onClick={(e) => { onClickType(e.target.value); navigate("/report/produce_rateinfo") }}
                className={_theme?.class}
                readOnly />
              <label htmlFor="check52">รายเดือน</label>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const __produceinfo = (location) => {
    if (location === "/report/produce_planinfo") {
      if (switchContent.type === '1') {
        onClickType("61")
      }
      return (
        <div className={`${styles.container}`}>
          <div className={`${styles.boxcol}`}>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check61"
                value={61}
                className={_theme?.class}
                checked={switchContent.type === "61"}
                onClick={(e) => { onClickType(e.target.value); navigate("/report/produce_planinfo") }}
                readOnly />
              <label htmlFor="check61">รายวัน</label>
            </div>
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check62"
                value={62}
                checked={switchContent.type === "62"}
                onClick={(e) => { onClickType(e.target.value); navigate("/report/produce_planinfo") }}
                className={_theme?.class}
                readOnly />
              <label htmlFor="check62">รายเดือน</label>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const alettWarn = (e) => {

  }

  const alettWarn2 = (stateDisable) => {

    //const loading = toast.loading();

    if (stateDisable) {
      toast.error(Message.notify.access_in_error, { duration: 3000 })
    }
    //toast.dismiss(loading);
  }

  useEffect(() => {
    if (profile_.id !== null || profile_.id !== undefined) {
      setPermissionReport()
    }
  }, [profile_.id, setPermissionReport])

  useEffect(() => {
    if (_idMenu) {
      _idMenu.click()
    }
  }, [_idMenu])

  if (profile_.userGroupId === 5) {
    return (
      <div className={`${styles.container}`}>
        <div className={`${styles.boxcol}`}>
          <div className={`${styles.boxrow}`}>
            <input
              type="checkbox"
              name="type"
              id="check10"
              value={10}
              //checked={switchContent.type === "1"}
              checked={location === "/admin/report/farmerinfo"}
              //onClick={(e) => { onClickType(e.target.value); navigate("/admin/report/farmerinfo") }}
              onClick={(e) => { onClickType("12"); navigate("/admin/report/farmerinfo"); alettWarn(e) }}
              // onClick={(e) => { onClickType("11"); navigate("/admin/report/farmerinfo"); alettWarn(e) }}
              readOnly
              disabled={_userPf.rep1}
            />
            <label
              style={_userPf.rep1 === true ? { color: "#d9d9d9" } : null}
              htmlFor="check10">รายได้กลุ่มเกษตรกร</label>
          </div>
          {_userPf.rep1 === true ? null : _farmer(location)}
          <div className={`${styles.boxrow}`}>
            <input
              type="checkbox"
              name="type"
              id="check20"
              value={20}
              //checked={switchContent.type === "4"}
              checked={location === "/admin/report/memberinfo"}
              //onClick={(e) => { onClickType(e.target.value); navigate("/admin/report/memberinfo") }}
              onClick={(e) => { onClickType("22"); navigate("/admin/report/memberinfo"); alettWarn(e) }}
              readOnly
              disabled={_userPf.rep2}
            />
            <label
              style={_userPf.rep2 === true ? { color: "#d9d9d9" } : null}
              htmlFor="check20">จำนวนสมาชิก</label>
          </div>
          {_userPf.rep2 === true ? null : _member(location)}
          <div className={`${styles.boxrow}`}>
            <input
              type="checkbox"
              name="type"
              id="check30"
              value={30}
              //checked={switchContent.type === "7"}
              checked={location === "/admin/report/plan_product_plantinfo"}
              onClick={(e) => { onClickType("31"); navigate("/admin/report/plan_product_plantinfo"); alettWarn(e) }}
              readOnly
              disabled={_userPf.rep3}
            />
            <label
              style={_userPf.rep3 === true ? { color: "#d9d9d9" } : null}
              htmlFor="check30">แผนผลการผลิตเชื้อเพลิง</label>
          </div>
          {_userPf.rep3 === true ? null : _plantproductplantinfo(location)}
          <div className={`${styles.boxrow}`}>
            <input
              type="checkbox"
              name="type"
              id="check40"
              value={40}
              //checked={switchContent.type === "8"}
              checked={location === "/admin/report/cashflowinfo"}
              onClick={(e) => { onClickType("42"); navigate("/admin/report/cashflowinfo"); alettWarn(e) }}
              readOnly
              disabled={_userPf.rep4}
            />
            <label
              style={_userPf.rep4 === true ? { color: "#d9d9d9" } : null}
              htmlFor="check40">รายได้ - รายจ่ายโรงไฟฟ้า</label>
          </div>
          {_userPf.rep4 === true ? null : _cashinfo(location)}
          <div className={`${styles.boxrow}`}>
            <input
              type="checkbox"
              name="type"
              id="check50"
              value={50}
              //checked={switchContent.type === "9"}
              checked={location === "/admin/report/produce_rateinfo"}
              onClick={(e) => { onClickType("52"); navigate("/admin/report/produce_rateinfo"); alettWarn(e) }}
              readOnly
              disabled={_userPf.rep5}
            />
            <label
              style={_userPf.rep5 === true ? { color: "#d9d9d9" } : null}
              htmlFor="check50">ปริมาณการผลิตไฟฟ้า</label>
          </div>
          {_userPf.rep5 === true ? null : _producerateinfo(location)}
          <div className={`${styles.boxrow}`}>
            <input
              type="checkbox"
              name="type"
              id="check60"
              value={60}
              checked={location === "/admin/report/produce_planinfo"}
              //checked={switchContent.type === "10"}
              onClick={(e) => { onClickType("61"); navigate("/admin/report/produce_planinfo"); alettWarn(e) }}
              readOnly
              disabled={_userPf.rep6}
            />
            <label
              style={_userPf.rep6 === true ? { color: "#d9d9d9" } : null}
              htmlFor="check60">แผนผลการผลิตไฟฟ้า</label>
          </div>
          {_userPf.rep6 === true ? null : _produceinfo(location)}
          <div className={`${styles.boxrow}`}>
            <input
              type="checkbox"
              name="type"
              id="check70"
              value={70}
              checked={location === "/admin/report/resource-harvest-3"}
              //checked={switchContent.type === "10"}
              onClick={(e) => { navigate("/admin/report/resource-harvest-3"); alettWarn(e) }}
              readOnly
              disabled={_userPf.rep7}
            />
            <label
              style={_userPf.rep7 === true ? { color: "#d9d9d9" } : null}
              htmlFor="check70">วัตถุดิบที่คาดว่าจะเก็บเกี่ยว</label>
          </div>
          {/* {_userPf.rep7 === true ? null : _harvestrespurce(location)} */}
        </div>
      </div>

    );
  } else {
    switch (location) {
      case '/report':
        break;
      case '/report/farmerinfo':
        break;
      case '/report/memberinfo':
        break;
      case '/report/plan_product_plantinfo':
        break;
      case '/report/cashflowinfo':
        break;
      case '/report/produce_rateinfo':
        break;
      case '/report/produce_planinfo':
        break;
      case '/report/resource-harvest-3':
        break;
      default:
        navigate("/")
        break;
    }

    return (
      <div className={`${styles.container}`}>
        <div className={`${styles.boxcol}`}>
          <div className={`${styles.boxrow}`} onClick={() => { alettWarn2(_userPf.rep1) }}>
            <input
              type="checkbox"
              name="type"
              id="check10"
              value={10}
              //checked={switchContent.type === "1"}
              checked={location === "/report/farmerinfo"}
              //onClick={(e) => { onClickType(e.target.value); navigate("/report/farmerinfo") }}
              onClick={(e) => { onClickType("12"); navigate("/report/farmerinfo"); }}
              // onClick={(e) => { onClickType("11"); navigate("/report/farmerinfo"); }}
              className={_theme?.class}
              readOnly
              disabled={_userPf.rep1}
            />
            <label
              style={_userPf.rep1 === true ? { color: "#d9d9d9" } : null}
              htmlFor="check10">รายได้กลุ่มเกษตรกร</label>
          </div>
          {_userPf.rep1 === true ? null : __farmer(location)}
          <div className={`${styles.boxrow}`} onClick={() => { alettWarn2(_userPf.rep2) }}>
            <input
              type="checkbox"
              name="type"
              id="check20"
              value={20}
              //checked={switchContent.type === "4"}
              checked={location === "/report/memberinfo"}
              //onClick={(e) => { onClickType(e.target.value); navigate("/report/memberinfo") }}
              onClick={(e) => { onClickType("22"); navigate("/report/memberinfo"); }}
              className={_theme?.class}
              readOnly
              disabled={_userPf.rep2}
            />
            <label
              style={_userPf.rep2 === true ? { color: "#d9d9d9" } : null}
              htmlFor="check20">จำนวนสมาชิก</label>
          </div>
          {_userPf.rep2 === true ? null : __member(location)}
          <div className={`${styles.boxrow}`} onClick={() => { alettWarn2(_userPf.rep3) }}>
            <input
              type="checkbox"
              name="type"
              id="check30"
              value={30}
              //checked={switchContent.type === "7"}
              checked={location === "/report/plan_product_plantinfo"}
              onClick={(e) => { onClickType("31"); navigate("/report/plan_product_plantinfo"); }}
              className={_theme?.class}
              readOnly
              disabled={_userPf.rep3}
            />
            <label
              style={_userPf.rep3 === true ? { color: "#d9d9d9" } : null}
              htmlFor="check30">แผนผลการผลิตเชื้อเพลิง</label>
          </div>
          {_userPf.rep3 === true ? null : __plantproductplantinfo(location)}
          <div className={`${styles.boxrow}`} onClick={() => { alettWarn2(_userPf.rep4) }}>
            <input
              type="checkbox"
              name="type"
              id="check40"
              value={40}
              //checked={switchContent.type === "8"}
              checked={location === "/report/cashflowinfo"}
              onClick={(e) => { onClickType("42"); navigate("/report/cashflowinfo"); }}
              className={_theme?.class}
              readOnly
              disabled={_userPf.rep4}
            />
            <label
              style={_userPf.rep4 === true ? { color: "#d9d9d9" } : null}
              htmlFor="check40">รายได้ - รายจ่ายโรงไฟฟ้า</label>
          </div>
          {_userPf.rep4 === true ? null : __cashinfo(location)}
          <div className={`${styles.boxrow}`} onClick={() => { alettWarn2(_userPf.rep5) }}>
            <input
              type="checkbox"
              name="type"
              id="check50"
              value={50}
              //checked={switchContent.type === "9"}
              checked={location === "/report/produce_rateinfo"}
              onClick={(e) => { onClickType("52"); navigate("/report/produce_rateinfo"); }}
              className={_theme?.class}
              readOnly
              disabled={_userPf.rep5}
            />
            <label
              style={_userPf.rep5 === true ? { color: "#d9d9d9" } : null}
              htmlFor="check50">ปริมาณการผลิตไฟฟ้า</label>
          </div>
          {_userPf.rep5 === true ? null : __producerateinfo(location)}
          <div className={`${styles.boxrow}`} onClick={() => { alettWarn2(_userPf.rep6) }}>
            <input
              type="checkbox"
              name="type"
              id="check60"
              value={60}
              checked={location === "/report/produce_planinfo"}
              //checked={switchContent.type === "10"}
              onClick={(e) => { onClickType("61"); navigate("/report/produce_planinfo"); }}
              className={_theme?.class}
              readOnly
              disabled={_userPf.rep6}
            />
            <label
              style={_userPf.rep6 === true ? { color: "#d9d9d9" } : null}
              htmlFor="check60">แผนผลการผลิตไฟฟ้า</label>
          </div>
          {_userPf.rep6 === true ? null : __produceinfo(location)}

          {_userPf.rep6 === true ? null : _produceinfo(location)}

          {_userPf.rep7 === true ? null : (
            <div className={`${styles.boxrow}`}>
              <input
                type="checkbox"
                name="type"
                id="check70"
                value={70}
                checked={location === "/report/resource-harvest-3"}
                //checked={switchContent.type === "10"}
                onClick={(e) => { navigate("/report/resource-harvest-3"); alettWarn(e) }}
                readOnly
                disabled={_userPf.rep7}
              />
              <label
                style={_userPf.rep7 === true ? { color: "#d9d9d9" } : null}
                htmlFor="check70">วัตถุดิบที่คาดว่าจะเก็บเกี่ยว</label>
            </div>
          )}
        </div>
      </div>
    );
  }

};

export default MenuBar;
