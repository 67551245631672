import React, { useEffect, useCallback, useState, useMemo } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import WKT from "ol/format/WKT";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { Fill, Stroke, Style } from "ol/style";
import Header from "../../../layouts/header/Header";
import Navbar from "../../../layouts/navbar/Navbar";
import MenuBar from "./MenuBar";
import Footer from "../../../layouts/footer_2/Footer";
import FarmerDetails from "./farmer_group/FarmerDetails";
import PowerPlantsDetails from "./farmer_group/PowerPlantsDetails";
import MemberInfo from "./member_info/MemberInfo";
import RawMaterial from "./raw_material/RawMaterial";
import Income from "./income/Income";
import MapElement from "../_partials/MapElement";
import FactoryPopup from "../_partials/FactoryPopup";
import EnterprisePopup2 from "../_partials/EnterprisePopup2";
import OverlayPopup2 from "../_partials/OverlayPopup2";
import Legends from "../_partials/Legends";
import useSwitchPath from "../../../utils/hooks/useSwitchPath";
import { QueryGetAll, QueryGetByID } from "../../../helpers/api.helper";
import { defaultApi } from "../../../services/api";
import {
  setEnterpriseData,
  setEnterpriseType,
  setFactoryType,
} from "../../../services/enterprise.slice";
import { setFactoryData } from "../../../services/factory.slice";
import { getProfileData, setEditabled } from "../../../services/auth.slice";
import { convertDateThai } from "../../../utils/format/Date.format";
import { getLocalStorageData, setLocalStorageData } from "../../../helpers/local_storage.helper";
import styles from "./scss/FarmerManage.module.scss";

const FarmerManage = () => {
  const dispatchFn_ = useDispatch();
  const profileData = useSelector(getProfileData);
  const { layerId } = useParams();
  const [_location, navigate] = useSwitchPath();

  const [_map, setMap] = useState(null);
  const [factory, setFactory] = useState({});
  const [allEnterprise, setAllEnterprise] = useState([]);
  const [enterprise, setEnterprise] = useState({});
  const [allEntMemByEntId, setAllEntMemByEntId] = useState([]);
  const [allFacMemByFacId, setAllFacMemByFacId] = useState([]);
  const [_colors, setColors] = useState([]);
  const [allPlant, setAllPlant] = useState([]);
  const [newPlantArr, setNewPlantArr] = useState([]);
  const [coordFeature, setCoordFeature] = useState(null);
  const [cropDetail, setCropDetail] = useState(null);
  const [layerType, setLayerType] = useState("factory");
  const [cropStatus, setCropStatus] = useState([]);

  const changeThemeByRole = useMemo(() => {
    const role = profileData?.userGroupId;
    let theme;

    if (role === 2) {
      theme = {
        color: "#789b33",
        backgroundColor: "#789b33",
        class: "ent",
      };
    } else {
      theme = "";
    }

    return theme;
  }, [profileData]);

  const checkUserCreatedEnt = useMemo(() => {
    const _allEnterprise = getLocalStorageData("allEnterprise");
    const datas = _allEnterprise || allEnterprise;
    const check_ = datas?.find(
      (item) => item.createdBy === profileData.id && item.factoryId === +layerId
    );

    return check_?.createdBy;
  }, [profileData, layerId, allEnterprise]);
  const checkUserEntMember = useMemo(() => {
    const datas = getLocalStorageData("allEnterprise");
    const allEnterprise = datas;
    // สร้างเซตของ enterpriseId ที่ต้องตัดออก
    const entIdsToRemove = new Set(
      allEnterprise?.map((enterprise) => enterprise.id)
    );

    // กรองข้อมูลใน allEntMemByEntId โดยตรวจสอบว่า enterpriseId อยู่ในเซต enterpriseIdsToRemove หรือไม่
    const filteredUserData = allEntMemByEntId?.filter((user) =>
      entIdsToRemove?.has(user.enterpriseId)
    );

    // กรองข้อมูลใน filteredUserData โดยตรวจสอบว่า userId ตรงกับ profileData.id หรือไม่
    const findedUserData = filteredUserData?.find(
      (user) => profileData.id === user.userId
    );

    return findedUserData?.userId;
  }, [allEntMemByEntId, profileData]);
  const _statusName4 = useMemo(() => {
    const newObj = cropStatus?.reduce((acc, item) => {
      acc[item.id] = item.id;

      return acc;
    }, {});

    return newObj;
  }, [cropStatus]);

  const setStrokeColor = useCallback(
    (plant) => {
      let color;
      newPlantArr?.forEach((item) => {
        if (item.name === plant) {
          color = item.color;
        }
      });
      return color;
    },
    [newPlantArr]
  );
  const setFillColor = useCallback(
    (plant) => {
      let color;
      let alpha = 0.6;
      newPlantArr?.forEach((item) => {
        if (item.name === plant) {
          color = item.color;
        }
      });
      // Remove the '#' symbol if present
      color = color?.replace("#", "");

      // Extract the RGB components
      const red = parseInt(color?.substring(0, 2), 16);
      const green = parseInt(color?.substring(2, 4), 16);
      const blue = parseInt(color?.substring(4, 6), 16);

      // Convert alpha to a value between 0 and 1
      alpha = parseFloat(alpha);

      // Create and return the RGBA string
      const rgba = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
      return rgba;
    },
    [newPlantArr]
  );

  const _createLayerCrp = useCallback(
    (data) => {
      /* Clear crop layers : START */
      _map
        .getLayers()
        .getArray()
        .filter((layer) => layer.get("crpId"))
        .forEach((layer) => _map.removeLayer(layer));
      /* Clear crop layers : END */

      /* Create crop layers : START */
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        const wkt = `${element.geom}`;
        const format = new WKT();
        const feature = format.readFeature(wkt, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857",
        });
        const vector = new VectorLayer({
          source: new VectorSource({
            features: [feature],
            loader: () => {
              feature.setProperties({
                crpId: element.id,
                farmerId: element.parcel.farmerId,
                parcelId: element.parcel.parcelId,
                plantType: element.plantType.name,
                plantDate: convertDateThai(element.plantDate),
                harvestPlan: convertDateThai(element.harvestPlan),
                yieldTarget: element.yieldTarget,
                area: element.area,
                cropStatus: element.cropStatus,
                harvestDate: convertDateThai(element.harvestDate),
                yield: element.yield,
              });
            },
          }),
          style: (feature) => {
            const plantType = feature.getProperties().plantType;
            return [
              new Style({
                stroke: new Stroke({
                  color: setStrokeColor(plantType),
                  // lineDash: [4],
                  width: 2,
                }),
                fill: new Fill({
                  color: setFillColor(plantType),
                }),
              }),
            ];
          },
        });
        vector.setProperties({
          crpId: element.id,
        });
        _map?.addLayer(vector);
      }
      /* Create crop layers : END */
    },
    [_map, setStrokeColor, setFillColor]
  );

  const getFactory = useCallback(async () => {
    if (!layerId) return;

    const qryGetById = {
      $expand: "%24expand=factoryStatus%2CfactoryType%2CimageNavigation",
      $filter: "",
      $orderby: "",
    };
    const _query = QueryGetByID(qryGetById);
    try {
      const res = await defaultApi.getFactory(layerId, _query);
      setFactory(res.data);

      dispatchFn_(setFactoryData(res.data));
    } catch (error) {
      console.log(error);
    }
  }, [dispatchFn_, layerId]);
  const getMultipleApi = useCallback(async () => {
    const query = `%24filter=statusCategories%20eq%20%274%27`;

    try {
      const [factoryType, enterpriseType, allPlant, colors, gbStatus] =
        await Promise.all([
          defaultApi.getFactoryType(),
          defaultApi.getEnterpriseType(),
          defaultApi.getAllPlantType(),
          defaultApi.getColors(),
          defaultApi.getGbStatus(query),
        ]);

      dispatchFn_(setFactoryType(factoryType.data));
      dispatchFn_(setEnterpriseType(enterpriseType.data));
      setAllPlant(allPlant.data);
      setColors(colors.data.color);
      setCropStatus(gbStatus.data);
    } catch (error) {
      console.log(error);
    }
  }, [dispatchFn_]);
  const getAllEnterprise = useCallback(async () => {
    if (!layerId) return;

    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=factoryId%20eq%20${layerId}`,
      $orderby: "",
    };

    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllEnterprise(_queryAll);
      const datas = res.data.datas;
      const filterEnt = datas.find(
        (item) =>
          item.createdBy === profileData.id ||
          checkUserEntMember === profileData.id
      );

      setAllEnterprise(datas);
      setEnterprise(filterEnt);
      dispatchFn_(setEnterpriseData(filterEnt));
      setLocalStorageData("allEnterprise", datas);
    } catch (error) {
      console.log(error);
    }
  }, [layerId, profileData, checkUserEntMember, dispatchFn_]);

  const entId = useMemo(() => {
    const filterEnt = allEnterprise.find(
      (item) =>
        item.createdBy === profileData.id ||
        checkUserEntMember === profileData.id
    );

    return filterEnt?.id;
  }, [allEnterprise, profileData, checkUserEntMember]);
  const getAllCrop = useCallback(
    async (id) => {
      const qryGetAll = {
        $count: "%24count=true",
        $expand: "",
        $top: "",
        $skip: "",
        $filter: `&%24filter=enterpriseId%20eq%20${id}`,
        $orderby: "",
      };
      const _queryAll = QueryGetAll(qryGetAll);
      try {
        const res = await defaultApi.getAllCrop(_queryAll);
        const datas = res.data.datas;
        const filter = datas.filter(
          (item) =>
            item.geom !== null && item.cropStatus !== _statusName4?.harvested
        );

        _createLayerCrp(filter);
      } catch (error) {
        console.log(error);
      }
    },
    [_statusName4, _createLayerCrp]
  );
  const getEnterprise = useCallback(async () => {
    const qryGetById = {
      $expand: `%24expand=enterpriseType%2Cfactory%2CenterpriseImageNavigation
          %2CenterpriseDocNavigation`,
      $filter: "",
      $orderby: "",
    };
    const _queryId = QueryGetByID(qryGetById);
    try {
      const res = await defaultApi.getEnterprise(entId, _queryId);
      setEnterprise(res.data);
      dispatchFn_(setEnterpriseData(res.data));
      /* ถ้าไอดีผู้สร้างตรงกับไอดีผู้ใช้ถึงจะสามารถแก้ไขข้อมูลได้ */
      if (
        res.data.createdBy === profileData?.id ||
        checkUserEntMember === profileData?.id
      ) {
        dispatchFn_(setEditabled(true));
      } else {
        dispatchFn_(setEditabled(false));
      }
      // if (layerType !== "factory") {
      getAllCrop(res.data.id);
      // }
    } catch (error) {
      console.log(error);
    }
  }, [dispatchFn_, getAllCrop, profileData, entId, checkUserEntMember]);
  const getAllFactoryMember = useCallback(async () => {
    if (!layerId) return;

    const query = `%24filter=factoryId%20eq%20${layerId}%20
    &%24expand=user`;

    try {
      const res = await defaultApi.getAllFactoryMember(query);

      setAllFacMemByFacId(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [layerId]);
  const getAllEnterpriseMember = useCallback(async () => {
    let query;

    if (entId) {
      query = `%24filter=enterpriseId%20eq%20${entId}%20
      &%24expand=user`;
    } else {
      query = `%24expand=user`;
    }

    try {
      const res = await defaultApi.getAllEnterpriseMember(query);

      setAllEntMemByEntId(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [entId]);

  useEffect(() => {
    /* Format current data to new data obj : START */
    let arr = [];
    for (let i = 0; i < allPlant.length; i++) {
      const element = allPlant[i];
      const newObj = { name: element.name, color: _colors[i].colorCode };
      arr[i] = newObj;
    }
    setNewPlantArr(arr);
    /* Format current data to new data obj : END */
  }, [allPlant, _colors]);

  useEffect(() => {
    // Use functions
    getFactory();
    getAllEnterprise();
    getMultipleApi();
    getAllEnterpriseMember();
    getAllFactoryMember();
    return () => {
      dispatchFn_(setEnterpriseData({}));
      dispatchFn_(setEditabled(false));
    };
  }, [
    getFactory,
    getAllEnterprise,
    getMultipleApi,
    getAllEnterpriseMember,
    getAllFactoryMember,
    dispatchFn_,
  ]);

  return (
    <div className={`${styles.container} background_1`}>
      <Header />
      <section className={`section ${styles.section_} container-xx mx-auto`}>
        <Navbar />
        <article className={`article ${styles.article_} row mx-auto`}>
          <aside
            className={`${styles.menu_bar} col-3`}
            style={{
              display: `${
                profileData.id !== checkUserCreatedEnt
                  ? profileData.id !== checkUserEntMember
                    ? "none"
                    : ""
                  : ""
              }`,
            }}
          >
            <MenuBar
              _location={_location}
              _navigate={navigate}
              _layerId={layerId}
            />
          </aside>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <aside
                    className={`${styles.map_} ${
                      profileData.id !== checkUserCreatedEnt
                        ? profileData.id !== checkUserEntMember
                          ? "col-8"
                          : "col-5"
                        : "col-5"
                    }`}
                  >
                    <div className="map_element">
                      <MapElement
                        onInit={(map) => setMap(map)}
                        _coordFeature={coordFeature}
                        _setLayerType={setLayerType}
                        _setCoordFeature={setCoordFeature}
                      />
                      <FactoryPopup
                        _map={_map}
                        _factory={factory}
                        _setLayerType={setLayerType}
                        _setCoordFeature={setCoordFeature}
                      />
                      <EnterprisePopup2
                        _map={_map}
                        _enterprise={enterprise}
                        _setLayerType={setLayerType}
                        _setCoordFeature={setCoordFeature}
                        _fetchEntId={getEnterprise}
                      />
                      <OverlayPopup2
                        _map={_map}
                        _layerType={layerType}
                        _setCropDetail={setCropDetail}
                        _cropStatus={cropStatus}
                        _statusName4={_statusName4}
                      />
                      {layerType === "enterprise" && (
                        <Legends _newPlantArr={newPlantArr} />
                      )}
                    </div>
                  </aside>
                  <aside className={`${styles.content_details} col-4`}>
                    {layerType === "enterprise" ? (
                      <FarmerDetails
                        _cropDetail={cropDetail}
                        _getEnterprise={getEnterprise}
                        _fetchEntMember={getAllEnterpriseMember}
                        _allEntMemByEntId={allEntMemByEntId}
                        _cropStatus={cropStatus}
                        _statusName4={_statusName4}
                      />
                    ) : (
                      <PowerPlantsDetails
                        _allFacMemByFacId={allFacMemByFacId}
                      />
                    )}
                  </aside>
                </>
              }
            />
            <Route
              path="/members"
              element={
                <aside className={`${styles.member_info} col-9`}>
                  <MemberInfo />
                </aside>
              }
            />
            <Route
              path="/raw-material"
              element={
                <aside className={`${styles.raw_material} col-9`}>
                  <RawMaterial _themeColor={changeThemeByRole} />
                </aside>
              }
            />
            <Route
              path="/income"
              element={
                <aside className={`${styles.income} col-9`}>
                  <Income _themeColor={changeThemeByRole} />
                </aside>
              }
            />
          </Routes>
        </article>
      </section>
      <Footer _themeColor={changeThemeByRole} />
    </div>
  );
};

export default FarmerManage;
