export const clearObj = (obj) => {
  for (let propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === 0 ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
};
