import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-hot-toast";
import WKT from "ol/format/WKT";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { Fill, Stroke, Style } from "ol/style";
import MapElement2 from "../../../_partials/MapElement2";
import LayerDraw2 from "../../../_partials/LayerDraw2";
import LayerEdit2 from "../../../_partials/LayerEdit2";
import Legends from "../../../../_partials/Legends";
import EditCropForm from "./EditCropForm";
import icon from "../../../../../../assets/img/Icon";
import {
  convertDateEu,
  convertDateThai,
} from "../../../../../../utils/format/Date.format";
import {
  CheckNumberDot,
  CheckValidLength,
} from "../../../../../../utils/validation_input/Validation";
import { QueryGetAll } from "../../../../../../helpers/api.helper";
import { defaultApi } from "../../../../../../services/api";
import useSwitch from "../../../../../../utils/hooks/useSwitch";
import Message from "../../../../../../utils/message/Message";
import { convertWKT } from "../../../../../../helpers/map.helper";
import { getEnterpriseData } from "../../../../../../services/enterprise.slice";
import styles from "./scss/IncreaseCultivation.module.scss";

const initData = {
  parcelId: "",
  plantTypeId: "",
  plantDate: "",
  area: "",
  harvestPlan: "",
  yieldTarget: "",
  cropStatus: "",
  harvestDate: "",
  yield: "",
  farmerId: "",
  polygon: "",
  note: "",
};

const IncreaseCultivation = ({ farmerData, _onSwitch2 }) => {
  const enterpriseData = useSelector(getEnterpriseData);

  const [_switch, onSwitch, _switch2, onSwitch2] = useSwitch();

  const [_map, setMap] = useState(null);
  const [_data, setData] = useState(initData);
  const [allParcel, setAllParcel] = useState([]);
  const [geomParcel, setGeomParcel] = useState(null);
  const [allPlant, setAllPlant] = useState([]);
  const [parcelId, setParcelId] = useState(null);
  const [newPlantArr, setNewPlantArr] = useState([]);
  const [allCrop, setAllCrop] = useState([]);
  const [_colors, setColors] = useState([]);
  const [cropId, setCropId] = useState(null);
  const [cropData, setCropData] = useState({});
  const [cropDup, setCropDup] = useState({});
  const [_cropStatus, setCropStatus] = useState([]);
  const [_polygon, setPolygon] = useState(null);
  const [_featureEdit, setFeatureEdit] = useState(null);
  const [selectedDate, setSelectedDate] = useState({
    plantDate: new Date(),
    harvestDate: new Date(),
    harvestPlan: new Date(),
  });
  const [validErr, setValidErr] = useState(false);
  const [validErr2, setValidErr2] = useState(false);
  const [openPlantDate, setOpenPlantDate] = useState(false);
  const [openHarvestPlan, setOpenHarvestPlan] = useState(false);
  const [openHarvestDate, setOpenHarvestDate] = useState(false);

  // const _gbStatusOpt1 = useMemo(() => {
  //   const filterData = _cropStatus.filter(
  //     (item) => item.statusCategories === "1"
  //   );

  //   return filterData;
  // }, [_cropStatus]);
  const _gbStatusOpt4 = useMemo(() => {
    const filterData = _cropStatus.filter(
      (item) => item.statusCategories === "4"
    );

    return filterData;
  }, [_cropStatus]);
  const _statusName1 = useMemo(() => {
    const filterData = _cropStatus.filter(
      (item) => item.statusCategories === "1"
    );
    const newObj = filterData.reduce((acc, item) => {
      acc[item.id] = item.id;

      return acc;
    }, {});

    return newObj;
  }, [_cropStatus]);
  const _statusName4 = useMemo(() => {
    const filterData = _cropStatus.filter(
      (item) => item.statusCategories === "4"
    );
    const newObj = filterData.reduce((acc, item) => {
      acc[item.id] = item.id;

      return acc;
    }, {});

    return newObj;
  }, [_cropStatus]);

  /* DatePicker : START */
  const onChangePlantDate = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, plantDate: dateFormat });
    setSelectedDate({ ...selectedDate, plantDate: e });
    setOpenPlantDate(!openPlantDate);
  };
  const onChangeHarvestDate = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, harvestDate: dateFormat });
    setSelectedDate({ ...selectedDate, harvestDate: e });
    setOpenHarvestDate(!openHarvestDate);
  };
  const onChangeHarvestPlan = (e) => {
    const dateFormat = convertDateEu(e);
    setData({ ..._data, harvestPlan: dateFormat });
    setSelectedDate({ ...selectedDate, harvestPlan: e });
    setOpenHarvestPlan(!openHarvestPlan);
  };
  /* DatePicker : END */

  const setStrokeColor = useCallback(
    (plant) => {
      let color;
      newPlantArr?.forEach((item) => {
        if (item.name === plant) {
          color = item.color;
        }
      });
      return color;
    },
    [newPlantArr]
  );
  const setFillColor = useCallback(
    (plant) => {
      let color;
      let alpha = 0.6;
      newPlantArr?.forEach((item) => {
        if (item.name === plant) {
          color = item.color;
        }
      });
      // Remove the '#' symbol if present
      color = color?.replace("#", "");

      // Extract the RGB components
      const red = parseInt(color?.substring(0, 2), 16);
      const green = parseInt(color?.substring(2, 4), 16);
      const blue = parseInt(color?.substring(4, 6), 16);

      // Convert alpha to a value between 0 and 1
      alpha = parseFloat(alpha);

      // Create and return the RGBA string
      const rgba = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
      return rgba;
    },
    [newPlantArr]
  );

  const _createLayerParcel = useCallback(
    (data) => {
      /* Clear parcel layers : START */
      _map
        ?.getLayers()
        .getArray()
        .filter((layer) => layer.get("parcelId"))
        .forEach((layer) => _map.removeLayer(layer));
      /* Clear parcel layers : END */

      /* Create parcel layers : START */
      const wkt = `${data.geom}`;
      const format = new WKT();
      const feature = format.readFeature(wkt, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });
      const vector = new VectorLayer({
        source: new VectorSource({
          features: [feature],
          loader: () => {
            feature.setProperties({
              parcelId: data.id,
            });
          },
        }),
        style: () => {
          return [
            new Style({
              stroke: new Stroke({
                color: "orange",
                width: 3,
              }),
              fill: new Fill({
                color: "rgba(245, 157, 39, 0.15)",
              }),
            }),
          ];
        },
        zIndex: 0,
      });
      vector.setProperties({
        parcelId: data.id,
      });
      _map?.addLayer(vector);
      /* Create parcel layers : END */
    },
    [_map]
  );
  const _createLayerCrops = useCallback(
    (id) => {
      /* Clear crop layers : START */
      _map
        ?.getLayers()
        .getArray()
        .filter((layer) => layer.get("cropId"))
        .forEach((layer) => _map.removeLayer(layer));
      /* Clear crop layers : END */
      const datas = allCrop?.filter((item) => item.parcelId === +id);
      const filterCropStatus = datas?.filter(
        (item) =>
          item.cropStatus !== _statusName4?.harvested &&
          item.status !== _statusName1?.disable
      );

      /* Create crops layers : START */
      for (let i = 0; i < filterCropStatus?.length; i++) {
        const element = filterCropStatus[i];
        const wkt = `${element.geom}`;
        const format = new WKT();
        const feature = format.readFeature(wkt, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857",
        });
        const vector = new VectorLayer({
          source: new VectorSource({
            features: [feature],
            loader: () => {
              feature.setProperties({
                cropId: element.id,
                farmerId: element.parcel.farmerId,
                parcelIdTx: element.parcel.parcelId,
                plantType: element.plantType.name,
                plantDate: convertDateThai(element.plantDate),
                harvestPlan: convertDateThai(element.harvestPlan),
                yieldTarget: element.yieldTarget,
                area: element.area,
                cropStatus: element.cropStatus,
                harvestDate: convertDateThai(element.harvestDate),
                yield: element.yield,
              });
            },
          }),
          style: (feature) => {
            const plantType = feature.getProperties().plantType;
            return [
              new Style({
                stroke: new Stroke({
                  color: setStrokeColor(plantType),
                  // lineDash: [4],
                  width: 2,
                }),
                fill: new Fill({
                  color: setFillColor(plantType),
                }),
              }),
            ];
          },
          zIndex: 1,
        });
        vector.setProperties({
          cropId: element.id,
        });
        _map?.addLayer(vector);
      }
      /* Create crops layers : END */
    },
    [_map, allCrop, _statusName4, _statusName1, setStrokeColor, setFillColor]
  );
  const _createLayerCrop = useCallback(
    (data) => {
      /* Clear crop layers : START */
      _map
        ?.getLayers()
        .getArray()
        .filter((layer) => layer.get("cropId") === data.id)
        .forEach((layer) => _map.removeLayer(layer));
      /* Clear crop layers : END */

      /* Create parcel layers : START */
      const wkt = `${data.geom}`;
      const format = new WKT();
      const feature = format.readFeature(wkt, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });
      const vector = new VectorLayer({
        source: new VectorSource({
          features: [feature],
          loader: () => {
            feature.setProperties({
              cropId: data.id,
              cropIdD: data.id,
              farmerId: data.parcel.farmerId,
              parcelIdTx: data.parcel.parcelId,
              plantType: data.plantType.name,
              plantDate: convertDateThai(data.plantDate),
              harvestPlan: convertDateThai(data.harvestPlan),
              yieldTarget: data.yieldTarget,
              area: data.area,
              cropStatus: data.cropStatus,
              harvestDate: convertDateThai(data.harvestDate),
              yield: data.yield,
            });
          },
        }),
        style: (feature) => {
          const cropStatus = feature.getProperties().cropStatus;
          if (cropStatus === _statusName4?.planting) {
            return [
              new Style({
                stroke: new Stroke({
                  color: "blue",
                  // lineDash: [4],
                  width: 2,
                }),
                fill: new Fill({
                  color: "rgba(39, 245, 106, 1)",
                }),
              }),
            ];
          } else {
            return [
              new Style({
                stroke: new Stroke({
                  color: "rgba(0,0,0,0)",
                  // lineDash: [4],
                  width: 2,
                }),
                fill: new Fill({
                  color: "rgba(0,0,0,0)",
                }),
              }),
            ];
          }
        },
        zIndex: 1,
      });
      vector.setProperties({
        cropId: data.id,
        cropIdD: data.id,
      });
      _map?.addLayer(vector);
      /* Create parcel layers : END */
      console.log("Create crop layers finish");
    },
    [_map, _statusName4]
  );
  const _deleteLayerCrop = useCallback(
    (data) => {
      /* Clear crop layers : START */
      _map
        ?.getLayers()
        .getArray()
        .filter((layer) => layer.get("cropId") === data.id)
        .forEach((layer) => _map.removeLayer(layer));
      /* Clear crop layers : END */

      /* Create parcel layers : START */
      const wkt = `${data.geom}`;
      const format = new WKT();
      const feature = format.readFeature(wkt, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      });
      const vector = new VectorLayer({
        source: new VectorSource({
          features: [feature],
        }),
        style: () => {
          return [
            new Style({
              stroke: new Stroke({
                color: "rgba(0,0,0,0)",
                // lineDash: [4],
                width: 2,
              }),
              fill: new Fill({
                color: "rgba(0,0,0,0)",
              }),
            }),
          ];
        },
        zIndex: 1,
      });
      vector.setProperties({
        cropId: null,
        cropIdD: null,
      });
      _map?.addLayer(vector);
      /* Create parcel layers : END */
      console.log("Delete crop layers finish");
    },
    [_map]
  );
  const onClearLayer = () => {
    /* Clear parcel layers : START */
    _map
      ?.getLayers()
      .getArray()
      .filter((layer) => layer.get("parcelId"))
      .forEach((layer) => _map.removeLayer(layer));
    /* Clear parcel layers : END */

    /* Clear crop layers : START */
    _map
      ?.getLayers()
      .getArray()
      .filter((layer) => layer.get("cropId"))
      .forEach((layer) => _map.removeLayer(layer));
    /* Clear crop layers : END */

    /* Clear crop layers : START */
    _map
      ?.getLayers()
      .getArray()
      .filter((layer) => layer.get("cropIdD"))
      .forEach((layer) => _map.removeLayer(layer));
    /* Clear crop layers : END */
  };

  const onChangeParcel = async (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
    try {
      const res = await defaultApi.getParcel(+value);
      if (res.data.geom !== null) {
        _createLayerParcel(res.data);
        _createLayerCrops(res.data.id);
        setGeomParcel(res.data.geom);
        setParcelId(res.data.id);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ..._data, [name]: value });
  };

  const getMultipleApi = useCallback(async () => {
    const qryGetAll = {
      $count: "$count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&$filter=farmerId eq ${farmerData.id}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const [allParcel, allCrop] = await Promise.all([
        defaultApi.getAllParcel(_queryAll),
        defaultApi.getAllCrop(_queryAll),
      ]);

      setAllParcel(allParcel.data.datas);
      setAllCrop(allCrop.data.datas);
    } catch (error) {
      console.log(error);
    }
  }, [farmerData]);
  const getMultipleApiNonParam = async () => {
    const query = `$filter=statusCategories eq '1' or statusCategories eq '4'`;

    try {
      const [allPlant, colors, gbStatus] = await Promise.all([
        defaultApi.getAllPlantType(),
        defaultApi.getColors(),
        defaultApi.getGbStatus(query),
      ]);

      setAllPlant(allPlant.data);
      setColors(colors.data.color);
      setCropStatus(gbStatus.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getCrop = useCallback(
    async (id) => {
      const openModal = document.querySelector("#open_modal");

      if (!cropId) return;

      try {
        const res = await defaultApi.getCrop(id);
        console.log(res.statusText);
        setCropData(res.data);
        if (res.data.cropStatus !== _statusName4?.harvested) {
          _createLayerCrop(res.data);
        } else {
          const cropDup_ = {
            enterpriseId: enterpriseData.id,
            parcelId: res.data.parcelId,
            plantTypeId: res.data.plantTypeId,
            plantDate: "",
            area: res.data.area,
            harvestPlan: "",
            yieldTarget: "",
            cropStatus: _statusName4?.planting,
            harvestDate: "",
            yield: "",
            farmerId: res.data.farmerId,
            polygon: convertWKT(res.data.geom),
            note: res.data.note,
            status: _statusName1?.active,
          };

          _deleteLayerCrop(res.data);
          setCropDup(cropDup_);
          setTimeout(() => {
            openModal.click();
            setValidErr2(false);
          }, 1000);
          setCropId(null);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [
      cropId,
      enterpriseData,
      _statusName4,
      _statusName1,
      _createLayerCrop,
      _deleteLayerCrop,
    ]
  );

  const createCrop = async () => {
    if (CheckValidLength(_data.parcelId)) return setValidErr(true);
    if (CheckValidLength(_data.plantTypeId)) return setValidErr(true);
    if (CheckValidLength(_data.plantDate)) return setValidErr(true);
    if (CheckValidLength(_data.harvestPlan)) return setValidErr(true);
    if (CheckNumberDot(_data.yieldTarget)) return setValidErr(true);
    if (CheckNumberDot(_data.area)) return setValidErr(true);
    if (CheckValidLength(_data.cropStatus)) return setValidErr(true);
    if (CheckValidLength(_data.polygon)) {
      toast.error("กรุณาวาดขอบเขตแปลง", { duration: 3000 });
      return setValidErr(true);
    }
    if (_data.cropStatus === _statusName4?.harvested) {
      if (CheckValidLength(_data.harvestDate)) return setValidErr(true);
      if (CheckNumberDot(_data.yield)) return setValidErr(true);
    }

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(_data).forEach((key) => {
      if (key === "parcelId") {
        params[key] = +_data[key];
      } else if (key === "plantTypeId") {
        params[key] = +_data[key];
      } else if (key === "yieldTarget") {
        params[key] = +_data[key];
      } else if (key === "area") {
        params[key] = +_data[key];
      } else if (key === "yield") {
        params[key] = +_data[key];
      } else if (key === "farmerId") {
        params[key] = farmerData.id;
      } else if (key === "note") {
        params[key] = _data.note === "" ? "ไม่ระบุ" : _data[key];
      } else {
        params[key] = _data[key];
      }
    });
    Object.assign(params, {
      enterpriseId: enterpriseData.id,
      status: "active",
    });
    console.log("createCrop", params);
    try {
      const res = await defaultApi.createCrop(params);
      console.log(res.statusText);
      setData(initData);
      setValidErr(false);
      onSwitch(false);
      onClearLayer();
      // fectch
      getMultipleApi();
      toast.success(Message.notify.insert_success, { duration: 3000 });
    } catch (error) {
      toast.error(Message.notify.insert_error, { duration: 3000 });
      console.log(error);
    } finally {
      toast.dismiss(loading);
    }
  };
  const updateCrop = async () => {
    if (CheckNumberDot(cropData.yieldTarget)) return setValidErr(true);
    if (CheckNumberDot(cropData.area)) return setValidErr(true);
    if (cropData.cropStatus === _statusName4?.harvested) {
      if (cropData.harvestDate === null || cropData.harvestDate === "")
        return setValidErr(true);
      if (cropData.yield === 0 || cropData.yield === "")
        return setValidErr(true);
    }

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(cropData).forEach((key) => {
      if (key === "parcelId") {
        params[key] = +cropData[key];
      } else if (key === "plantTypeId") {
        params[key] = +cropData[key];
      } else if (key === "yieldTarget") {
        params[key] = +cropData[key];
      } else if (key === "area") {
        params[key] = +cropData[key];
      } else if (key === "yield") {
        params[key] = +cropData[key];
      } else if (key === "farmerId") {
        params[key] = farmerData.id;
      } else if (key === "geom") {
        params[key] = null;
      } else if (key === "polygon") {
        params[key] = _polygon !== null ? _polygon : convertWKT(cropData.geom);
      } else if (key === "parcel") {
        params[key] = null;
      } else if (key === "enterpriseId") {
        params[key] = enterpriseData.id;
      } else if (key === "note") {
        params[key] = cropData.note === "" ? "ไม่ระบุ" : cropData[key];
      } else {
        params[key] = cropData[key];
      }
    });
    try {
      const res = await defaultApi.updateCrop(cropId, params);
      console.log(res.statusText);

      /* Clear crop layers : START */
      _map
        .getLayers()
        .getArray()
        .filter((layer) => layer.get("coordEdit"))
        .forEach((layer) => _map.removeLayer(layer));
      console.log(
        _map
          ?.getLayers()
          .getArray()
          .filter(
            (layer) => layer.get("cropStatus") === _statusName4?.harvested
          )
      );
      /* Clear crop layers : END */

      // fetch data
      getMultipleApi();
      getCrop(cropId);

      // Reset State
      setValidErr(false);
      onSwitch2(false);
      toast.success(Message.notify.update_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };
  const duplicateCrop = async () => {
    const { plantDate, harvestPlan, yieldTarget } = cropDup;
    const closeModal = document.querySelector("#close_modal");

    if (
      CheckValidLength(plantDate) ||
      CheckValidLength(harvestPlan) ||
      CheckNumberDot(yieldTarget)
    )
      return setValidErr2(true);

    const loading = toast.loading(Message.notify.loading);
    const params = {};
    Object.keys(cropDup).forEach((key) => {
      if (key === "yieldTarget") {
        params[key] = +cropDup[key];
      } else {
        params[key] = cropDup[key];
      }
    });

    try {
      const res = await defaultApi.createCrop(params);
      console.log(res.statusText);

      // fectch
      getMultipleApi();
      // Reset State
      setCropDup({});
      setData(initData);
      onClearLayer();
      setValidErr2(false);
      closeModal.click();
      toast.success(Message.notify.insert_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.insert_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };
  const deleteCrop = async () => {
    if (!cropId) return;
    if (!window.confirm(Message.notify.delete_crop_confirm)) return;

    const loading = toast.loading(Message.notify.loading);
    try {
      const res = await defaultApi.deleteCrop(cropId);
      console.log(res.statusText);

      getMultipleApi();
      _deleteLayerCrop(cropData);
      setCropId(null);
      onSwitch(false);

      toast.success(Message.notify.delete_crop_success, { duration: 3000 });
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.delete_crop_error, { duration: 3000 });
    } finally {
      toast.dismiss(loading);
    }
  };

  useEffect(() => {
    /* Format current data to new data obj : START */
    let arr = [];
    for (let i = 0; i < allPlant.length; i++) {
      const element = allPlant[i];
      const newObj = { name: element.name, color: _colors[i]?.colorCode };
      arr[i] = newObj;
    }
    setNewPlantArr(arr);
    /* Format current data to new data obj : END */
  }, [allPlant, _colors]);

  useEffect(() => {
    // Use functions
    getMultipleApi();
    getMultipleApiNonParam();
  }, [getMultipleApi]);

  useEffect(() => {
    /* Function close dropdown 'PlantDate and HarvestPlan and HarvestDate' : START */
    const onCloseDropdownPlantDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_plant_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenPlantDate(false);
      }
    };
    const onCloseDropdownHarvestPlan = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_harvest_plan_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenHarvestPlan(false);
      }
    };
    const onCloseDropdownHarvestDate = (event) => {
      const element = event.target;
      const dropdownMenu = document.querySelector("#ent_harvest_date_d");
      const previousM = document.querySelector(
        ".react-datepicker__navigation--previous"
      );
      const nextM = document.querySelector(
        ".react-datepicker__navigation--next"
      );

      if (!dropdownMenu || !previousM || !nextM) {
        // ถ้าไม่มี dropdownMenu, previousM, nextM ให้ไม่ทำอะไร
        return;
      }

      // ตรวจสอบว่า event.target ไม่อยู่ใน dropdownMenu, previousM, nextM
      if (
        !dropdownMenu.contains(element) &&
        !previousM.contains(element) &&
        !nextM.contains(element)
      ) {
        setOpenHarvestDate(false);
      }
    };

    if (openPlantDate) {
      document.addEventListener("click", onCloseDropdownPlantDate);
    }
    if (openHarvestPlan) {
      document.addEventListener("click", onCloseDropdownHarvestPlan);
    }
    if (openHarvestDate) {
      document.addEventListener("click", onCloseDropdownHarvestDate);
    }
    /* Function close dropdown 'PlantDate and HarvestPlan and HarvestDate' : END */

    return () => {
      document.removeEventListener("click", onCloseDropdownPlantDate);
      document.removeEventListener("click", onCloseDropdownHarvestPlan);
      document.removeEventListener("click", onCloseDropdownHarvestDate);
    };
  }, [openPlantDate, openHarvestPlan, openHarvestDate]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.left_}`}>
        <div className={`${styles.map_element}`}>
          <MapElement2
            _onInit={(map) => setMap(map)}
            _switch={_switch}
            _setCropData={setCropData}
            _setCropId={setCropId}
            _geom={geomParcel}
          />
          <LayerDraw2 _map={_map} _switch={_switch} _setData={setData} />
          <LayerEdit2
            _map={_map}
            _switch2={_switch2}
            _onSwitch2={onSwitch2}
            _setPolygon={setPolygon}
            _setCropData={setCropData}
            _createLayerCrops={_createLayerCrops}
            _parcelId={_data?.parcelId}
            _feature={_featureEdit}
            _setFeature={setFeatureEdit}
          />
          <Legends _newPlantArr={newPlantArr} />
          <div className={`${styles.button_top_right}`}>
            {!_switch && cropId !== null && (
              <button
                type="button"
                className={`${styles.btn_}`}
                onClick={deleteCrop}
              >
                ลบแปลงเพาะปลูก
              </button>
            )}
          </div>
          <div className={`${styles.button_bottom_center}`}>
            {!_switch && cropId === null && (
              <>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={() => {
                    if (_data.parcelId !== "") onSwitch(true);
                    else toast.error("กรุณาเลือกรหัสแปลง", { duration: 3000 });
                  }}
                >
                  วาดขอบเขต
                </button>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={() => _onSwitch2(false)}
                >
                  กลับ
                </button>
              </>
            )}
            {_switch && cropId === null && (
              <>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={createCrop}
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={() => {
                    onSwitch(false);
                    setValidErr(false);
                    onClearLayer();
                  }}
                >
                  ยกเลิก
                </button>
              </>
            )}
            {!_switch2 && cropId !== null && (
              <>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={() => {
                    if (!_featureEdit)
                      return toast.error(Message.notify.warnning_edit_crop, {
                        duration: 5000,
                      });
                    onSwitch2(true);
                  }}
                >
                  แก้ไข
                </button>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={() => {
                    setData(initData);
                    setCropId(null);
                    setParcelId(null);
                    setValidErr(false);
                    onClearLayer();
                    setSelectedDate({
                      plantDate: new Date(),
                      harvestDate: new Date(),
                      harvestPlan: new Date(),
                    });
                  }}
                >
                  ยกเลิก
                </button>
              </>
            )}
            {_switch2 && cropId !== null && (
              <>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={updateCrop}
                >
                  บันทึก
                </button>
                <button
                  type="button"
                  className={`${styles.btn_}`}
                  onClick={() => {
                    onSwitch2(false);
                    setValidErr(false);
                    _createLayerCrops(_data?.parcelId);
                    setFeatureEdit(null);
                  }}
                >
                  ยกเลิก
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {cropId === null && (
        <div className={`${styles.right_}`}>
          <h4 className={`${styles.title_text}`}>
            {farmerData?.firstname} {farmerData?.lastname}
          </h4>
          <div className={`${styles.input_form}`}>
            <div className={`${styles.input_}`}>
              <label>รหัสแปลง</label>
              <select
                name="parcelId"
                className={`${
                  validErr && _data.parcelId === "" ? "error" : ""
                }`}
                onChange={onChangeParcel}
              >
                <option
                  selected={_data.parcelId !== "" ? false : true}
                  style={{ display: "none" }}
                >
                  เลือกรหัสแปลง
                </option>
                {allParcel.map((item) => {
                  return (
                    <option
                      selected={parcelId === item.id ? true : false}
                      key={item.id}
                      value={item.id}
                    >
                      {item.parcelId}
                    </option>
                  );
                })}
              </select>
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.parcelId)}
              </p>
            )}
            <div className={`${styles.input_}`}>
              <label>
                ชนิดเชื้อเพลิง / วัตถุดิบ{" "}
                <sup className={`${styles.asterisk}`}>*</sup>
              </label>
              <select
                name="plantTypeId"
                className={`${
                  validErr && _data.plantTypeId === "" ? "error" : ""
                }`}
                onChange={onChange}
                disabled={!_switch}
              >
                <option
                  selected={_data.plantTypeId !== "" ? false : true}
                  style={{ display: "none" }}
                >
                  เลือกชนิดเชื้อเพลิง / วัตถุดิบ
                </option>
                {allPlant.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.plantTypeId)}
              </p>
            )}
            <div className={`${styles.input_}`}>
              <label>
                พื้นที่ของเชื้อเพลิง{" "}
                <sup className={`${styles.asterisk}`}>*</sup>
              </label>
              <div
                className={`${styles.input_unit} ${
                  validErr && _data.area === "" ? "error" : ""
                }`}
                style={{
                  backgroundColor: _switch
                    ? "transparent"
                    : "rgba(239,239,239,0.8)",
                }}
              >
                <input
                  type="text"
                  name="area"
                  value={_data.area}
                  onChange={onChange}
                  disabled={!_switch}
                />
                <div className={`${styles.unit}`}>
                  <span>ไร่</span>
                </div>
              </div>
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckNumberDot(_data.area)}
              </p>
            )}
            <div className={`${styles.input_}`}>
              <label>
                วันที่เริ่มเพาะปลูก{" "}
                <sup className={`${styles.asterisk}`}>*</sup>
              </label>
              <div
                className={`${styles.input_unit} ${
                  validErr && _data.plantDate === "" ? "error" : ""
                }`}
              >
                <div className={`${styles.info_box_}`}>
                  <p>
                    {_data.plantDate !== ""
                      ? convertDateThai(_data.plantDate)
                      : ""}
                  </p>
                </div>
                <div
                  className={`${styles.unit}`}
                  onClick={() => {
                    if (!_switch) return;
                    setOpenPlantDate(!openPlantDate);
                  }}
                >
                  <img src={icon.calendar} alt="" id="ent_plant_date_d" />
                </div>
              </div>
              {openPlantDate && (
                <DatePicker
                  selected={selectedDate.plantDate}
                  onChange={onChangePlantDate}
                  inline
                />
              )}
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.plantDate)}
              </p>
            )}
            <div className={`${styles.input_}`}>
              <label>
                วันที่คาดว่าจะเก็บเกี่ยว{" "}
                <sup className={`${styles.asterisk}`}>*</sup>
              </label>
              <div
                className={`${styles.input_unit} ${
                  validErr && _data.harvestPlan === "" ? "error" : ""
                }`}
              >
                <div className={`${styles.info_box_}`}>
                  <p>
                    {_data.harvestPlan !== ""
                      ? convertDateThai(_data.harvestPlan)
                      : ""}
                  </p>
                </div>
                <div
                  className={`${styles.unit}`}
                  onClick={() => {
                    if (!_switch) return;
                    setOpenHarvestPlan(!openHarvestPlan);
                  }}
                >
                  <img src={icon.calendar} alt="" id="ent_harvest_plan_d" />
                </div>
              </div>
              {openHarvestPlan && (
                <DatePicker
                  selected={selectedDate.harvestPlan}
                  onChange={onChangeHarvestPlan}
                  inline
                />
              )}
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.harvestPlan)}
              </p>
            )}
            <div className={`${styles.input_}`}>
              <label>
                ผลผลิตที่คาดว่าจะได้รับ(ตัน){" "}
                <sup className={`${styles.asterisk}`}>*</sup>
              </label>
              <div
                className={`${styles.input_unit} ${
                  validErr && _data.yieldTarget === "" ? "error" : ""
                }`}
                style={{
                  backgroundColor: _switch
                    ? "transparent"
                    : "rgba(239,239,239,0.8)",
                }}
              >
                <input
                  type="text"
                  name="yieldTarget"
                  value={_data.yieldTarget}
                  onChange={onChange}
                  disabled={!_switch}
                />
                <div className={`${styles.unit}`}>
                  <span>ตัน</span>
                </div>
              </div>
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckNumberDot(_data.yieldTarget)}
              </p>
            )}
            <div className={`${styles.input_}`}>
              <label>
                สถานะการเก็บเกี่ยว <sup className={`${styles.asterisk}`}>*</sup>
              </label>
              <select
                name="cropStatus"
                className={`${
                  validErr && _data.cropStatus === "" ? "error" : ""
                }`}
                onChange={(e) => {
                  const { value } = e.target;
                  if (value === _statusName4?.planting) {
                    setData({
                      ..._data,
                      cropStatus: value,
                      harvestDate: "",
                      yield: "",
                    });
                  } else {
                    setData({ ..._data, cropStatus: value });
                  }
                }}
                disabled={!_switch}
              >
                <option
                  selected={_data.cropStatus !== "" ? false : true}
                  style={{ display: "none" }}
                >
                  เลือกสถานะการเก็บเกี่ยว
                </option>
                {_gbStatusOpt4?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.statusName}
                    </option>
                  );
                })}
              </select>
            </div>
            {validErr && (
              <p className={`${styles.err_message}`}>
                {CheckValidLength(_data.cropStatus)}
              </p>
            )}
            {_data.cropStatus === _statusName4?.harvested && (
              <>
                <div className={`${styles.input_}`}>
                  <label>วันที่เก็บเกี่ยว</label>
                  <div
                    className={`${styles.input_unit} ${
                      validErr && _data.harvestDate === "" ? "error" : ""
                    }`}
                  >
                    <div className={`${styles.info_box_}`}>
                      <p>
                        {_data.harvestDate !== ""
                          ? convertDateThai(_data.harvestDate)
                          : ""}
                      </p>
                    </div>
                    <div
                      className={`${styles.unit}`}
                      onClick={() => {
                        setOpenHarvestDate(!openHarvestDate);
                      }}
                    >
                      <img src={icon.calendar} alt="" id="ent_harvest_date_d" />
                    </div>
                  </div>
                  {openHarvestDate && (
                    <DatePicker
                      selected={selectedDate.harvestDate}
                      onChange={onChangeHarvestDate}
                      inline
                    />
                  )}
                </div>
                {validErr && (
                  <p className={`${styles.err_message}`}>
                    {CheckValidLength(_data.harvestDate)}
                  </p>
                )}
                <div className={`${styles.input_}`}>
                  <label>ผลผลิตที่ได้รับ</label>
                  <div
                    className={`${styles.input_unit} ${
                      validErr && _data.yield === "" ? "error" : ""
                    }`}
                  >
                    <input
                      type="text"
                      name="yield"
                      value={_data.yield}
                      onChange={onChange}
                    />
                    <div className={`${styles.unit}`}>
                      <span>ตัน</span>
                    </div>
                  </div>
                </div>
                {validErr && (
                  <p className={`${styles.err_message}`}>
                    {CheckNumberDot(_data.yield)}
                  </p>
                )}
              </>
            )}
            <div className={`${styles.input_}`}>
              <label>บันทึกเพิ่มเติม</label>
              <textarea
                name="note"
                cols="30"
                rows="3"
                value={_data.note || ""}
                onChange={onChange}
                disabled={!_switch}
              ></textarea>
            </div>
          </div>
        </div>
      )}
      {cropId !== null && (
        <EditCropForm
          _cropData={cropData}
          _setCropData={setCropData}
          _allPlant={allPlant}
          _switch2={_switch2}
          validErr={validErr}
          _farmerData={farmerData}
          _gbStatusOpt4={_gbStatusOpt4}
          _statusName4={_statusName4}
        />
      )}

      <ModalDuplicateCrop
        _cropDup={cropDup}
        _setCropDup={setCropDup}
        _validErr2={validErr2}
        _duplicateCrop={duplicateCrop}
      />
    </div>
  );
};

const ModalDuplicateCrop = ({
  _cropDup,
  _setCropDup,
  _validErr2,
  _duplicateCrop,
}) => {
  const [selectedDate, setSelectedDate] = useState({
    plantDate: new Date(),
    harvestPlan: new Date(),
  });
  const [openPlantDate, setOpenPlantDate] = useState(false);
  const [openHarvestPlan, setOpenHarvestPlan] = useState(false);

  /* DatePicker : START */
  const onChangePlantDate = (e) => {
    const dateFormat = convertDateEu(e);

    _setCropDup((prev) => ({ ...prev, plantDate: dateFormat }));
    setSelectedDate({ ...selectedDate, plantDate: e });
    setOpenPlantDate(!openPlantDate);
  };
  const onChangeHarvestPlan = (e) => {
    const dateFormat = convertDateEu(e);

    _setCropDup((prev) => ({ ...prev, harvestPlan: dateFormat }));
    setSelectedDate({ ...selectedDate, harvestPlan: e });
    setOpenHarvestPlan(!openHarvestPlan);
  };
  /* DatePicker : END */

  const onChange = (e) => {
    const { name, value } = e.target;

    _setCropDup((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <div
        className={`modal fade ${styles.modal_dup_crop}`}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                ต้องการที่จะสร้างแปลงเพาะปลูกซ้ำหรือไม่ ?
              </h5>
            </div>
            <div className="modal-body">
              <div className="input_form">
                <div className="input_">
                  <h5>วันที่เริ่มเพาะปลูก</h5>
                  <div
                    className={`input_unit ${
                      _validErr2 && _cropDup?.plantDate === "" ? "error" : ""
                    }`}
                  >
                    <div className="info_box">
                      <p>
                        {_cropDup?.plantDate !== ""
                          ? convertDateThai(_cropDup?.plantDate)
                          : ""}
                      </p>
                    </div>
                    <div
                      className="unit"
                      onClick={() => {
                        setOpenPlantDate(!openPlantDate);
                        setOpenHarvestPlan(false);
                      }}
                    >
                      <img src={icon.calendar} alt="" />
                    </div>
                  </div>
                  {openPlantDate && (
                    <DatePicker
                      selected={selectedDate.plantDate}
                      onChange={onChangePlantDate}
                      inline
                    />
                  )}
                  {_validErr2 && (
                    <p className="err_message">
                      {CheckValidLength(_cropDup?.plantDate)}
                    </p>
                  )}
                </div>
                <div className="input_">
                  <h5>วันที่คาดว่าจะเก็บเกี่ยว</h5>
                  <div
                    className={`input_unit ${
                      _validErr2 && _cropDup?.harvestPlan === "" ? "error" : ""
                    }`}
                  >
                    <div className="info_box">
                      <p>
                        {_cropDup?.harvestPlan !== ""
                          ? convertDateThai(_cropDup?.harvestPlan)
                          : ""}
                      </p>
                    </div>
                    <div
                      className="unit"
                      onClick={() => {
                        setOpenHarvestPlan(!openHarvestPlan);
                        setOpenPlantDate(false);
                      }}
                    >
                      <img src={icon.calendar} alt="" />
                    </div>
                  </div>
                  {openHarvestPlan && (
                    <DatePicker
                      selected={selectedDate.harvestPlan}
                      onChange={onChangeHarvestPlan}
                      inline
                    />
                  )}
                  {_validErr2 && (
                    <p className="err_message">
                      {CheckValidLength(_cropDup?.harvestPlan)}
                    </p>
                  )}
                </div>
                <div className="input_">
                  <h5>ผลผลิตที่คาดว่าจะได้รับ</h5>
                  <div
                    className={`input_unit ${
                      _validErr2 && _cropDup?.yieldTarget === "" ? "error" : ""
                    }`}
                  >
                    <div className="info_box">
                      <input
                        type="text"
                        name="yieldTarget"
                        value={_cropDup?.yieldTarget || ""}
                        onChange={onChange}
                      />
                    </div>
                    <div className="unit">
                      <span>ตัน</span>
                    </div>
                  </div>
                  {_validErr2 && (
                    <p className="err_message">
                      {CheckNumberDot(_cropDup?.yieldTarget)}
                    </p>
                  )}
                </div>
                <div className="input_">
                  <h5>บันทึกเพิ่มเติม</h5>
                  <textarea
                    name="note"
                    cols="30"
                    rows="3"
                    value={_cropDup?.note || ""}
                    onChange={onChange}
                  ></textarea>
                </div>

                <div className="button_bottom_center">
                  <button
                    type="button"
                    className="btn_ ok"
                    onClick={_duplicateCrop}
                  >
                    ตกลง
                  </button>
                  <button
                    type="button"
                    id="close_modal"
                    className="btn_ cancel"
                    data-bs-dismiss="modal"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p
        id="open_modal"
        data-bs-toggle="modal"
        data-bs-target="#staticBackdrop"
        hidden
      ></p>
    </>
  );
};

export default IncreaseCultivation;
