import React, { useState, useEffect, useCallback, useRef } from "react";
import { Overlay } from "ol";
import icon from "../../../assets/img/Icon";
import { defaultApi } from "../../../services/api";
import { QueryGetAll } from "../../../helpers/api.helper";
import { convertCoordinates } from "../../../helpers/map.helper";
import styles from "./EnterprisePopup2.module.scss";

const EnterprisePopup2 = ({
  _map,
  _enterprise,
  _setLayerType,
  _setCoordFeature,
  _fetchEntId,
}) => {
  const container = useRef();

  const [allFarmer, setAllFarmer] = useState([]);

  const entIcon = (id) => {
    let image;
    if (1 === id) {
      image = icon.index_2;
    } else if (2 === id) {
      image = icon.index_3;
    } else if (3 === id) {
      image = icon.group10;
    } else if (4 === id) {
      image = icon.group11;
    }
    return image;
  };

  const onPopupClick = () => {
    // Set detail type
    _setLayerType("enterprise");
    // Set coordi เอาไปทำ map center ตรง feature ที่คลิก
    _setCoordFeature([_enterprise.lng, _enterprise.lat]);
    // Get ent data by id
    _fetchEntId(_enterprise.id);
  };

  const getAllFarmer = useCallback(async () => {
    if (!_enterprise?.id) return;

    const gryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=enterpriseId%20eq%20${_enterprise.id}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(gryGetAll);
    try {
      const res = await defaultApi.getAllFarmer(_queryAll);
      setAllFarmer(res.data.datas);
    } catch (error) {
      console.log(error);
    }
  }, [_enterprise]);

  useEffect(() => {
    /* Create overlay layer : START */
    let entData = [_enterprise];
    for (let i = 0; i < entData.length; i++) {
      const overlay = new Overlay({
        element: container.current,
        position: convertCoordinates(entData[i]?.lng, entData[i]?.lat),
        stopEvent: false,
      });
      _map?.addOverlay(overlay);
    }
    /* Create overlay layer : END */
  }, [_map, _enterprise]);

  useEffect(() => {
    // Use functions
    getAllFarmer();
  }, [getAllFarmer]);

  return (
    <div
      id={`popup2_ent`}
      className={`ol_popup ${styles.ol_popup}`}
      ref={container}
      onClick={onPopupClick}
    >
      <div
        id="ent_popup_content"
        className={`popup_content ${styles.popup_content}`}
      >
        <div className={`${styles.img_icon}`}>
          <img src={entIcon(_enterprise?.enterpriseTypeId)} alt="" />
        </div>
        <div className={`${styles.text_content}`}>
          <div>
            <p id="ent_text_name">{_enterprise?.name}</p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            {/* allFarmer.length */}
            <p id="ent_text_details">{Number(allFarmer.length).toLocaleString()}</p>
            <span>คน</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterprisePopup2;
