import React, { useState, useEffect, useCallback } from "react";
import EditForm from "./EditForm";
import icon from "../../../../../../../assets/img/Icon";
import useSwitch from "../../../../../../../utils/hooks/useSwitch";
import { defaultApi } from "../../../../../../../services/api";
import { convertDateThai } from "../../../../../../../utils/format/Date.format";
import { QueryGetByID } from "../../../../../../../helpers/api.helper";
import { downloadFilePdf } from "../../../../../../../helpers/func.helper";
import styles from "./EditContractPForm.module.scss";

const EditContractP_Form = ({
  _switchID,
  _onSwitch,
  _contractStatus,
  _getAllContractPpa,
}) => {
  const [_switch, onSwitch] = useSwitch();
  const [contractPpa, setContractPpa] = useState([]);

  const getContractPpa = useCallback(async () => {
    const qryGetById = {
      $expand: "&%24expand=factory%2CppaDocNavigation",
      $filter: "",
      $orderby: "",
    };
    const _query = QueryGetByID(qryGetById);
    try {
      const res = await defaultApi.getContractPpa(_switchID, _query);
      setContractPpa(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [_switchID]);

  useEffect(() => {
    getContractPpa();
  }, [getContractPpa]);

  return (
    <div className={`${styles.container}`}>
      {_switch ? (
        <EditForm
          _onSwitch={onSwitch}
          _contractPpa={contractPpa}
          _fetchData={() => {
            getContractPpa();
            _getAllContractPpa();
          }}
          _contractStatus={_contractStatus}
        />
      ) : (
        <div className={`${styles.input_form}`}>
          <div className={`${styles.input_flex}`}>
            <div className={`${styles.input_1}`}>
              <h5>สัญญาเลขที่</h5>
              <div
                className={`${styles.info_box}`}
                style={{ background: "#f5f5f5" }}
              >
                <p>{contractPpa?.no}</p>
              </div>
            </div>
            <div className={`${styles.input_2}`}>
              <h5>สถานะสัญญา</h5>
              <div className={`${styles.info_box}`}>
                <p>
                  {
                    _contractStatus?.find(
                      (item) => item.id === contractPpa?.status
                    )?.statusName
                  }
                </p>
              </div>
            </div>
          </div>
          <div className={`${styles.input_flex}`}>
            <div className={`${styles.input_1}`}>
              <h5>วันเริ่มต้นสัญญา PPA</h5>
              <div className={`${styles.info_box}`}>
                <p>{convertDateThai(contractPpa?.signDate)}</p>
              </div>
            </div>
            <div className={`${styles.input_2}`}>
              <h5>วันสิ้นสุดสัญญา PPA</h5>
              <div className={`${styles.info_box}`}>
                <p>{convertDateThai(contractPpa?.endDate)}</p>
              </div>
            </div>
          </div>
          <div className={`${styles.input_flex}`}>
            <div className={`${styles.input_1}`}>
              <h5>การไฟฟ้าคู่สัญญา</h5>
              <div className={`${styles.info_con}`}>
                <div className={`${styles.info_box}`}>
                  <p>{contractPpa?.buyer}</p>
                </div>
                <span>และ</span>
              </div>
            </div>
            <div className={`${styles.input_2}`}>
              <h5>ผู้ผลิตไฟฟ้าคู่สัญญา</h5>
              <div className={`${styles.info_box}`}>
                <p>{contractPpa?.factory?.name}</p>
              </div>
            </div>
          </div>
          <div className={`${styles.input_flex}`}>
            <div className={`${styles.input_1}`}>
              <h5>ระยะเวลาสัญญา</h5>
              <div className={`${styles.input_unit}`}>
                <div className={`${styles.info_box}`}>
                  <p>{contractPpa?.period}</p>
                </div>
                <div className={`${styles.unit}`}>
                  <span style={{ display: "block" }}>ปี</span>
                </div>
              </div>
            </div>
            <div className={`${styles.input_2}`}>
              <h5>ปริมาณไฟฟ้าเสนอขาย</h5>
              <div className={`${styles.input_unit}`}>
                <div className={`${styles.info_box}`}>
                  <p>{contractPpa?.amount}</p>
                </div>
                <div className={`${styles.unit}`}>
                  <span>MW</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.input_flex}`}>
            <div className={`${styles.input_1}`}>
              <div className={`${styles.input_file}`}>
                <h5>สัญญาฉบับจริง</h5>
                <div className={`${styles.input_unit}`}>
                  <div className={`${styles.info_box}`}>
                    <p>{contractPpa?.ppaDocNavigation?.fileName}</p>
                  </div>
                  <div className={`${styles.unit}`}>
                    <img
                      src={icon.document}
                      alt=""
                      title="ดาวน์โหลดไฟล์"
                      onClick={() =>
                        downloadFilePdf(
                          contractPpa?.ppaDoc,
                          contractPpa?.ppaDocNavigation
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`${styles.input_2}`}>
              <h5>SCOD</h5>
              <div className={`${styles.info_con}`}>
                <div className={`${styles.info_box}`}>
                  <p>{convertDateThai(contractPpa?.scod)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.input_flex}`}>
            <div className={`${styles.input_1}`}>
              <h5>COD</h5>
              <div className={`${styles.info_con}`}>
                <div className={`${styles.info_box}`}>
                  <p>{convertDateThai(contractPpa?.cod)}</p>
                </div>
              </div>
            </div>
            <div className={`${styles.input_2}`}></div>
          </div>
        </div>
      )}

      {!_switch && (
        <div className={`${styles.button_right}`}>
          <button
            type="button"
            className={`${styles.btn_}`}
            onClick={() => _onSwitch(null)}
          >
            กลับ
          </button>
          <button
            type="button"
            className={`${styles.btn_}`}
            onClick={() => onSwitch(true)}
          >
            แก้ไขสัญญา
          </button>
        </div>
      )}
    </div>
  );
};

export default EditContractP_Form;
