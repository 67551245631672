import React, { useState, useMemo, useEffect } from "react";
import Pagination from "../../../../../components/pagination/Pagination";
import { convertDateThai } from "../../../../../utils/format/Date.format";
import {
  exportCsvFile,
  exportXlsxFile,
} from "../../../../../helpers/func.helper";
import ExportPopup from "../../../../../layouts/exportpopup/ExportPopup";
import { defaultApi } from "../../../../../services/api";
import styles from "./scss/Production.module.scss";

const pageSize = 10;

const Production = ({
  _allEnterpriseSupply,
  _totalCount,
  _currentPage,
  _onPaginate,
  _obj,
  _themeColor,
  _cropStatus
}) => {
  const [_triggerExport, setTriggerExport] = useState(false);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (_currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;

    return _allEnterpriseSupply?.slice(firstPageIndex, lastPageIndex);
  }, [_currentPage, _allEnterpriseSupply]);

  /* Export csv and excel : START */
  const onExportCsv = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "EnterpriseSupplyReport";
    let fileType = "csv";
    let obj = _obj;
    // Ready
    exportCsvFile(exportName, fileType, obj);
  };
  const onExportXlsx = () => {
    // ข้อมูลที่ต้องส่งไปให้ ฟังก์ชั่น ใช้งาน
    let exportName = "EnterpriseSupplyReport";
    let fileType = "excel";
    let obj = _obj;
    // Ready
    exportXlsxFile(exportName, fileType, obj);
  };
  /* Export csv and excel : END */

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.table_container}`}>
        <div className={`${styles.table_box} table-responsive`}>
          <table className={`table table-bordered`}>
            <thead>
              <tr>
                <th>ลำดับ</th>
                <th>รหัสแปลง</th>
                <th>ชื่อ - นามสกุล</th>
                <th>ชนิดเชื้อเพลิง / วัตถุดิบ</th>
                <th>พื้นที่ของเชื้อเพลิง(ไร่)</th>
                <th>วันที่เริ่มเพาะปลูก</th>
                <th>วันที่คาดว่าจะเก็บเกี่ยว</th>
                <th>ผลผลิตที่คาดว่าจะได้รับ (ตัน)</th>
                <th>ผลผลิตที่คาดว่าจะได้รับ (ตัน/ไร่)</th>
                <th>สถานะ</th>
                <th>บันทึกเพิ่มเติม</th>
              </tr>
            </thead>
            <tbody>
              {currentTableData?.map((item, index) => {
                return (
                  <tr key={(_currentPage - 1) * 10 + (index + 1)}>
                    <td>{(_currentPage - 1) * 10 + (index + 1)}</td>
                    <td>{item.parcelId}</td>
                    <td>
                      {item.firstname} {item.lastname}
                    </td>
                    <td>{item.name}</td>
                    <td>{item.area}</td>
                    <td>{convertDateThai(item.plantDate)}</td>
                    <td>{convertDateThai(item.harvestPlan)}</td>
                    <td>{item.yieldTarget}</td>
                    <td>
                      {item.yieldPerAreaRai
                        ? item.yieldPerAreaRai?.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          })
                        : "-"}
                    </td>
                    <td>
                      {_cropStatus?.find((val) => val.id === item.cropStatus)
                        ?.statusName || "-"}
                    </td>
                    <td>{item.note || "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className={`${styles.pagination_}`}>
          <Pagination
            pageSize={pageSize}
            totalCount={_totalCount}
            currentPage={_currentPage}
            onPageChange={(page) => _onPaginate(page)}
            _themeColor={_themeColor}
          />
        </div>
      </div>

      <div className={`${styles.button_right}`}>
        <ExportPopup
          label={"document"}
          trigger={_triggerExport}
          event={{ csv: onExportCsv, xlsx: onExportXlsx }}
        />
        <button
          type="button"
          className={`${styles.btn_}`}
          onClick={() => setTriggerExport(!_triggerExport)}
        >
          Export
        </button>
      </div>
    </div>
  );
};

export default Production;
