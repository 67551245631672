import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  factoryData: {},
  allFactoryData: [],
  factoryStatus: [],
  factoryType: [],
  submitActive: false,
};

export const factorySlice = createSlice({
  name: "factorySlice",
  initialState,
  reducers: {
    setFactoryData(state, { payload }) {
      state.factoryData = payload;
    },
    setAllFactoryData(state, { payload }) {
      state.allFactoryData = payload;
    },
    setFactoryStatus(state, { payload }) {
      state.factoryStatus = payload;
    },
    setFactoryType(state, { payload }) {
      state.factoryType = payload;
    },
    setSubmitActive(state, { payload }) {
      state.submitActive = payload;
    },
  },
});

export const {
  setFactoryData,
  setAllFactoryData,
  setFactoryStatus,
  setFactoryType,
  setSubmitActive,
} = factorySlice.actions;

export const getFactoryData = (state) => state.factorySlice.factoryData;
export const getAllFactoryData = (state) => state.factorySlice.allFactoryData;
export const getFactoryStatus = (state) => state.factorySlice.factoryStatus;
export const getFactoryType = (state) => state.factorySlice.factoryType;
export const getSubmitActive = (state) => state.factorySlice.submitActive;
