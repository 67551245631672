import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import WKT from "ol/format/WKT";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { Fill, Stroke, Style } from "ol/style";
import Header from "../../../layouts/header/Header";
import Navbar from "../../../layouts/navbar/Navbar";
import Footer from "../../../layouts/footer_2/Footer";
import MenuBar from "./MenuBar";
import PowerPlantsDetails from "./power_plants/PowerPlantsDetails";
import FarmerDetails from "./power_plants/FarmerDetails";
import Contract from "./contract_management/contract/Contract";
import TradingRecords from "./contract_management/trading_records/TradingRecords";
import PowerGene from "./power_gene/PowerGene";
import RawMaterial from "./raw_material/RawMaterial";
import Expenses from "./expenses/Expenses";
import MapElement from "../_partials/MapElement";
import FactoryPopup from "../_partials/FactoryPopup";
import TradingPopup from "../_partials/TradingPopup";
import EnterprisePopup from "../_partials/EnterprisePopup";
import OverlayPopup from "../_partials/OverlayPopup";
import Legends from "../_partials/Legends";
import useSwitchChecked from "../../../utils/hooks/useSwitchChecked";
import useSwitchPath from "../../../utils/hooks/useSwitchPath";
import { defaultApi } from "../../../services/api";
import {
  setFactoryData,
  setFactoryStatus,
  setFactoryType,
} from "../../../services/factory.slice";
import { QueryGetAll, QueryGetByID } from "../../../helpers/api.helper";
import { getProfileData, setEditabled } from "../../../services/auth.slice";
import { convertDateThai } from "../../../utils/format/Date.format";
import { getLocalStorageData } from "../../../helpers/local_storage.helper";
import styles from "./scss/OfficerManage.module.scss";

const ManagePowerPlants = () => {
  const dispatchFn_ = useDispatch();
  const profileData = useSelector(getProfileData);
  const { layerId } = useParams();
  const [_location, navigate] = useSwitchPath();
  const [switchChecked, onSwitchChecked] = useSwitchChecked();

  const [_map, setMap] = useState(null);
  const [factory, setFactory] = useState({});
  const [allEnterprise, setAllEnterprise] = useState([]);
  const [enterprise, setEnterprise] = useState({});
  const [allFacMemByFacId, setAllFacMemByFacId] = useState([]);
  const [allEntMemByEntId, setAllEntMemByEntId] = useState([]);
  const [_colors, setColors] = useState([]);
  const [allPlant, setAllPlant] = useState([]);
  const [newPlantArr, setNewPlantArr] = useState([]);
  const [coordFeature, setCoordFeature] = useState(null);
  const [layerType, setLayerType] = useState("factory");
  const [cropStatus, setCropStatus] = useState([]);

  const checkUserCreatedFac = useMemo(() => {
    const datas = getLocalStorageData("allFactory");
    const allFactory = datas;
    const check_ = allFactory?.find(
      (item) => item.createdBy === profileData.id && item.id === +layerId
    );
    return check_?.createdBy;
  }, [profileData, layerId]);
  const checkUserFacMember = useMemo(() => {
    const datas = getLocalStorageData("allFactory");
    const allFactory = datas;
    // สร้างเซตของ factoryId ที่ต้องการตัดออก
    const facIdsToRemove = new Set(allFactory?.map((factory) => factory.id));

    // กรองข้อมูลใน allFacMemByFacId โดยตรวจสอบว่า factoryId อยู่ในเซต factoryIdsToRemove หรือไม่
    const filteredUserData = allFacMemByFacId?.filter((user) =>
      facIdsToRemove?.has(user.factoryId)
    );

    // กรองข้อมูลใน filteredUserData โดยตรวจสอบว่า userId ตรงกับ profileData.id หรือไม่
    const findedUserData = filteredUserData?.find(
      (user) => profileData.id === user.userId
    );

    return findedUserData?.userId;
  }, [allFacMemByFacId, profileData]);
  const _statusName4 = useMemo(() => {
    const newObj = cropStatus?.reduce((acc, item) => {
      acc[item.id] = item.id;

      return acc;
    }, {});

    return newObj;
  }, [cropStatus]);

  const setStrokeColor = useCallback(
    (plant) => {
      let color;
      newPlantArr?.forEach((item) => {
        if (item.name === plant) {
          color = item.color;
        }
      });
      return color;
    },
    [newPlantArr]
  );
  const setFillColor = useCallback(
    (plant) => {
      let color;
      let alpha = 0.6;
      newPlantArr?.forEach((item) => {
        if (item.name === plant) {
          color = item.color;
        }
      });
      // Remove the '#' symbol if present
      color = color?.replace("#", "");

      // Extract the RGB components
      const red = parseInt(color?.substring(0, 2), 16);
      const green = parseInt(color?.substring(2, 4), 16);
      const blue = parseInt(color?.substring(4, 6), 16);

      // Convert alpha to a value between 0 and 1
      alpha = parseFloat(alpha);

      // Create and return the RGBA string
      const rgba = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
      return rgba;
    },
    [newPlantArr]
  );

  const _createLayerCrp = useCallback(
    async (data) => {
      /* Clear crop layers : START */
      _map
        .getLayers()
        .getArray()
        .filter((layer) => layer.get("crpId"))
        .forEach((layer) => _map.removeLayer(layer));
      /* Clear crop layers : END */

      /* Create crop layers : START */
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        const wkt = `${element.geom}`;
        const format = new WKT();
        const feature = format.readFeature(wkt, {
          dataProjection: "EPSG:4326",
          featureProjection: "EPSG:3857",
        });
        const vector = new VectorLayer({
          source: new VectorSource({
            features: [feature],
            loader: () => {
              feature.setProperties({
                crpId: element.id,
                cropStatus: element.cropStatus,
                harvestPlan: convertDateThai(element.harvestPlan),
                plantType: element?.plantType?.name,
              });
            },
          }),
          style: (feature) => {
            const plantType = feature.getProperties().plantType;
            return [
              new Style({
                stroke: new Stroke({
                  color: setStrokeColor(plantType),
                  // lineDash: [4],
                  width: 2,
                }),
                fill: new Fill({
                  color: setFillColor(plantType),
                }),
              }),
            ];
          },
        });
        vector.setProperties({
          crpId: element.id,
          cropStatus: element.cropStatus,
          plantDate: convertDateThai(element.plantDate),
          plantType: element?.plantType?.name,
        });
        _map?.addLayer(vector);
      }
      /* Create crop layers : END */
    },
    [_map, setStrokeColor, setFillColor]
  );

  const getFactory = useCallback(async () => {
    try {
      const res = await defaultApi.getFactory(layerId);
      setFactory(res.data);
      dispatchFn_(setFactoryData(res.data));
      /* ถ้าไอดีผู้สร้างตรงกับไอดีผู้ใช้ถึงจะสามารถแก้ไขข้อมูลได้ */
      if (
        res.data.createdBy === profileData?.id ||
        checkUserFacMember === profileData?.id
      ) {
        dispatchFn_(setEditabled(true));
      } else {
        dispatchFn_(setEditabled(false));
      }
    } catch (error) {
      console.log(error);
    }
  }, [dispatchFn_, layerId, profileData, checkUserFacMember]);
  const getMultipleApi = useCallback(async () => {
    const query = `%24filter=statusCategories%20eq%20%274%27`;

    try {
      const [factoryStatus, factoryType, allPlant, colors, gbStatus] =
        await Promise.all([
          defaultApi.getFactoryStatus(),
          defaultApi.getFactoryType(),
          defaultApi.getAllPlantType(),
          defaultApi.getColors(),
          defaultApi.getGbStatus(query),
        ]);

      dispatchFn_(setFactoryStatus(factoryStatus.data));
      dispatchFn_(setFactoryType(factoryType.data));
      setAllPlant(allPlant.data);
      setColors(colors.data.color);
      setCropStatus(gbStatus.data);
    } catch (error) {
      console.log(error);
    }
  }, [dispatchFn_]);
  const getAllEnterprise = useCallback(async () => {
    if (!layerId) return;
    if (checkUserCreatedFac !== profileData.id) {
      if (checkUserFacMember !== profileData.id) {
        return;
      }
    }
    const qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=factoryId%20eq%20${layerId}`,
      $orderby: "",
    };
    const _queryAll = QueryGetAll(qryGetAll);
    try {
      const res = await defaultApi.getAllEnterprise(_queryAll);
      const datas = res.data.datas;
      const filter = datas.filter((item) => item.geom !== null);
      setAllEnterprise(filter);
    } catch (error) {
      console.log(error);
    }
  }, [layerId, checkUserCreatedFac, checkUserFacMember, profileData]);
  const getAllCrop = useCallback(
    async (id) => {
      const qryGetAll = {
        $count: "%24count=true",
        $expand: "",
        $top: "",
        $skip: "",
        $filter: `&%24filter=enterpriseId%20eq%20${id}`,
        $orderby: "",
      };
      const _queryAll = QueryGetAll(qryGetAll);
      try {
        const res = await defaultApi.getAllCrop(_queryAll);
        const datas = res.data.datas;
        const filter = datas.filter(
          (item) =>
            item.geom !== null && item.cropStatus !== _statusName4?.harvested
        );
        _createLayerCrp(filter);
      } catch (error) {
        console.log(error);
      }
    },
    [_statusName4, _createLayerCrp]
  );
  const getEnterprise = async (entId) => {
    if (!entId) return;
    const qryGetAll = {
      $expand: `%24expand=enterpriseType%2Cfactory%2CenterpriseImageNavigation
      %2CenterpriseDocNavigation`,
      $filter: "",
      $orderby: "",
    };
    const _queryID = QueryGetByID(qryGetAll);
    try {
      const res = await defaultApi.getEnterprise(entId, _queryID);
      setEnterprise(res.data);
      getAllCrop(res.data.id);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllFactoryMember = useCallback(async () => {
    const query = `%24filter=factoryId%20eq%20${layerId}%20
    &%24expand=user`;

    try {
      const res = await defaultApi.getAllFactoryMember(query);

      setAllFacMemByFacId(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [layerId]);
  const getAllEnterpriseMember = useCallback(async () => {
    if (!enterprise?.id) return;

    const query = `%24filter=enterpriseId%20eq%20${enterprise?.id}%20
    &%24expand=user`;

    try {
      const res = await defaultApi.getAllEnterpriseMember(query);

      setAllEntMemByEntId(res.data);
    } catch (error) {
      console.log(error);
    }
  }, [enterprise]);

  useEffect(() => {
    /* Format current data to new data obj : START */
    let arr = [];
    for (let i = 0; i < allPlant.length; i++) {
      const element = allPlant[i];
      const newObj = { name: element.name, color: _colors[i].colorCode };
      arr[i] = newObj;
    }
    setNewPlantArr(arr);
    /* Format current data to new data obj : END */
  }, [allPlant, _colors]);

  useEffect(() => {
    // Use functions
    getFactory();
    getMultipleApi();
    getAllEnterprise();
    getAllFactoryMember();
    getAllEnterpriseMember();
    return () => {
      dispatchFn_(setFactoryData({}));
      dispatchFn_(setEditabled(false));
    };
  }, [
    getFactory,
    getMultipleApi,
    getAllEnterprise,
    getAllFactoryMember,
    getAllEnterpriseMember,
    dispatchFn_,
  ]);

  return (
    <div className={`${styles.container} background_1`}>
      <Header />
      <section className={`section ${styles.section_}`}>
        <Navbar />
        <article className={`article ${styles.article_} row mx-auto`}>
          <aside
            className={`${styles.menu_bar} col-3`}
            style={{
              display: `${
                profileData.id !== checkUserCreatedFac
                  ? profileData.id !== checkUserFacMember
                    ? "none"
                    : ""
                  : ""
              }`,
            }}
          >
            <MenuBar
              switchChecked={switchChecked}
              onSwitchChecked={onSwitchChecked}
              _location={_location}
              _navigate={navigate}
              _layerId={layerId}
            />
          </aside>
          <Routes>
            {/* Manage power plants */}
            <Route
              path="/"
              element={
                <>
                  <aside
                    className={`${styles.map_} ${
                      profileData.id !== checkUserCreatedFac
                        ? profileData.id !== checkUserFacMember
                          ? "col-8"
                          : "col-5"
                        : "col-5"
                    }`}
                  >
                    <div className="map_element">
                      <MapElement
                        onInit={(map) => setMap(map)}
                        _coordFeature={coordFeature}
                        _setLayerType={setLayerType}
                        _setCoordFeature={setCoordFeature}
                      />
                      <FactoryPopup
                        _map={_map}
                        _factory={factory}
                        _setLayerType={setLayerType}
                        _setCoordFeature={setCoordFeature}
                      />
                      <TradingPopup _map={_map} _factory={factory} />
                      <EnterprisePopup
                        _map={_map}
                        _allEnterprise={allEnterprise}
                        _setLayerType={setLayerType}
                        _setCoordFeature={setCoordFeature}
                        _fetchEntId={getEnterprise}
                      />
                      <OverlayPopup
                        _map={_map}
                        _layerType={layerType}
                        _setLayerType={setLayerType}
                        _cropStatus={cropStatus}
                        _statusName4={_statusName4}
                      />
                      {layerType === "enterprise" && (
                        <Legends _newPlantArr={newPlantArr} />
                      )}
                    </div>
                  </aside>
                  <aside className={`${styles.content_details} col-4`}>
                    {layerType === "factory" ? (
                      <PowerPlantsDetails
                        _fetchFacData={getFactory}
                        _fetchFacMember={getAllFactoryMember}
                        _allFacMemByFacId={allFacMemByFacId}
                        _viewDataByUser={
                          profileData.id !== checkUserCreatedFac
                            ? profileData.id !== checkUserFacMember
                              ? true
                              : false
                            : false
                        }
                      />
                    ) : (
                      <FarmerDetails
                        _enterpriseData={enterprise}
                        _allEntMemByEntId={allEntMemByEntId}
                      />
                    )}
                  </aside>
                </>
              }
            />
            {/* End */}
            {/* Manage contact */}
            <Route
              path="/contract"
              element={
                <>
                  {switchChecked === "1" && (
                    <aside className={`${styles.contract_} col-9`}>
                      <Contract />
                    </aside>
                  )}
                  {switchChecked === "2" && (
                    <aside className={`${styles.trading_records} col-9`}>
                      <TradingRecords />
                    </aside>
                  )}
                </>
              }
            />
            {/* End */}
            {/* Manage production */}
            <Route
              path="/production"
              element={
                <aside className={`${styles.power_gene} col-9`}>
                  <PowerGene />
                </aside>
              }
            />
            {/* End */}
            {/* Manage raw-material */}
            <Route
              path="/raw-material"
              element={
                <aside className={`${styles.raw_material} col-9`}>
                  <RawMaterial />
                </aside>
              }
            />
            {/* End */}
            {/* Manage expenses */}
            <Route
              path="expenses"
              element={
                <aside className={`${styles.expenses} col-9`}>
                  <Expenses />
                </aside>
              }
            />
            {/* End */}
          </Routes>
        </article>
      </section>
      <Footer />
    </div>
  );
};

export default ManagePowerPlants;
