import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import icon from "../../../../../../assets/img/Icon";
import { defaultApi } from "../../../../../../services/api";
import Message from "../../../../../../utils/message/Message";
import {
  CheckNumberDot,
  CheckValidLength,
} from "../../../../../../utils/validation_input/Validation";
import MultipleInputEdit from "./MultipleInputEdit";
import styles from "./scss/AddDeed.module.scss";

const EditForm = ({
  parcelData,
  setParcelData,
  setCoordPolygon,
  validErr,
  _inputListEdit,
  _setInputListEdit,
  _themeColor,
  _getPolygonArea,
}) => {
  const [pdfName, setPdfName] = useState("");

  const onChange = (e) => {
    const { name, value } = e.target;
    setParcelData({ ...parcelData, [name]: value });
  };

  const updateFileDetail = async (e) => {
    const file = document.querySelector("#edit_file");
    if (!window.confirm(Message.notify.add_file_update)) {
      file.value = "";
      setPdfName("");
      return;
    } else if (!e.target.files.length) {
      return setParcelData({
        ...parcelData,
        parcelDoc: parcelData?.parcelDoc,
      });
    }

    const loading = toast.loading(Message.notify.add_file_loading);
    const _id = parcelData?.parcelDoc;
    const param1 = "ParcelDoc";
    const param2 = new FormData();
    param2.append("FormFile", e.target.files[0]);

    try {
      const res = await defaultApi.updateFileDetail(_id, param1, param2);
      console.log(res.statusText);
      setParcelData({ ...parcelData, parcelDoc: res?.data.id });
      toast.success(Message.notify.update_success);
      file.value = "";
    } catch (error) {
      console.log(error);
      toast.error(Message.notify.update_error);
    } finally {
      toast.dismiss(loading);
    }
  };

  const getFilePdfName = () => {
    const file = document.querySelector("#edit_file");

    file?.addEventListener("change", () => {
      if (file.value) {
        setPdfName(file.files[0].name);
      } else {
        setPdfName("");
      }
    });
  };

  useEffect(() => {
    // Use functions
    getFilePdfName();
  }, []);
  return (
    <div className={`${styles.input_form}`}>
      <div className={`${styles.input_}`}>
        <label style={{ color: _themeColor?.color }}>
          เลขที่โฉนด <sup className={`${styles.asterisk}`}>*</sup>
        </label>
        <input
          type="text"
          name="parcelNo"
          value={parcelData?.parcelNo || ""}
          className={`${validErr && parcelData.parcelNo === "" ? "error" : ""}`}
          onChange={onChange}
        />
      </div>
      {validErr && (
        <p className={`${styles.err_message}`}>
          {CheckValidLength(parcelData.parcelNo)}
        </p>
      )}
      <div className={`${styles.input_}`}>
        <label style={{ color: _themeColor?.color }}>
          ที่ตั้ง <sup className={`${styles.asterisk}`}>*</sup>
        </label>
        <input
          type="text"
          name="address"
          value={parcelData?.address || ""}
          className={`${validErr && parcelData.address === "" ? "error" : ""}`}
          onChange={onChange}
        />
      </div>
      {validErr && (
        <p className={`${styles.err_message}`}>
          {CheckValidLength(parcelData.address)}
        </p>
      )}
      <div className={`${styles.input_}`}>
        <label style={{ color: _themeColor?.color }}>
          รหัสแปลง <sup className={`${styles.asterisk}`}>*</sup>
        </label>
        <input
          type="text"
          name="parcelId"
          value={parcelData?.parcelId || ""}
          className={`${validErr && parcelData.parcelId === "" ? "error" : ""}`}
          onChange={onChange}
        />
      </div>
      {validErr && (
        <p className={`${styles.err_message}`}>
          {CheckValidLength(parcelData.parcelId)}
        </p>
      )}
      {/* MultipleInput : START */}
      <MultipleInputEdit
        validErr={validErr}
        _setCoordPolygon={setCoordPolygon}
        _inputListEdit={_inputListEdit}
        _setInputListEdit={_setInputListEdit}
        _themeColor={_themeColor}
        _getPolygonArea={_getPolygonArea}
      />
      {/* MultipleInput : END */}
      <div className={`${styles.input_}`}>
        <label style={{ color: _themeColor?.color }}>
          พื้นที่ทั้งหมด <sup className={`${styles.asterisk}`}>*</sup>
        </label>
        <div
          className={`${styles.input_unit} ${
            validErr && parcelData.area === "" ? "error" : ""
          }`}
        >
          <input
            type="text"
            name="area"
            value={parcelData?.area}
            onChange={onChange}
          />
          <div className={`${styles.unit}`}>
            <span style={{ color: _themeColor?.color }}>ไร่</span>
          </div>
        </div>
      </div>
      {validErr && (
        <p className={`${styles.err_message}`}>
          {CheckNumberDot(parcelData.area)}
        </p>
      )}
      <div className={`${styles.input_file}`}>
        <label style={{ color: _themeColor?.color }}>
          แนบเอกสารสิทธิ์แปลงที่ดิน{" "}
          <sup className={`${styles.asterisk}`}>*</sup>
        </label>
        <div
          className={`${styles.input__} ${
            validErr && parcelData.parcelDoc === "" ? "error" : ""
          }`}
        >
          <div className={`${styles.info_box}`}>
            <p>
              {pdfName !== ""
                ? pdfName
                : parcelData?.parcelDocNavigation.fileName}
            </p>
          </div>
          <div className={`${styles.unit}`}>
            <img
              src={icon.document}
              alt=""
              title="แนบไฟล์"
              onClick={() => document.querySelector("#file-label").click()}
            />
          </div>
        </div>
        <input
          type="file"
          name="parcelDoc"
          id="edit_file"
          accept="application/pdf"
          onChange={updateFileDetail}
          hidden
        />
        <label htmlFor="edit_file" id="file-label" hidden></label>
      </div>
      {validErr && (
        <p className={`${styles.err_message}`}>
          {CheckValidLength(parcelData.parcelDoc)}
        </p>
      )}
      <span className={`${styles.desc_text}`}>
        *ระบบรองรับไฟล์ที่มีนามสกุล .pdf และมีขนาดไม่เกิน 5 MB
      </span>
    </div>
  );
};

export default EditForm;
