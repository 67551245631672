import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { QueryGetAll } from "../../../helpers/api.helper";
import { defaultApi } from "../../../services/api";
import { getProfileData } from "../../../services/auth.slice";
import ChartsMonthSalesPlan from "./charts/ChartsMonthSalesPlan";
import ExportPopup from "../../../layouts/exportpopup/ExportPopup";
import { convertDateThai2 } from "../../../utils/format/Date.format";
import styles from "./scss/ReportMonthSalesPlan.module.scss";

const ReportMonthSalesPlan = ({ themeColor }) => {
  const chart = useRef();
  const [_fstLoad, fstLoad] = useState(true);

  const [_setKeyFact, keyFact] = useState({
    entpId: "",
    factId: "",
  });

  const [_resName, resName] = useState(null);
  const [_resItem, resItem] = useState(null);
  const [_resDfSet, resDfSet] = useState(null);

  const [_colors, setColor] = useState({});

  const profileData = useSelector(getProfileData);

  const [_triggerExport, setTriggerExport] = useState(false);
  const [stateMonthYear, setStateMonthYear] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    active: false,
  });

  const [_activeSelect, setActiveSelect] = useState(false)

  window.ChartTitleUnit = {
    title: 'วันที่',
    unit: ['ตัน'],
    totalStatus : true
  }

  const downloadCSV = () => {
    // if (chart && chart.current && chart.current.chart) {
    //   chart.current.chart.downloadCSV();
    // }
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.downloadCSV();
    }
  };

  const downloadImage = () => {
    if (chart && chart.current && chart.current.chart) {
      chart.current.chart.setTitle({ text: "แผนผลการขายเชื้อเพลิง" });
      chart.current.chart.setSubtitle({ text: _resName });

      chart.current.chart.exportChart({
        type: "image/jpeg",
        filename: "chart",
      });

      chart.current.chart.setTitle({ text: "" });
      chart.current.chart.setSubtitle({ text: "" });
    }
  };

  const info = useCallback(async () => {
    let qryGetAll = {
      $count: "%24count=true",
      $expand: "",
      $top: "",
      $skip: "",
      $filter: `&%24filter=createdBy%20eq%20${profileData.id}`,
      $orderby: "",
    };

    const resAllEnp = await defaultApi.getAllEnterpriseMember(``);

    if (resAllEnp?.data?.length > 0) {
      for (let index = 0; index < resAllEnp?.data?.length; index++) {
        const element = resAllEnp?.data[index];
        if (+element?.userId === +profileData?.id) {
          qryGetAll = {
            $count: "%24count=true",
            $expand: "",
            $top: "",
            $skip: "",
            $filter: `&%24filter=id%20eq%20${element.enterpriseId}`,
            $orderby: "",
          };
        }
      }
    }

    const _query = QueryGetAll(qryGetAll);
    const res_entp = await defaultApi.getAllEnterprise(_query);
    const entpId = res_entp.data.datas[0].id;
    const factId = res_entp.data.datas[0].factory.id;
    keyFact({
      entpId: entpId,
      factId: factId,
    });

    let date = new Date();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    const res_dashboard = await defaultApi.getReportDashboard(
      "powergenYear=" +
        year +
        "&factoryTransactionYear=" +
        year +
        "&enterpriseTransactionYear=" +
        year
    );
    const res_item = await defaultApi.getreportEnterpriseTransactionMonth(
      "enterpriseId=" + entpId + "&atMonth=" + month + "&atYear=" + year
    );
    const colors = await defaultApi.getColors();
    setColor(colors);
    resItem(res_item.data);
    resDfSet(res_dashboard.data);
    resName(res_entp.data.datas[0].name);
  }, [profileData]);

  const dateMonth = () => {
    let monlst = [
      "",
      "ม.ค.",
      "ก.พ.",
      "มี.ค.",
      "เม.ย.",
      "พ.ค.",
      "มิ.ย.",
      "ก.ค.",
      "ส.ค.",
      "ก.ย.",
      "ต.ค.",
      "พ.ย.",
      "ธ.ค.",
    ];

    let month = [];

    for (let index = 0; index <= 12; index++) {
      let num = "";
      index < 10 ? (num = "0" + index) : (num = index);
      month[index] = { month: num.toString(), monthName: monlst[index] };
    }

    let optMonths = month.map((data, index) => {
      let i;
      if (index >= 1) {
        i = (
          <option key={index} value={data.month}>
            {data.monthName}
          </option>
        );
      }
      return i;
    });

    return (
      <>
        <option key={`df_0`} style={{ display: "none" }}>
          เดือน
        </option>
        {optMonths}
      </>
    );
  };

  const dateYear = () => {
    let dt = new Date();
    let year = dt.getFullYear();
    let years = [];

    for (let index = 0; index < 10; index++) {
      years[index] = year;
      year--;
    }

    let optYear = years.map((data, index) => {
      return (
        <option key={index} value={data}>
          {data + 543}
        </option>
      );
    });

    return (
      <>
        <option key={`df_0`} style={{ display: "none" }}>
          ปี
        </option>
        {optYear}
      </>
    );
  };

  const iSearch = async () => {
    // const res_dashboard = await defaultApi.getReportDashboard("powergenYear=" + datetime.year + "&factoryTransactionYear=" + datetime.year + "&enterpriseTransactionYear=" + datetime.year)
    const res_item = await defaultApi.getreportEnterpriseTransactionMonth(
      "enterpriseId=" +
        _setKeyFact.entpId +
        "&atMonth=" +
        stateMonthYear.month +
        "&atYear=" +
        stateMonthYear.year
    );
    resItem(res_item.data);
    // resDfSet(res_dashboard.data)

    setStateMonthYear((prev) => ({ ...prev, active: false }));
  };

  const setMonth = (value) => {
    setStateMonthYear((prev) => ({ ...prev, month: value, active: true }));
  };

  const setYear = (value) => {
    setStateMonthYear((prev) => ({ ...prev, year: value, active: true }));
  };

  const dateDefault = useMemo(() => {
    const date = new Date();
    if (_activeSelect) {
      return `${convertDateThai2(
        stateMonthYear.month.toString(),
        "month"
      )}/${convertDateThai2(stateMonthYear.year.toString(), "year")}`;
    } else {
      return `${convertDateThai2(date, "day")}/${convertDateThai2(
        stateMonthYear.month.toString(),
        "month"
      )}/${convertDateThai2(stateMonthYear.year.toString(), "year")}`;
    }
    
  }, [stateMonthYear, _activeSelect]);

  useEffect(() => {
    if (stateMonthYear.active) iSearch();
  }, [stateMonthYear]);

  useEffect(() => {
    if (_fstLoad === true) {
      info();
      fstLoad(false);
    }
  }, [_fstLoad, info]);

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.date_filter}`}>
        <div className={`${styles.group_}`}>
          <select
            style={{ color: "black" }}
            name=""
            id="sel01"
            onChange={(e) => {
              setMonth(e.target.value);
              setActiveSelect(true)
            }}
          >
            {dateMonth()}
          </select>
        </div>
        <div className={`${styles.group_}`}>
          <select
            style={{ color: "black" }}
            name=""
            id="sel02"
            onChange={(e) => {
              setYear(e.target.value);
              setActiveSelect(true)
            }}
          >
            {dateYear()}
          </select>
        </div>
      </div>

      <h4
        className={`${styles.title_text}`}
        style={{ background: themeColor.backgroundColor }}
      >
        แผนผลการขายเชื้อเพลิง
      </h4>

      <h5 className={`${styles.title_text_}`}>{_resName}</h5>

      <div className={`${styles.show_date}`}>
        <p>ข้อมูลล่าสุด ณ วันที่</p>
        <p>{dateDefault}</p>
      </div>

      <div className={`${styles.charts_}`}>
        <ChartsMonthSalesPlan
          ref={chart}
          colors={_colors}
          datain={_resItem}
          dfSet={_resDfSet}
          datetime={stateMonthYear}
        />
      </div>

      <div className={`${styles.export_}`}>
        <ExportPopup
          label={"report"}
          trigger={_triggerExport}
          event={{ csv: downloadCSV, image: downloadImage }}
        />
        <button
          id="exporting"
          type="button"
          className={`${styles.btn_}`}
          onClick={() => {
            setTriggerExport(!_triggerExport);
          }}
        >
          Export
        </button>
      </div>
    </div>
  );
};

export default ReportMonthSalesPlan;
